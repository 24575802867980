<template>
    <div>
        <section class="bg-half-170 bg-light d-table w-100 d-print-none mt-88 mt-sm-73 home-a">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12 text-center">
                        <div class="pages-heading">
                            <h4 class="fz-24 fw-600 mb-0"> Privacy Policy </h4>
                            <ul class="list-unstyled mb-2 minus--38">
                                <li class="list-inline-item h6 date text-muted">
                                    <span class="text-dark">Last Revised :</span> 23th Jan, 2024
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="position-breadcrumb d-flex align-items-center justify-content-center">
                    <nav aria-label="breadcrumb" class="d-inline-block">
                        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                            <li class="breadcrumb-item">
                                <a class="text-theme" @click="$router.push('/')">Qapin</a>
                            </li>
                            <li aria-current="page" class="breadcrumb-item active">Privacy Policy</li>
                        </ul>
                    </nav>
                </div>
            </div>
        </section>
        <div class="relative w-full h-auto overflow-auto bg-white privacy-policy-wrapper">
            <!-- <TheHomeSiteHeader /> -->
            <div class="col-md-12 col-12 mt-10 mb-10 bg-light pt-5">
                <!--<div class="w-full mx-auto xl:mt-24 xl:mx-auto xl:max-w-7xl privacy ">
                    <div class="content-area">
                        <header class="entry-header">
                            <div class="d-flex align-items-center text-white">
                                <div>Privacy Policy</div>
                                <div class="ml-auto align-items-center">
                                    <div class="dl">Effective Date: 30 July 2019</div>
                                </div>
                            </div>
                        </header>
                    </div>
                </div>-->
                <div class="w-full mx-auto xl:mx-auto xl:max-w-7xl " >
                    <b-card no-body>
                        <b-tabs pills card vertical>
                            <b-tab title="Welcome To QAPIN" active>
                                <b-card-text>
                                    <div class="w-full px-4  bg-white xl:px-0 xl:mx-auto ">
                                        <div class="col-md-12 col-12">
                                            <div class="w-full mx-auto xl:mx-auto xl:max-w-7xl privacy">
                                                <div>
                                                    <div class="">
                                                        <div class="terms-content">
                                                            <h3><strong><span>Welcome To QAPIN</span></strong></h3><br>
                                                            <p><span>Below, We Describe In Detail How We Collect, Use And
                                                                    Disclose Your Personal Data, And What Choices You
                                                                    Have With Respect To Your Personal Data. Please Read
                                                                    This Privacy Policy Carefully.</span></p>
                                                            <p><span>If, After Reading It, You Still Have Any Questions,
                                                                    Please <a class="text-theme" href="javascript:void(0)" @click="$router.push({name:'ContactUs',path:'/contact-us'}).catch(()=>{})">Contact</a> Us So That We Could Address Your
                                                                    Concerns.</span></p>
                                                            <div class="table-responsive">
                                                                <table class="table table-bordered" style="color: #777777;">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>1.</td>
                                                                            <td>GENERAL INFORMATION</td>
                                                                            <td>1</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>2.</td>
                                                                            <td>WHAT PERSONAL DATA DO WE COLLECT? </td>
                                                                            <td>2</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>3.</td>
                                                                            <td>FOR WHAT PURPOSES DO WE USE PERSONAL DATA?
                                                                            </td>
                                                                            <td>3</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>4.</td>
                                                                            <td>NON-PERSONAL DATA</td>
                                                                            <td>4</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>5.</td>
                                                                            <td>MARKETING COMMUNICATION</td>
                                                                            <td>5</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>6.</td>
                                                                            <td>RETENTION PERIOD</td>
                                                                            <td>6</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>7.</td>
                                                                            <td>HOW DO WE SHARE AND DISCLOSE DATA?</td>
                                                                            <td>7</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>8.</td>
                                                                            <td>SECURITY</td>
                                                                            <td>8</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>9.</td>
                                                                            <td>AGE LIMITATIONS</td>
                                                                            <td>9</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>10.</td>
                                                                            <td>YOUR RIGHTS REGARDING PERSONAL DATA</td>
                                                                            <td>10</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>11.</td>
                                                                            <td>AMENDMENTS</td>
                                                                            <td>11</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>12.</td>
                                                                            <td>Office Locations</td>
                                                                            <td>12</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </b-card-text>
                            </b-tab>
                            <b-tab title="1. GENERAL INFORMATION">
                                <b-card-text>

                                    <div class="w-full px-4   bg-white   xl:px-0 xl:mx-auto ">
                                        <div class="col-md-12 col-12">
                                            <div class="w-full mx-auto xl:mx-auto xl:max-w-7xl privacy">
                                                <div class="content-inner">
                                                    <h3><span>1. GENERAL INFORMATION</span></h3>
                                                    <p><span>Applicability Of The Privacy Policy. This Privacy Policy (the
                                                            “Privacy Policy”) Governs The
                                                            Processing Of Personal Data Collected From Individual Users
                                                            (“you” And “your”) Through The Online
                                                            Platform “Qapin” Available At http://www.qapin.com, The Related
                                                            Software And Services
                                                            (collectively, “QAPIN”). This Privacy Policy Does Not Apply To
                                                            Any Third-party Applications Or
                                                            Software That Integrate With QAPIN Or Any Other Third-party
                                                            Products, Services Or Businesses.</span>
                                                    </p>
                                                    <p><span>1.2. Responsible Entity (data Controller). The Entity That Is
                                                            Responsible For The Processing Of
                                                            Your Personal Data Through QAPIN Is [to Be Inserted – Company
                                                            Name] Having A Registered Place Of
                                                            Business At [to Be Inserted – Company Address], And A Business
                                                            Registration Number [to Be Inserted –
                                                            Company Number] (“we”, “us”, And “our”).</span></p>
                                                    <p><span>1.3. About QAPIN. QAPIN Is An Online Platform Which Allows
                                                            Individual Users And Business Entities
                                                            To Search And Hire Independent Contractors Providing Quality
                                                            Services In The Field Of Engineering
                                                            (the “Freelancers”), Negotiate And Conclude Service Contracts,
                                                            Pay For The Services Provided By The
                                                            Freelancers, And Provide Feedback. QAPIN Can Be Used By Two
                                                            Types Of Users, Namely, The Users
                                                            Looking For The Services Provided By The Freelancers (the
                                                            “Clients”) And The Freelancers Providing
                                                            Engineering Services.</span></p>
                                                    <p><span>1.4. Term And Termination. This Privacy Policy Enters Into
                                                            Force On The Effective Date Indicated At
                                                            The Top Of The Privacy Policy And Remains Valid Until Terminated
                                                            Or Updated By Us.</span></p>
                                                    <p><span>1.5. Your Consent To The Privacy Policy. Your Use Of QAPIN Is
                                                            Subject To This Privacy Policy.
                                                            Before You Register Your User Account, We Will Ask You To Review
                                                            This Privacy Policy. We Also
                                                            Encourage You To Review The Privacy Policy Before Submitting Any
                                                            Personal Data Through QAPIN. In
                                                            Some Cases, We May Seek To Obtain Your Consent For The
                                                            Processing Of Your Personal Data. For
                                                            Example, We May Seek Your Prior Consent For The Following
                                                            Purposes:</span></p>
                                                    <ul>
                                                        <li> If We Are Required By Law To Do So;</li>
                                                        <li> If We Intend To Collect Other Types Of Personal Data That Are
                                                            Not Mentioned In This Privacy Poli
                                                        </li>
                                                        <li> If We Intend To Use Your Personal Data For The Purposes That
                                                            Are Not Indicated In This Privacy
                                                            Policy;</li>
                                                        <li> If We Would Like To Disclose Or Transfer Your Personal Data To
                                                            Third Parties That Are Not Indicated
                                                            In This Privacy Policy; Or</li>
                                                        <li> If We Significantly Amend This Privacy Policy. </li>
                                                    </ul>
                                                    <p><span>1.6. Cookies. QAPIN Uses Cookies. For Detailed Information On
                                                            Our Use Of Cookies, Please Refer To
                                                            Our Cookie Policy.</span></p>
                                                    <p><span>1.7. Third-party Links. QAPIN May Contain Links To Websites
                                                            Owned And Operated By Third Parties
                                                            (e.g., Social Media Providers). This Privacy Policy Does Not
                                                            Apply To Such Third-party Websites And
                                                            We Are Not Responsible In Any Manner For The Data Protection And
                                                            Security Practices Of Such
                                                            Websites. Please Read Carefully The Privacy Policies Of The
                                                            Respective Third-party Websites Before
                                                            Submitting Any Personal Data To Them And Take Reasonable
                                                            Security Measures Before Using Any
                                                            Third-party Services.</span></p><br>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </b-card-text>
                            </b-tab>
                            <b-tab title="2. WHAT PERSONAL DATA DO WE COLLECT?">
                                <b-card-text>
                                    <div class="w-full px-4   bg-white   xl:px-0 xl:mx-auto ">
                                        <div class="col-md-12 col-12">
                                            <div class="w-full mx-auto xl:mx-auto xl:max-w-7xl privacy">
                                                <div class="content-inner">

                                                    <h3><span>2. WHAT PERSONAL DATA DO WE COLLECT?</span></h3>
                                                    <p><span>2.1. Types Of Personal Data. We Comply With Data Minimization
                                                            Principles And Collect Only A Minimal
                                                            Amount Of Personal Data That Is Necessary For Ensuring Your
                                                            Proper Use Of QAPIN:</span></p>
                                                    <ul>
                                                        <li> When You Register Your User Account, We Collect Your (i) Email
                                                            Address, (ii) First Name, (iii) Last
                                                            Name, (iv) Password, (v) Date Of Birth, And (vi) Location.</li>
                                                        <li>When You Register Your User Account By Using Your Social Media
                                                            Profile, We Collect Any Personal Data
                                                            That You Make Available Through The Settings Of Your Profile
                                                            (e.g., Your Full Name, Photo, Date Of
                                                            Birth, And Location).</li>
                                                        <li> When You Update Your User Account As The Freelancer, We Collect
                                                            Your (i) Image, (ii) Information
                                                            About Your Education And Working Experience, (iii) Any Personal
                                                            Data Provided In Your
                                                            Certifications, Licenses, And Insurance Documents, (iv) Your
                                                            Social Media Links, And (v) Any
                                                            Personal Data That May Be Included In Your Bio Or Portfolio.
                                                        </li>
                                                        <li>When You Post A Job As The Client, We Collect Personal Data That
                                                            May Be Included In Your Job
                                                            Description, Such As Your (i) Company Name And (ii) The Scope Of
                                                            Work.</li>
                                                        <li> When You Create A Company Page, We Collect (i) Company Name,
                                                            (ii) Website URL, (iii) Company Logo,
                                                            And (iv) Any Other Information That You Decide To Provide About
                                                            The Company.</li>
                                                        <li>When You Create A Training Program, We Collect (i) Company Name,
                                                            (ii) Location, (iii) Course Name,
                                                            And (iv) Photo Of The Entity That Provides Training.</li>
                                                        <li>When You Withdraw Money, We Collect Your Mobile Phone Number.
                                                        </li>
                                                        <li>When You Make A Payment Through QAPIN Or We Make A Payout, We
                                                            May Have Access To The Payment Details
                                                            Provided By You, Such As Your (i) PayPal Account Information,
                                                            (ii) Credit Card Number, CVC, And
                                                            Expiration Date, (iii) Bank Account Number, (iv) Billing
                                                            Address, And (v) Payer’s Name.</li>
                                                        <li> When You Contact Us By Email, We Collect Your (i) Name, (ii)
                                                        <!-- <li> When You <a href="javascript:void(0)" class="text-theme" @click="$router.push({name:'ContactUs',path:'/contact-us'}).catch(()=>{})">Contact Us</a> By Email, We Collect Your (i) Name, (ii) -->
                                                            Email Address, And (iii) Any
                                                            Information Or Personal Data That You Decide To Provide Us.</li>
                                                        <li>When You Submit A Review On QAPIN, We Collect Any Information
                                                            And Personal Data That You Decide To
                                                            Provide In Your Review.</li>
                                                        <li> When You Use QAPIN, We Collect Your IP Address And Geo-location
                                                            Information.</li>
                                                        <li>When You Conclude Service Contracts Through QAPIN, We Collect
                                                            Information About Your Transaction.
                                                        </li>
                                                        <li> When You Communicate With Other Users Of QAPIN, We May Have
                                                            Access To Your Communication Carried
                                                            Through Messaging Functionality Available On QAPIN.</li>
                                                    </ul>
                                                    <p><span>2.2. Additional Data. We May Receive Certain Additional Data
                                                            When Submitted Through QAPIN If You
                                                            Participate In A Focus Group, Contest, Activity Or Event,
                                                            Request Support, Interact With Our Social
                                                            Media Accounts, Submit Your Feedback And Reviews Or Otherwise
                                                            Communicate With Us. Please Note That
                                                            The Provision Of Such Data Is Optional And You May Choose What
                                                            Personal Data You Would Like To Share
                                                            With Us. We Kindly Request You To Exercise Your Due Diligence
                                                            When Making Your Personal Data
                                                            Publicly Available. We Will Use Such Personal Data To Reply To
                                                            You, Provide You With The Requested
                                                            Services, Or For Pursuing Our Legitimate Business Interests
                                                            (i.e., To Analyse And Improve Our
                                                            Business).</span></p>
                                                    <p><span>2.3. Professional Data. When You Update Your Profile On QAPIN
                                                            As The Freelancer And Create A
                                                            Portfolio, You Can Submit Certain Professional Information About
                                                            Yourself, Such As The (i) The
                                                            Examples Of Projects Completed By You, (ii) The Types Of
                                                            Services That You Offer, And (iii) The
                                                            Qualifications, Licenses, Insurances, And Certificates Obtained
                                                            By You. Moreover, The Clients May
                                                            Submit Reviews About The Services Offered By The Freelancers.
                                                            Such Service Data Is Used To Ensure
                                                            That Freelancers’ Profiles Are Informative And The Services
                                                            Provided Through QAPIN Are Of A High
                                                            Quality. Please Note That Most Of Such Professional Data Does
                                                            Not Qualify As Personal Data Because
                                                            It Does Not Allow Us To Identify You. However, If Professional
                                                            Data Is Combined With Your Personal
                                                            Data So That You Can Be Identified, We Will Treat Such Data As
                                                            Personal Data And Make Sure That Is
                                                            Used For Legitimate Purposes Only (i.e., To Feature The
                                                            Professional Information On Your
                                                            Profile).</span></p>
                                                    <p><span>2.4. Communication Data. We Put Reasonable Efforts To Ensure
                                                            That Any Communication Data
                                                            Transmitted Through QAPIN Between The Clients And The
                                                            Freelancers Remains Confidential And Properly
                                                            Protected. Moreover, We Do Not Intentionally And Directly
                                                            Access, Manage, Correct, Delete, Share, Or
                                                            Disclose Communication Data, Unless (i) We Are Requested Or
                                                            Authorised By The Clients Or The
                                                            Freelancers To Do So, (ii) Such Communication Data Is Strictly
                                                            Necessary For Provision Of QAPIN, Or
                                                            (iii) We Are Requested By Law Enforcement Agencies To Do
                                                            So.</span></p>
                                                    <p><span>2.5. Sensitive Data. We Do Not Collect Any Special Categories
                                                            Of Personal Data (sensitive Data)
                                                            From You, Such As Your Health Information, Opinion About Your
                                                            Religious And Political Beliefs,
                                                            Racial Origins, Membership Of A Professional Or Trade
                                                            Association, Or Information About Your Sexual
                                                            Orientation, Unless You Decide To Provide Such Sensitive Data,
                                                            At Your Own Sole Discretion.</span>
                                                    </p>
                                                    <p><span>2.6. Payment Processing. All Payments Related To QAPIN Are Made
                                                            Via A Bank Transfer Or Processed By
                                                            Our Third-party Payment Processors PayPal, AliPay, etc – .
                                                            Please Note That The Payments Processors
                                                            May Collect From You Some Personal Data, Which Will Allow Them
                                                            To Make The Payments Requested By You
                                                            (e.g., Your Name, Bank Account Or Credit Card Details). The
                                                            Payment Processors Handle All The Steps
                                                            In The Payment Process Through Their Systems, Including Data
                                                            Collection And Data Processing. We Do
                                                            Not Have Access To Your Payment Data, Unless Such Data Is
                                                            Necessary For Ensuring That The Payment
                                                            Was Successfully Processed Or Maintaining Our Accountancy
                                                            Records. Please Note That The Payment
                                                            Processors Take The Security Of Your Payment Transactions Very
                                                            Seriously And Make Sure That Their
                                                            Infrastructure Is Safe And Secure. For More Information On The
                                                            Privacy Practices And Security
                                                            Measures Taken By The Payment Processors, Please Refer To The
                                                            Website Of The Payment Processor That
                                                            You Have Chosen.</span></p>
                                                    <p><span>2.7. Failure To Provide Personal Data. If You Fail To Provide
                                                            Us With The Personal Data When
                                                            Requested, We May Not Be Able To Perform The Requested Operation
                                                            And You May Not Be Able To Use The
                                                            Full Functionality Of QAPIN, Receive The Services Provided
                                                            Through QAPIN, Or Get Our
                                                            Response.</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-card-text>
                            </b-tab>
                            <b-tab title="3. FOR WHAT PURPOSES DO WE USE PERSONAL DATA?">
                                <b-card-text>

                                    <div class="w-full px-4   bg-white   xl:px-0 xl:mx-auto ">
                                        <div class="col-md-12 col-12">
                                            <div class="w-full mx-auto xl:mx-auto xl:max-w-7xl privacy">
                                                <div class="content-inner">


                                                    <h3><span>3. FOR WHAT PURPOSES DO WE USE PERSONAL DATA?</span></h3>
                                                    <p><span>3.1. We Respect Strictest Data Protection Principles. Thus, We
                                                            Process Your Personal Data Only For
                                                            Specified And Legitimate Purposes Explicitly Mentioned In This
                                                            Privacy Policy. In Short, We Will Use
                                                            Personal Data Only For The Purposes Of Enabling You To Use
                                                            QAPIN, Maintaining And Improving QAPIN,
                                                            Conducting Research About Our Business Activities,
                                                            Administrative Purposes, And Replying To Your
                                                            Enquiries. The Detailed Description Of The Purposes And Legal
                                                            Basis For Processing Of Your Personal
                                                            Data Is Provided Below.</span></p>
                                                    <p><span>3.2. The Service Fees Payable By The Clients. If You Use QAPIN
                                                            As The Client, Each Service Contract
                                                            Is Subject To The Service Fees Payable By You To The Freelancer
                                                            Through QAPIN (the “Service Fees”)
                                                            For The Agreed Project. Please Note That The Service Fees Paid
                                                            By You Through QAPIN Constitute The
                                                            Entire Amount Payable Under The Respective Service Contract And
                                                            The Freelancers That Provide
                                                            Services Under Such Service Contract Must Not Charge You Any
                                                            Additional Fees For The Said Services,
                                                            Unless Agreed Otherwise By You. By Concluding A Service
                                                            Contract, You Agree To Pay The Service Fees
                                                            To The Chosen Freelancer Through QAPIN In Accordance With The
                                                            Terms And Conditions Of The Service
                                                            Contract.</span></p>
                                                    <p><span>3.3. The Commission Payable By The Freelancer. When The Client
                                                            And The Freelancer Conclude A
                                                            Service Contract Through QAPIN For The Project, We Charge The
                                                            Freelancer A 5% Commission Fee (the
                                                            “Commission”) On The Net Amount Of The Total Price Of The
                                                            Project. The Fees Are Deductible
                                                            Automatically From Freelancer’s Balance After The Client
                                                            Approves The Payment. The Fees Are
                                                            Indicated In United States Dollars (USD). By Accepting These
                                                            Terms, The Freelancers Agree To Pay The
                                                            Commission In Accordance With These Terms And The Terms And
                                                            Conditions In Force At The Moment The
                                                            Service Contract Is Concluded Between The Freelancer And The
                                                            Client. The Commission Rates Remain
                                                            Valid For As Long As They Are Indicated On QAPIN. The Freelancer
                                                            Is Solely Responsible For Defining
                                                            The Service Fees And Communicating Them To The Client; Such
                                                            Service Fees Will Be Subject To The
                                                            Commission And The Service Fees Featured On QAPIN To The Client
                                                            Shall Include Freelancer’s Service
                                                            Charges And Our Commission.</span></p>
                                                    <div class="table-responsive">
                                                        <table class="table table-bordered" >
                                                            <tbody>
                                                                <tr>
                                                                    <th colspan="2" style="width: 30%;">Personal Data</th>
                                                                    <th colspan="2" style="width: 30%;">Purpose</th>
                                                                    <th colspan="2" style="width: 40%;">Legal Basis</th>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="6">
                                                                        <h5>When You Register A User Account</h5>
                                                                    </td>
                                                                </tr>
                                                                <tr rowspan="6">
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>First Name</li>
                                                                            <li>Last Name</li>
                                                                            <li>Email Address</li>
                                                                            <li>Password</li>
                                                                            <li>Date Of Birth</li>
                                                                            <li>Location</li>
                                                                        </ul>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>To Create, Verify And Maintain Your User
                                                                                Account</li>
                                                                            <li>To Enable You To Use QAPIN</li>
                                                                            <li>To Contact You, If Necessary</li>
                                                                            <li>To Provide You With The Requested Services
                                                                            </li>
                                                                            <li>To Analyze, Improve, And Evaluate Our
                                                                                Business Activities</li>
                                                                            <li>To Tailor QAPIN To Your Particular Location
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>Performing A Contract With You</li>
                                                                            <li>Pursuing Our Legitimate Business Interests
                                                                                (to Ensure Security, Analyse And
                                                                                Improve Our Business Activities)</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="6">
                                                                        <h5>When You Register Your User Account By Using
                                                                            Your Social Media Profile:</h5>
                                                                    </td>
                                                                </tr>
                                                                <tr rowspan="6">
                                                                    <td colspan="2">Any Personal Data That You Make
                                                                        Available Though The Settings Of Your
                                                                        Profile (e.g., Full Name, Photo, Date Of Birth, And
                                                                        Location).</td>
                                                                    <td colspan="2"></td>
                                                                    <td colspan="2"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="6">
                                                                        <h5>When You Update Your User Account As The
                                                                            Freelancer:</h5>
                                                                    </td>
                                                                </tr>
                                                                <tr rowspan="6">
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>Image</li>
                                                                            <li>Education History</li>
                                                                            <li>Working Experience</li>
                                                                            <li>Certifications, Licenses, And Insurance
                                                                                Documents</li>
                                                                            <li>Social Media Links</li>
                                                                            <li>Bio Or Portfolio</li>
                                                                        </ul>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>To Update Your Account</li>
                                                                            <li>To Feature Your Profile To The Clients</li>
                                                                            <li>To Enable You To Use The Full Functionality
                                                                                Of QAPIN And Conclude Service
                                                                                Contracts</li>
                                                                            <li>To Provide You With The Requested Services
                                                                            </li>
                                                                            <li>To Analyze, Improve, And Evaluate Our
                                                                                Business Activities</li>
                                                                        </ul>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>Performing A Contract With You</li>
                                                                            <li>Your Consent (for Optional Personal Data))
                                                                            </li>
                                                                            <li>Pursuing Our Legitimate Business Interests
                                                                                (to Analyse And Improve Our Business
                                                                                Activities)</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="6">
                                                                        <h5>When You Post A Job As The Client:</h5>
                                                                    </td>
                                                                </tr>
                                                                <tr rowspan="4">
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>Company Name</li>
                                                                            <li>Scope Of The Job</li>
                                                                        </ul>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>To Feature Your Job On QAPIN</li>
                                                                        </ul>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>Performing A Contract With You</li>
                                                                            <li>Your Consent (for Optional Personal Data))
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="6">
                                                                        <h5>When You Create A Company Page:</h5>
                                                                    </td>
                                                                </tr>
                                                                <tr rowspan="6">
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>Company Name</li>
                                                                            <li>Website URL</li>
                                                                            <li>Company Logo</li>
                                                                            <li>Any Other Information That You Decide To
                                                                                Provide About The Company</li>
                                                                        </ul>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>To Feature Company Profile On QAPIN</li>
                                                                            <li>To Contact You, If Necessary</li>
                                                                            <li>To Provide You With The Requested Services
                                                                            </li>
                                                                            <li>To Analyze, Improve, And Evaluate Our
                                                                                Business Activities</li>
                                                                        </ul>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>Performing A Contract With You</li>
                                                                            <li>Pursuing Our Legitimate Business Interests
                                                                                (to Ensure Security, Analyse And
                                                                                Improve Our Business Activities)</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="6">
                                                                        <h5>When You Create A Training Program:</h5>
                                                                    </td>
                                                                </tr>
                                                                <tr rowspan="6">
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>Company Name</li>
                                                                            <li>Location</li>
                                                                            <li>Course Name</li>
                                                                            <li>Photo</li>
                                                                            <li>Any Information That You Decide To Provide
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>To Feature The Training Program On QAPIN
                                                                            </li>
                                                                            <li>To Provide You With The Requested Services
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>Performing A Contract With You</li>
                                                                            <li>Your Consent (for Optional Data)</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="6">
                                                                        <h5>When You Withdraw Money:</h5>
                                                                    </td>
                                                                </tr>
                                                                <tr rowspan="6">
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>Mobile Phone Number</li>
                                                                        </ul>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>To Verify Your Account</li>
                                                                        </ul>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>Pursuing Our Legitimate Business Interests
                                                                                (to Ensure Security)</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="6">
                                                                        <h5>When You Make A Payment Through QAPIN:</h5>
                                                                    </td>
                                                                </tr>
                                                                <tr rowspan="6">
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>Credit Card Number, CVC, And Expiration Date
                                                                            </li>
                                                                            <li>Bank Account Number</li>
                                                                            <li>Billing Address</li>
                                                                            <li>Payer’s Name</li>
                                                                            <li>PayPal Information</li>
                                                                        </ul>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>To Process Your Payment</li>
                                                                            <li>To Maintain Our Business Records</li>
                                                                        </ul>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>Performing A Contract With You</li>
                                                                            <li>Pursuing Our Legitimate Business Interests
                                                                                (to Administer Our Business)</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="6">
                                                                        <h5>When You Contact Us By Email:</h5>
                                                                    </td>
                                                                </tr>
                                                                <tr rowspan="6">
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>Name</li>
                                                                            <li>Email Address</li>
                                                                            <li>Any Information Or Personal Data That You
                                                                                Decide To Provide Us In Your Message
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>To Respond To Your Enquiries</li>
                                                                            <li>To Provide You With The Requested
                                                                                Information</li>
                                                                        </ul>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>Performing A Contract With You</li>
                                                                            <li>Your Consent (for Optional Personal Data)
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="6">
                                                                        <h5>When You Submit A Review:</h5>
                                                                    </td>
                                                                </tr>
                                                                <tr rowspan="6">
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>Any Information Or Personal Data That You
                                                                                Decide To Provide In Your Review</li>
                                                                        </ul>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>To Feature Your Review</li>
                                                                            <li>To Contact You, If Necessary</li>
                                                                            <li>To Ensure Security Of QAPIN</li>
                                                                            <li>To Improve QAPIN</li>
                                                                        </ul>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>Your Consent (optional Personal Data)</li>
                                                                            <li>Pursuing Our Legitimate Business Interests
                                                                                (to Analyze And Improve Our Business)
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="6">
                                                                        <h5>When You Use QAPIN:</h5>
                                                                    </td>
                                                                </tr>
                                                                <tr rowspan="6">
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li> IP Address</li>
                                                                            <li>Geo-location Information</li>
                                                                        </ul>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>To Analyze, Improve, And Evaluate Our
                                                                                Business Activities</li>
                                                                            <li>To Customise QAPIN For Your Location</li>
                                                                        </ul>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>Pursuing Our Legitimate Business Interests
                                                                                (to Analyse And Improve Our Business
                                                                                Activities)</li>
                                                                            <li>Your Consent (for Precise Geo-location
                                                                                Information)</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="6">
                                                                        <h5>When You Conclude Service Contracts Through
                                                                            QAPIN:</h5>
                                                                    </td>
                                                                </tr>
                                                                <tr rowspan="6">
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>Your Transaction Data</li>
                                                                        </ul>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>To Comply With Our Contractual Obligations
                                                                            </li>
                                                                            <li>To Maintain Business Records</li>
                                                                            <li>To Analyse Our Business</li>
                                                                        </ul>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>Performing A Contract With You</li>
                                                                            <li>Pursuing Our Legitimate Business Interests
                                                                                (to Administer, Analyse And Improve
                                                                                Our Business Activities)</li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="6">
                                                                        <h5>When You Communicate With Other Users:</h5>
                                                                    </td>
                                                                </tr>
                                                                <tr rowspan="6">
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>Your Communication Data</li>
                                                                        </ul>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>To Ensure Security Of QAPIN</li>
                                                                            <li>To Comply With Our Contractual Obligations
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                    <td colspan="2">
                                                                        <ul>
                                                                            <li>Performing A Contract With You</li>
                                                                            <li>Pursuing Our Legitimate Business Interests
                                                                                (to Ensure Security, Administer,
                                                                                Analyze And Improve Our Business Activities)
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-card-text>
                            </b-tab>
                            <b-tab title="4. NON-PERSONAL DATA">
                                <b-card-text>

                                    <div class="w-full px-4   bg-white   xl:px-0 xl:mx-auto ">
                                        <div class="col-md-12 col-12">
                                            <div class="w-full mx-auto xl:mx-auto xl:max-w-7xl privacy">
                                                <div class="content-inner">

                                                    <h3><span>4. NON-PERSONAL DATA</span></h3>
                                                    <p><span>4.1. Types Of Non-personal Data. When You Use QAPIN, We May
                                                            Automatically Collect Certain Technical
                                                            Non-personal Data About Your Use Of QAPIN And Your Device For
                                                            Analytics Purposes. Such Non-personal
                                                            Data Does Not Allow Us To Identify You In Any Manner.</span></p>
                                                    <p><span>4.2. Your Feedback. If You <a class="text-theme" href="javascript:void(0)" @click="$router.push({name:'ContactUs',path:'/contact-us'}).catch(()=>{})">Contact Us</a>, We May Keep Records Of
                                                            Any Questions, Complaints Or
                                                            Compliments Made By You And The Response, If Any. Where
                                                            Possible, We Will De-identify Your Personal
                                                            Data. Please Note That De-identified Personal Data Is Also
                                                            Considered To Be Non-personal
                                                            Data.</span></p>
                                                    <p><span>4.3. Purposes Of Non-personal Data. We Will Use Non-personal
                                                            Data In Furtherance Of Our Legitimate
                                                            Interests In Operating QAPIN, Conducting Our Business
                                                            Activities, And Developing New Products And
                                                            Services. More Specifically, We Collect The Non-personal Data
                                                            For The Following Purposes:</span></p>
                                                    <ul>
                                                        <li>To Analyse What Kind Of Users Visit And Use QAPIN;</li>
                                                        <li>To Identify The Channels Through Which QAPIN Is Accessed And
                                                            Used;</li>
                                                        <li>To Examine The Relevance, Popularity, And Engagement Rate Of The
                                                            Content Available On QAPIN;</li>
                                                        <li>To Investigate And Help Prevent Security Issues And Abuse;</li>
                                                        <li>To Develop And Provide Search, Learning, And Productivity Tools
                                                            And Additional Features To QAPIN;
                                                            And</li>
                                                        <li>To Personalise QAPIN For Your Specific Needs.</li>
                                                    </ul>
                                                    <p><span>Aggregated Data. In Case Your Non-personal Data Is Combined
                                                            With Certain Elements Of Your Personal
                                                            Data In A Way That Allows Us To Identify You, We Will Handle
                                                            Such Aggregated Data As Personal Data.
                                                            If Your Personal Data Is Aggregated Or De-identified In A Way
                                                            That It Can No Longer Be Associated
                                                            With An Identified Or Identifiable Natural Person, It Will Not
                                                            Be Considered Personal Data And We
                                                            May Use It For Any Business Purpose.</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </b-card-text>
                            </b-tab>
                            <b-tab title="5. MARKETING COMMUNICATION">
                                <b-card-text>

                                    <div class="w-full px-4   bg-white   xl:px-0 xl:mx-auto ">
                                        <div class="col-md-12 col-12">
                                            <div class="w-full mx-auto xl:mx-auto xl:max-w-7xl privacy">
                                                <div class="content-inner">
                                                    <h3><span>5. MARKETING COMMUNICATION</span></h3>
                                                    <p><span>5.1. Marketing Messages. To Keep You Up-to-date With QAPIN, We
                                                            May Send You Marketing Messages,
                                                            Such As Newsletters, Brochures, Promotions And Advertisements,
                                                            Informing You About Our New Services
                                                            Or New Features Of QAPIN. Please Note That You Will Receive Such
                                                            Marketing Messages Or Be Contacted
                                                            By Us For Marketing Purposes Only If:</span></p>
                                                    <ul>
                                                        <li>We Receive Your Express (“opt-in”) Consent To Receive Marketing
                                                            Messages; Or</li>
                                                        <li>We Decide To Send You Marketing Messages About Our New Services
                                                            That Are Closely Related To The
                                                            Services Already Used By You.</li>
                                                    </ul>
                                                    <p><span>5.2. Opting-out. You Can Opt-out From Receiving Marketing
                                                            Messages At Any Time Free Of Charge By
                                                            Clicking On The “unsubscribe” Link Contained In Any Of The
                                                            Messages Sent To You Or Contacting Us
                                                            Directly.</span></p>
                                                    <p><span>5.3. Informational Notices. From Time To Time (if We Have Your
                                                            Email Address), We May Send You
                                                            Informational Notices, Such As Service-related, Technical Or
                                                            Administrative Emails, Information
                                                            About QAPIN And Your User Account, Your Privacy And Security,
                                                            And Other Important Matters. Please
                                                            Note That We Will Send Such Notices On An “if-needed” Basis And
                                                            They Do Not Fall Within The Scope Of
                                                            Direct Marketing Communication That Requires Your Prior
                                                            Consent.</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </b-card-text>
                            </b-tab>
                            <b-tab title="6. RETENTION PERIOD">
                                <b-card-text>

                                    <div class="w-full px-4   bg-white   xl:px-0 xl:mx-auto ">
                                        <div class="col-md-12 col-12">
                                            <div class="w-full mx-auto xl:mx-auto xl:max-w-7xl privacy">
                                                <div class="content-inner">
                                                    <h3><span>6. RETENTION PERIOD</span></h3>
                                                    <p><span>6.1. Retention Of Personal Data. We Will Store Your Personal
                                                            Data In Our Systems Only For As Long
                                                            As Such Personal Data Is Required For The Purposes Described In
                                                            This Privacy Policy, We Are Obliged
                                                            By Law To Store Such Data For Certain Period Of Time (for
                                                            Example, If We Need To Keep Our
                                                            Accountancy Records), You Request Us To Delete Your Personal
                                                            Data, Or Until You Stop Using QAPIN And
                                                            Deactivate Your User Account - Whichever Comes First. After Your
                                                            Personal Data Is No Longer
                                                            Necessary For Its Purposes And There Is No Other Legal Basis For
                                                            Storing It (e.g., We Are Not
                                                            Obliged By Law To Store Your Personal Data), We Will Immediately
                                                            Delete Your Personal Data From Our
                                                            Systems.</span></p>
                                                    <p><span>6.2. Retention Of Non-personal Data. We May Retain Non-personal
                                                            Data Pertaining To You For As Long
                                                            As Necessary For The Purposes Described In This Privacy Policy.
                                                            This May Include Keeping
                                                            Non-personal Data After You Have Deactivated Your User Account
                                                            For The Period Of Time Needed For Us
                                                            To Pursue Legitimate Business Interests, Conduct Audits, Comply
                                                            With (and Demonstrate Compliance
                                                            With) Legal Obligations, Resolve Disputes And Enforce Our
                                                            Agreements.</span></p>
                                                    <p><span>6.3. Retention As Required By Law. Please Note That, In Some
                                                            Cases, We May Be Obliged By Law To
                                                            Store Your Personal Data For A Certain Period Of Time. In Such
                                                            Cases, We Will Store Your Personal
                                                            Data For The Time Period Stipulated By The Applicable Law And
                                                            Delete The Personal Data As Soon As
                                                            The Required Retention Period Expires.</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </b-card-text>
                            </b-tab>
                            <b-tab title="7. HOW DO WE SHARE AND DISCLOSE DATA?">
                                <b-card-text>

                                    <div class="w-full px-4   bg-white   xl:px-0 xl:mx-auto ">
                                        <div class="col-md-12 col-12">
                                            <div class="w-full mx-auto xl:mx-auto xl:max-w-7xl privacy">
                                                <div class="content-inner">
                                                    <h3><span>7. HOW DO WE SHARE AND DISCLOSE DATA?</span></h3>
                                                    <p><span>7.1. Do We Share Your Personal Data? If Necessary, We May
                                                            Disclose Your Personal Data To The
                                                            Service Providers With Whom We Cooperate And Other Third Parties
                                                            (our Data Processors). For Example,
                                                            We May Share Your Personal And Non-personal Data With Entities
                                                            That Provide Certain Technical
                                                            Support Services To Us, Such As Web Analytics, Advertising,
                                                            Email Distribution, And Developing
                                                            Services, Or If You Explicitly Request Us To Disclose The
                                                            Personal Data.</span></p>
                                                    <p><span>7.2. When Do We Share Your Personal Data? The Disclosure Of
                                                            Your Personal Data Is Limited To The
                                                            Situations When Such Data Is Required For The Following
                                                            Purposes:</span></p>
                                                    <ul>
                                                        <li>Ensuring The Operation Of QAPIN;</li>
                                                        <li>Ensuring The Delivery Of The Services Requested By You;</li>
                                                        <li>Providing You With The Requested Information;</li>
                                                        <li>Processing Payments;</li>
                                                        <li>Pursuing Our Legitimate Business Interests;</li>
                                                        <li>Enforcing Our Rights, Preventing Fraud, And Security Purposes;
                                                        </li>
                                                        <li>Carrying Out Our Contractual Obligations;</li>
                                                        <li>Law Enforcement Purposes; Or</li>
                                                        <li>If You Provide Your Prior Consent To Such A Disclosure.</li>
                                                    </ul>
                                                    <p><span>7.3. List Of Data Processors. We Will Share Your Personal Data
                                                            Only With The Third Parties That
                                                            Agree To Ensure An Adequate Level Of Protection Of Personal Data
                                                            That Is Consistent With This
                                                            Privacy Policy And The Applicable Data Protection Laws. The
                                                            Third Parties (data Processors) That May
                                                            Have Access To Your Personal Data Are Listed Below.</span></p>
                                                    <div class="table-responsive">
                                                        <table class="table table-bordered custom-table" style="color: #777777">
                                                            <tbody>
                                                                <tr>
                                                                    <th>Service</th>
                                                                    <th>Name</th>
                                                                    <th>Location</th>
                                                                    <th>More Information</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>Database Service Provider</td>
                                                                    <td>MySQL</td>
                                                                    <td>The United Sates</td>
                                                                    <td><a class="text-theme"
                                                                            href="http://www.mysql.com">http://www.mysql.com</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Business Analytics Service Provider</td>
                                                                    <td>Google Analytics</td>
                                                                    <td>The United States</td>
                                                                    <td><a class="text-theme"
                                                                            href="http://www.mysql.com">http://analytics.google.com</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Advertising Service Provider</td>
                                                                    <td>Google AdSense</td>
                                                                    <td>The United States</td>
                                                                    <td><a class="text-theme"
                                                                            href="http://www.google.com/adsense">http://www.google.com/adsense</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Transactional Email Service Provider</td>
                                                                    <td>SendGrid</td>
                                                                    <td>The United States</td>
                                                                    <td><a class="text-theme"
                                                                            href="http://sendgrid.com">http://sendgrid.com</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td rowspan="4" style="vertical-align: middle;">Payment
                                                                        Service Providers</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>PayPal</td>
                                                                    <td>The United States</td>
                                                                    <td><a class="text-theme"
                                                                            href="http://www.paypal.com">http://www.paypal.com</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>AliPay</td>
                                                                    <td>China</td>
                                                                    <td><a class="text-theme"
                                                                            href="http://intl.alipay.com">http://intl.alipay.com</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>[to be inserted]</td>
                                                                    <td>[to be inserted]</td>
                                                                    <td>[to be inserted]</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <p><span>7.4. Sharing Of Non-personal Data. We May Disclose Non-personal
                                                            Data And De-identified Data For Any
                                                            Purpose. For Example, We May Share It With Prospects Or Partners
                                                            For Business Or Research Purposes,
                                                            For Improving QAPIN, Responding To Lawful Requests From Public
                                                            Authorities Or Developing New
                                                            Products And Services.</span></p>
                                                    <p><span>7.5. Legal Requests. If Necessary, We Will To Disclose
                                                            Information About The Users Of QAPIN To The
                                                            Extent Necessary For Pursuing A Public Interest Objective, Such
                                                            As National Security Or Law
                                                            Enforcement.</span></p>
                                                    <p><span>7.6. Successors. In Case Our Business Is Sold Partly Or Fully,
                                                            We Will Provide Your Personal Data
                                                            To A Purchaser Or Successor Entity And Request The Successor To
                                                            Handle Your Personal Data In Line
                                                            With This Privacy Policy.</span></p>
                                                    <p><span>7.7. Transfer Of Personal Data Outside The EEA. We And Some Of
                                                            Our Data Processors Listed In
                                                            Section 7 Of This Privacy Policy Are Located Outside The
                                                            European Economic Area (EEA) And, If You
                                                            Reside In The EEA, We May Need To Transfer Your Personal Data To
                                                            Jurisdictions Outside The EEA. In
                                                            Case It Is Necessary To Make Such A Transfer, We Will Make Sure
                                                            That The Jurisdiction In Which The
                                                            Recipient Third Party Is Located Guarantees An Adequate Level Of
                                                            Protection For Your Personal Data
                                                            (e.g., The Country In Which The Recipient Is Located Is
                                                            White-listed By The European Commission Or
                                                            The Recipient Is A Privacy-Shield Certified Entity) Or We
                                                            Conclude An Agreement With The Respective
                                                            Third Party That Ensures Such Protection (e.g., A Data
                                                            Processing Agreement Based On The Standard
                                                            Contractual Clauses Provided By The European Commission).</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </b-card-text>
                            </b-tab>
                            <b-tab title="8. SECURITY">
                                <b-card-text>

                                    <div class="w-full px-4   bg-white   xl:px-0 xl:mx-auto ">
                                        <div class="col-md-12 col-12">
                                            <div class="w-full mx-auto xl:mx-auto xl:max-w-7xl privacy">
                                                <div class="content-inner">
                                                    <h3><span>8. SECURITY</span></h3>
                                                    <p><span>8.1. Our Security Measures. We Put Our Best Efforts To Keep
                                                            Your Personal Data Safe And Secure. We
                                                            Implement Organisational And Technical Information Security
                                                            Measures To Protect Your Personal Data
                                                            From Loss, Misuse, Unauthorised Access, And Disclosure. The
                                                            Security Measures Taken By Us Include
                                                            Secured Networks, Limited Access To Your Personal Data By Our
                                                            Staff, And Anonymisation Of Personal
                                                            Data (when Possible). In Order To Ensure The Security Of Your
                                                            Personal Data, We Kindly Ask You To
                                                            Use QAPIN Through A Secure Network Only.</span></p>
                                                    <p><span>8.2. Handling Security Breaches. Although We Put Our Best
                                                            Efforts To Protect Your Personal Data,
                                                            Given The Nature Of Communications And Information Processing
                                                            Technology And The Internet, We Cannot
                                                            Be Liable For Any Unlawful Destruction, Loss, Use, Copying,
                                                            Modification, Leakage, And Falsification
                                                            Of Your Personal Data Caused By Circumstances That Are Beyond
                                                            Our Reasonable Control. In Case A
                                                            Personal Data Breach Occurs, We Will Immediately Take Reasonable
                                                            Measures To Mitigate The Breach, As
                                                            Required By The Applicable Law. Our Liability For Any Security
                                                            Breach Will Be Limited To The Highest
                                                            Extent Permitted By The Applicable Law.</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </b-card-text>
                            </b-tab>
                            <b-tab title="9. AGE LIMITATIONS">
                                <b-card-text>

                                    <div class="w-full px-4   bg-white   xl:px-0 xl:mx-auto ">
                                        <div class="col-md-12 col-12">
                                            <div class="w-full mx-auto xl:mx-auto xl:max-w-7xl privacy">
                                                <div class="content-inner">

                                                    <h3><span>9. AGE LIMITATIONS</span></h3>
                                                    <p><span>9.1. We Do Not Allow Anyone Younger Than 18 Years Old To Use
                                                            QAPIN. Thus, We Do Not Knowingly
                                                            Collect Personal Data Of Persons Below The Age Of 18. If You
                                                            Learn That Anyone Younger Than 18 Has
                                                            Unlawfully Provided Us With Personal Data And You Are A Parent
                                                            Or Legal Guardian Of That Person,
                                                            Please Contact Us And We Will Take Immediate Steps To Delete
                                                            <!-- Please <a href="javascript:void(0)" class="text-theme" @click="$router.push({name:'ContactUs',path:'/contact-us'}).catch(()=>{})">Contact Us</a> And We Will Take Immediate Steps To Delete -->
                                                            Such Personal Data.</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </b-card-text>
                            </b-tab>
                            <b-tab title="10. YOUR RIGHTS REGARDING PERSONAL DATA">
                                <b-card-text>

                                    <div class="w-full px-4   bg-white   xl:px-0 xl:mx-auto ">
                                        <div class="col-md-12 col-12">
                                            <div class="w-full mx-auto xl:mx-auto xl:max-w-7xl privacy">
                                                <div class="content-inner">
                                                    <h3><span>10. YOUR RIGHTS REGARDING PERSONAL DATA</span></h3>
                                                    <p><span>10.1. What Rights Do You Have? Subject To Any Exemptions
                                                            Provided By Law, You May Ask Us To:</span>
                                                    </p>
                                                    <ul>
                                                        <li>Get A Copy Of Your Personal Data That We Store;</li>
                                                        <li>Get A List Of Purposes For Which Your Personal Data Is
                                                            Processed;</li>
                                                        <li>Correct Inaccurate Personal Data;</li>
                                                        <li>Move Your Personal Data To Another Processor;</li>
                                                        <li>Delete Your Personal Data From Our Systems;</li>
                                                        <li>Object And Restrict Processing Of Your Personal Data;</li>
                                                    </ul>
                                                    <p><span>10.2. How To Exercise Your Rights? If You Would Like To
                                                            Exercise Your Rights Listed Above, Please
                                                            Contact Us By Email At info@qapin.com And Explain In Detail Your
                                                            <!-- <a href="javascript:void(0)" class="text-theme" @click="$router.push({name:'ContactUs',path:'/contact-us'}).catch(()=>{})">Contact Us</a> By Email At info@qapin.com And Explain In Detail Your -->
                                                            Request. In Order To Verify The
                                                            Legitimacy Of Your Request, We May Ask You To Provide Us With An
                                                            Identifying Piece Of Information,
                                                            So That We Would Be Able To Identify You In Our System. We Will
                                                            Answer Your Request Within A
                                                            Reasonable Timeframe But No Later Than 2 Weeks.</span></p>
                                                    <p><span>10.3. How To Launch A Complaint? If You Would Like To Launch A
                                                            Complaint About The Way In Which We
                                                            Handle Your Personal Data, We Kindly Ask You To Contact Us First
                                                            And Express Your Concerns. After
                                                            You Contact Us, We Will Investigate Your Complaint And Provide
                                                            <!-- You <a href="javascript:void(0)" class="text-theme" @click="$router.push({name:'ContactUs',path:'/contact-us'}).catch(()=>{})">Contact Us</a>, We Will Investigate Your Complaint And Provide -->
                                                            You With Our Response As Soon As
                                                            Possible. If You Are Not Satisfied With The Outcome Of Your
                                                            Complaint, You Have The Right To Lodge A
                                                            Complaint With Your Local Data Protection Authority.</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </b-card-text>
                            </b-tab>

                            <b-tab title="11. AMENDMENTS">
                                <b-card-text>

                                    <div class="w-full px-4   bg-white   xl:px-0 xl:mx-auto ">
                                        <div class="col-md-12 col-12">
                                            <div class="w-full mx-auto xl:mx-auto xl:max-w-7xl privacy">
                                                <div class="content-inner">
                                                    <h3><span>11. AMENDMENTS</span></h3>
                                                    <p><span>11.1. The Privacy Policy May Be Changed From Time To Time To
                                                            Address The Changes In Laws,
                                                            Regulations, And Industry Standards.</span></p>
                                                    <p><span>11.2. The Amended Version Of The Privacy Policy Will Be Posted
                                                            On This Page With A New Effective
                                                            Date And, If We Have Your Email Address, We Will Send You A
                                                            Notice About All The Changes Implemented
                                                            By Us. We Encourage You To Review Our Privacy Policy To Stay
                                                            Informed.</span></p>
                                                    <p><span>11.3. For Significant Material Changes In The Privacy Policy
                                                            Or, Where Required By The Applicable
                                                            Law, We May Seek Your Consent. If You Disagree With The Changes
                                                            To The Privacy Policy, You Should
                                                            Cease Using QAPIN.</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </b-card-text>
                            </b-tab>

                        </b-tabs>
                    </b-card>
                </div>

            </div>
            <!-- <TheHomeSiteFooter class="" v-if="!isAuth" /> -->
        </div>
    </div>
</template>


<script>
// import TheHomeSiteHeader from "../../components/TheHomeSiteHeader.vue";
// import TheHomeSiteFooter from "./TheHomeSiteFooter.vue";

export default {
    components: {
        // TheHomeSiteFooter,
        // TheHomeSiteHeader
    },
    data() {
    return {
      isAuth: null,
    }
  },
  mounted() {
    this.isAuth = localStorage.getItem("token");
    if (this.$route.name == "ExpireLink") {
      this.isAuth = null;
    }
    this.getCurrentUserDetails();
  },

}
</script>
<style  lang="css">
.custom-table td {
    padding: 10px 10px !important;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.table-bordered td,
.table-bordered th {
    border: 1px solid #dee2e6;
}

.privacy {
    margin: 0px 0px 50px;

}

.privacy .content-area {
    padding: 10px;
    border: 2px dashed #f2613c;
}

.privacy .entry-header {
    background-color: #f2613c;
    padding: 10px;
    color: #333;
    margin-bottom: 2px;
}

.privacy li {
    font-size: 16px;
    text-align: justify;
    font-weight: 400;
    color: #404040;
    margin-bottom: 10px;
    list-style: disc;
    margin-left: 0px;
}

.privacy h3 {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 24px;
}

.privacy p span {
    font-size: 15px;
    text-align: justify;
    font-weight: 400;
    color: #404040;
}

.privacy p {
    text-align: justify;
}
.privacy-policy-wrapper .nav-pills .nav-link.active,
.privacy-policy-wrapper .nav-pills .show>.nav-link {
    color: #f2613c;
    background-color: #fff;
    border-left: 3px solid #f2613c;
    border-radius: 0px;
}

.privacy-policy-wrapper .nav-pills .nav-link {
  color: #333;
}

.privacy-policy-wrapper .tab-content{

    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;

}
.privacy-policy-wrapper .card{
    border: 0 !important;
}
.privacy-policy-wrapper .card-header {
    padding: 2.5rem 1rem;

}
.privacy-policy-wrapper table th {
    background-color: #eee!important;
    color: #333;
    padding: 10px 12px!important;
}
</style>