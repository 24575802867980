<template>
    <b-card-text>
        <b-card-group deck>
            <b-card class="py-4 px-4 bg-white br-20 mt-2">
                <h4 class="mb-0 text-black fz-24 fw-500">
                    <b>My Teams</b>
                </h4>
                <hr>
                <b-row>
                    <b-col xl="6" lg="6" sm="6" class="text-left">
                        <h4 class="mb-0 fz-18"><strong>Team name</strong></h4>
                    </b-col>
                    <b-col xl="6" lg="6" sm="6" class="text-left">
                        <h4 class="mb-0 fz-18"><strong>Action</strong></h4>
                    </b-col>
                    <b-col xl="12" lg="12" sm="12">
                        <hr class="mt-0 mb-0">
                    </b-col>
                </b-row>
                <template v-if="teamData.length > 0">
                    <template v-for="(team, index) in teamData" >
                        <b-row :key="index" >
                        <!-- <b-row :key="index" v-if="team.ownAgency == 0"> -->
                            <b-col xl="6" lg="6" sm="6">
                                <h5 class="text-black mb-0 fw-600 fz-18">{{team.first_name}}</h5>
                            </b-col>
                            <b-col xl="6" lg="6" sm="6">
                                <b-dropdown size="lg" id="dropdown-right" right variant="link" toggle-class="text-decoration-none" no-caret class="mt-0 float-none">
                                    <template #button-content>
                                    <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 2C4 3.10457 3.10457 4 2 4C0.895431 4 0 3.10457 0 2C0 0.895431 0.895431 0 2 0C3.10457 0 4 0.895431 4 2ZM11 2C11 3.10457 10.1046 4 9 4C7.89543 4 7 3.10457 7 2C7 0.895431 7.89543 0 9 0C10.1046 0 11 0.895431 11 2ZM16 4C17.1046 4 18 3.10457 18 2C18 0.895431 17.1046 0 16 0C14.8954 0 14 0.895431 14 2C14 3.10457 14.8954 4 16 4Z" fill="#666666"/>
                                    </svg>
                                    </template>
                                    <b-dropdown-item v-b-modal.modal-Leaveteam @click="CheckTeamdata(team.login_master_id)" v-if="team.ownAgency == 0">Leave Team</b-dropdown-item>
                                </b-dropdown>
                            </b-col>
                        </b-row>
                    </template >
                </template>
            </b-card>
        </b-card-group>
        <!-- model for confirm reject -->
        <b-modal id="modal-Leaveteam" title="Leave Agency" centered hide-footer size="md" no-close-on-backdrop> 
            <template>
                <div class="p-5 text-center m-auto">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#F2613C" class="bi bi-trash m-auto" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                    </svg>
                    <h5 class="mt-4" v-if="removeAgencyStatus">{{'Are you sure, Want to Leave this Agency?'}}</h5>
                    <h5 class="mt-4" v-else>{{"You Can't Leave this Agency?"}}</h5>
                </div>
                <p v-if="!removeAgencyStatus">{{"The member has  active contracts or active proposals or active offer."}}</p>

                <hr>
                <div>
                    <div class="text-right">
                        <a href="javascript:void(0);" class="text-theme mr-2" @click="$bvModal.hide('modal-Leaveteam')">Cancel</a>
                        <b-button type="button" class="btn btn-theme" v-if="removeAgencyStatus" @click="RemoveAgency()">Confirm</b-button>
                    </div>
                </div>
            </template>
        </b-modal>
    </b-card-text>
</template>
<script>
    import { mapActions } from "vuex";
    export default {
        // mixins: [validationMixin],
        validations: { 
            
        },
        data() {
            return {
                teamData                   : [],
                removeAgencyStatus         : false,
                agencyId                   : '',
            };
        },
        computed: {
        }, 
        mounted() {
            if(this.$route.query.current == 4){
                this.getAssociateWithagency();
            }
        },
        methods: {
            ...mapActions(["genericAPIPOSTRequest"]),
            // this fun is for to get associated agency
            async getAssociateWithagency() {
                var res = await this.genericAPIPOSTRequest({
                    requestUrl: "associateWithagency",
                    params: {
                        'type' : 'agencyList',
                    },
                });
                
                if(res){
                    this.teamData = res;
                }
            },
            // fun is for to check any active contract/active offer/submit proposal
            async CheckTeamdata(_i){
                this.agencyId = _i;
                var res = await this.genericAPIPOSTRequest({
                    requestUrl: "checkContractActivities",
                    params: {
                        agency_id   : this.agencyId,
                    },
                });
                if(res){
                    this.agencyId = (res == 0)?this.agencyId:'';
                }
                this.removeAgencyStatus = res;

            },
            // fun is for to remove agency from team
            async RemoveAgency(){
                var res = await this.genericAPIPOSTRequest({
                    requestUrl: "leaveAgency",
                    params: {
                        agency_id   : this.agencyId,
                    },
                });
                if(res){
                    this.getAssociateWithagency();
                    this.$toastr.s('Leave Agency Successfully');
                }else{
                    this.getAssociateWithagency();
                    this.$toastr.e("Can't Leave Agency");
                }
                this.getCompanyCount();
                this.$bvModal.hide('modal-Leaveteam');
            },
            async getCompanyCount() {
                var res = await this.genericAPIPOSTRequest({
                    requestUrl: "company/getCompanyCount",
                    params: { data: this.getLoginUserIdRequest() },
                });
                this.$store.dispatch("listAccount", res);
            },
        }
    };
</script>