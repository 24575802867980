import { render, staticRenderFns } from "./Globalhrservices.vue?vue&type=template&id=6cf320e8&scoped=true"
import script from "./Globalhrservices.vue?vue&type=script&lang=js"
export * from "./Globalhrservices.vue?vue&type=script&lang=js"
import style0 from "./Globalhrservices.vue?vue&type=style&index=0&id=6cf320e8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cf320e8",
  null
  
)

export default component.exports