import { render, staticRenderFns } from "./QapinUse.vue?vue&type=template&id=4bc4f316&scoped=true"
import script from "./QapinUse.vue?vue&type=script&lang=js"
export * from "./QapinUse.vue?vue&type=script&lang=js"
import style0 from "./QapinUse.vue?vue&type=style&index=0&id=4bc4f316&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bc4f316",
  null
  
)

export default component.exports