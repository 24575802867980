<template>
    <b-modal  centered no-close-on-backdrop id="edit-category-modal" size="lg" :title="(is_primary==false && category_data_new.length == 0) ? 'Add Categories' : (is_primary==false && category_data_new.length > 0)?'Edit Categories':(is_primary==true && category_data_new.length == 0)?'Add Main Categories':'Edit Main Categories'" >
        <div class="text-left">
            <h5 class="fz-20 fw-600 text-black">What are the main services you offer to clients? </h5>
            <p class="fz-14 fw-400 text-muted" v-if="is_primary != true">Select up to {{categoryData.length}} categories.</p>
            <div class="fz-14 tags mb-0">
                <template v-for="(catName,cids) in category_data_new">
                    <div  :key="cids" class="up-skill-badge inline-block pt-1" v-if="is_primary == true && catName.is_primary =='Y'">
                        <div class="d-flex align-items-center">
                            {{catName.name}}
                        </div>
                    </div>
                    <div  :key="cids" class="up-skill-badge inline-block pt-1" v-else-if="is_primary == false && catName.is_primary =='Y'">
                        <div class="d-flex align-items-center" >
                            {{catName.name}}
                        </div>
                    </div>
                    <div  :key="cids" class="up-skill-badge inline-block pt-1" v-else-if="is_primary == false && catName.is_primary =='N'">
                        <div class="d-flex align-items-center">
                            <!-- {{(catName.is_primary== (is_primary==true?'N':'Y'))? catName.name :catName.name}}    -->
                            {{catName.name}}
                            <a href="javascript:" class="text-muted">
                                <div class="up-icon xs pt-1">
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-2" v-on:click="removefromCategoryarr(cids)"><rect width="0.878423" height="8.78423" rx="0.439212" transform="matrix(0.703924 0.710276 -0.703924 0.710276 9.22266 3.08838)" fill="#F2613C"></rect><rect width="0.878423" height="8.78423" rx="0.439212" transform="matrix(0.703929 -0.71027 0.703929 0.71027 3.03906 3.7124)" fill="#F2613C"></rect></svg>
                                </div>
                            </a>
                        </div>
                    </div>
                </template>
            </div>
            <div class="accordion mt-3" v-if="categoryData.length > 0">
            <div  v-for="(category,id) in categoryData" :key="id">
                <b-button  class="d-block mb-0" v-b-toggle="`collapse-${id}`" v-on:click="addCategoryArr(category.qp_role_master_id,category.role_name)">{{category.role_name }}
                <!-- <i class="fa fa-chevron-down float-right fw-400 text-muted fz-12"></i> -->
                </b-button>
                <!-- <b-collapse :id="`collapse-${id}`">
                <b-card>
                    <div class="mb-30 tags">
                    <span class="up-skill-badge" v-for="(skill,skid) in category.skills" :key="skid">
                        {{skill.skill_name}}
                    </span>
                    </div>
                </b-card>
                </b-collapse> -->
            </div>
            </div>
        </div>
        <template #modal-footer>
            <div class="w-100 text-right">
            <a href="javascript:void(0);" class="text-theme mr-2"  @click="$bvModal.hide('edit-category-modal')">Cancel</a>
            <b-button
                size="lg"
                class="btn btn-theme"
                @click="updateCategory()"
            >
                Save
            </b-button>
            </div>
        </template>
    </b-modal>
</template>
<script>

import { mapActions } from "vuex";
export default {
  props:['is_primary','category_data'], 
  data() {
    return {
        categoryData        : [],
        category_ids        : [],
        category_data_new   : [],
    };
  },
  mounted() {
    this.category_data_new = this.category_data;
    this.getCategory();
  },
  methods: {
    ...mapActions(["genericAPIPOSTRequest"]),
    // this fun is for to get category list
    async getCategory(){
        var res = await this.genericAPIPOSTRequest({
            requestUrl  : "common/getQpRoleMasterList",
            params      : {},
        });
        this.categoryData = res;
    },
    // this fun is for to update category
    async updateCategory(){
        var response = await this.genericAPIPOSTRequest({
            requestUrl: "updateCategory",
            params: {'role_id': this.category_ids,'is_primary': this.is_primary,'data': this.getLoginUserIdRequest()},
        });
        if(response){
            this.$toastr.s('categorys successfully Updated');
            var res =   await this.genericAPIPOSTRequest({
                requestUrl: "user/getUserDetails",
                params: {
                    'data'    : this.getLoginUserIdRequest(),
                },
            });
            this.$emit('userDetail',res);
            this.$bvModal.hide('edit-category-modal');
        }else{
            this.$toastr.e('Categorys Not Updated');
            this.$bvModal.hide('edit-category-modal');
        }
    },
    removefromCategoryarr(index){
        if(this.category_data_new.length > 1){
          this.category_data_new.splice(index, 1); 
          this.category_ids.splice(index, 1); 
          // \\OR   
          // this.$delete(this.category_data,index);
          // \\both will do the same
        }
    },
    addCategoryArr(_i,_v){
        var arr ={'id':_i,'name':_v,'is_primary':(this.is_primary==true)?'Y':'N'};
        if(this.is_primary){ 
            this.category_data_new = [];
            this.category_ids  = []; 
            if(!this.category_data_new.some(data => data.id === _i)){
                this.category_data_new.push(arr);     
            }
            if(!this.category_ids.some(data => data === _i)){
                this.category_ids.push(_i);     
            }
        }else if(!this.is_primary){
            if(!this.category_data.some(data => data.id === _i)){
                this.category_data.push(arr);     
            }
            if(!this.category_ids.some(data => data === _i)){
                this.category_ids.push(_i);     
            }
        }
    },
  },
};
</script>