import { render, staticRenderFns } from "./NewsFeed.vue?vue&type=template&id=7c1be4dd&scoped=true"
import script from "./NewsFeed.vue?vue&type=script&lang=js"
export * from "./NewsFeed.vue?vue&type=script&lang=js"
import style0 from "./NewsFeed.vue?vue&type=style&index=0&id=7c1be4dd&prod&lang=css"
import style1 from "./NewsFeed.vue?vue&type=style&index=1&id=7c1be4dd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c1be4dd",
  null
  
)

export default component.exports