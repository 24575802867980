<template>
        <div class="relative w-full h-auto overflow-auto bg-white home-a">
    <div class="w-full pt-12 mb-20 xl:pt-40 md:pt-20 xl:px-0  css-144ctnr">
            <div class="md:justify-between md:flex w-full mx-auto xl:mx-auto xl:max-w-7xl">
                <div>
                    <div class=""><span class="css-79e8z3">Certifications List</span></div>
                    <h1
                        class="w-full max-w-2xl text-4xl fw-600 xl:pt-6 md:pt-24 md:max-w-lg xl:max-w-2xl xl:text-6xl css-1wfjlp">
                        Certifications List</h1>
                    <div class=" xl:mt-1">
                        <p class="w-full max-w-2xl text-4xl fw-400 md:max-w-lg xl:max-w-2xl xl:text-2xl css-1plhbgk"
                            style="font-size: 20px; line-height: 1.3; font-family: inherit;">
                            Get source inspectors of skills and globally recognized certificates</p>
                            <!-- Get source inspectors of skills and <a href="javascript:void(0)" @click="$router.push({name:'demoPage',path:'/demo'}).catch(()=>{})">globally</a> recognized certificates</p> -->
                    </div>
                    <div class="items-center mt-10 space-y-4 md:space-x-4 md:space-y-0 md:flex"><button @click="$router.push('/client/dashboard').catch(()=>{})"
                            class="w-full px-4 py-2 text-base text-white transition-all duration-500 ease-in-out rounded-md shadow-sm cursor-pointer md:w-40 hover:bg-opacity-50 bg-primary-500 hover:shadow-lg">
                            Post Job </button><br>
                        <button @click="$router.push('/freelancer').catch(()=>{})"
                            class="w-full px-4 py-2 text-base text-black transition-all duration-500 ease-in-out border-solid rounded-md shadow-sm cursor-pointer md:w-40 border-1 hover:text-white border-primary-500 hover:bg-primary-500 hover:shadow-lg">
                            Find Job </button>
                    </div>
                </div>
                <div class="mt-24 md:mt-0">
          <img src="web/Research paper-pana.svg" alt="" class="object-contain h-96 w-100"  />
          </div>
            </div>
        </div>
        <!-- <TheHomeSiteHeader /> -->
        <div class="w-full mx-auto xl:mx-auto mt-5" style="max-width: 80rem;">
            <div class="container master-tag">
                <div id="w-node-_412574db-0221-58f3-f7ef-37cfdc222117-e5ae7e41" class="column">
                    <h1 class="flex justify-center text-3xl text-center text-black xl:text-4xl xl:text-left fw-bold "> Qapin
                        lets enterprises to find talent source globally in minutes</h1>
                        <!-- lets enterprises to find talent source <a href="javascript:void(0)" @click="$router.push({name:'demoPage',path:'/demo'}).catch(()=>{})">globally</a> in minutes</h1> -->
                    <!-- <p class="margin-15 text-center ">Global expansion is essential for any business, but
                        navigating the right talent never been easier, and onboarding takes a long time, costs a lot of
                        money and requires collaboration across many agencies. Qapin streamlines the entire process with
                        simple tool and source experts for every jobs. </p>
                    <p class="margin-15 text-center">Get source inspectors of skills and globally
                        recognized certificates </p> -->
                </div>
            </div>
        </div>
        <div class="w-full pt-5 mx-auto xl:mx-auto" style="max-width: 80rem;">
            <div class="md:justify-between md:space-x-10 md:flex">
                <div class="mt-24 md:mt-0">
                    <ul class="enterprise-table mb-0 list-none flex-wrap pb-4 flex-row">
                        <li class="-mb-px mr-2 last:mr-0 mb-2 flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(1)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 1, 'text-white bg-pink-600': openTab === 1 }">
                                ARAMCO
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2  flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(2)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 2, 'text-white bg-pink-600': openTab === 2 }">
                                AWS
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2  flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(3)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 3, 'text-white bg-pink-600': openTab === 3 }">
                                TWI
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2  flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(4)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 4, 'text-white bg-pink-600': openTab === 4 }">
                                API
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2  flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(5)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 5, 'text-white bg-pink-600': openTab === 5 }">
                                ASME
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2  flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(6)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 6, 'text-white bg-pink-600': openTab === 6 }">
                                NACE
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2  flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(7)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 7, 'text-white bg-pink-600': openTab === 7 }">
                                AUDITORS
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2  flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(8)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 8, 'text-white bg-pink-600': openTab === 8 }">
                                SIX SEGMA
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2  flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(9)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 9, 'text-white bg-pink-600': openTab === 9 }">
                                NEBOSH
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2  flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(10)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 10, 'text-white bg-pink-600': openTab === 10 }">
                                FROSIO
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2  flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(11)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 11, 'text-white bg-pink-600': openTab === 11 }">
                                SSPC
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2  flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(12)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 12, 'text-white bg-pink-600': openTab === 12 }">
                                ICORR
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2  flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(13)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 13, 'text-white bg-pink-600': openTab === 13 }">
                                IRATA
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2  flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(14)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 14, 'text-white bg-pink-600': openTab === 14 }">
                                COMPEX - OK
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2  flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(15)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 15, 'text-white bg-pink-600': openTab === 15 }">
                                GWO
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2  flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(16)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 16, 'text-white bg-pink-600': openTab === 16 }">
                                IOSH
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2  flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(17)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 17, 'text-white bg-pink-600': openTab === 17 }">
                                OSHA
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2  flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(18)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 18, 'text-white bg-pink-600': openTab === 18 }">
                                PMI
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2  flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(19)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 19, 'text-white bg-pink-600': openTab === 19 }">
                                PMI
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2  flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(20)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 20, 'text-white bg-pink-600': openTab === 20 }">
                                LEEA
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2  flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(21)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 21, 'text-white bg-pink-600': openTab === 21 }">
                                OPITO
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2  flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(22)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 22, 'text-white bg-pink-600': openTab === 22 }">
                                ECITB
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2  flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(23)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 23, 'text-white bg-pink-600': openTab === 23 }">
                                MARITIME
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2  flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(24)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 24, 'text-white bg-pink-600': openTab === 24 }">
                                EEMUA
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2 flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(25)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 25, 'text-white bg-pink-600': openTab === 25 }">
                                IMAS
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2 flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(26)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 26, 'text-white bg-pink-600': openTab === 26 }">
                                EEHA
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2 flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(27)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 27, 'text-white bg-pink-600': openTab === 27 }">
                                AKER Coabis
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2 flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(28)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 28, 'text-white bg-pink-600': openTab === 28 }">
                                PED
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2 flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(29)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 29, 'text-white bg-pink-600': openTab === 29 }">
                                NDT-EN473
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2 flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(30)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 30, 'text-white bg-pink-600': openTab === 30 }">
                                NDT-EN4179
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2 flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(31)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 31, 'text-white bg-pink-600': openTab === 31 }">
                                NDT-ACCP
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2 flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(32)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 32, 'text-white bg-pink-600': openTab === 32 }">
                                NDT-ASNT
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2 flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(33)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 33, 'text-white bg-pink-600': openTab === 33 }">
                                NDT - ISO 9712
                            </a>
                        </li>
                        <li class="-mb-px mr-2 last:mr-0 mb-2 flex-auto text-center">
                            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                                v-on:click="toggleTabs(34)"
                                v-bind:class="{ 'text-pink-600 bg-white': openTab !== 34, 'text-white bg-pink-600': openTab === 34 }">
                                NDT - NAS410
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="w-full mx-auto xl:mx-auto" style="max-width: 80rem;">
                    <div class="md:justify-between md:space-x-10 md:flex">

                        <div
                            class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded enterprise-right">
                            <div class="flex-auto table-box" style="margin: 20px;">
                                <div class="tab-content tab-space">
                                    <div v-bind:class="{ 'hidden': openTab !== 1, 'block': openTab === 1 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>
                                                    <th colspan="2">ARAMCO</th>
                                                    <tr>
                                                        <td>Electrical-General</td>
                                                        <td>QM01</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Instrumentation-General</td>
                                                        <td>QM02</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Mechanical-General</td>
                                                        <td>QM03</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Non-Destructive Examination</td>
                                                        <td>QM04</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Line Pipe</td>
                                                        <td>QM05</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Fabricated Piping</td>
                                                        <td>QM06</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Valves</td>
                                                        <td>QM07</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Fittings</td>
                                                        <td>QM08</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Gaskets</td>
                                                        <td>QM09</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Marine Hoses</td>
                                                        <td>QM10</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Coatings-Critical</td>
                                                        <td>QM11</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Coatings-Non-Critical</td>
                                                        <td>QM12</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Lumber</td>
                                                        <td>QM13</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Fasteners</td>
                                                        <td>QM14</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Structural Steels</td>
                                                        <td>QM15</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Transformers</td>
                                                        <td>QM16</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Cables</td>
                                                        <td>QM17</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Cables (Pantograth cables, etc)</td>
                                                        <td>QM18</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Motor Control Center-MCC</td>
                                                        <td>QM19</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Switchgear</td>
                                                        <td>QM20</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Generators & Motors</td>
                                                        <td>QM21</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Generators and Motors</td>
                                                        <td>QM22</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Diesel and Gas Engines</td>
                                                        <td>QM23</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Aircraft Re-fuelers &de-fuel</td>
                                                        <td>QM24</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Cranes</td>
                                                        <td>QM25</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Rotating Equipment-Pumps</td>
                                                        <td>QM26</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Rotating Equipment-Compress</td>
                                                        <td>QM27</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Rotating Equipment-Turbines</td>
                                                        <td>QM28</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Rotating Equipment-Gears</td>
                                                        <td>QM29</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Pressure Vessels</td>
                                                        <td>QM30</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Heat Exchangers</td>
                                                        <td>QM31</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Boilers</td>
                                                        <td>QM32</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Heaters</td>
                                                        <td>QM33</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Tanks</td>
                                                        <td>QM34</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Skid Mounted Equipment-Mech</td>
                                                        <td>QM35</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Skid Mounted Equipment-Elect</td>
                                                        <td>QM36</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Relief Valves, Control Valves</td>
                                                        <td>QM37</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Meter Skids, Prover Loops</td>
                                                        <td>QM38</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Process Control Systems</td>
                                                        <td>QM39</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Distributed Control System-DC</td>
                                                        <td>QM40</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Oil Country Tubular Goods-OC</td>
                                                        <td>QM41</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Wellhead Equipment</td>
                                                        <td>QM42</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Quality System Assessor</td>
                                                        <td>QM43</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>HVAC</td>
                                                        <td>QM44</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Vehicle and Accessories</td>
                                                        <td>QM45</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Switch rack</td>
                                                        <td>QM46</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Panel Board</td>
                                                        <td>QM47</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Cathodic Protection</td>
                                                        <td>QM48</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Remote Terminal Unit -RTU</td>
                                                        <td>QM49</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Emergency Shutdown Sys.ESD</td>
                                                        <td>QM50</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Compressor/Turbine Control Systems</td>
                                                        <td>QM51</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Compressor/Turbine Vibration Monitoring </td>
                                                        <td>QM52</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Marshaling Cabinets </td>
                                                        <td>QM53</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 2, 'block': openTab === 2 }">

                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">AWS</th>

                                                    <tr>
                                                        <td>Certified Associate welding inspector</td>
                                                        <td>AWS CAWI</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Certified Welding Inspector</td>
                                                        <td>AWS CWI</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Senior Certified Welding Inspector</td>
                                                        <td> SCWI</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Certified Welding Educator</td>
                                                        <td>AWS CWE</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Certified Radiographic Interpreter</td>
                                                        <td>AWS CRI</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Certified Welding Supervisor</td>
                                                        <td>AWS CWS</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Certified Welding Sales Representative</td>
                                                        <td>AWS CWSR</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Certified Welding Engineer</td>
                                                        <td>AWS CWEng</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Certified Robotic Arc Welder</td>
                                                        <td>CRAW</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 3, 'block': openTab === 3 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">TWI</th>

                                                    <tr>
                                                        <td>Visual Welding Inspector</td>
                                                        <td>CSWIP 3.0</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Welding Inspector</td>
                                                        <td>CSWIP 3.1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Senior Welding Inspector</td>
                                                        <td>CSWIP 3.2.1</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Senior Welding Inspector</td>
                                                        <td>CSWIP 3.2.2</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Welding Quality Control Co-Ordinator</td>
                                                        <td>CSWIP QCC</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Plant Inspector Level 1</td>
                                                        <td>CSWIP PI Level 1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Plant Inspector Level 2</td>
                                                        <td>CSWIP PI Level 2</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Plant Inspector Level 3</td>
                                                        <td>CSWIP PI Level 3</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Underwater (Diver) Inspector - Grade 3.1U </td>
                                                        <td>CSWIP 3.1U</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Underwater (Diver) Inspector - Grade 3.2U</td>
                                                        <td>CSWIP 3.2U</td>
                                                    </tr>
                                                    <tr>
                                                        <td>ROV Inspector</td>
                                                        <td>CSWIP 3.3U</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Underwater Inspection Controller</td>
                                                        <td>CSWIP 3.4U</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Underwater (Diver) Inspector (Concrete)- 3.1UC </td>
                                                        <td>CSWIP 3.1UC</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Underwater (Diver) Inspector (Concrete)- 3.2UC</td>
                                                        <td>CSWIP 3.2UC</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Welding Instructor</td>
                                                        <td>CSWIP WI</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Senior Welding Instructor</td>
                                                        <td>CSWIP SWI</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Welding Supervisor</td>
                                                        <td>CSWIP CS</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Cathodic Protection Personnel Level 1</td>
                                                        <td>CSWIP CPP Level 1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Cathodic Protection Personnel Level 2</td>
                                                        <td>CSWIP CPP Level 2</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Cathodic Protection Personnel Level 3</td>
                                                        <td>CSWIP CPP Level 3</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Thermographic Inspector Level 1</td>
                                                        <td>CSWIP TI Level 1</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Quantitative Thermographic Inspector Level 2</td>
                                                        <td>CSWIP QTI Level 2</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Senior Thermographic Inspector Level 3</td>
                                                        <td>CSWIP STI Level 3</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>CSWIP Welding Examiner </td>
                                                        <td>CSWIP Welding Examiner</td>
                                                    </tr>
                                                    <tr>
                                                        <td>CSWIP Brazing Examiner </td>
                                                        <td>CSWIP Brazing Examiner</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>CSWIP Welding Technical Representative </td>
                                                        <td>CSWIP WTR</td>
                                                    </tr>
                                                    <tr>
                                                        <td>CSWIP Supervisor of FRP plate </td>
                                                        <td>CSWIP SFRP</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>CSWIP Welding Examiners for Vehicle Repair (Automotive Sector)
                                                        </td>
                                                        <td>CSWIP WEVR (Automotive)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>CSWIP Plastics Welders Level 1</td>
                                                        <td>CSWIP PW Level 1</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>CSWIP Plastics Welders Level 2</td>
                                                        <td>CSWIP PW Level 2</td>
                                                    </tr>
                                                    <tr>
                                                        <td>CSWIP Master Welder</td>
                                                        <td>CSWIP MW</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>CSWIP Heat Treatment Operator Level 1</td>
                                                        <td>CSWIP HTO Level 1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>CSWIP Heat Treatment Operator Level 2</td>
                                                        <td>CSWIP HTO Level 2</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>CSWIP Heat Treatment Operator Level 3</td>
                                                        <td>CSWIP HTO Level 3</td>
                                                    </tr>
                                                    <tr>
                                                        <td>BGAS Senior Pipeline Inspector Grade 1 </td>
                                                        <td>BGAS SPI Grade 1</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>BGAS Senior Pipeline Inspector Grade 2</td>
                                                        <td>BGAS SPI Grade 2</td>
                                                    </tr>
                                                    <tr>
                                                        <td>BGAS Senior Welding Inspector </td>
                                                        <td>BGAS SWI</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>BGAS Welding Inspector </td>
                                                        <td>BGAS WI</td>
                                                    </tr>
                                                    <tr>
                                                        <td>BGAS Ultrasonic Inspector Plate </td>
                                                        <td>BGAS UI(Plate)</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>BGAS Mill Coating Inspector </td>
                                                        <td>BGAS MCI </td>
                                                    </tr>
                                                    <tr>
                                                        <td>BGAS Site Coating Inspector </td>
                                                        <td>BGAS SCI</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>BGAS Painting Inspector Grade 1 </td>
                                                        <td>BGAS PI Grade 1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>BGAS Painting Inspector Grade 2</td>
                                                        <td>BGAS PI Grade 2</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>BGAS Painting Supervisor Grade 4 </td>
                                                        <td>BGAS PS Grade 4 </td>
                                                    </tr>
                                                    <tr>
                                                        <td>BGAS Agricultural/Environmental Inspector </td>
                                                        <td>BGAS A/E Inspector </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>BGAS Radiographer </td>
                                                        <td>BGAS Radiographer</td>
                                                    </tr>
                                                    <tr>
                                                        <td>BGAS Radiographic Assistant</td>
                                                        <td>BGAS Radiographic Assistant</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 4, 'block': openTab === 4 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">API</th>
                                                    <tr>
                                                        <td>Pipeline pigging inline inspection for integrity assessment</td>
                                                        <td>API 1163 </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Fitness for Services</td>
                                                        <td>API 579</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Pipeline Construction Inspector</td>
                                                        <td>API 1169</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Pressure Vessel Inspector</td>
                                                        <td>API 510</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Piping Inspector</td>
                                                        <td>API 570</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Corrosion and Materials</td>
                                                        <td>API 571</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Welding Inspection and Metallurgy</td>
                                                        <td>API 577</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Risk Based Inspection</td>
                                                        <td>API 580-</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Aboveground Storage Tank Inspector</td>
                                                        <td>API 653</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Refractory Personnel</td>
                                                        <td>API 936</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Auditor - Q1</td>
                                                        <td>API AQ1</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Auditor - Q2</td>
                                                        <td>API AQ2</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Internal Auditor - Q1</td>
                                                        <td>API IAQ1</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Internal Auditor - Q2</td>
                                                        <td>API IAQ2</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Lead Auditor - Q1</td>
                                                        <td>API LAQ1</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Lead Auditor - Q2</td>
                                                        <td>API LAQ2</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Qualification of UT Examiners (Phased Array)</td>
                                                        <td>API QUPA</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Qualification of UT Examiners (Sizing)</td>
                                                        <td>API QUSE</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Qualification of UT Examiners (Crack Sizing)</td>
                                                        <td>API QUSEPA</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Qualification of UT Examiners (Detection)</td>
                                                        <td>API QUTE</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Qualification of UT Examiners (Thickness Measurement)</td>
                                                        <td>API QUTETM</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Source Inspector - Electrical Equipment</td>
                                                        <td>API SIEE</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Source Inspector - Fixed Equipment</td>
                                                        <td>API SIFE</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Source Inspector - Rotating Equipment</td>
                                                        <td>API SIRE</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Tank Entry Supervisor</td>
                                                        <td>API TES</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 5, 'block': openTab === 5 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">ASME</th>
                                                    <tr>
                                                        <td>Authorized Inspector </td>
                                                        <td>ASME AI</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Authorized Nuclear Inservice Inspector</td>
                                                        <td>ASME I</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Authorized Nuclear Inspector (Concrete) (C)</td>
                                                        <td>ASME C</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Authorized Nuclear Inspector (N) </td>
                                                        <td>ASME N</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Authorized Nuclear Inspector Supervisor (NS)</td>
                                                        <td>ASME NS</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Inservice Inspector Commission (IC)</td>
                                                        <td>ASME IC</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Inspector Supervisor(B)</td>
                                                        <td>ASME B</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Inspector Supervisor(O)</td>
                                                        <td>ASME O</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Pressure Relief Valve Repair (VR)</td>
                                                        <td>ASME VR</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Repair Inspector (R)</td>
                                                        <td>ASME R</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Boiler and Pressure Vessel Repair (RO)</td>
                                                        <td>ASME RO</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Pressure Equipment Inspector (PEI)</td>
                                                        <td>ASME PEI</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Commissioned inspector</td>
                                                        <td>ASME CI </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 6, 'block': openTab === 6 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">NACE</th>

                                                    <tr>
                                                        <td>NACE CIP Level 1</td>
                                                        <td>NACE CIP Level 1</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>NACE CIP Level 2</td>
                                                        <td>NACE CIP Level 2</td>
                                                    </tr>
                                                    <tr>
                                                        <td>NACE CIP Level 3</td>
                                                        <td>NACE CIP Level 3</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>NACE CP Technologist </td>
                                                        <td>NACE CP Technologist </td>
                                                    </tr>
                                                    <tr>
                                                        <td>NACE CP Specialist</td>
                                                        <td>NACE CP Specialist</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>NACE Refining Corrosion Technologist</td>
                                                        <td>NACE RCT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>NACE Corrosion Technologist</td>
                                                        <td>NACE CT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>NACE Senior Corrosion Technologist</td>
                                                        <td>NACE SCT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>NACE Internal Corrosion Technologist</td>
                                                        <td>NACE ICT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>NACE PCIM Technologist</td>
                                                        <td>NACE PCIM Technologist</td>
                                                    </tr>
                                                    <tr>
                                                        <td>NACE Senior Internal Corrosion Technologist</td>
                                                        <td>NACE SICT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>NACE Chemical Treatment Specialist</td>
                                                        <td>NACE CTS</td>
                                                    </tr>
                                                    <tr>
                                                        <td>NACE Corrosion Specialist</td>
                                                        <td>NACE CS</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>NACE Internal Corrosion Specialist</td>
                                                        <td>NACE ICS</td>
                                                    </tr>
                                                    <tr>
                                                        <td>NACE Material Selection/Design Specialist</td>
                                                        <td>NACE MSDS</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>NACE Protective Coating Specialist</td>
                                                        <td>NACE PCS</td>
                                                    </tr>
                                                    <tr>
                                                        <td>NACE CP Tester </td>
                                                        <td>NACE CP Tester </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>NACE CP Technician </td>
                                                        <td>NACE CP Technician </td>
                                                    </tr>
                                                    <tr>
                                                        <td>NACE CP Technician – Maritime </td>
                                                        <td>NACE CP Technician – Maritime </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>NACE Corrosion Technician</td>
                                                        <td>NACE Corrosion Technician</td>
                                                    </tr>
                                                    <tr>
                                                        <td>NACE OCAT Technician</td>
                                                        <td>NACE OCAT Technician</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>NACE Protective Coating Technician</td>
                                                        <td>NACE Protective Coating Technician</td>
                                                    </tr>
                                                    <tr>
                                                        <td>NACE SCAT Technician</td>
                                                        <td>NACE SCAT Technician</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>NACE PCIM Technician</td>
                                                        <td>NACE PCIM Technician</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 7, 'block': openTab === 7 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">Auditors</th>
                                                    <tr>
                                                        <td>Food Safety Management System</td>
                                                        <td>ISO 22000 Lead Auditor </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Food Safety Management System</td>
                                                        <td>ISO 22000 Internal Auditor</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Food Safety Management System (FSSC 22000)</td>
                                                        <td>FSSC 22000 Lead Auditor</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Occupational Health and Safety Management</td>
                                                        <td>ISO 45001 Internal Auditor</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Occupational Health and Safety Management</td>
                                                        <td>ISO 45001 Lead Auditor</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Occupational Health and Safety Management</td>
                                                        <td>ISO 45001 Lead Implementer</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Quality Management System </td>
                                                        <td>ISO 9001 Lead Auditor</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Quality Management System </td>
                                                        <td>ISO 9001 Internal Auditor</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Quality Management System </td>
                                                        <td>ISO 9001 Lead Implementer</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Environmental Management System </td>
                                                        <td>ISO 14001 Internal Auditor </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Environmental Management System </td>
                                                        <td>ISO 14001 Lead Auditor </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Environmental Management System </td>
                                                        <td>ISO 14001 Lead Implementer</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Occupational Health and Safety </td>
                                                        <td>ISO 18001 Lead Auditor</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Occupational Health and Safety </td>
                                                        <td>ISO 18001 Internal Auditor </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Information Security Management Systems</td>
                                                        <td>ISO 27001 Internal Auditor</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Information Security Management Systems</td>
                                                        <td>ISO 27001 Lead Auditor</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Information Security Management Systems</td>
                                                        <td>ISO 27001 Lead Implementer </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>IT Service Management Systems</td>
                                                        <td>ISO 20000 Lead Auditor</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IT Service Management Systems</td>
                                                        <td>ISO 20000 Lead Implementer </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>IT Service Management Systems</td>
                                                        <td>ISO 20000 Internal Auditor</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Business Continuity Management System </td>
                                                        <td>ISO 22301 Internal Auditor </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Business Continuity Management System </td>
                                                        <td>ISO 22301 Lead Auditor</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Business Continuity Management System </td>
                                                        <td>ISO 22301 Lead Implementer</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Energy Management System </td>
                                                        <td>ISO 50001 Internal Auditor </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Energy Management System </td>
                                                        <td>ISO 50001 Lead Auditor </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Energy Management System </td>
                                                        <td>ISO 50001 Lead Implementer</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Electronics Industry Citizenship Coalition</td>
                                                        <td>EICC Provisional auditor</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Electronics Industry Citizenship Coalition</td>
                                                        <td>EICC auditor </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Electronics Industry Citizenship Coalition</td>
                                                        <td>EICC Lead Auditor </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>PQMS Internal Auditor (ISO 19011)</td>
                                                        <td>ISO 19011 Lead Auditor </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Aerospace Quality Management </td>
                                                        <td>AS/EN/JISQ 9100 Lead Auditor </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Aerospace Quality Management </td>
                                                        <td>AS/EN/JISQ 9100 Internal Auditor</td>
                                                    </tr>
                                                    <tr>
                                                        <td>AS9110C Lead Auditor </td>
                                                        <td>AS/EN 9110C Lead Auditor </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>AS9120B Lead Auditor</td>
                                                        <td>AS/EN 9120B Lead Auditor </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Maritime Safety Management Systems </td>
                                                        <td>ISM Lead Auditor </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Pharmaceutical Quality Management Systems </td>
                                                        <td>PQMS Lead Auditor </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Social Management Systems</td>
                                                        <td>SA8000 Lead Auditor </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Quality Management system Medical Devise</td>
                                                        <td>ISO 13485 Lead Auditor </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Quality Management system Medical Devise</td>
                                                        <td>ISO 13485 Internal Auditor</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Laboratory Quality System </td>
                                                        <td>ISO 17025 Internal Auditor</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Laboratory Quality System </td>
                                                        <td>ISO 17025 Lead Auditor</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Automotive Quality Management System</td>
                                                        <td>IATF 16949 Lead Auditor </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Automotive Quality Management System</td>
                                                        <td>IATF 16949 Certified First and Second Party Auditor</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>IRCA Maritime Safety & Security Management Systems Auditor</td>
                                                        <td>Maritime Safety & Security Management Systems Auditor</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Quality Management In The Automotive Industry</td>
                                                        <td>VDA 6.1 Internal Auditor</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Quality Management in The Automotive Industry</td>
                                                        <td>VDA 6.1 Auditor</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Quality Management for Service Providers in Automotive Industry
                                                        </td>
                                                        <td>VDA 6.2 Internal Auditor</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Quality Management for Service Providers in Automotive Industry
                                                        </td>
                                                        <td>VDA 6.2 IAuditor</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Process Auditor in Automotive Industry </td>
                                                        <td>VDA 6.3 Internal Auditor </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Process Auditor in Automotive Industry </td>
                                                        <td>VDA 6.3 Auditor </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Quality Management for Production Equipment Suppliers in
                                                            Automotive
                                                            Industry</td>
                                                        <td>VDA 6.4 Internal Auditor </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Quality Management for Production Equipment Suppliers in
                                                            Automotive
                                                            Industry</td>
                                                        <td>VDA 6.4 Auditor </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Quality Management Systems in the rail industry</td>
                                                        <td>ISO/TS 22163 Lead Auditor </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Eco-Management and Audit Scheme </td>
                                                        <td>EMAS Auditor </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Risk management requirements for medical devices</td>
                                                        <td>ISO 14971</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Quality Management System for Medicinal Packaging Materials
                                                            Suppliers
                                                        </td>
                                                        <td>ISO 15378 </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Good Manufacturing Practices (GMP) for cosmetics</td>
                                                        <td>ISO 22716</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Quality management for healthcare organizations</td>
                                                        <td>DIN EN 15224</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Quality Manage system for Telecommunications </td>
                                                        <td>TL 9000 Lead Auditor </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Quality Manage system for Telecommunications </td>
                                                        <td>TL 9000 Lead Implementer </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Quality Manage system for Telecommunications </td>
                                                        <td>TL 9000 Internal Auditor </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Worldwide Responsible Accredited Production Internal Auditor
                                                        </td>
                                                        <td>WRAP Internal Auditor </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Worldwide Responsible Accredited Production Lead Auditor</td>
                                                        <td>WRAP Lead Auditor </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Performance assessment – Social responsibility </td>
                                                        <td>ISO 26000 Lead Auditor </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Performance assessment – Social responsibility </td>
                                                        <td>ISO 26000 Internal Auditor</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>EU Pressure Equipment Directive </td>
                                                        <td>PED Auditor </td>
                                                    </tr>
                                                    <tr>
                                                        <td>The Eco-Audit Eco-Management and Audit Scheme</td>
                                                        <td>EMAS Internal Auditor </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>The Eco-Audit Eco-Management and Audit Scheme</td>
                                                        <td>EMAS Lead Auditor</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Certified HACCP Auditor (CHA)</td>
                                                        <td>HACCP Auditor </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Food Safety Standards Authority of India </td>
                                                        <td>FSSAI Auditor </td>
                                                    </tr>
                                                    <tr>
                                                        <td>EU REACH</td>
                                                        <td>REACH Auditor </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Oil and Gas Quality Management System (OGQMS) auditor </td>
                                                        <td>ISO 29001 Lead Auditor </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 8, 'block': openTab === 8 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">SIX SEGMA</th>

                                                    <tr>
                                                        <td>Lean Six Sigma White belt</td>
                                                        <td>Six Sigma White belt</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Lean Six Sigma Yellow belt</td>
                                                        <td>Six Sigma Yellow belt</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Lean Six Sigma Green belt</td>
                                                        <td>Six Sigma Green belt</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Lean Six Sigma Black belt</td>
                                                        <td>Six Sigma Black belt</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Lean Six Sigma Advanced Green belt</td>
                                                        <td>Six Sigma Advanced Green belt</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Lean Six Sigma Advanced Black belt</td>
                                                        <td>Six Sigma Advanced Black belt</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Lean Six Sigma Master Black belt</td>
                                                        <td>Six Sigma Master Black belt</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 9, 'block': openTab === 9 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">NEBOSH</th>
                                                    <tr>
                                                        <td>NEBOSH Diploma in OHSAS</td>
                                                        <td>NEBOSH OHSAS</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>NEBOSH Diploma in OHSAS(Pilot)</td>
                                                        <td>NEBOSH OHSAS(Pilot)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>NEBOSH Diploma in Environmental Management</td>
                                                        <td>NEBOSH DEM</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>NEBOSH International general Cert. in OHSAS</td>
                                                        <td>NEBOSH IGC</td>
                                                    </tr>
                                                    <tr>
                                                        <td>NEBOSH Fire Safety and Risk Management </td>
                                                        <td>NEBOSH FSRM</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>NEBOSH International Fire Safety and Risk Management </td>
                                                        <td>NEBOSH IFSRM</td>
                                                    </tr>
                                                    <tr>
                                                        <td>NEBOSH National Construction Health and Safety </td>
                                                        <td>NEBOSH NCHS</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>NEBOSH International Construction Health and Safety </td>
                                                        <td>NEBOSH ICHS</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 10, 'block': openTab === 10 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">FROSIO</th>
                                                    <tr>
                                                        <td>FROSIO Inspector Level – 1 White</td>
                                                        <td>FROSIO Level 1</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>FROSIO Inspector Level – 2 Green</td>
                                                        <td>FROSIO Level 2</td>
                                                    </tr>
                                                    <tr>
                                                        <td>FROSIO Inspector Level – 3 Red</td>
                                                        <td>FROSIO Level 3</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 11, 'block': openTab === 11 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">SSPC</th>
                                                    <tr>
                                                        <td>Bridge Coating Inspector</td>
                                                        <td>SSPC BCI</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Aerospace Coating Application Specialist Certification Program
                                                        </td>
                                                        <td>SSPC BCI</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Protective Coatings Inspector</td>
                                                        <td>SSPC PCI Level 1</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Protective Coatings Inspector</td>
                                                        <td>SSPC PCI Level 2</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Protective Coatings Inspector</td>
                                                        <td>SSPC PCI Level 3</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Concrete Coating Inspector</td>
                                                        <td>SSPC CCI </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Master Coating Inspector</td>
                                                        <td>SSPC MCI </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>NAVSEA Basic Paint Inspector</td>
                                                        <td>SSPC NBPI </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Protective Coatings Specialist</td>
                                                        <td>SSPC PCS </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Thermal Spray Inspector</td>
                                                        <td>SSPC THERMAL INSP </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Industrial Coating Safety Management</td>
                                                        <td>SSPC SAFETY </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Project Management for the Industrial Painting Contractor</td>
                                                        <td>SSPC PRO MGMT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Quality Control Supervisor</td>
                                                        <td>SSPC QCS </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 12, 'block': openTab === 12 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">ICorr</th>
                                                    <tr>
                                                        <td>Painting Inspector – Level 1</td>
                                                        <td>ICorr PI Level 1</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Painting Inspector – Level 2</td>
                                                        <td>ICorr PI Level 2</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Painting Inspector – Level 3</td>
                                                        <td>ICorr PI Level 3</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Fireproofing Inspector – Level 2</td>
                                                        <td>ICorr FI Level 2</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Insulation Inspector – Level 2</td>
                                                        <td>ICorr II Level 2</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Pipeline coating Inspector – Level 2</td>
                                                        <td>ICorr PCI Level 2</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Hot dip galvanizing inspector</td>
                                                        <td>ICorr HDGI Level 2</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 13, 'block': openTab === 13 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>


                                                    <th colspan="2">IRATA</th>
                                                    <tr>
                                                        <td>IRATA Level 1</td>
                                                        <td>IRATA Level 1</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>IRATA Level 2</td>
                                                        <td>IRATA Level 2</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IRATA Level 3</td>
                                                        <td>IRATA Level 3</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 14, 'block': openTab === 14 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">CompEx - OK</th>
                                                    <tr>
                                                        <td>Preparation & installation of Ex ‘d’, ‘n’, ‘e’ and ‘p’
                                                            electrical
                                                            equipment</td>
                                                        <td>Ex01</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Inspection and maintenance of Ex ‘d’, ‘n’, ‘e’ and ‘p’
                                                            electrical
                                                            equipment</td>
                                                        <td>Ex02</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Preparation & installation of Ex 'i' equipment and systems</td>
                                                        <td>Ex03</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Inspection and maintenance of Ex 'i' equipment and systems</td>
                                                        <td>Ex04</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Preparation & installation of electrical equipment protected by
                                                            enclosure, for use in
                                                            Explosive Dust Atmospheres</td>
                                                        <td>Ex05</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Maintenance and inspection of electrical equipment protected by
                                                            enclosure, for use in
                                                            Explosive Dust Atmospheres</td>
                                                        <td>Ex06</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Preparation, installation and de-commissioning of electrical
                                                            installations at Fuel
                                                            Filling stations</td>
                                                        <td>Ex07</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Preparation, installation and de-commissioning of electrical
                                                            installations at Fuel
                                                            Filling stations</td>
                                                        <td>Ex08</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Preparation & Installation of electrical equipment in explosive
                                                            atmospheres within the
                                                            water industry</td>
                                                        <td>Ex09</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Inspection, test and maintenance of electrical equipment in
                                                            explosive
                                                            atmospheres within
                                                            the water industry</td>
                                                        <td>Ex10</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Mechanical practitioners working in explosive atmospheres</td>
                                                        <td>Ex11</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Design of Non-Intrinsically Safe electrical equipment
                                                            installations in
                                                            explosive
                                                            atmospheres</td>
                                                        <td>Ex12A </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Design of Intrinsically Safe electrical equipment in explosive
                                                            atmospheres</td>
                                                        <td>Ex12B </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Responsible Person Module </td>
                                                        <td>Ex14</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 15, 'block': openTab === 15 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">Globle Wind Organization </th>
                                                    <tr>
                                                        <td>GWO – Fire Awareness</td>
                                                        <td>GWO – Fire Awareness</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>GWO – Manual Handling</td>
                                                        <td>GWO – Manual Handling</td>
                                                    </tr>
                                                    <tr>
                                                        <td>GWO – Provide first Aid</td>
                                                        <td>GWO – Provide first Aid</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>GWO – Safe work at Heights</td>
                                                        <td>GWO – Safe work at Heights</td>
                                                    </tr>
                                                    <tr>
                                                        <td>GWO – Basic Safety Training refresher </td>
                                                        <td>GWO – Basic Safety Training refresher </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>GWO – Basic Safety Training </td>
                                                        <td>GWO – Basic Safety Training </td>
                                                    </tr>
                                                    <tr>
                                                        <td>GWO – Sea Survival </td>
                                                        <td>GWO – Sea Survival </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 16, 'block': openTab === 16 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">IOSH</th>
                                                    <tr>
                                                        <td>IOSH Managing Safely </td>
                                                        <td>IOSH MS</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>IOSH Safety and health for business </td>
                                                        <td>IOSH SAHFB</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IOSH Managing safely refresher </td>
                                                        <td>IOSH MSR</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>IOSH Managing occupational and wellbeing </td>
                                                        <td>IOSH MOAW</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IOSH Environment for business </td>
                                                        <td>IOSH EFB</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>IOSH Safety and health for business </td>
                                                        <td>IOSH SAHFB</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IOSH Fire safety for Managers</td>
                                                        <td>IOSH FSFM</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>IOSH Fire safety awareness </td>
                                                        <td>IOSH FSA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IOSH Working Safely </td>
                                                        <td>IOSH WS</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 17, 'block': openTab === 17 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">OSHA</th>
                                                    <tr>
                                                        <td>Compliance safety and health officer</td>
                                                        <td>OSHA inspector</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 18, 'block': openTab === 18 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">PMI(Project Management Institute)</th>
                                                    <tr>
                                                        <td>Project Management Professional</td>
                                                        <td>PMI PMP</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Program Management Professional</td>
                                                        <td>PMI PgMP</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Portfolio Management Professional</td>
                                                        <td>PMI PfMP</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Certified Associate in Project Management</td>
                                                        <td>PMI CAPM</td>
                                                    </tr>
                                                    <tr>
                                                        <td>PMI Professional in Business Analysis </td>
                                                        <td>PMI-PBA</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>PMI Agile Certified Practitioner </td>
                                                        <td>PMI-ACP</td>
                                                    </tr>
                                                    <tr>
                                                        <td>PMI Risk Management Professional</td>
                                                        <td>PMI-RMP</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>PMI Scheduling Professional </td>
                                                        <td>PMI-SP</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 19, 'block': openTab === 19 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">LEEA</th>
                                                    <tr>
                                                        <td>LEEA – Foundation </td>
                                                        <td>LEEA – FOU </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>LEEA – Lifting Equipment General </td>
                                                        <td>LEEA – LEG </td>
                                                    </tr>
                                                    <tr>
                                                        <td>LEEA – Lifting Machines Manual </td>
                                                        <td>LEEA – LMM </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>LEEA – Lifting Machines Power </td>
                                                        <td>LEEA – LMP </td>
                                                    </tr>
                                                    <tr>
                                                        <td>LEEA – Runways & Crane structures </td>
                                                        <td>LEEA – RCS </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>LEEA – Overhead Traveling cranes </td>
                                                        <td>LEEA – OTC </td>
                                                    </tr>
                                                    <tr>
                                                        <td>LEEA – Offshore Container Examination</td>
                                                        <td>LEEA – OCE</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>LEEA – Mobile Crane Examination</td>
                                                        <td>LEEA – MCE</td>
                                                    </tr>
                                                    <tr>
                                                        <td>LEEA – Foundation Course Blended</td>
                                                        <td>LEEA – FOU (B)</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>LEEA – Lifting Equipment General Advanced Program Blended</td>
                                                        <td>LEEA – LEG (B)</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 20, 'block': openTab === 20 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">LEEA</th>
                                                    <tr>
                                                        <td>LEEA – Foundation </td>
                                                        <td>LEEA – FOU </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>LEEA – Lifting Equipment General </td>
                                                        <td>LEEA – LEG </td>
                                                    </tr>
                                                    <tr>
                                                        <td>LEEA – Lifting Machines Manual </td>
                                                        <td>LEEA – LMM </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>LEEA – Lifting Machines Power </td>
                                                        <td>LEEA – LMP </td>
                                                    </tr>
                                                    <tr>
                                                        <td>LEEA – Runways & Crane structures </td>
                                                        <td>LEEA – RCS </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>LEEA – Overhead Traveling cranes </td>
                                                        <td>LEEA – OTC </td>
                                                    </tr>
                                                    <tr>
                                                        <td>LEEA – Offshore Container Examination</td>
                                                        <td>LEEA – OCE</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>LEEA – Mobile Crane Examination</td>
                                                        <td>LEEA – MCE</td>
                                                    </tr>
                                                    <tr>
                                                        <td>LEEA – Foundation Course Blended</td>
                                                        <td>LEEA – FOU (B)</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>LEEA – Lifting Equipment General Advanced Program Blended</td>
                                                        <td>LEEA – LEG (B)</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 21, 'block': openTab === 21 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">OPITO</th>
                                                    <tr>
                                                        <td>Basic H2S </td>
                                                        <td>H2S</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Basic Offshore Safety Induction and Emergency </td>
                                                        <td>BOSIET </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Basic Onshore Emergency Response</td>
                                                        <td>BOER</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>BOSIET Bridging Elements</td>
                                                        <td>BRIDGE</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Compressed Air Emergency Breathing System (Initial Deployment
                                                            Training
                                                        </td>
                                                        <td>CA-EBS</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Control Room Operator</td>
                                                        <td>CRO</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Elected Safety Representatives Development Module 1</td>
                                                        <td>ESR1</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Elected Safety Representatives Development Module 2</td>
                                                        <td>ESR2</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Elected Safety Representatives Development Module 3</td>
                                                        <td>ESR3</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Elected Safety Representatives Development Module 4</td>
                                                        <td>ESR4</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Emergency Breathing System</td>
                                                        <td>EBS</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Escape Chute Training</td>
                                                        <td>ECT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Further Offshore Emergency Response Team Leader</td>
                                                        <td>OERTL-F</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Further Offshore Emergency Response Team Member</td>
                                                        <td>OERTM-F</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Further Offshore Emergency Training</td>
                                                        <td>FOET </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Further Onshore Emergency Response</td>
                                                        <td>FOER</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Helicopter Landing Officer (HLO) Workplace Competence Assessment
                                                        </td>
                                                        <td>HLO WCA</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Helicopter Underwater Escape Training</td>
                                                        <td>HUET </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Helideck Assistant (HDA) Workplace Competence Assessment</td>
                                                        <td>HDA WCA</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Helideck Emergency Response Team Leader (HERTL) Further Training
                                                            -
                                                            HERTLF</td>
                                                        <td>HERTL-F</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Helideck Emergency Response Team Leader (HERTL) Training</td>
                                                        <td>HERTL</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Helideck Emergency Response Team Leader (HERTL) Workplace
                                                            Competence
                                                            Assessment</td>
                                                        <td>HERTL WCA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Helideck Emergency Response Team Member (HERTM) Further Training
                                                            –
                                                            HERTMF</td>
                                                        <td>HERTM-F</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Helideck Emergency Response Team Member (HERTM) Training</td>
                                                        <td>HERTM</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Helideck Emergency Response Team Member (HERTM) Workplace
                                                            Competence
                                                            Assessment</td>
                                                        <td>HERTM WCA</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Helideck Operations Initial Training</td>
                                                        <td>HOIT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Major Emergency Management Initial Response Training</td>
                                                        <td>MEMIR</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Minimum Industry Safety Training for Experienced Workers</td>
                                                        <td>MIST CBT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Offshore Emergency Response Team Leader</td>
                                                        <td>OERTL</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Offshore Emergency Response Team Member</td>
                                                        <td>OERTM</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Offshore Lifeboat Coxswain</td>
                                                        <td>COX</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Offshore Lifeboat Coxswain Further Training (Free Fall)</td>
                                                        <td>COX-F (Free)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Offshore Lifeboat Coxswain Further Training (Single Fall)</td>
                                                        <td>COX-F (Single Fall)</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Offshore Lifeboat Coxswain Further Training (Twin Fall)</td>
                                                        <td>COX-F (Twin)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Offshore Radio Operator</td>
                                                        <td>RO</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>OIM Controlling Emergencies</td>
                                                        <td>OIM</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Onshore Control Room Operator Emergency Response Assessment</td>
                                                        <td>Onshore CRO</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Plant Manager / Incident Commander</td>
                                                        <td>PM/IC</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Travel Safely by Boat</td>
                                                        <td>BOAT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Travel Safely by Boat - Further Training</td>
                                                        <td>BOAT-F</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Tropical Basic Offshore Safety Induction and Emergency Training
                                                        </td>
                                                        <td>TBOSIET</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Tropical Further Offshore Emergency Training</td>
                                                        <td>TFOET</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Tropical Helicopter Underwater Escape Training</td>
                                                        <td>THUET</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Application of Insulation Systems Competence Assessment Level 2
                                                        </td>
                                                        <td>NMI-C2</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Application of Insulation Systems Training</td>
                                                        <td>NMI</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Authorized Gas Tester Training Level 1</td>
                                                        <td>AGT1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Authorized Gas Tester Training Level 2</td>
                                                        <td>AGT2</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Authorized Gas Tester Training Level 3</td>
                                                        <td>AGT3</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Banksman and Slinger Training - Stage 1</td>
                                                        <td>BS1</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Banksman and Slinger Training - Stage 3</td>
                                                        <td>BS3</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Banksman and Slinger Training - Stage 4</td>
                                                        <td>BS4</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Blaster Sprayer Competence </td>
                                                        <td>BSC</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Blaster/Sprayer Training</td>
                                                        <td>BS</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Competence Assessor</td>
                                                        <td>CASS</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Control of Work Refresher Training for Performing Authorities
                                                        </td>
                                                        <td>COWPA-R</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Control of Work Training for Performing Authorities</td>
                                                        <td>COWPA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Dangerous Goods By Sea</td>
                                                        <td>DGS</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Fire Proofing Training</td>
                                                        <td>FP</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Further Authorized Gas Tester Training Level 1 (Further Gas
                                                            Testing for
                                                            Confined Space)
                                                        </td>
                                                        <td>AGT1-F</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Further Authorized Gas Tester Training Level 2 (Further Gas
                                                            Testing for
                                                            Hot Work) </td>
                                                        <td>AGT2-F</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Further Authorized Gas Tester Training Level 3 (Further Gas
                                                            Monitoring
                                                            for Hotwork
                                                            Sites) </td>
                                                        <td>AGT3-F
                                                        </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Internal Verifier</td>
                                                        <td>IV</td>
                                                    </tr>
                                                    <tr>
                                                        <td>International Minimum Industry Safety Training</td>
                                                        <td>IMIST</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>LOLER Competent Person (Competence Re-Assessment)</td>
                                                        <td>LCP-R</td>
                                                    </tr>
                                                    <tr>
                                                        <td>LOLER Competent Person Assessment</td>
                                                        <td>LCP</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Minimum Industry Safety Training</td>
                                                        <td>MIST</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Offshore Crane Operator - Stage 1 Introductory Training</td>
                                                        <td>OCO1</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Offshore Drilling Industry Green hand</td>
                                                        <td>GH</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Offshore Lifeboat Coxswain Supplementary Fall Training (Single
                                                            Fall)
                                                        </td>
                                                        <td>COX (SS)</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Offshore Lifeboat Coxswain Supplementary Fall Training (Twin
                                                            Fall) </td>
                                                        <td>COX (TS)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Offshore Lifeboat Coxswain Training (Free Fall) </td>
                                                        <td>COX (Free)</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Offshore Lifeboat Coxswain Training (Single Fall) </td>
                                                        <td>COX (Single)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Offshore Lifeboat Coxswain Training (Twin Fall)</td>
                                                        <td>COX (Twin)</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Offshore Safety Representative</td>
                                                        <td>OSR</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Offshore Safety Representatives Refresher Training</td>
                                                        <td>OSR-R</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Preparation of Dangerous Goods for Transport by Sea (Refresher
                                                            Training)
                                                        </td>
                                                        <td>DGS-R</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Rigger Competence - Stage 3</td>
                                                        <td>R3</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Rigger Competence - Stage 4</td>
                                                        <td>R4</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Rigger Training - Stage 1</td>
                                                        <td>R1</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Safe Driving at Work</td>
                                                        <td>SDAW</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Command and Control for ERRV Masters and Mates</td>
                                                        <td>C&C</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>ERRV Crew Advanced Medical Aid</td>
                                                        <td>AMA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>ERRV Crew Daughter Craft Coxswain</td>
                                                        <td>DCC</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>ERRV Crew Fast Rescue Craft Boatman</td>
                                                        <td>FRCB</td>
                                                    </tr>
                                                    <tr>
                                                        <td>ERRV Crew Fast Rescue Craft Coxswain</td>
                                                        <td>FRCC</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>ERRV Crew Initial Training Shipboard Operations</td>
                                                        <td>ITSO</td>
                                                    </tr>
                                                    <tr>
                                                        <td>ERRV Further Crew Advanced Medical Aid</td>
                                                        <td>AMA-F</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Ongoing Onboard Development & Training Program for ERRV Master
                                                            and Crew
                                                        </td>
                                                        <td>OODTP</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 22, 'block': openTab === 22 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">ECITB</th>
                                                    <tr>
                                                        <td>CCNSG Safety Passport Scheme</td>
                                                        <td>CCNSG Safety Passport</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>CCNSG Safety Passport Scheme</td>
                                                        <td>Combined CCNSG/ACE card</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Hand Torque Bolted Connections</td>
                                                        <td>MJI 10</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td></td>
                                                        <td>MJI 11</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Hydraulically Tensioned Bolted connections</td>
                                                        <td>MJI 18</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Hydraulically Torque Bolted Connections</td>
                                                        <td>MJI 19</td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>MJI 20</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Hydraulically Tensioned Subsea Bolted Connections</td>
                                                        <td>MJI 21</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Hydraulically Torqued Subsea Bolted Connections</td>
                                                        <td>MJI 22</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Hydraulically Tensioned Wind Turbine Bolted Connections</td>
                                                        <td>MJI 31</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Hydraulically Torqued Wind Turbine Bolted Connections</td>
                                                        <td>MJI 32</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td></td>
                                                        <td>SBT01</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Shape small bore tubing components in engineering construction
                                                        </td>
                                                        <td>SBT02</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Assemble small bore tubing components in engineering
                                                            construction</td>
                                                        <td>SBT03</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Dismantle and remove small bore tubing assemblies in engineering
                                                            construction</td>
                                                        <td>SBT04</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Replace Components in small bore tubing assemblies in
                                                            engineering
                                                            construction</td>
                                                        <td>SBT05</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Install small bore tubing in engineering construction</td>
                                                        <td>SBT 06</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Test small bore tubing in engineering construction </td>
                                                        <td>SBT 08</td>
                                                    </tr>
                                                    <tr>
                                                        <td>SMALL BORE TUBING – Assemble and install small bore tubing
                                                            assemblies
                                                        </td>
                                                        <td>TSBT 01</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>SMALL BORE TUBING – Disassemble and reinstall SBT assemblies
                                                        </td>
                                                        <td>TSBT 02</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Assemble and install small bore tubing assemblies</td>
                                                        <td>TSBT 03</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Abrasive Wheels</td>
                                                        <td>AW01</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Carry out planned maintenance procedures on engineering
                                                            construction
                                                            mechanical plant
                                                            and equipment</td>
                                                        <td>MPS Mech 03</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Adjust engineering construction mechanical plant and equipment
                                                            to meet
                                                            operating
                                                            requirements.</td>
                                                        <td>MPS Mech 04</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Remove components from engineering construction mechanical plant
                                                            and
                                                            equipment</td>
                                                        <td>MPS Mech 05</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Replace components in engineering construction mechanical plant
                                                            and
                                                            equipment</td>
                                                        <td>MPS Mech 06</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Determine the feasibility of repairing engineering construction
                                                            mechanical plant and
                                                            equipment components</td>
                                                        <td>MPS Mech 12
                                                        </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Appointed Person Moving Loads</td>
                                                        <td>APML 01</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Working in Low Risk Confined Spaces</td>
                                                        <td>CS 01</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Working in Medium Risk Confined Spaces</td>
                                                        <td>CS 02</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Working in High Risk Confined Space</td>
                                                        <td>CS 03</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Confined Space Appreciation</td>
                                                        <td>CS 04</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Contribute to Effective Working Relationships in Engineering
                                                            Construction</td>
                                                        <td>CO 01</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Work Safely and Minimize Risk in Engineering Construction</td>
                                                        <td>CO 02</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Identify and Deal with Hazards and Emergencies in the
                                                            Engineering
                                                            Construction Work
                                                            Environment</td>
                                                        <td>CO 03</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Introduction To Offshore Decommissioning</td>
                                                        <td>OSD 01</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Join pipe in engineering construction by TIG/MMA</td>
                                                        <td>WPP 03</td>
                                                    </tr>

                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Join pipe in engineering construction by TIG welding</td>
                                                        <td>WPP 01</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Position and install engineering construction electrical plant
                                                            and
                                                            equipment</td>
                                                        <td>MPS Elec 01</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Position and install engineering construction electrical plant
                                                            and
                                                            equipment</td>
                                                        <td>MPS Elec 02</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Carry out planned maintenance procedures on electrical plant and
                                                            equipment in
                                                            engineering construction</td>
                                                        <td>MPS Elec 03</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Adjust electrical plant and equipment to meet operating
                                                            requirements in
                                                            engineering
                                                            construction</td>
                                                        <td>MPS Elec 04</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Remove components from electrical plant and equipment in
                                                            engineering
                                                            construction</td>
                                                        <td>MPS Elec 05</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Replace components from electrical plant and equipment in
                                                            engineering
                                                            construction</td>
                                                        <td>MPS Elec 06</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Monitor the performance and condition of electrical plant and
                                                            equipment
                                                            in engineering
                                                            construction</td>
                                                        <td>MPS Elec 07</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Assess the performance and condition of engineering construction
                                                            electrical plant and
                                                            equipment.</td>
                                                        <td>MPS Elec 08</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Diagnose and determine the causes of faults in engineering
                                                            construction
                                                            electrical plant
                                                            and equipment</td>
                                                        <td>MPS Elec 09</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Assemble components of electrical plant and equipment in
                                                            engineering
                                                            construction</td>
                                                        <td>MPS Elec 10</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Repair components from engineering construction electrical plant
                                                            and
                                                            equipment.</td>
                                                        <td>MPS Elec 11</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Hand over engineering construction electrical plant and
                                                            equipment.</td>
                                                        <td>MPS Elec 12</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Determine the feasibility of repairing components from
                                                            electrical plant
                                                            and equipment in
                                                            engineering construction</td>
                                                        <td>MPS Elec 13</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Test the performance and condition of engineering construction
                                                            electrical plant and
                                                            equipment.</td>
                                                        <td>MPS Elec 14</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Analyze the test results relating to the tested engineering
                                                            construction
                                                            electrical
                                                            plant and equipment.</td>
                                                        <td>MPS Elec 15</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Install engineering construction electrical wiring systems,
                                                            wiring
                                                            enclosures and
                                                            equipment (plant)</td>
                                                        <td>IPS Elec 01</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Connect engineering construction wiring systems and equipment
                                                            using safe
                                                            and approved
                                                            methods (plant)</td>
                                                        <td>IPS Elec 02</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Inspect and test an engineering construction electrical
                                                            installation
                                                            (plant)</td>
                                                        <td>IPS Elec 03</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Diagnose and correct electrical faults (plant) in engineering
                                                            construction</td>
                                                        <td>IPS Elec 04</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Position and install engineering construction instrument and
                                                            control
                                                            systems</td>
                                                        <td> MPS I&C 01</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Dismantle engineering construction instrument and control
                                                            systems</td>
                                                        <td>MPS I&C 02</td>
                                                    </tr>

                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Carry out planned engineering construction maintenance on
                                                            instrument and
                                                            control systems
                                                        </td>
                                                        <td>MPS I&C 03</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Adjust instrument and control systems to meet operating
                                                            requirements in
                                                            engineering
                                                            construction</td>
                                                        <td>MPS I&C 04</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Remove components from instrument and control systems in
                                                            engineering
                                                            construction</td>
                                                        <td>MPS I&C 05</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Repair components from engineering construction instrument and
                                                            control
                                                            systems</td>
                                                        <td>MPS I&C 10</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Test the performance and condition of instrument and control
                                                            systems in
                                                            engineering
                                                            construction</td>
                                                        <td>MPS I&C 13</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Analyze the test results relating to the tested engineering
                                                            construction
                                                            instrument and
                                                            control system</td>
                                                        <td>MPS I&C 14</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Diagnose and determine the causes of faults in engineering
                                                            construction
                                                            instrument and
                                                            control systems</td>
                                                        <td>MPS I&C 15</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Principles of Flow Measurement and Uncertainty</td>
                                                        <td>FM01</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Human Performance - Foundation Level</td>
                                                        <td>HuP01</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Prepare work areas in support of engineering construction
                                                            activities
                                                        </td>
                                                        <td>SECA 01</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Prepare materials in support of engineering construction
                                                            activities</td>
                                                        <td>SECA 02</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Prepare equipment in support of engineering construction
                                                            activities</td>
                                                        <td>SECA 03</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Move engineering construction loads using manually operated
                                                            equipment
                                                        </td>
                                                        <td>SECA 04</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Reinstate the work area after completing engineering
                                                            construction
                                                            activities</td>
                                                        <td>SECA 05</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Shape engineering construction mechanical components by material
                                                            removal
                                                            using hand
                                                            tools engineering construction</td>
                                                        <td>SIPS Mech 01</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Mark out to the required engineering construction specification
                                                        </td>
                                                        <td>IPS Mech 06</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Interpret welding procedures, specifications and standards in
                                                            engineering construction
                                                        </td>
                                                        <td>WPL 07</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Hydraulic Principles</td>
                                                        <td>HYD 01</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Shape engineering construction pipework components by material
                                                            removal
                                                            using hand tools
                                                        </td>
                                                        <td>IPS Pipe 01</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Assemble engineering construction pipework components to meet
                                                            specification</td>
                                                        <td>IPS Pipe 02</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Position and install engineering construction pipework</td>
                                                        <td>IPS Pipe 03</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Mark out to the required specification for pipefitting
                                                            activities in
                                                            engineering
                                                            construction</td>
                                                        <td>IPS Pipe 04</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Establish compliance of engineering construction installation
                                                            with the
                                                            specification
                                                        </td>
                                                        <td>IPS Pipe 05</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Test the integrity and condition of installed pipework in
                                                            engineering
                                                            construction</td>
                                                        <td>IPS Pipe 06</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Shape engineering construction pipework components by manually
                                                            applied
                                                            pressure</td>
                                                        <td>IPS Pipe 07</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Dismantle and remove engineering construction pipework
                                                            components</td>
                                                        <td>IPS Pipe 08</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Support welding operations in engineering construction </td>
                                                        <td>SECO 01 </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Interpret welding procedures, specifications and standards in
                                                            engineering construction
                                                        </td>
                                                        <td>WPL 07</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Principles of moving engineering construction loads</td>
                                                        <td>LAPL01 </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Performing simple lifting and positioning operations in
                                                            engineering
                                                            construction</td>
                                                        <td>LAPL02</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Move Engineering Construction Loads under Supervision</td>
                                                        <td>LAPL03</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Position and install engineering construction mechanical plant
                                                            and
                                                            equipment</td>
                                                        <td>IPS Mech 08</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Manual Handling</td>
                                                        <td>MH 01</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Prepare Loads for Moving or Lifting and Positioning in
                                                            Engineering
                                                            Construction</td>
                                                        <td>ML 01</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Move Loads in Engineering Construction</td>
                                                        <td>ML 02</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Determine resource requirements to achieve moving, lifting and
                                                            positioning objectives in
                                                            engineering construction</td>
                                                        <td>ML 03</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Lift and position loads in engineering construction</td>
                                                        <td>ML 04</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Determine technical requirements to achieve moving, lifting and
                                                            positioning objectives
                                                            in engineering construction.</td>
                                                        <td>ML 05</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Dismantle engineering construction assemblies for movement.</td>
                                                        <td>ML 06</td>
                                                    </tr>
                                                    <tr>
                                                        <td>On-Site Pipe Cutting and Pipe End Weld Preparation</td>
                                                        <td>OSM 01</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>On-Site Joint Face Machining-Basic</td>
                                                        <td>OSM 02.1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>On-Site Milling</td>
                                                        <td>OSM 04</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>On-Site Joint Face Machining-Advanced</td>
                                                        <td>OSM 05</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Shape components of fabricated steel structures by material
                                                            removal
                                                            using hand tools in
                                                            engineering construction. </td>
                                                        <td>FSS 01</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Assemble components of steel fabrications to meet specification
                                                            in
                                                            engineering
                                                            construction</td>
                                                        <td>FSS 02</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Manually form components to specification in engineering
                                                            construction
                                                        </td>
                                                        <td>FSS 03</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Join materials by manually controlled welding process in
                                                            engineering
                                                            construction</td>
                                                        <td>FSS 04</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Fundamentals of Pressure Safety Valves</td>
                                                        <td>PSV01</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Dismantle engineering construction mechanical plant and
                                                            equipment</td>
                                                        <td>TMPS Mech 02</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Root Cause Analysis</td>
                                                        <td>RCA 01</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 23, 'block': openTab === 23 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">Maritime</th>
                                                    <tr>
                                                        <td>Ordinary Seaman </td>
                                                        <td>Ordinary Seaman</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Able Seaman Unlimited</td>
                                                        <td>Able Seaman Unlimited</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Able Seaman Limited</td>
                                                        <td>Able Seaman Limited</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Able Seaman Special</td>
                                                        <td>Able Seaman Special</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Certificates of Competency</td>
                                                        <td>COC</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Certificates of Equivalent Competency</td>
                                                        <td>CEC</td>
                                                    </tr>
                                                    <tr>
                                                        <td>GMDSS General Operations Certificate</td>
                                                        <td>GMDSS GOC</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Personal Survival Techniques</td>
                                                        <td>STCW A-VI/1-1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Fire Fighting and Fire Prevention </td>
                                                        <td>STCW A-VI/1-2</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Elementary First Aid </td>
                                                        <td>STCW A-VI/1-3</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Personal Safety and Social Responsibility</td>
                                                        <td>STCW A-VI/1-4</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Proficiency in Security Awareness</td>
                                                        <td>STCW A-VI/6-1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Advanced Fire Fighting</td>
                                                        <td>STCW A-VI/4-1</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Proficiency for Persons in Charge of Medical Care on Board Ship
                                                        </td>
                                                        <td>STCW A-VI/4-2</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Proficiency in Medical First Aid</td>
                                                        <td>STCW A-VI/3</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Proficiency in Survival Craft and Rescue Boats Other Than Fast
                                                            Rescue
                                                            Boats (Restricted)
                                                        </td>
                                                        <td>STCW A-VI/2</td>
                                                    </tr>
                                                    <tr>
                                                        <td>ERW Risk Awareness Officer </td>
                                                        <td>ERW Risk Awareness Officer </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>ERW Risk Awareness Manager</td>
                                                        <td>ERW Risk Awareness Manager</td>
                                                    </tr>
                                                    <tr>
                                                        <td>ERW Risk Assessor </td>
                                                        <td>ERW Risk Assessor </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>ENG1 Medical Certified </td>
                                                        <td>ENG1 Medical Certified </td>
                                                    </tr>
                                                    <tr>
                                                        <td>MLS5 Medical Certified </td>
                                                        <td>MLS5 Medical Certified </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 24, 'block': openTab === 24 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">EEMUA -Engineering Equipment Materials Users Association
                                                    </th>
                                                    <tr>
                                                        <td>EEMUA 159 Tank Assessor </td>
                                                        <td>EEMUA 159 Tank Assessor </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>EEMUA 159 Tank Strategies </td>
                                                        <td>EEMUA 159 Tank Strategies </td>
                                                    </tr>
                                                    <tr>
                                                        <td>EEMUA 159 Tank Intro</td>
                                                        <td>EEMUA 159 Tank Intro</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Subsea engineering: materials selection and corrosion control
                                                        </td>
                                                        <td>EEMUA 194</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Mechanical integrity</td>
                                                        <td>EEMUA 231</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 25, 'block': openTab === 25 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">IMAS (International Mine Action Standards)</th>
                                                    <tr>
                                                        <td>IMAS Level 1 </td>
                                                        <td>IMAS Level 1 </td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>IMAS Level 2</td>
                                                        <td>IMAS Level 2 </td>
                                                    </tr>
                                                    <tr>
                                                        <td>IMAS Level 3</td>
                                                        <td>IMAS Level 3</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>IMAS Conversion Course - Humanitarian Mine Action (HMA)</td>
                                                        <td>IMAS-CC </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Humanitarian IED Clearance Course</td>
                                                        <td>HIEDC</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Humanitarian IEDD Operator Course</td>
                                                        <td>HIEDD</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ammunition & Physical Stockpile, Safety & Security Management
                                                        </td>
                                                        <td>PSSM</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 26, 'block': openTab === 26 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>


                                                    <th colspan="2">EEHA</th>
                                                    <tr>
                                                        <td>Cert IV in Hazardous Area</td>
                                                        <td>Cert IV in Hazardous Area</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>EEHA</td>
                                                        <td>EEHA</td>
                                                    </tr>
                                                    <tr>
                                                        <td>EEHA Overhaul</td>
                                                        <td>EEHA Overhaul</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 27, 'block': openTab === 27 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">AKER Coabis</th>
                                                    <tr>
                                                        <td>Coabis – Onshore administrator</td>
                                                        <td>Coabis – Onshore administrator</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Coabis – Subsea Inspector</td>
                                                        <td>Coabis – Subsea Inspector</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Coabis - Topsides and onshore Inspector</td>
                                                        <td>Coabis - Topsides and onshore Inspector</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 28, 'block': openTab === 28 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">PED</th>
                                                    <tr>
                                                        <td>Pressure Equipment Directive Auditor </td>
                                                        <td>PED Auditor</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Pressure Equipment Directive Inspector </td>
                                                        <td>PED Inspector </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 29, 'block': openTab === 29 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>
                                                    <th colspan="2">NDT-EN473</th>
                                                    <tr>
                                                        <td>Acoustic emission testing (AT)</td>
                                                        <td>EN 473 Level 2 - AT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Leak Testing (LT)</td>
                                                        <td>EN 473 Level 2 - LT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Liquid penetrant (PT)</td>
                                                        <td>EN 473 Level 2 - PT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Magnetic Particle (MT)</td>
                                                        <td>EN 473 Level 2 - MT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Radiographic (RT)</td>
                                                        <td>EN 473 Level 2 - RT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Eddy Current (ET)</td>
                                                        <td>EN 473 Level 2 - ET</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ultrasonic (UT)</td>
                                                        <td>EN 473 Level 2 - UT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Visual Testing (VT)</td>
                                                        <td>EN 473 Level 2 - VT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Acoustic emission testing (AT)</td>
                                                        <td>EN 473 Level 3 - AT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Leak Testing (LT)</td>
                                                        <td>EN 473 Level 3 - LT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Liquid penetrant (PT)</td>
                                                        <td>EN 473 Level 3 - PT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Magnetic Particle (MT)</td>
                                                        <td>EN 473 Level 3 - MT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Radiographic (RT)</td>
                                                        <td>EN 473 Level 3 - RT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Eddy Current (ET)</td>
                                                        <td>EN 473 Level 3 - ET</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ultrasonic (UT)</td>
                                                        <td>EN 473 Level 3 - UT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Visual Testing (VT)</td>
                                                        <td>EN 473 Level 3 - VT</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 30, 'block': openTab === 30 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">NDT-EN4179 </th>
                                                    <tr>
                                                        <td>Liquid penetrant (PT)</td>
                                                        <td>EN 4179 Level 2 - PT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Magnetic Particle (MT)</td>
                                                        <td>EN 4179 Level 2 - MT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Radiographic (RT)</td>
                                                        <td>EN 4179 Level 2 - RT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Thermography (IRT)</td>
                                                        <td>EN 4179 Level 2 - IRT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ultrasonic (UT)</td>
                                                        <td>EN 4179 Level 2 - UT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Eddy Current (ET)</td>
                                                        <td>EN 4179 Level 2 - ET</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Shearography (ST)</td>
                                                        <td>EN 4179 Level 2 - ST</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Liquid penetrant (PT)</td>
                                                        <td>EN 4179 Level 3 - PT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Magnetic Particle (MT)</td>
                                                        <td>EN 4179 Level 3 - MT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Radiographic (RT)</td>
                                                        <td>EN 4179 Level 3 - RT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Thermography (IRT)</td>
                                                        <td>EN 4179 Level 3 - IRT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Ultrasonic (UT)</td>
                                                        <td>EN 4179 Level 3 - UT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Eddy Current (ET)</td>
                                                        <td>EN 4179 Level 3 - ET</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Shearography (ST)</td>
                                                        <td>EN 4179 Level 3 - ST</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 31, 'block': openTab === 31 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">NDT - ACCP </th>
                                                    <tr>
                                                        <td>Liquid penetrant (PT)</td>
                                                        <td>ACCP Level 2 - PT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Magnetic Particle (MT)</td>
                                                        <td>ACCP Level 2 - MT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Radiographic (RT)</td>
                                                        <td>ACCP Level 2 - RT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Eddy Current (ET)</td>
                                                        <td>ACCP Level 2 - IRT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ultrasonic (UT)</td>
                                                        <td>ACCP Level 2 - UT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Visual & Optical (VT)</td>
                                                        <td>ACCP Level 2 – VT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Liquid penetrant (PT)</td>
                                                        <td>ACCP Level 3 - PT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Magnetic Particle (MT)</td>
                                                        <td>ACCP Level 3 - MT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Radiographic (RT)</td>
                                                        <td>ACCP Level 3 - RT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Eddy Current (ET)</td>
                                                        <td>ACCP Level 3- IRT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ultrasonic (UT)</td>
                                                        <td>ACCP Level 3 - UT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Visual & Optical (VT)</td>
                                                        <td>ACCP Level 3 – VT</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 32, 'block': openTab === 32 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">NDT-ASNT</th>

                                                    <tr>
                                                        <td>Liquid penetrant (PT)</td>
                                                        <td>ASNT Level 2- PT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Magnetic Particle (MT)</td>
                                                        <td>ASNT Level 2- MT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Radiographic (RT)</td>
                                                        <td>ASNT Level 2- RT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Eddy Current (ET)</td>
                                                        <td>ASNT Level 2- ET</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ultrasonic (UT)</td>
                                                        <td>ASNT Level 2- UT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Visual & Optical (VT)</td>
                                                        <td>ASNT Level 2- VT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Acoustic emission testing (AET)</td>
                                                        <td>ASNT Level 2- AET</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Radiographic Film interpretation (RTFI)</td>
                                                        <td>ASNT Level 2- RTFI</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Phased Array Ultrasonic Inspector (PAUT)</td>
                                                        <td>ASNT Level 2- PAUT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Phased Array Corrosion & Erosion Monitoring Endorsement (PACM)
                                                        </td>
                                                        <td>ASNT Level 2- PACM</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Digitized radiographic interpretation (DRI)</td>
                                                        <td>ASNT Level 2- DRI</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Automated Ultrasonic Inspector (AUT)</td>
                                                        <td>ASNT Level 2- AUT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Guided Wave Testing (GWT)</td>
                                                        <td>ASNT Level 2- GWT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>TOFD Ultrasonic Testing (TOFD)</td>
                                                        <td>ASNT Level 2- TOFD</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Visual and optical testing (VT)</td>
                                                        <td>ASNT Level 2- VT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>AC Field Measurement (ACFM)</td>
                                                        <td>ASNT Level 2- ACFM</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Leak Testing (LT)</td>
                                                        <td>ASNT Level 2- LT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Liquid penetrant (PT)</td>
                                                        <td>ASNT Level 3 - PT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Magnetic Particle (MT)</td>
                                                        <td>ASNT Level 3 - MT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Radiographic (RT)</td>
                                                        <td>ASNT Level 3 - RT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Eddy Current (ET)</td>
                                                        <td>ASNT Level 3 - ET</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Ultrasonic (UT)</td>
                                                        <td>ASNT Level 3 - UT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Visual & Optical (VT)</td>
                                                        <td>ASNT Level 3 - VT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Acoustic emission testing (AET)</td>
                                                        <td>ASNT Level 3 - AET</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Radiographic Film interpretation (RTFI)</td>
                                                        <td>ASNT Level 3 - RTFI</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Phased Array Ultrasonic Inspector (PAUT)</td>
                                                        <td>ASNT Level 3 - PAUT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Phased Array Corrosion & Erosion Monitoring Endorsement (PACM)
                                                        </td>
                                                        <td>ASNT Level 3 - PACM</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Digitized radiographic interpretation (DRI)</td>
                                                        <td>ASNT Level 3 - DRI</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Automated Ultrasonic Inspector (AUT)</td>
                                                        <td>ASNT Level 3 - AUT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Guided Wave Testing (GWT)</td>
                                                        <td>ASNT Level 3 - GWT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>TOFD Ultrasonic Testing (TOFD)</td>
                                                        <td>ASNT Level 3 - TOFD</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Visual and optical testing (VT)</td>
                                                        <td>ASNT Level 3 - VT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>AC Field Measurement (ACFM)</td>
                                                        <td>ASNT Level 3 - ACFM</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Leak Testing (LT)</td>
                                                        <td>ASNT Level 3 - LT</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 33, 'block': openTab === 33 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>


                                                    <th colspan="2">NDT - ISO 9712 </th>
                                                    <tr>
                                                        <td>Acoustic emission testing (AT)</td>
                                                        <td>ISO 9712 Level 2 - AT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Leak Testing (LT)</td>
                                                        <td>ISO 9712 Level 2 - LT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Liquid penetrant (PT)</td>
                                                        <td>ISO 9712 Level 2 - PT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Magnetic Particle (MT)</td>
                                                        <td>ISO 9712 Level 2 - MT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Radiographic (RT)</td>
                                                        <td>ISO 9712 Level 2 - RT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Eddy Current (ET)</td>
                                                        <td>ISO 9712 Level 2 - ET</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ultrasonic (UT)</td>
                                                        <td>ISO 9712 Level 2 - UT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Visual Testing (VT)</td>
                                                        <td>ISO 9712 Level 2 - VT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Infrared Thermographic Testing (TT)</td>
                                                        <td>ISO 9712 Level 2 - TT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Strain Testing (ST)</td>
                                                        <td>ISO 9712 Level 2 – ST</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Acoustic emission testing (AT)</td>
                                                        <td>ISO 9712 Level 3 - AT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Leak Testing (LT)</td>
                                                        <td>ISO 9712 Level 3 - LT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Liquid penetrant (PT)</td>
                                                        <td>ISO 9712 Level 3 - PT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Magnetic Particle (MT)</td>
                                                        <td>ISO 9712 Level 3 - MT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Radiographic (RT)</td>
                                                        <td>ISO 9712 Level 3 - RT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Eddy Current (ET)</td>
                                                        <td>ISO 9712 Level 3 - ET</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ultrasonic (UT)</td>
                                                        <td>ISO 9712 Level 3 - UT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Visual Testing (VT)</td>
                                                        <td>ISO 9712 Level 3 - VT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Infrared Thermographic Testing (TT)</td>
                                                        <td>ISO 9712 Level 3 - TT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Strain Testing (ST)</td>
                                                        <td>ISO 9712 Level 3 – ST</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div v-bind:class="{ 'hidden': openTab !== 34, 'block': openTab === 34 }">
                                        <div class="tbl-content">
                                            <table style="cellpadding:0; cellspacing:0; border:0">
                                                <tbody>

                                                    <th colspan="2">NDT - NAS410 </th>
                                                    <tr>
                                                        <td>Acoustic emission (AE)</td>
                                                        <td>NAS410 Level 2 - AE</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Neutron radiography (NRT)</td>
                                                        <td>NAS410 Level 2 - NRT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Liquid penetrant (PT)</td>
                                                        <td>NAS410 Level 2 - PT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Magnetic Particle (MT)</td>
                                                        <td>NAS410 Level 2 - MT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Radiographic (RT)</td>
                                                        <td>NAS410 Level 2 - RT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Eddy Current (ET)</td>
                                                        <td>NAS410 Level 2 - ET</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ultrasonic (UT)</td>
                                                        <td>NAS410 Level 2 - UT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Acoustic emission (AE)</td>
                                                        <td>NAS410 Level 3 - AE</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Neutron radiography (NRT)</td>
                                                        <td>NAS410 Level 3 - NRT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Liquid penetrant (PT)</td>
                                                        <td>NAS410 Level 3 - PT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Magnetic Particle (MT)</td>
                                                        <td>NAS410 Level 3 - MT</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Radiographic (RT)</td>
                                                        <td>NAS410 Level 3 - RT</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Eddy Current (ET)</td>
                                                        <td>NAS410 Level 3 - ET</td>
                                                    </tr>
                                                    <tr style="background: rgba(242, 97, 60, 0.07);">
                                                        <td>Ultrasonic (UT)</td>
                                                        <td>NAS410 Level 3 - UT</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="xl:px-0">
            <div class="inset-0 xl:px-0">
                <div class="left-0 right-0 w-full mx-auto bg-center shadow-xl xl:px-10 xl:h-auto"
                    style="background:  rgb(242 97 60);">
                    <div class="py-6 text-center xl:py-14">
                        <p class="text-sm uppercase fw-600 text-white text-primary-500">JOIN QAPIN </p>
                        <h4 class="mt-2 text-xl text-white fw-bold md:text-5xl">
                            Register for Qapin today
                        </h4>
                        <p class="max-w-sm mx-auto mt-4 text-sm text-white xl:text-lg fw-200 xl:w-auto"
                            style="max-width: 60rem;">
                            it’s the future of inspection services. Bringing the inspection community
                            together in one easy to use platform, Qapin is simple, free and convenient
                            but don’t take our word for it - create your
                        </p>
                        <div class="px-10 mt-6 xl:px-0">
                            <button @click="$router.push('/login').catch(()=>{})"
                                class="w-40 px-4 py-2 text-base text-black transition-all duration-500 ease-in-out rounded-md bg-white shadow-sm cursor-pointer md:w-52 hover:bg-opacity-50 hover:text-black fw-200 xl:w-auto bg-primary-500 hover:shadow-lg">
                                Join for Free
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!-- 
        <TheHomeSiteFooter class="" v-if="!isAuth" /> -->
    </div>
</template>
  
<script>
// import TheHomeSiteHeader from "../../TheHomeSiteHeader.vue";
// import TheHomeSiteHeader from "../../components/TheHomeSiteHeader.vue";
// import TheHomeSiteFooter from "./TheHomeSiteFooter.vue";
import "tiny-slider/src/tiny-slider.scss";
export default {
    components: {
        // TheHomeSiteFooter,
        //  TheHomeSiteHeader
    },
    data() {
        return {
            isAuth: null,
            openTab: 1
        }
    },
    methods: {
        toggleTabs: function (tabNumber) {
            this.openTab = tabNumber
        }
    },
    mounted() {
        this.isAuth = localStorage.getItem("token");
        if (this.$route.name == "ExpireLink") {
            this.isAuth = null;
        }
        this.getCurrentUserDetails();
    },
}
</script>
<style scoped>
.made-with-love {
    margin-top: 40px;
    padding: 10px;
    clear: left;
    text-align: center;
    font-size: 10px;
    font-family: arial;
    color: #fff;
}

.made-with-love i {
    font-style: normal;
    color: #F50057;
    font-size: 14px;
    position: relative;
    top: 2px;
}

.made-with-love a {
    color: #fff;
    text-decoration: none;
}

.made-with-love a:hover {
    text-decoration: underline;
}

table th {
    background-color: #f2613c !important;
    border-radius: 4px;

}

.table-box {
    margin: 20px;
}

/* for custom scrollbar for webkit browser*/

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* h1 {
    font-size: 30px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 300;
    text-align: center;
    margin-bottom: 15px;
} */

table {
    width: 100%;
    table-layout: fixed;
}

.tbl-content {
    height: auto;
    overflow-x: auto;
    margin-top: 0px;

}

th {
    padding: 13px 15px !important;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
}

table tbody {
    border: 0px solid #0000001f !important;
}

td {
    padding: 15px;
    text-align: center;
    vertical-align: middle;
    font-weight: 500;
    font-size: 17px;
    color: #000000;
}

.text-xs {
    padding: 8px 60px !important;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
}

.bg-pink-600 {
    background-color: #f2613c !important;
    cursor: pointer;
}

.text-pink-600 {
    color: #f2613c !important;
    cursor: pointer;
}

.css-144ctnr {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-flow: row nowrap;
    column-gap: 64px;
    padding: 126px 64px;
    min-height: 567px;
    background: rgba(242, 97, 60, 0.07);
}

.css-79e8z3 {
    margin: 0px;
    letter-spacing: 0.00938em;
    color: rgb(13, 32, 73);
    font-family: Silka, Helvetica, sans-serif;
    line-height: 170%;
    background-color: rgb(191 153 153 / 20%);
    padding: 4px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: 14px !important;
}

.css-12k8mko {
    margin: 0px;
    color: rgb(6, 18, 44);
    font-family: Silka, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    max-width: 600px;
}

.css-1plhbgk {
    margin: 0px;
    letter-spacing: 0.00938em;
    color: rgb(28, 28, 28);
    font-family: Silka, Helvetica, sans-serif !important;
    line-height: 170% !important;
    font-weight: 400 !important;
    font-size: 18px !important;
}

.css-1wfjlp {
    margin-top: 0px;
    letter-spacing: 0.00938em;
    color: rgb(16, 16, 16);
    font-family: Silka, Helvetica, sans-serif;
    line-height: 120%;
    font-weight: 700 !important;
    font-size: 64px !important;
}

.css-ajm3pi {
    margin: 0px 0px 24px;
    letter-spacing: 0.00735em;
    color: rgb(16, 16, 16);
    font-family: Silka, Helvetica, sans-serif;
    line-height: 43px !important;
    font-weight: 700 !important;
    max-width: 600px !important;
    font-size: 28px !important;
}

.hero-text {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    line-height: 75px;
}

.margin-15 {
    margin: 0px;
    color: rgb(6, 18, 44);
    font-family: Silka, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;

}

.agenct-scale {
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.36;
    margin-bottom: 16px;
    margin-top: 0;
    text-decoration: none;
}

@media (min-width: 1280px) {
    .xl\:text-6xl {
        font-size: 4.75rem;
        line-height: 1.2;
    }

    .xl\:pt-40 {
        padding-top: 12rem;
    }
    .xl\:max-w-7xl {
    max-width: 85rem;
}
}
@media (max-width: 480px) {

.mt-24{
  margin-top: 0px;
}
.pb-24 {
  padding-bottom: 0px;
}



.css-ajm3pi {
  max-width: 40rem;
  padding-top: 0px;
  margin: 0px 0px 24px;
  letter-spacing: 0.00735em;
  color: rgb(16, 16, 16);
  font-family: Silka, Helvetica, sans-serif;
  line-height: 43px !important;
  font-weight: 700 !important;
  max-width: 600px !important;
  font-size: 25px !important;
}

.css-1wfjlp {
  margin-top: 0px;
  letter-spacing: 0.00938em;
  color: rgb(16, 16, 16);
  font-family: Silka, Helvetica, sans-serif;
  line-height: 120%;
  font-weight: 700 !important;
  font-size: 28px !important;
}
}
@media screen and (min-width: 320px) and (max-width: 815px) {
    .mb-20{
        margin-bottom:0px!important;
    }
}
</style>
  