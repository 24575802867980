<template>
  <a>
  <!--<footer
    v-if="(isAuth && $store.state.isNewsfeed && this.$route.name!='Login' && this.$route.name!='chat' && this.$route.name !='BasicInfo' && this.$route.name !='ExpireLink' && this.$route.name!='ForgotPassword' && this.$route.name!='update-profile') || this.$route.name =='agencyPublicProfile' || this.$route.name=='freelancerProfile'"
    id="social"
    class="py-5 pb-3 bg-black footer-area section-gap foot"
  >-->
  <footer
    v-if="(this.$route.name!='Login' && this.$route.name!='chat' && this.$route.name !='BasicInfo' && this.$route.name !='ExpireLink' && this.$route.name!='ForgotPassword' && this.$route.name!='update-profile' && this.$route.name!='NewsFeed') || this.$route.name =='agencyPublicProfile' || this.$route.name=='freelancerProfile'"
    id="social"
    class="py-5 pb-3 bg-black footer-area section-gap foot"
  >
    <b-container>
      <b-row class="mb-30 footer-menu">
        <b-col lg="2" md="12" sm="12">
          <div class="single-footer-widget">
           
            <div class="align-items-center ul-head">
              <h6>Navigate</h6>
              <ul id="list2" class="footer-menu-list list2">
                <li class="cursor-pointer"><a class="color-w" @click="$router.push('/home')" >Home</a></li>
                <li class="cursor-pointer"><a class="color-w" @click="$router.push({'name':'post-job','path':'/client/post-job/','params':{pid:'POID'}})">Post a Job</a></li>
                <li class="cursor-pointer"><a class="color-w" @click="$router.push('/client/search')">Find a Freelancer</a></li>
                <li class="cursor-pointer"><a class="color-w" @click="$router.push('/freelancer/search')">Find a Job</a></li>
                <li class="cursor-pointer"><a class="color-w" @click="$router.push('/agency-solutions')" >Agency Solutions</a></li>
                <li class="cursor-pointer"><a class="color-w" @click="$router.push('/certifications')" >Certification List</a></li>
              </ul>
            </div>
          </div>
        </b-col>

        <b-col lg="2" md="12" sm="12" class="social-widget">
          <div class="single-footer-widget">
           
            <div class="align-items-center ul-head">
              <h6>Company Info</h6>
              <ul id="list2" class="footer-menu-list list2">
                <li><a class="color-w" @click="$router.push('/about')" href="#top">About Qapin</a></li>
                <li><a class="color-w" @click="$router.push('/how-it-work')" href="#top">How Qapin Works</a></li>
                <li><a class="color-w" @click="$router.push('/why-qapin')"  href="#top">Why Qapin</a></li>
                <!-- <li><a href="#top">Work Agreements</a></li> -->
                <li><a class="color-w" @click="$router.push('/safe-payments')"  href="#top">SafePay</a></li>
                <li><a class="color-w" @click="$router.push('/pricing')"  href="#top">Pricing</a></li>
                <!--<li><a @click="$router.push('/demo')"  href="#top">Demo</a></li>-->
              </ul>
            </div>
          </div>
        </b-col>
        <b-col lg="2" md="12" sm="12" class="social-widget">
          <div class="single-footer-widget">
            
            <div class="align-items-center ul-head">
              <h6>Resources</h6>
              <ul id="list2" class="footer-menu-list list2">
                <li><a class="color-w" @click="$router.push('/faqs')" href="#top">Help & FAQ</a></li>
                <li><a class="color-w" href="https://blog.qapin.com">Blog</a></li>
                <li><a class="color-w" @click="$router.push('/contact-us')" href="#top">Contact Us</a></li>
                <!-- <li><a href="#top">Cookie Settings</a></li> -->
                <li><a class="color-w" @click="$router.push('/global-expansion')" href="#top">Global Expansion</a></li>
                
              </ul>
            </div>
          </div>
        </b-col>
        <b-col lg="2" md="12" sm="12" class="social-widget">
          <div class="single-footer-widget">
         
            <div class="align-items-center ul-head">
              <h6>Policies</h6>
              <ul id="list2" class="footer-menu-list list2">
                <!-- <li><a href="#top">IP Policy</a></li> -->
                <li><a class="color-w" @click="$router.push('/privacy-policy')"  href="#top">Privacy Policy</a></li>
                <li><a class="color-w" @click="$router.push('/terms-of-service')" href="#top">Terms of Service</a></li>
              </ul>
              <h6>Important Links</h6>
              <ul id="list2" class="footer-menu-list list2">
                <li><a class="color-w" @click="$router.push('/demo')" href="#top">Request a Demo</a></li>
                <li><a class="color-w" @click="$router.push('/enterprise')" href="#top">Enterprise</a></li>
              </ul>
            </div>
          </div>
        </b-col>
        <b-col lg="4" md="12" sm="12" class="social-widget">
          <div class="single-footer-widget">
        
            <div class="footer-social ul-head">
              <h6>Connect With Us</h6>
            <ul class="d-flex align-items-center ">
              <li class="mr-2">
                <a target="blank" href="https://www.facebook.com/qapinInc" class="border br-50 py-1 px-2 w-10 h-10 block color-w">
                  <i class="fa fa-facebook" aria-hidden="true"></i>
                </a>
              </li>
              <li class="mr-2">
                <a target="blank" href="https://twitter.com/QapinInc" class="border br-50 py-2 px-2 w-10 h-10 block color-w">
                  <!-- <i class="fa-x-twitter"></i> -->
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
                </a>
              </li>
              <li class="mr-2">
                <a target="blank" href="https://www.instagram.com/qapininc/" class="border br-50 py-1 px-2 w-10 h-10 block color-w">
                  <i class="fa fa-instagram" aria-hidden="true"></i>
                </a>
              </li>
              <li class="mr-2">
                <a target="blank" href="https://www.linkedin.com/company/qapin-com/" class="border br-50 py-1 px-2 w-10 h-10 block color-w">
                  <i class="fa fa-linkedin" aria-hidden="true"></i>
                </a>
              </li>
              <li class="mr-2">
                <a target="blank" href="https://in.pinterest.com/qapinmarketplace/" class="border br-50 py-1 px-2 w-10 h-10 block color-w">
                  <i class="fa fa-pinterest" aria-hidden="true"></i>
                </a>
              </li>
              <li class="mr-2">
                <a target="blank" href="https://www.youtube.com/@qapin" class="border br-50 py-1 px-2 w-10 h-10 block color-w">
                  <i class="fa fa-youtube-play"></i>
                </a>
              </li>
              
            </ul>
          </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="footer-bottom pt-30">
        <b-col lg="6" md="12" sm="12" class="footer-logo">
          <a @click="$router.push('/')">
            <WebLogo class="lg:w-full lg:h-full" />
          </a>
        </b-col>
        <b-col lg="6" md="12" sm="12" class="footer-copyright">
          <p class="copyright text-right mb-0" style="margin-bottom:0px!important;">Copyright © Qapin - All Rights Reserved.</p>
        </b-col>
      </b-row>
    </b-container>
  </footer>
  <!--<TheHomeSiteFooter v-else-if="(!isAuth && $store.state.isNewsfeed && this.$route.name!='Login' && this.$route.name!='chat' && this.$route.name !='BasicInfo' && this.$route.name !='ExpireLink' && this.$route.name!='ForgotPassword' && this.$route.name!='update-profile') || this.$route.name =='agencyPublicProfile' || this.$route.name=='freelancerProfile'" />-->

  </a>
  
</template>

<script>
import { mapGetters } from "vuex";
// import TheHomeSiteFooter from './TheHomeSiteFooter.vue'
//import TheHomeSiteFooter from "../views/landing-home/TheHomeSiteFooter.vue";
import WebLogo from "./icons/WebLogo.vue";
export default {
  props: ["username"],
  components: {
    WebLogo,
    //TheHomeSiteFooter
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["isNewsfeed"]),
    isAuth(){
      return this.$store.state.currentUserData;
    },
  },
  mounted() {
  },
};
</script>

<style scoped>

@media (min-width: 1400px)
{
.container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1300px !important;
} 
}

.ul-head h6{
  padding-left: 2rem!important;

}

/*.footer-menu{
  padding-left: 100px;
}*/

.single-footer-widget h6 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-size: 20px!important;
  font-weight: 700!important;
  color: #fff;
  line-height: 25px;
}
.single-footer-widget li a {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #bcbcbc;
}

.single-footer-widget li span {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #bcbcbc;
}
.single-footer-widget li .fa {
  padding: 4px;
}

a {
  text-decoration: none;
}
ul li a {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #666666;
}
ul li span {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #666666;
}
p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #999999;
}
.copyright {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #bcbcbc;
}
.footer-bottom {
  border-top: 1px solid rgb(255, 255, 255, 0.15);
}
.footer-bottom ul li{
  width: 38px;
    height: 38px;
    padding: 4px 10px;
    margin-right: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 19px;
  box-shadow: 0 7px 17px 0 rgb(4 27 53 / 5%);
    text-align: center;
}

p {
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
}
h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0 !important;
  margin-bottom: 0.5rem !important;
  font-weight: 500 !important;
  line-height: 1.2 !important;
}
</style>
