<template>
    <!-- model for confirm reject -->
    <b-modal id="confirm-modal" title="Remove Billing" centered hide-footer size="md" no-close-on-backdrop> 
            <template>
            <div class="p-5 text-center m-auto">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#F2613C" class="bi bi-trash m-auto" viewBox="0 0 16 16">
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                </svg>
                <h5 class="mt-4">{{ title }}</h5>
            </div>
            <hr>
            <div>
                <div class="text-right">
                    <a href="javascript:void(0);" class="text-theme mr-2" @click="rejectRequest()">Cancel</a>
                    <b-button type="button" class="btn btn-theme" @click="acceptRequest()">Confirm</b-button>
                </div>
            </div>
            </template>
        </b-modal>
</template>
<script>
    import { mapActions } from "vuex";
    export default {
        props       : ['title'],
        computed    : {
            
        },
        data() {
            return {
            
            }
        },
        methods: {
            ...mapActions(["genericAPIPOSTRequest"]),
            async acceptRequest(){
                this.$emit('accept');
            },
            async rejectRequest(){
                this.$emit('reject');
            },
        },
        mounted(){
            
        }
    }
</script>