<template>
    <b-sidebar id="sidebar-message" ref="mySidebar" title="" shadow right backdrop class="sidebar-message"
        no-close-on-backdrop @change="sideBarManagement();updateHtmlClass()">
        <div class="px-3 py-2 br-20">
            <b-card-group deck>
                <b-card title-class="text-theme font-14" header-tag="header" footer-tag="footer" class="br-20" header-class="py-4 px-4 bg-white br-20">
                    <template #header>
                        <div class="d-md-flex align-items-center">
                            <h4 class="mb-0 fz-24 fw-500 text-black"><b>Message</b></h4>
                            <div class="ml-auto text-theme d-flex align-items-center">
                                <a class="d-flex align-items-center" :href="$router.resolve({name: 'messageDetails',path:'/client/jobpostreview/message-details/',params: {pid:job_id,uid:messageData.userDetails.user_id,cpn:pageName}}).href"
                                    target="_blank">
                                    <div class="up-icon w-3 mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#F2613C" aria-hidden="true"
                                            viewBox="0 0 14 14" role="img">
                                            <path
                                                d="M12 7.252h2V14H0V0h6.602v2.014H2v9.971h10V7.252zM8.602 0v2.014h2.088L6.795 5.935l1.414 1.424L12 3.54v1.898h2V0H8.602z">
                                            </path>
                                        </svg>
                                    </div>
                                    <span class="text-theme">Open job in a new window</span>
                                </a>
                            </div>
                        </div>
                    </template>
                    <b-row>
                        <b-col lg="12" xl="12" sm="12" class="p-0">
                            <div class="bordered border-bottom msg-details-wrappper">
                                <div class="py-4 px-4">
                                    <div class="d-md-flex align-items-start text-left">
                                        <div class="img-wrapper mr-3">
                                            <img :src="messageData.userDetails.profile_picture_path_url"
                                                class="img-fluid">
                                            <span class="online" :class="(checkUserOnlineOrOffline(messageData.userDetails) == true) ? 'bg-success' : ''"></span>
                                        </div>
                                        <div class="content ml-2">
                                            <h4 class="fz-20 fw-600 text-black mr-2 mb-0">
                                                {{shortName(messageData.userDetails.first_name,messageData.userDetails.last_name)}}</h4>
                                            <ul class="mb-0 d-flex align-items-center minus-margin">
                                                <li>
                                                    <svg width="24" height="29" viewBox="0 0 24 29" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M11.9999 5.28027C8.37764 5.28027 5.40918 8.40136 5.40918 12.2748C5.40918 14.3585 6.46842 16.8771 8.23787 19.6617C8.85278 20.6294 9.51552 21.5658 10.1854 22.4404C10.6054 22.9889 10.952 23.4148 11.1785 23.6801C11.6242 24.202 12.3759 24.2018 12.8214 23.68C13.0479 23.4148 13.3945 22.9889 13.8145 22.4404C14.4844 21.5658 15.1471 20.6294 15.762 19.6617C17.5315 16.8771 18.5907 14.3585 18.5907 12.2748C18.5906 8.40135 15.6222 5.28027 11.9999 5.28027ZM9.18859 18.847C7.55932 16.283 6.59072 13.9799 6.59072 12.2748C6.59072 9.19124 9.00544 6.65236 11.9999 6.65236C14.9944 6.65236 17.4091 9.19123 17.4092 12.2748C17.4092 13.9799 16.4406 16.283 14.8113 18.847C13.7963 20.4444 12.7477 21.8156 11.9999 22.6934C11.2521 21.8155 10.2036 20.4443 9.18859 18.847ZM12 14.7608C10.7673 14.7608 9.77138 13.5702 9.77138 12.1142C9.77138 10.6582 10.7673 9.4677 12 9.4677C13.2326 9.4677 14.2285 10.6582 14.2285 12.1142C14.2285 13.5703 13.2326 14.7608 12 14.7608ZM12 13.5663C12.6578 13.5663 13.1999 12.9182 13.1999 12.1142C13.1999 11.3103 12.6578 10.6621 12 10.6621C11.3421 10.6621 10.7999 11.3103 10.7999 12.1142C10.7999 12.9182 11.3421 13.5663 12 13.5663Z"
                                                            fill="#999999" />
                                                    </svg>
                                                </li>
                                                <li>{{messageData.userDetails.country_name+', '+messageData.userDetails.state_name+', '+messageData.userDetails.city_name }}
                                                    <!-- - 10:52 am local time-->
                                                </li>
                                            </ul>
                                            <!-- <h6 class="mb-0"><a class="text-theme">View Profile</a></h6> -->
                                            <h6 class="mb-0">
                                                <a class="text-theme" href="javascript:void(0);"
                                                    @click="updateHtmlClass();$router.push({name:'ViewProfile',path:'/client/jobpostreview/ViewProfile',params:{pid:job_id,uid:messageData.userDetails.user_id}}).catch(()=>{})">
                                                    View Profile
                                                </a>
                                            </h6>
                                            
                                            <div class="weightage-wrap">
                                                <h5 class="mb-0 percentage fz-1rem">{{messageData.userDetails.profileSucessRate}}%</h5>
                                                <b-progress variant="success" :striped="striped" height="5px"
                                                    :value="messageData.userDetails.profileSucessRate" :max="max"
                                                     animated></b-progress>
                                                <span class="mb-0 text-success"
                                                    v-if="messageData.userDetails.profileSucessRate < 20">Incomplete</span>
                                                <span class="mb-0 text-success"
                                                    v-else-if="messageData.userDetails.profileSucessRate < 50">Success</span>
                                                <span class="mb-0 text-success"
                                                    v-else-if="messageData.userDetails.profileSucessRate < 75">Success</span>
                                                <span class="mb-0 text-success"
                                                    v-else-if="messageData.userDetails.profileSucessRate < 90">Success</span>
                                                <span class="mb-0 text-success"
                                                    v-else-if="messageData.userDetails.profileSucessRate == 100">Completed</span>
                                            </div>
                                        </div>
                                        <div class="ml-auto d-flex align-items-center" v-if="pageName != 'archived'">
                                            <b-dropdown size="md" right class="mr-2 mt-0 t-0"
                                                variant="outline-secondary" toggle-class="text-decoration-none"
                                                no-caret>
                                                <template #button-content>
                                                    <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                                                </template>
                                                <b-dropdown-item href="javascript:void(0);">
                                                    <div class="d-flex align-items-center" v-b-modal.notemodal>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                            fill="currentColor" class="bi bi-journals mr-2"
                                                            viewBox="0 0 16 16">
                                                            <path
                                                                d="M5 0h8a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2 2 2 0 0 1-2 2H3a2 2 0 0 1-2-2h1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1H1a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v9a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1H3a2 2 0 0 1 2-2z" />
                                                            <path
                                                                d="M1 6v-.5a.5.5 0 0 1 1 0V6h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V9h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 2.5v.5H.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1H2v-.5a.5.5 0 0 0-1 0z" />
                                                        </svg>
                                                        Add notes
                                                    </div>
                                                </b-dropdown-item>
                                                <b-dropdown-item href="javascript:void(0);" v-if="pageName == 'allproposal'">
                                                    <div class="d-flex align-items-center" v-b-modal.decline-modal>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-octagon mr-2" viewBox="0 0 16 16">
                                                            <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z"/>
                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                                        </svg>
                                                        Decline Candidate
                                                    </div>
                                                </b-dropdown-item>
                                                <b-dropdown-item href="javascript:void(0);">
                                                    <div class="d-flex align-items-center" v-b-modal.flagging-modal>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-slash-circle mr-2" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                            <path d="M11.354 4.646a.5.5 0 0 0-.708 0l-6 6a.5.5 0 0 0 .708.708l6-6a.5.5 0 0 0 0-.708z"/>
                                                        </svg>
                                                        Flag as inappropriate
                                                    </div>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                            <span v-if="(pageName == 'allproposal' || pageName == 'shortlisted') && contractData.proposalDetails.application_status != 2 && contractData.proposalDetails.application_status > 4">
                                                <b-button class="mr-2 btn-outline-theme"
                                                    v-if="contractData.proposalDetails.application_status != 3"
                                                    @click="proposalLike(3)">Shortlist</b-button>
                                                <b-button class="mr-2 btn-outline-theme" v-else>Shortlisted</b-button>
                                            </span>
                                            <template v-if="pageName != 'offers' && pageName != 'hired'">
                                                <a class="mr-2 btn-theme" v-if="isPermissionForClient('hire-btn') && contractData.proposalDetails.application_status != 7 && contractData.proposalDetails.application_status !=2" target="_blank" :href="$router.resolve({name:'hiredetails',path:'/client/hire-details',params:{ 'pid':job_id,'uid':encodedid(messageData.userDetails.user_id)}}).href">Hire</a>
                                            </template>
                                            

                                            <b-button variant="outline-secondary"
                                                :class="(messageData.favorite.length>0)?'saved-btn':''"
                                                :id="'favorite-'+messageData.userDetails.user_id"
                                                @click="addFavorite(messageData.userDetails.user_id)"><i
                                                    class="fa fa-heart-o" aria-hidden="true"></i>
                                            </b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                        <b-col lg="4" xl="4" sm="12" class="p-0">
                            <div class="border-bottom text-left py-4 px-4">
                                <h6 class="fw-500"><b>Applicant</b></h6>
                                <p>{{messageData.userDetails.first_name+' '+messageData.userDetails.last_name}} has
                                    applied to or been invited to your or your company's <i>{{jobDetails.job_title}}</i></p>
                            </div>
                            <div class="border-bottom text-left py-4 px-4">
                                <h5><b>How they match</b></h5>
                                <p>Worked on <a href="javascript:void(0);"
                                        class="text-theme">{{messageData.similarjobHistory.length}}jobs</a> like yours
                                </p>
                            </div>
                        </b-col>
                        <b-col lg="8" xl="8" sm="12" class="p-0">
                            <div class="border-left text-left py-4 px-4" v-if="contractData.proposalDetails!=''">
                                <div class="d-md-flex align-items-center">
                                    <h4 class="mb-4 fz-24 fw-500 text-black"><b>Proposal Details</b></h4>
                                    <div class="ml-auto">
                                        <h4 class="mb-0 fz-24 fw-500 text-black"><b>${{contractData.proposalDetails.cost}}</b></h4>
                                        <p class="mb-0 fz-18">Proposed Bid</p>
                                    </div>
                                </div>
                                <h5 class="mb-4 fw-600">Cover letter</h5>
                                <!-- <vue-read-more-smooth :lines="2" class="mt-2"  v-if="contractData.proposalDetails.cover_letter!=''">
                                    <p class="mb-2"> {{contractData.proposalDetails.cover_letter}}
                                    </p>
                                </vue-read-more-smooth> -->
                                <p v-if="contractData.proposalDetails.cover_letter!=''" v-html=" getHtmlContent(contractData.proposalDetails.cover_letter) " ></p>
                                    <!-- <read-more more-str="Read more" :text="getHtmlContent(contractData.proposalDetails.cover_letter)" link="#" less-str="Read less" :max-chars="250"></read-more> -->
                                <!-- </p> -->
                                <br>
                                <div class="thanks-wrap mb-4">
                                    <div>
                                        <ul  class="ml-2 pl-0 mt-4 attachment "  v-if="contractData.proposalDetails.attachment" style="padding: 6px 0px;">
                                            <template v-for="(attc, index) in contractData.proposalDetails.attachment">
                                            <li class="attatchment-wrap mr-5 mb-2"  :key="index" v-if="index >= 0" style="border-radius: 20px;padding: 2px 10px;margin-right: 5px;">
                                                <i class="fa fa-paperclip border-none text-black fz-18 rotate-45"></i>
                                                <a :href="contractData.proposalDetails.attachmentwithUrl+'/'+attc" target="_blank"><span class="ml-2 text-theme">{{attc}}</span></a>
                                            </li>
                                            </template>
                                        </ul>
                                    </div>
                                </div>
                                <hr>
                                <span v-if="contractData.proposalDetails.question">
                                    <span v-for="(ques,index) in contractData.proposalDetails.question" :key="index">
                                        <h6>{{index+1}}. {{ques.text}}</h6>
                                        <p class="ml-4"> {{ques.answer}}</p>
                                        <hr>
                                    </span>
                                </span>
                                <div class="mb-4" v-if="contractData.originalMilestoneDetails.length > 0">
                                    <table class="table ">
                                        <thead >
                                            <tr>
                                                <th >#</th>
                                                <th >Description</th>
                                                <th >Delivery Date</th>
                                                <th >Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(milestone,index) in contractData.originalMilestoneDetails" :key="index">
                                                <td scope="row">{{ parseInt(index)+1 }}</td>
                                                <td>{{ milestone.description }}</td>
                                                <td>{{ milestone.dueDate }}</td>
                                                <td>{{ milestone.amount }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <hr v-if="contractData.originalMilestoneDetails.length > 0">
                                <div class="chat-wrap" style="height:500px;" v-if="pageName != 'archived'">
                                    <h5 class="fw-600">Chat with {{shortName(messageData.userDetails.first_name,messageData.userDetails.last_name)}}</h5>
                                    <b-col xl="12" lg="12" sm="12" class="conversation">
                                        <chatBoxData ref="oprateChatboxs" :chatroomdata="chatroomdata" :pageType="'sidebar'" />
                                    </b-col>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-card-group>
            <b-card-group deck class="mt-4">
                <b-card title-class="text-theme font-14" header-tag="header" footer-tag="footer"
                    header-class="py-4 px-4 bg-white">
                    <b-row>
                        <b-col lg="4" xl="4" sm="12" class="p-0">
                            <div class="border-bottom text-left py-4 px-4 d-flex align-items-center justify-content-space-between">
                                <div>
                                    <h6><b>${{totalEarning}}</b></h6>
                                    <p class="fz-14 fw-600">Total Earnings</p>
                                </div>
                                <div>
                                    <h6><b>{{messageData.jobHistory.length}}</b></h6>
                                    <p class="fz-14 fw-600">Project Completed</p>
                                </div>
                            </div>
                            <div class="border-bottom text-left py-4 px-4">
                                <h5><b>Hours per week</b></h5>
                                <p class="mb-4">{{(messageData.userDetails.availabilityName!='' && messageData.userDetails.availabilityName!=null)?messageData.userDetails.availabilityName:'Not Found'}}<br>
                                    <!--<i class="fa fa-chevron-left fz-14" aria-hidden="true"></i> 24 hrs response time-->
                                </p>

                                <h5><b>Languages</b></h5>
                                <p class="mb-4" v-for="(lang,index) in messageData.userDetails.language_arr" :key="index">
                                    <span>{{lang}} :</span>
                                    <span v-if="messageData.userDetails.proficiency_id[index] == 1">Basic</span>
                                    <span v-else-if="messageData.userDetails.proficiency_id[index] == 2">Conversational</span>
                                    <span v-else-if="messageData.userDetails.proficiency_id[index] == 3">Fluent</span>
                                    <span v-else-if="messageData.userDetails.proficiency_id[index] == 4">Native or Bilingual</span>
                                </p>

                                <h5><b>Verification</b></h5>
                                <p class="mb-4"><b>ID:</b> Verified</p>

                                <h5><b>Education</b></h5>
                                <span v-if="messageData.education.length > 0">
                                    <div class="edu-wrap mb-4" v-for="(education,index) in messageData.education"
                                        :key="index">
                                        <p class="mb-1"><b>{{education.university_name}}</b></p>
                                        <p class="mb-0">{{education.course_name+'('+education.start_year+'-'+education.end_year+')'}}</p>
                                        <!-- <vue-read-more-smooth :lines="2" class="mt-2"  v-if="education.education_description!=''">
                                            <p class="mb-0"> {{education.education_description}}
                                            </p>
                                        </vue-read-more-smooth> -->
                                        <p class="mb-0" v-if="education.education_description!=''">
                                            <read-more more-str="Read more" :text="education.education_description" link="#" less-str="Read less" :max-chars="50"></read-more>
                                        </p>
                                    </div>
                                </span>
                                <span v-else>
                                    <p class="mb-1">Not Found</p>
                                </span>
                                <template v-if="contractData.proposalDetails.agency_id > 0 ">
                                    <h5><b>Apply with</b></h5>
                                    <div class="d-flex align-items-center mt-3">
                                        <div class="img-associate">
                                            <img :src="contractData.proposalDetails.agencyData.profile_picture_path" alt="" class="rounded-md shadow w-10 h-10">
                                        </div>
                                        <div class="ml-2">
                                            <a href="javascript:void(0);" class="d-flex text-black">
                                                <p class="mb-0">{{contractData.proposalDetails.agencyData.first_name}}</p>
                                            </a>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </b-col>
                        <b-col lg="8" xl="8" sm="12" class="p-0">
                            <div class="border-left border-bottom text-left py-4 px-4" v-if="messageData.userDetails!=''">
                                <div class="d-md-flex align-items-center">
                                    <h4 class="mb-4 fz-24 fw-500 text-black w-80"><b>{{messageData.userDetails.tagline}}</b></h4>
                                    <div class="ml-auto">
                                        <h4 class="mb-4 fz-24 fw-500 text-black"><b>${{messageData.userDetails.hourly_rate}}/Day</b></h4>
                                    </div>
                                </div>
                                <!-- <vue-read-more-smooth :lines="2" class="mt-2"  v-if="messageData.userDetails.about_self_desc!=''"> -->
                                    <p class="mb-2"> 
                                        <read-more more-str="Read more" :text="getHtmlContent(messageData.userDetails.about_self_desc)" link="#" less-str="Read less" :max-chars="250"></read-more>
                                    </p>
                                <!-- </vue-read-more-smooth> -->
                            </div>
                            <div class="border-left border-bottom text-left py-4 px-4" v-if="messageData.jobHistory.length > 0">
                                <div class="d-md-flex align-items-center mb-3">
                                    <h5 class="mr-2 mb-0 fw-600"><b>Work History</b></h5>
                                </div>
                                <div class="tabs-wrapper work-history-wrapper">
                                    <b-tabs>
                                        <b-tab :title="'Completed jobs ('+messageData.jobHistory.length+')' " active>
                                            <!-- <template v-if="messageData.jobHistory.length > 0">
                                                <template v-for="(history,index) in messageData.jobHistory">
                                                    <b-card-text class="border-bottom"  :key="index" v-if="index+1 <= competedJobView">
                                                        <a href="javascript:void(0);" class="text-theme titles-text">
                                                            <h6>{{history.job_title}}</h6>
                                                        </a>
                                                        <div class="d-flex align-items-center">
                                                            <h6 class="mr-2 mb-0"><b>${{history.cost}}</b></h6>
                                                            <h6 class="mb-0">{{history.from_date+' - '+history.to_date}}</h6>
                                                        </div>
                                                        <div class="d-flex align-items-center mt-2">
                                                            <div class="star-rating mr-2" v-if="history.from_rating !='' && history.to_rating!='' && history.from_rating !=null && history.to_rating!=null">
                                                                <i class="fa fa-star  fz-14" :class="(history.to_rating >=1 )?'text-theme':''" aria-hidden="true"></i>
                                                                <i class="fa fa-star  fz-14" :class="(history.to_rating >=2 )?'text-theme':''" aria-hidden="true"></i>
                                                                <i class="fa fa-star  fz-14" :class="(history.to_rating >=3 )?'text-theme':''" aria-hidden="true"></i>
                                                                <i class="fa fa-star  fz-14" :class="(history.to_rating >=4 )?'text-theme':''" aria-hidden="true"></i>
                                                                <i class="fa fa-star  fz-14" :class="(history.to_rating ==5 )?'text-theme':''" aria-hidden="true"></i>
                                                            </div>
                                                            <div v-else>
                                                                <p>No feedback given.</p>
                                                            </div>
                                                        </div>
                                                        <p class="text-muted mt-1" v-if="history.from_rating !='' && history.to_rating!='' && history.from_rating !=null && history.to_rating!=null">
                                                            {{ history.to_review }}
                                                        </p>
                                                    </b-card-text>
                                                </template>
                                                <template v-if="messageData.jobHistory.length > competedJobView">
                                                    <p class="text-theme cursor-pointer" @click="competedJobView += 3">View more</p>
                                                </template>
                                            </template> -->
                                            <template v-if="messageData.jobHistory.length > 0">
                                                <template v-for="(history,index) in messageData.jobHistory">
                                                    <b-card-text class="border-bottom"
                                                        :key="index" v-if="index+1 <= competedJobView">
                                                        <a href="javascript:void(0);" class="text-theme">
                                                            <h6>{{history.job_title}}</h6>
                                                        </a>
                                                        <div class="d-flex align-items-center">
                                                            <div class="star-rating mr-2" v-if="history.from_rating !='' && history.to_rating!='' && history.from_rating !=null && history.to_rating!=null">
                                                                <i class="fa fa-star  fz-14" :class="(history.to_rating >=1 )?'text-theme':''" aria-hidden="true"></i>
                                                                <i class="fa fa-star  fz-14" :class="(history.to_rating >=2 )?'text-theme':''" aria-hidden="true"></i>
                                                                <i class="fa fa-star  fz-14" :class="(history.to_rating >=3 )?'text-theme':''" aria-hidden="true"></i>
                                                                <i class="fa fa-star  fz-14" :class="(history.to_rating >=4 )?'text-theme':''" aria-hidden="true"></i>
                                                                <i class="fa fa-star  fz-14" :class="(history.to_rating ==5 )?'text-theme':''" aria-hidden="true"></i>
                                                            </div>
                                                            <div class="star-rating mr-2" v-else>
                                                                <p>No feedback given.</p>
                                                            </div> 
                                                            {{(history.from_rating !='' && history.to_rating!='' && history.from_rating !=null && history.to_rating!=null)?' | ':''}} 
                                                            <h6 class=" mr-2 mb-0" :class="(history.from_rating !='' && history.to_rating!='' && history.from_rating !=null && history.to_rating!=null)?'ml-2':''"><b>${{history.cost+' | '}}</b></h6>
                                                            <h6 class="ml-0 mb-0">{{history.contractStart+' - '+history.contractEnd}}</h6>
                                                        </div>
                                                        <p class="text-muted mt-1" v-if="history.from_rating !='' && history.to_rating!='' && history.from_rating !=null && history.to_rating!=null">
                                                            {{ history.to_review }}
                                                        </p>
                                                    </b-card-text>
                                                </template>
                                                <template v-if="messageData.jobHistory.length > competedJobView">
                                                    <p class="text-theme cursor-pointer" @click="competedJobView += 3">View more</p>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <div class="text-center">
                                                    <svg width="149" height="149" viewBox="0 0 149 149" fill="none" xmlns="http://www.w3.org/2000/svg" class="m-auto">
                                                        <path d="M65.7535 6.47396C70.8988 2.90571 77.7184 2.90571 82.8637 6.47396C86.1714 8.76789 90.2618 9.63733 94.2166 8.88711C100.368 7.72012 106.598 10.4939 109.848 15.8464C111.936 19.2874 115.319 21.7454 119.237 22.6686C125.332 24.1047 129.895 29.1726 130.686 35.3839C131.195 39.377 133.286 42.9986 136.49 45.4356C141.473 49.2264 143.581 55.7122 141.777 61.7083C140.618 65.563 141.055 69.7219 142.99 73.2513C146.001 78.7414 145.288 85.5236 141.202 90.2677C138.575 93.3176 137.282 97.2947 137.615 101.306C138.133 107.546 134.723 113.452 129.06 116.124C125.42 117.842 122.621 120.949 121.294 124.749C119.228 130.661 113.711 134.669 107.451 134.807C103.427 134.895 99.6067 136.596 96.8481 139.527C92.5572 144.087 85.8866 145.505 80.1119 143.085C76.3995 141.529 72.2177 141.529 68.5053 143.085C62.7306 145.505 56.06 144.087 51.769 139.527C49.0105 136.596 45.1902 134.895 41.1659 134.807C34.9059 134.669 29.3887 130.661 27.3235 124.749C25.9958 120.949 23.1976 117.842 19.5571 116.124C13.8943 113.452 10.4845 107.546 11.0021 101.306C11.3348 97.2947 10.0425 93.3176 7.41543 90.2677C3.3289 85.5236 2.61606 78.7414 5.62693 73.2513C7.56254 69.7219 7.99966 65.563 6.84015 61.7083C5.03652 55.7122 7.1439 49.2264 12.1275 45.4356C15.3313 42.9986 17.4222 39.377 17.9308 35.3839C18.7219 29.1726 23.2851 24.1047 29.3797 22.6686C33.2977 21.7454 36.6809 19.2874 38.7697 15.8464C42.0188 10.4939 48.2488 7.72012 54.4005 8.88711C58.3554 9.63733 62.4458 8.76789 65.7535 6.47396Z" fill="#F2613C"/>
                                                        <path d="M68.4151 83.8327H76.452L83.8639 55.4354L86.4536 44.1836H74.2195L72.5228 55.4354L68.4151 83.8327ZM68.5044 105.086C73.6837 105.086 77.2557 100.621 77.2557 96.1561C77.2557 92.3162 74.7553 89.4586 70.9154 89.4586C65.7361 89.4586 62.1641 94.1022 62.1641 98.4779C62.1641 102.407 64.6645 105.086 68.5044 105.086Z" fill="white"/>
                                                    </svg>
                                                    <h4 class="fz-20 fw-600 text-black">You don't have any job history yet</h4>
                                                </div>
                                            </template>
                                        </b-tab>
                                        <b-tab :title="'In progress ('+messageData.jobUnderprocess.length+')'" v-if="messageData.jobUnderprocess.length > 0">
                                            <template v-if="messageData.jobUnderprocess.length > 0">
                                                <template v-for="(jobUnderprocess,index) in messageData.jobUnderprocess">
                                                    <b-card-text class="border-bottom"  :key="index" v-if="index+1 <= progressJobView">
                                                        <a href="javascript:void(0);" class="text-dark" v-b-modal.modal-1>
                                                            <h6>{{jobUnderprocess.job_title}}</h6>
                                                        </a>

                                                        <!-- <div class="d-flex align-items-center">
                                                            <h6 class="mr-2 mb-0"><b>$ {{jobUnderprocess.cost}}</b></h6>
                                                            <h6 class="mb-0">{{jobUnderprocess.from_date+' - '+jobUnderprocess.to_date}}</h6>
                                                        </div> -->
                                                        <!-- <vue-read-more-smooth :lines="3" class="mt-2" v-if="jobUnderprocess.cover_letter">
                                                            <p class="text-muted mt-2" v-html="getHtmlContent(jobUnderprocess.cover_letter)"></p>
                                                        </vue-read-more-smooth> -->
                                                        <!-- <p class="text-muted mt-2"><read-more more-str="Read more" :text="jobUnderprocess.cover_letter" link="#" less-str="Read less" :max-chars="250"></read-more></p> -->

                                                    </b-card-text>
                                                </template>
                                                <template v-if="messageData.jobUnderprocess.length > progressJobView">
                                                    <p class="text-theme cursor-pointer" @click="progressJobView += 3">View more</p>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <div class="text-center">
                                                    <svg width="149" height="149" viewBox="0 0 149 149" fill="none" xmlns="http://www.w3.org/2000/svg" class="m-auto">
                                                        <path d="M65.7535 6.47396C70.8988 2.90571 77.7184 2.90571 82.8637 6.47396C86.1714 8.76789 90.2618 9.63733 94.2166 8.88711C100.368 7.72012 106.598 10.4939 109.848 15.8464C111.936 19.2874 115.319 21.7454 119.237 22.6686C125.332 24.1047 129.895 29.1726 130.686 35.3839C131.195 39.377 133.286 42.9986 136.49 45.4356C141.473 49.2264 143.581 55.7122 141.777 61.7083C140.618 65.563 141.055 69.7219 142.99 73.2513C146.001 78.7414 145.288 85.5236 141.202 90.2677C138.575 93.3176 137.282 97.2947 137.615 101.306C138.133 107.546 134.723 113.452 129.06 116.124C125.42 117.842 122.621 120.949 121.294 124.749C119.228 130.661 113.711 134.669 107.451 134.807C103.427 134.895 99.6067 136.596 96.8481 139.527C92.5572 144.087 85.8866 145.505 80.1119 143.085C76.3995 141.529 72.2177 141.529 68.5053 143.085C62.7306 145.505 56.06 144.087 51.769 139.527C49.0105 136.596 45.1902 134.895 41.1659 134.807C34.9059 134.669 29.3887 130.661 27.3235 124.749C25.9958 120.949 23.1976 117.842 19.5571 116.124C13.8943 113.452 10.4845 107.546 11.0021 101.306C11.3348 97.2947 10.0425 93.3176 7.41543 90.2677C3.3289 85.5236 2.61606 78.7414 5.62693 73.2513C7.56254 69.7219 7.99966 65.563 6.84015 61.7083C5.03652 55.7122 7.1439 49.2264 12.1275 45.4356C15.3313 42.9986 17.4222 39.377 17.9308 35.3839C18.7219 29.1726 23.2851 24.1047 29.3797 22.6686C33.2977 21.7454 36.6809 19.2874 38.7697 15.8464C42.0188 10.4939 48.2488 7.72012 54.4005 8.88711C58.3554 9.63733 62.4458 8.76789 65.7535 6.47396Z" fill="#F2613C"/>
                                                        <path d="M68.4151 83.8327H76.452L83.8639 55.4354L86.4536 44.1836H74.2195L72.5228 55.4354L68.4151 83.8327ZM68.5044 105.086C73.6837 105.086 77.2557 100.621 77.2557 96.1561C77.2557 92.3162 74.7553 89.4586 70.9154 89.4586C65.7361 89.4586 62.1641 94.1022 62.1641 98.4779C62.1641 102.407 64.6645 105.086 68.5044 105.086Z" fill="white"/>
                                                    </svg>
                                                    <h4 class="fz-20 fw-600 text-black">You don't have any job Under process yet</h4>
                                                </div>
                                            </template>
                                        </b-tab>
                                    </b-tabs>
                                </div>
                            </div>
                            <!--<b-modal id="modal-1" size="lg" title="Title">
                                <div class="d-md-flex align-items-center mb-4">
                                    <ul class="d-flex align-items-center custom-margin fz-18 mb-0">
                                        <li>Web Development</li>
                                        <li class="ml-2 mr-2">|</li>
                                        <li>May 14, 2022 - Sep 5, 2022</li>
                                    </ul>
                                    <div class="ml-auto">
                                        <p class="fz-18 mb-0">Fixed Price</p>
                                    </div>
                                </div>
                                <div class="feedback-wrapper">
                                    <h4 class="fz-24 fw-600">Job Feedback</h4>
                                    <hr>
                                    <h5 class="fz-20 fw-600 mb-1">Client's Feedback</h5>
                                    <p class="fz-18 text-muted mb-4">No feedback given</p>
                                    <h5 class="fz-20 fw-600 mb-1">Freelancer's feedback to client</h5>
                                    <ul class="d-flex align-items-center custom-margin">
                                        <li><i class="fa fa-star mr-1 text-theme"></i></li>
                                        <li><i class="fa fa-star mr-1 text-theme"></i></li>
                                        <li><i class="fa fa-star mr-1 text-theme"></i></li>
                                        <li><i class="fa fa-star mr-1"></i></li>
                                        <li><i class="fa fa-star mr-1"></i></li>
                                    </ul>
                                    <p class="fz-18 mb-4"><i>" Thank you "</i></p><br>
                                </div>
                                <div class="job-details-wrapper">
                                    <h4 class="fz-24 fw-600">Job Details</h4>
                                    <hr>
                                    <b-row>
                                        <b-col lg="6" xl="6" sm="12">
                                            <div class="freelancer-wrap">
                                                <h5 class="fz-20 fw-600 mb-1">Job Description</h5>
                                                <p class="fz-18 text-muted mb-4">Background:</p>
                                                <div class="kdfkjf000"></div>
                                                <p class="fz-18 text-muted mb-2">We are looking for experienced
                                                    JavaScript engineers to...</p>
                                                <a href="javascript:void(0);" class="text-theme mb-3 d-block">more</a>
                                                <h5 class="fz-20 fw-600 mb-1">Skills Required</h5>
                                                <ul class="align-items-center fz-14 post tags mb-4 inline-block">
                                                    <li class="mr-1 align-items-center inline-block"> Issues/NCR
                                                        management</li>
                                                    <li class="mr-1 align-items-center inline-block"> Fabrication
                                                        schedule follow up</li>
                                                    <li class="mr-1 align-items-center inline-block"> Effective
                                                        Coordination</li>
                                                </ul>
                                                <div class="d-flex align-items-center">
                                                    <div>
                                                        <h5 class="fz-20 fw-600 mb-1">Fixed Price Job</h5>
                                                        <p class="fz-18 text-muted mb-4">Deliver by: Jul 19, 2022</p>
                                                    </div>
                                                    <div class="ml-auto">
                                                        <h5 class="fz-20 fw-600 mb-1">$14.00</h5>
                                                        <p class="fz-18 text-muted mb-4">Budget</p>
                                                    </div>
                                                </div>
                                                <a href="javascript:void(0);" class="text-theme mb-3 d-block">View
                                                    entire job post</a>
                                            </div>
                                        </b-col>
                                        <b-col lg="6" xl="6" sm="12">
                                            <div class="freelancer-wrap">
                                                <h5 class="fz-20 fw-600 mb-1">About the client</h5>
                                                <ul class="d-flex align-items-center custom-margin mb-0">
                                                    <li><i class="fa fa-star mr-1 text-theme"></i></li>
                                                    <li><i class="fa fa-star mr-1 text-theme"></i></li>
                                                    <li><i class="fa fa-star mr-1 text-theme"></i></li>
                                                    <li><i class="fa fa-star mr-1"></i></li>
                                                    <li><i class="fa fa-star mr-1"></i></li>
                                                </ul>
                                                <p class="fz-18 mb-4">4.963 of 8,733 reviews</p>
                                                <h5 class="fz-20 fw-600 mb-1">United Stats</h5>
                                                <p class="fz-18 text-muted mb-4">Santa Clara</p>
                                                <p class="fz-18 text-muted mb-4">13,917 Hires 370 Active</p>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-modal>-->
                            <div class="border-left border-bottom text-left py-4 px-4" v-if="messageData.userDetails.allSkill_name!=''">
                                <h5 class="mr-2 fw-600">Skills</h5>
                                <div class="tags-wrap">
                                    <ul v-if="messageData.userDetails.allSkill_name">
                                        <li v-for="(catname,index) in messageData.userDetails.allSkill_name" :key="index">
                                            <a href="javascript:void(0);" class="d-flex align-items-center fz-14">
                                                <span class="mr-2">{{catname.name}}</span>
                                            </a>
                                        </li>
                                    </ul>
                                    <ul v-else>
                                        <div class="text-center">
                                            <svg width="149" height="149" viewBox="0 0 149 149" fill="none" xmlns="http://www.w3.org/2000/svg" class="m-auto">
                                                <path d="M65.7535 6.47396C70.8988 2.90571 77.7184 2.90571 82.8637 6.47396C86.1714 8.76789 90.2618 9.63733 94.2166 8.88711C100.368 7.72012 106.598 10.4939 109.848 15.8464C111.936 19.2874 115.319 21.7454 119.237 22.6686C125.332 24.1047 129.895 29.1726 130.686 35.3839C131.195 39.377 133.286 42.9986 136.49 45.4356C141.473 49.2264 143.581 55.7122 141.777 61.7083C140.618 65.563 141.055 69.7219 142.99 73.2513C146.001 78.7414 145.288 85.5236 141.202 90.2677C138.575 93.3176 137.282 97.2947 137.615 101.306C138.133 107.546 134.723 113.452 129.06 116.124C125.42 117.842 122.621 120.949 121.294 124.749C119.228 130.661 113.711 134.669 107.451 134.807C103.427 134.895 99.6067 136.596 96.8481 139.527C92.5572 144.087 85.8866 145.505 80.1119 143.085C76.3995 141.529 72.2177 141.529 68.5053 143.085C62.7306 145.505 56.06 144.087 51.769 139.527C49.0105 136.596 45.1902 134.895 41.1659 134.807C34.9059 134.669 29.3887 130.661 27.3235 124.749C25.9958 120.949 23.1976 117.842 19.5571 116.124C13.8943 113.452 10.4845 107.546 11.0021 101.306C11.3348 97.2947 10.0425 93.3176 7.41543 90.2677C3.3289 85.5236 2.61606 78.7414 5.62693 73.2513C7.56254 69.7219 7.99966 65.563 6.84015 61.7083C5.03652 55.7122 7.1439 49.2264 12.1275 45.4356C15.3313 42.9986 17.4222 39.377 17.9308 35.3839C18.7219 29.1726 23.2851 24.1047 29.3797 22.6686C33.2977 21.7454 36.6809 19.2874 38.7697 15.8464C42.0188 10.4939 48.2488 7.72012 54.4005 8.88711C58.3554 9.63733 62.4458 8.76789 65.7535 6.47396Z" fill="#F2613C"/>
                                                <path d="M68.4151 83.8327H76.452L83.8639 55.4354L86.4536 44.1836H74.2195L72.5228 55.4354L68.4151 83.8327ZM68.5044 105.086C73.6837 105.086 77.2557 100.621 77.2557 96.1561C77.2557 92.3162 74.7553 89.4586 70.9154 89.4586C65.7361 89.4586 62.1641 94.1022 62.1641 98.4779C62.1641 102.407 64.6645 105.086 68.5044 105.086Z" fill="white"/>
                                            </svg>
                                            <h4 class="fz-20 fw-600 text-black">You don't have any Skills yet</h4>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-card-group>
            <b-card-group deck class="mt-4" v-if="messageData.similarjobHistory.length > 0">
                <b-card title-class="text-theme font-14" header-tag="header" footer-tag="footer"
                    header-class="bg-white p-0">
                    <template #header>
                        <div class="bordered border-right-none py-4 px-4">
                            <h4 class="mb-0 fz-24 fw-500 text-black text-left"><b>Employment History
                                    {{(messageData.similarjobHistory.length > 0)?'('+messageData.similarjobHistory.length+')':''}}</b></h4>
                        </div>
                    </template>
                    <b-row>
                        <b-col lg="12" xl="12" sm="12" class="p-0">
                            <div class=" text-left py-4 px-4" v-if="messageData.similarjobHistory.length > 0 ">
                                <span v-for="(history,index) in messageData.similarjobHistory" :key="index">
                                    <h5>{{history.job_title }}</h5>
                                    <h6>{{history.from_date+' - '+history.to_date }}</h6>
                                    <hr>
                                </span>
                            </div>
                            <div class="text-left py-4 px-4" v-else>
                                <div class="text-center">
                                    <svg width="149" height="149" viewBox="0 0 149 149" fill="none" xmlns="http://www.w3.org/2000/svg" class="m-auto">
                                        <path d="M65.7535 6.47396C70.8988 2.90571 77.7184 2.90571 82.8637 6.47396C86.1714 8.76789 90.2618 9.63733 94.2166 8.88711C100.368 7.72012 106.598 10.4939 109.848 15.8464C111.936 19.2874 115.319 21.7454 119.237 22.6686C125.332 24.1047 129.895 29.1726 130.686 35.3839C131.195 39.377 133.286 42.9986 136.49 45.4356C141.473 49.2264 143.581 55.7122 141.777 61.7083C140.618 65.563 141.055 69.7219 142.99 73.2513C146.001 78.7414 145.288 85.5236 141.202 90.2677C138.575 93.3176 137.282 97.2947 137.615 101.306C138.133 107.546 134.723 113.452 129.06 116.124C125.42 117.842 122.621 120.949 121.294 124.749C119.228 130.661 113.711 134.669 107.451 134.807C103.427 134.895 99.6067 136.596 96.8481 139.527C92.5572 144.087 85.8866 145.505 80.1119 143.085C76.3995 141.529 72.2177 141.529 68.5053 143.085C62.7306 145.505 56.06 144.087 51.769 139.527C49.0105 136.596 45.1902 134.895 41.1659 134.807C34.9059 134.669 29.3887 130.661 27.3235 124.749C25.9958 120.949 23.1976 117.842 19.5571 116.124C13.8943 113.452 10.4845 107.546 11.0021 101.306C11.3348 97.2947 10.0425 93.3176 7.41543 90.2677C3.3289 85.5236 2.61606 78.7414 5.62693 73.2513C7.56254 69.7219 7.99966 65.563 6.84015 61.7083C5.03652 55.7122 7.1439 49.2264 12.1275 45.4356C15.3313 42.9986 17.4222 39.377 17.9308 35.3839C18.7219 29.1726 23.2851 24.1047 29.3797 22.6686C33.2977 21.7454 36.6809 19.2874 38.7697 15.8464C42.0188 10.4939 48.2488 7.72012 54.4005 8.88711C58.3554 9.63733 62.4458 8.76789 65.7535 6.47396Z" fill="#F2613C"/>
                                        <path d="M68.4151 83.8327H76.452L83.8639 55.4354L86.4536 44.1836H74.2195L72.5228 55.4354L68.4151 83.8327ZM68.5044 105.086C73.6837 105.086 77.2557 100.621 77.2557 96.1561C77.2557 92.3162 74.7553 89.4586 70.9154 89.4586C65.7361 89.4586 62.1641 94.1022 62.1641 98.4779C62.1641 102.407 64.6645 105.086 68.5044 105.086Z" fill="white"/>
                                    </svg>
                                    <h4 class="fz-20 fw-600 text-black">You don't have any history yet</h4>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-card-group>

            <!-- Certification Start -->
            <CertificationList :getUserCertificationListArr="getUserCertificationListArr"></CertificationList>
            
            <b-card-group deck class="mt-4 employement-sidebar-container" v-if="messageData.employmentData.length >0 ">
                <b-card title-class="text-theme font-14" header-tag="header" footer-tag="footer"
                    header-class="bg-white p-0">
                    <template #header>
                        <div class="bordered border-right-none py-4 px-4">
                            <h4 class="mb-0 fz-24 fw-500 text-black text-left"><b>Employment History
                                    {{(messageData.employmentData.length>0)?'('+messageData.employmentData.length+')':''}}</b></h4>
                        </div>
                    </template>
                    <b-row>
                        <b-col lg="12" xl="12" sm="12" class="p-0">
                            <div class=" text-left py-4 px-4" v-if="messageData.employmentData.length >0 ">
                                <span v-for="(employment,index) in messageData.employmentData" :key="index">
                                    <b><h5 class="name">{{employment.company_name }}</h5>
                                    <h6 class="name">{{employment.role}}</h6>
                                    <h6 class="location">{{employment.from_date+' - '}}{{(employment.is_working == false) ? employment.to_date+' | '+employment.location : 'Present'+' | '+employment.location}} </h6></b>
                                    <ol class="ml__32 content">
                                        <vue-read-more-smooth :lines="3">
                                            <li class="fz-18 text-muted fw-400" v-for="(response ,reIndex) in employment.responsibilities" :key="reIndex">
                                            {{response}}
                                            </li>
                                        </vue-read-more-smooth>
                                    </ol>
                                    <hr>
                                </span>
                            </div>
                            <div class="text-left py-4 px-4" v-else>
                                <div class="text-center">
                                    <svg width="149" height="149" viewBox="0 0 149 149" fill="none" xmlns="http://www.w3.org/2000/svg" class="m-auto">
                                        <path d="M65.7535 6.47396C70.8988 2.90571 77.7184 2.90571 82.8637 6.47396C86.1714 8.76789 90.2618 9.63733 94.2166 8.88711C100.368 7.72012 106.598 10.4939 109.848 15.8464C111.936 19.2874 115.319 21.7454 119.237 22.6686C125.332 24.1047 129.895 29.1726 130.686 35.3839C131.195 39.377 133.286 42.9986 136.49 45.4356C141.473 49.2264 143.581 55.7122 141.777 61.7083C140.618 65.563 141.055 69.7219 142.99 73.2513C146.001 78.7414 145.288 85.5236 141.202 90.2677C138.575 93.3176 137.282 97.2947 137.615 101.306C138.133 107.546 134.723 113.452 129.06 116.124C125.42 117.842 122.621 120.949 121.294 124.749C119.228 130.661 113.711 134.669 107.451 134.807C103.427 134.895 99.6067 136.596 96.8481 139.527C92.5572 144.087 85.8866 145.505 80.1119 143.085C76.3995 141.529 72.2177 141.529 68.5053 143.085C62.7306 145.505 56.06 144.087 51.769 139.527C49.0105 136.596 45.1902 134.895 41.1659 134.807C34.9059 134.669 29.3887 130.661 27.3235 124.749C25.9958 120.949 23.1976 117.842 19.5571 116.124C13.8943 113.452 10.4845 107.546 11.0021 101.306C11.3348 97.2947 10.0425 93.3176 7.41543 90.2677C3.3289 85.5236 2.61606 78.7414 5.62693 73.2513C7.56254 69.7219 7.99966 65.563 6.84015 61.7083C5.03652 55.7122 7.1439 49.2264 12.1275 45.4356C15.3313 42.9986 17.4222 39.377 17.9308 35.3839C18.7219 29.1726 23.2851 24.1047 29.3797 22.6686C33.2977 21.7454 36.6809 19.2874 38.7697 15.8464C42.0188 10.4939 48.2488 7.72012 54.4005 8.88711C58.3554 9.63733 62.4458 8.76789 65.7535 6.47396Z" fill="#F2613C"/>
                                        <path d="M68.4151 83.8327H76.452L83.8639 55.4354L86.4536 44.1836H74.2195L72.5228 55.4354L68.4151 83.8327ZM68.5044 105.086C73.6837 105.086 77.2557 100.621 77.2557 96.1561C77.2557 92.3162 74.7553 89.4586 70.9154 89.4586C65.7361 89.4586 62.1641 94.1022 62.1641 98.4779C62.1641 102.407 64.6645 105.086 68.5044 105.086Z" fill="white"/>
                                    </svg>
                                    <h4 class="fz-20 fw-600 text-black">You don't have any history yet</h4>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-card-group>
        </div>
        <!-- flagging model -->
        <b-modal id="flagging-modal" size="lg" title="Why are you flagging this?" hide-footer no-close-on-backdrop>
            <form @submit.prevent="submitFlaggingData">
                <b-form-group class="mb-3">
                    <b-form-radio-group class="fz-16" :options="flaggingoptions" value-field="id" text-field="title"
                        name="flavour-2a" stacked v-model="addFlagging.flagging_id" :error-messages="flagflagging_id"
                        @input="$v.addFlagging.flagging_id.$touch()" @blur="$v.addFlagging.flagging_id.$touch()">
                    </b-form-radio-group>
                    <div class="error" v-if="flagflagging_id[0]">{{flagflagging_id[0]}}</div>
                </b-form-group>

                <label class="fz-18 fw-600 mb-2">Please tell us more</label>
                <b-form-textarea id="textarea" placeholder="Share additional details" rows="6" max-rows="8"
                    v-model="addFlagging.report_content" :error-messages="flagreport_content"
                    @input="$v.addFlagging.report_content.$touch()" @blur="$v.addFlagging.report_content.$touch()">
                </b-form-textarea>
                <div class="error" v-if="flagreport_content[0]">{{flagreport_content[0]}}</div>

                <hr>
                <div class="text-right">
                    <a href="javascript:void(0);" class="btn btn-outline-theme mr-2"
                        @click="$bvModal.hide('flagging-modal');">Cancel</a>
                    <b-button type="buttton" class="btn btn-theme">Submit</b-button>
                </div>
            </form>
        </b-modal>
        <!-- Decline Modal -->
        <b-modal id="decline-modal" size="lg" title="Decline" hide-footer no-close-on-backdrop>
            <h4 class="fz-18 text-black text-capitali">Select Decline to remove the freelancer from consideration for
                this job. Optionally, you can include a message to let the freelancer know why you're not interested.
            </h4>
            <form @submit.prevent="submitDeclineData">
                <label class="fz-18 fw-600 mb-2">Reason</label><br>
                <b-form-select v-model="addDecline.decline_reason_id" :options="declineReason" text-field="title"
                    value-field="id" :error-messages="declineReason_id"
                    @input="$v.addDecline.decline_reason_id.$touch()" @blur="$v.addDecline.decline_reason_id.$touch()"
                    class="mb-4"></b-form-select>
                <div class="error" v-if="declineReason_id[0]">{{declineReason_id[0]}}</div>

                <br>
                <label class="fz-18 fw-600 mb-2">Message (Optional)</label>
                <b-form-textarea v-model="addDecline.decline_reason" id="textarea" rows="6" max-rows="8"
                    :error-messages="declineReason_message" @input="$v.addDecline.decline_reason.$touch()"
                    @blur="$v.addDecline.decline_reason.$touch()"></b-form-textarea>
                <div class="error" v-if="declineReason_message[0]">{{declineReason_message[0]}}</div>

                <hr>
                <div class="text-right">
                    <a href="javascript:void(0);" class="btn btn-outline-theme mr-2"
                        @click="$bvModal.hide('decline-modal');">Cancel</a>
                    <b-button type="buttton" class="btn btn-theme">Decline</b-button>
                </div>
            </form>
        </b-modal>
        <!-- note model -->
        <b-modal id="notemodal" class="notemodal" size="lg" :title="'Note about '+messageData.userDetails.first_name+' '+messageData.userDetails.last_name+'.'" hide-footer no-close-on-backdrop>
            <form @submit.prevent="submitNoteData">
                <label class="fz-18 fw-600 mb-2">Note</label><br>
                <b-form-textarea id="textarea" rows="6" v-model="messageData.noteData.note" max-rows="8" class="mb-2"
                    :error-messages="notedescription" @input="$v.messageData.noteData.note.$touch()"
                    @blur="$v.messageData.noteData.note.$touch()"></b-form-textarea>
                <div class="error" v-if="notedescription[0]">{{notedescription[0]}}</div>
                <span><i class="fa fa-lock" aria-hidden="true"></i> The talent won't see anything you put
                    here.</span><br>
                <label class="fz-18 fw-600 mb-2 mt-4">Soft Skills</label><br>
                <div class="tags-wrap mb-1 inline-block">
                    <div class="inline-block mr-2 pos-rel" v-for="(soft,index) in SoftSkill" :key="index">
                        <input type="checkbox" class="pos-absolute" v-model="messageData.noteData.skill"
                            :id="'control_'+index" name="select" :value="soft.id" search>
                        <label :for="'control_'+index" class="tags-label">
                            <h4 class="mb-0 fw-600">{{soft.title}}</h4>
                        </label>
                    </div>
                </div> <br>
                <label class="fz-18 fw-600 mb-2 mt-2" for="tags-pills">Custom Tags</label><br>
                <b-form-tags v-model="messageData.noteData.tags" input-id="tags-pills" tag-variant="theme" tag-pills
                    separator="" placeholder="Start typing" remove-on-delete></b-form-tags>
                <hr>
                <div class="text-right">
                    <a href="javascript:void(0);" class="btn btn-outline-theme mr-2"
                        @click="$bvModal.hide('notemodal');">Cancel</a>
                    <b-button type="buttton" class="btn btn-theme">Save Note</b-button>
                </div>
            </form>
        </b-modal>
    </b-sidebar>
</template> 
<script>

import { mapActions } from "vuex";
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import VueReadMoreSmooth from "vue-read-more-smooth";
import CertificationList from "@/components/public-profile/public-certification.vue";
import chatBoxData from "../../../components/chat/chatBoxMessageView.vue";
export default {
    mixins: [validationMixin],
    validations: {
        addFlagging: {
            flagging_id: { required },
            report_content: { required },
        },
        addDecline: {
            decline_reason_id: { required },
            decline_reason: { required },
        },
        messageData: {
            noteData: {
                note: { required },
            }
        },
    },
    components: {
        VueReadMoreSmooth,
        CertificationList,
        chatBoxData
    },
    props: ['messageData', 'proposal', 'jobId','jobDetails','pageName'],
    data() {
        return {
            value: new Date().toISOString().substr(0, 10),
            striped: true,
            max: 0,
            job_id: '',
            flaggingoptions: [],
            SoftSkill: [],
            declineReason: [],
            addFlagging: {
                flagging_id: '',
                report_content: '',
            },
            addDecline: {
                decline_reason_id: '',
                decline_reason: '',
                decline_by:'',
                application_status: 8,
            },
            checkCoverdata:false, 
            // noteData:{
            //     to_user_id:'',
            //     note:'',
            //     skill:[],
            //     tags:[],
            // },
            getUserCertificationListArr: [],
            chatroomdata:[],
            sidebarStatus : false,
            contractData :{
                originalMilestoneDetails :[],
                MilestoneDetails :[],
                proposalDetails :'',
            },
            competedJobView : 3,
            progressJobView : 3,
        }
    },
    methods: {
        ...mapActions(["genericAPIPOSTRequest","UpdateCurrentUrl"]),
        ...mapActions('global',["EmptyMessageData"]),
        // this fun is for to get milestone data
        async getMilestoneData(){
            var getMilestoneData = await this.genericAPIPOSTRequest({
                requestUrl: "getProposalFullDetails",
                params: { 
                    "user_id": this.messageData.userDetails.user_id, 
                    "job_id": this.jobId ,
                },
            });
            if (getMilestoneData.milestone.length > 0) {
                this.contractData.MilestoneDetails          = getMilestoneData.milestone;
                this.contractData.proposalDetails           = getMilestoneData.proposal;
                this.contractData.originalMilestoneDetails  = getMilestoneData.original;
            }
        },
        // this fun is for to manage side bar section hide and show
        sideBarManagement(){
            if(this.sidebarStatus == true){
                this.EmptyMessageData();
                this.UpdateCurrentUrl({params:''});
                this.UpdateCurrentUrl({params:'index'});

            }
            this.competedJobView = 3;
            this.progressJobView = 3;
            this.sidebarStatus = !this.sidebarStatus;
        },
        // this fun is for to get certification list
        async getUserCertificationList() {
            var res = await this.genericAPIPOSTRequest({
                requestUrl: "user/getUserCertificationList",
                params: { user_id: this.messageData.userDetails.user_id },
            });
            this.getUserCertificationListArr = res;
        },
        // this fun is for to add note for user
        async submitNoteData() {
            this.$v.messageData.noteData.$touch();
            if (!this.$v.messageData.noteData.$invalid) {
                this.messageData.noteData.to_user_id = this.messageData.userDetails.user_id;
                var res = await this.genericAPIPOSTRequest({
                    requestUrl: "addNote",
                    params: this.messageData.noteData
                });
                if (res) {
                    this.$toastr.s('Note Added Successfully');
                    this.getNotedata(this.messageData.userDetails.user_id);
                    this.$bvModal.hide('notemodal');
                }
            }
        },
        // this fun is for to get note data
        async getNotedata(_v) {
            var response = await this.fgenericAPIPOSTRequest({
                requestUrl: "getNote",
                params: { 'to_user_id': _v }
            });
            if (response) {
                this.messageData.noteData = response;
            }
        },
        // this fun is for to add flag on user
        async submitDeclineData() {
            this.$v.addDecline.$touch();
            if (!this.$v.addDecline.$invalid) {
                this.addDecline.user_id = this.messageData.userDetails.user_id;
                this.addDecline.decline_by = this.jobDetails.user_id;
                this.addDecline.jobs_id = this.jobId;
                var res = await this.genericAPIPOSTRequest({
                    requestUrl: "updateJobApply",
                    params: this.addDecline
                });
                if (res) {
                    this.$toastr.s('Proposal Decline Done');
                    this.$bvModal.hide('decline-modal');
                    this.$bvModal.hide('sidebar-message');
                    this.$emit('modelopenclose');
                    this.$emit('getProposalData');
                    this.$emit('applicationStatus');
                }
            }
        },
        // this fun is for to add flag on user
        async submitFlaggingData() {
            this.$v.addFlagging.$touch();
            if (!this.$v.addFlagging.$invalid) {
                this.addFlagging.report_user_id = this.messageData.userDetails.user_id;
                var res = await this.genericAPIPOSTRequest({
                    requestUrl: "addUserflag",
                    params: this.addFlagging
                });
                if (res) {
                    this.$toastr.s('Flag as inappropriate Done');
                    this.$bvModal.hide('flagging-modal');
                }
            }
        },
        //this fun is for to get flagging reason
        async getdeclineReason() {
            var res = await this.genericAPIPOSTRequest({
                requestUrl: "getdeclineReason",
                params: {'type':'client'}
            });
            if (res) {
                this.declineReason = res;
            }
        },
        //this fun is for to get flagging reason
        async getflagging() {
            var res = await this.genericAPIPOSTRequest({
                requestUrl: "job/getflagginglist",
                params: {}
            });
            if (res) {
                this.flaggingoptions = res;
            }
        },
        //this fun is for to get Soft Skill
        async getSoftSkill() {
            var res = await this.genericAPIPOSTRequest({
                requestUrl: "getSoftSkill",
                params: {}
            });
            if (res) {
                this.SoftSkill = res;
            }
        },
        // this fun is for to add to favorite
        async addFavorite(_i) {
            var res = await this.genericAPIPOSTRequest({
                requestUrl: "addremovefavorite",
                params: {
                    'favorite_user_id': _i
                }
            });
            if (res == "added") {
                document.getElementById('favorite-' + _i).classList.add("saved-btn");
                this.$toastr.s('Added to Favorite');
            } else if (res == "remove") {
                document.getElementById('favorite-' + _i).classList.remove("saved-btn");
                this.$toastr.s('Remove From Favorite');
            }
        },
        //this fun is for to like the post
        async proposalLike(_i) {
            var res = await this.genericAPIPOSTRequest({
                requestUrl: "job/addHire",
                params: {
                    "jobData": {
                        'user_id': this.messageData.userDetails.user_id,
                        'jobs_id': this.jobId
                    },
                    "action": _i
                }
            });
            if (res) {
                this.contractData.proposalDetails.application_status = 3;
                this.checkCoverdata=true;
                this.$emit('getProposalData');
                this.$emit('applicationStatus');

                //   this.$refs.mysidebar.hide();
                this.$toastr.s('Proposal Shortlisted Successfully');
            } else {
                this.$toastr.e('Proposal Like Unsuccessfull');
            }
        },

        //this fun is for to dislike the post
        async proposalDislike(_v, _i) {
            var res = await this.genericAPIPOSTRequest({
                requestUrl: "job/addHire",
                params: { "jobData": _v, "action": _i }
            });
            if (res) {
                this.$emit('getProposalDatas');
                this.$emit('applicationStatus');
                this.$toastr.s('Proposal Archived Successfully');
            } else {
                this.$toastr.e('Proposal DisLike Unsuccessfull');
            }
        },

        // this fun is for to get chat user list
        async getChatlist(){
            this.chatroomdata=[];
            var  param={'user_id':this.messageData.userDetails.user_id,'client_id':this.$store.state.currentUserData.login_master_id,'job_id': this.jobId,'userType': this.$store.state.accountType};
            var res = await this.genericAPIPOSTRequest({
                requestUrl:'getchatroom',
                params: param,
            });
            if (res.length > 0) {
                this.chatroomdata = res[0];
                // this.$refs.oprateChatboxs.getselectedusermessage();
                // if (this.$refs.oprateChatboxs) {
                //     this.$refs.oprateChatboxs.getselectedusermessage();
                // }
                var that = this;
                setTimeout(() => {
                    that.$refs.oprateChatboxs.getselectedusermessage();
                }, 1000);
            }
        },

    },
    computed: {
        flagflagging_id() {
            const errors = []
            if (!this.$v.addFlagging.flagging_id.$dirty) return errors
            !this.$v.addFlagging.flagging_id.required && errors.push('Please Select Flag is required')
            return errors
        },
        flagreport_content() {
            const errors = []
            if (!this.$v.addFlagging.report_content.$dirty) return errors
            !this.$v.addFlagging.report_content.required && errors.push('Description is required')
            return errors
        },
        declineReason_id() {
            const errors = []
            if (!this.$v.addDecline.decline_reason_id.$dirty) return errors
            !this.$v.addDecline.decline_reason_id.required && errors.push('Please Select Reason is required')
            return errors
        },
        declineReason_message() {
            const errors = []
            if (!this.$v.addDecline.decline_reason.$dirty) return errors
            !this.$v.addDecline.decline_reason.required && errors.push('Message is required')
            return errors
        },
        notedescription() {
            const errors = []
            if (!this.$v.messageData.noteData.note.$dirty) return errors
            !this.$v.messageData.noteData.note.required && errors.push('Note is required')
            return errors
        },
        // function to total burget amount
        totalEarning() {
            var amount = 0;
            this.messageData.jobHistory.map(data => {
                amount += parseFloat(data.cost);
            });
            return amount;
        },
    },
    mounted() {
        this.job_id = this.$route.params.pid;
        //this fun is for to get flagging reason
        this.getflagging();
        //this fun is for to get decline reason
        this.getdeclineReason();
        //this fun is for to get Soft Skill
        this.getSoftSkill();
        // this.getMilestoneData();
        // this.getUserCertificationList();
    }
}
</script>
<style>
.notemodal input[type=checkbox] {
    opacity: 0;
    z-index: 1;
    top: 8px;
}

.notemodal input[type=checkbox]:not(:disabled)~.tags-label {
    cursor: pointer;
}

.notemodal input[type=checkbox]:disabled~.tags-label {
    color: #bcc2bf;
    border-color: #bcc2bf;
    box-shadow: none;
    cursor: not-allowed;
}

.notemodal .tags-label {
    height: 100%;
    display: block;
    background: white;
    border: 2px solid #8f8f8f;
    border-radius: 20px;
    padding: .3rem 1rem;
    margin-bottom: .4rem;
    text-align: center;
    box-shadow: 0px 3px 10px -2px rgba(161, 170, 166, 0.5);
    position: relative;
}

.notemodal .tags-label h4 {
    color: #6a6a6a;
    font-size: 14px;
}

.notemodal input[type=checkbox]:checked+.tags-label {
    background: #f5f5f5;
    color: white;
}

.notemodal input~.tags-label:before {
    display: none;
}

.notemodal input[type=checkbox]#control_05:checked+.tags-label {
    background: red;
    border-color: red;
}

.notemodal .b-form-tags-list {
    margin-left: -32px !important;
}

.notemodal .badge {
    color: #6a6a6a;
    padding: 0px 10px;
    border-radius: 15px;
    border: 1px solid #6a6a6a;
    font-weight: 600;
    font-size: 16px;
    display: flex !important;
    align-items: center !important;
}

.sidebar-message .card {
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.sidebar-message .card-body {
    padding: 12px;
}

.sidebar-message .w-80 {
    width: 80% !important;
}

.sidebar-message .tabs-wrapper {
    max-height: 327px;
}

.sidebar-message .tags-wrap ul {
    margin-left: -32px;
}

.sidebar-message .img-wrapper {
    width: 120px;
    height: 120px;
    border-radius: 10px;
    position: relative;
    box-shadow: 0px 0px 12px -2px rgb(0 0 0 / 20%);
    padding: 5px;
}

.sidebar-message .img-wrapper img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.sidebar-message .img-wrapper .dot {
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #48c048;
    border-radius: 50%;
    top: 5px;
    right: 22px;
    border: 2px solid #d0caca;
}

.sidebar-message .img-wrap {
    width: 10%;
}

.sidebar-message .minus-margin {
    margin-left: -32px;
}

.justify-content-space-around {
    justify-content: space-around;
}

.width-80 {
    width: 80%;
}

.t-0 {
    top: 0px !important;
}

.t-0.dropdown ul {
    padding-left: 0px !important;
}

.custom-margin {
    margin-left: -32px;
}

#flagging-modal .custom-control {
    margin-bottom: 15px;
}

#flagging-modal label span {
    font-size: 16px;
}
.work-history-wrapper .nav-tabs .nav-link{color:#666666;border: 0;padding-left: 0;}
.work-history-wrapper .nav-tabs .nav-link.active{color:#f2613d;border: 0;
border-bottom: 2px solid #f2613d;padding-left: 0;}
.work-history-wrapper .nav-tabs{border:0px;margin-left:-32px;}
</style>