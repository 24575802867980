<template>
    <span>
        <div class="row message-head pos-rel"  >
            <div class="content-preloader"  v-if="loaderHideShow">
                <div class="loader-wrapper">
                    <div class="loader">
                        <div class="dot"></div>
                    </div>
                    <div class="loader">
                        <div class="dot"></div>
                    </div>
                    <div class="loader">
                        <div class="dot"></div>
                    </div>
                    <div class="loader">
                        <div class="dot"></div>
                    </div>
                    <div class="loader">
                        <div class="dot"></div>
                    </div>
                    <div class="loader">
                        <div class="dot"></div>
                    </div>
                </div>
            </div>
            <div class="row message overflow-x-hidden pos-rel h-390 "  @scroll="handleScroll" id="conversation">
                <span v-if="messagelist.length > 0 " >
                    <div class="row message-body"   v-for="(msgs,index) in  messagelist " :key="index"  >
                        <div class="col-sm-12 mb-2">
                            <div class="unread-time-wrapper" >
                                <div class="pos-rel time-wrap">
                                    <span class="time pos-absolute">{{msgs.key}}</span>
                                </div>
                            </div>
                        </div>

                        <div class="content-holder mt-2"  v-for="(msg,ind) in  msgs.value" :key="ind">
                            <div class="d-flex align-items-center" >
                                <div class="img-holder w-10">
                                    <div class="heading-avatar-icon">
                                        <img :src="msg.key.profile_picture_path">
                                        <span class="status-icon"></span>
                                    </div>
                                </div>
                                <div class="content-holder d-flex">
                                    <p class="mb-0 ml-2">{{msg.key.first_name}} {{msg.key.last_name }}</p>
                                    <span class="msg-time ml-2">{{ convertToLocalTime(msg.key.time) }}</span>
                                </div>
                            </div>
                            <div v-for="(messages,mindex) in msg.value" :key="mindex" :id="mindex" :class="(messages.type==3)?'pdf-div-css':(messages.type==2)?'chat-img-div-css':''">
                                <div class="mb-2 pl-50"  v-if="messages.type==1">
                                    <div class="single-msg" id="single-msg">
                                        <span v-if="messages.type==1" class="msg-span">
                                            <!-- <read-more more-str="Read more" :text="getHtmlContent(messages.message)" link="#" less-str="Read less" :max-chars="300"></read-more> -->
                                            <div v-if="messages.is_deleted==0"><p v-html="getHtmlContent(messages.message)"></p></div>
                                            <div  class="mb-3" v-else><span class="bg-grey p-2 br-10 text-muted"><i>{{(messages.sended_by == $store.state.currentUserData.login_master_id)?'You removed this message':'Message Was Removed'}}</i></span></div>
                                            <!-- <span class="msg-time ml-2">{{ messages.time }}</span> -->
                                        </span>
                                        <div class="dropdown-option" v-if="messages.is_deleted == 0 && messages.type==1 && messages.sended_by == $store.state.currentUserData.login_master_id">
                                            <b-dropdown size="md" right  variant="link" toggle-class="text-decoration-none" no-caret v-if="messages.sended_by == $store.state.currentUserData.login_master_id" >
                                                <template #button-content>
                                                    <i class="fa fa-ellipsis-v text-muted action-dots-right" aria-hidden="true"></i>
                                                </template>
                                                <b-dropdown-item href="javascript:void(0);"  @click="deleteMsg(messages.chat_id,index,ind,mindex,$event)">
                                                    <i class="fa fa-trash" aria-hidden="true"></i> Delete
                                                </b-dropdown-item>
                                                <b-dropdown-item href="javascript:void(0);"  @click="updateMessage.editMessage = true; sendChat.message = messages.message; updateMessage.chatIndex = index; updateMessage.chatData = messages; updateMessage.chatParentIndex = ind; updateMessage.chatChildIndex= mindex;">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i> Edit
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div class="inline-block mb-2 parent img-parent"  v-else-if="messages.type==2">
                                    <div class="msg-span file-span pos-rel chat-img-div-css mr-4" id="img-div" v-for="(documents,docIndex) in messages.data " @click="setSlider(messages.data)" :key="docIndex" v-b-modal.modal-image-slider>
                                        <span class="msg-span p-0"> 
                                            <img :src="documents.media" width="100" height="100" @click="setImage(docIndex)">
                                            <!-- <span class="msg-time">{{ documents.time }}</span> -->
                                        </span>
                                        <div class="dropdown-option" v-if="documents.type==2">
                                            <b-dropdown size="md" right  variant="link" toggle-class="text-decoration-none" no-caret >
                                                <template #button-content>
                                                    <i class="fa fa-ellipsis-v text-muted action-dots-right" aria-hidden="true"></i>
                                                </template>
                                                <b-dropdown-item href="javascript:void(0);" v-if="$store.state.currentUserData.login_master_id == documents.sended_by" @click="deleteFile(documents.chat_id,index,ind,mindex,docIndex)">
                                                    <i class="fa fa-trash" aria-hidden="true"></i> Delete
                                                </b-dropdown-item>
                                                <b-dropdown-item href="javascript:void(0);" v-else @click="downloadFile(documents.media_withoutbasepath)">
                                                    <a></a>
                                                    <i class="fa fa-download" aria-hidden="true"></i> Download
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div v-else-if="messages.type==3" class="parent file-parent">
                                    <div class="msg-span file-span pos-rel pdf-div-css" v-for="(documents,docIndex) in messages.data " :key="docIndex" id="file-div">
                                        <a href="javascript:void(0)" class="text-black"><i class="fa fa-file mr-4" aria-hidden="true"></i>{{ documents.message }}</a>
                                        <div class="dropdown-option" v-if="documents.type==3">
                                            <b-dropdown size="md" right  variant="link" toggle-class="text-decoration-none" no-caret >
                                                <template #button-content>
                                                    <i class="fa fa-ellipsis-v text-muted action-dots-right" aria-hidden="true"></i>
                                                </template>
                                                <b-dropdown-item href="javascript:void(0);" v-if="$store.state.currentUserData.login_master_id == documents.sended_by" @click="deleteFile(documents.chat_id,index,ind,mindex,docIndex)">
                                                    <i class="fa fa-trash" aria-hidden="true"></i> Delete
                                                </b-dropdown-item>
                                                <b-dropdown-item href="javascript:void(0);" v-else @click="downloadFile(documents.media_withoutbasepath)">
                                                    <i class="fa fa-download" aria-hidden="true"></i> Download
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </span>
                <span class="no-data-conversation" v-else>
                    <a href="javascript:void(0)">
                        <div class="col-sm-12 col-xl-12 sideBar-avatar text-center">
                            <div class="image-holder">
                                <img src="/web/chat-box.png" alt="Chat" class="img-fluid">
                            </div>
                            <h4 class="fz-16 fw-600 text-muted mb-0">Nothing here</h4>
                            <p class="fz-12 fw-400 text-muted">There are no chats in your feed. <a href="javascript:void(0);" class="text-theme">Create a New Chat.</a></p>
                        </div>
                    </a>
                </span>
                
            </div>
            
        </div>
        <div class="up-d-composer-attachment" v-if="preview_list.length > 0 ">
            <div class="up-d-file-attachment composer-attachment" v-for="(prev,pindex) in preview_list" :key="pindex">
                <div class="file-attachment">
                    <div style="display: flex;">
                        <div class="attachment-preview">
                            <a target="_blank" class="up-d-story-img loaded" style="width: 40px; height: 40px;">
                                <img :src="image_list[pindex]" v-if="prev.type == 'image/jpeg' || prev.type == 'image/jpg' || prev.type == 'image/png' || prev.type == 'image/svg' || prev.type == 'image/gif'">
                                <i class="fa fa-file mt-1" v-else aria-hidden="true"></i>
                            </a>
                        </div>
                        <div class="file-name-wrapper">
                            <span class="file-name">
                                <span class="name">
                                    {{prev.name}}
                                </span>
                            </span> 
                            <div class="file-content">
                                <span class="text-size d-flex align-items-center">
                                    {{prev.size | sizeInMb}}
                                    <span class="file-check-icon"  :id="'progress-complete_'+pindex" style="display: none;"></span>
                                </span>
                            </div>
                        </div> 
                        <span class="remove-attachment" @click="removePrev(pindex)">
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 14 14" role="img" class="remove-icon"><polygon fill-rule="evenodd" points="12.524 0 7 5.524 1.476 0 0 1.476 5.524 7 0 12.524 1.476 14 7 8.476 12.524 14 14 12.524 8.476 7 14 1.476"></polygon></svg>
                        </span>
                    </div>
                    <div class="progress progress-height" :id="'progress-parent_'+pindex" v-if="prev.successStatus != 100">
                        <div class="progress-bar" role="progressbar" :id="'progressbar_'+pindex" :style="'width:'+ prev.successStatus+'%'" :aria-valuenow="prev.successStatus" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div> 
            </div>
        </div>
        <div class="row reply d-flex align-items-center m-0" v-if="messagelist.length > 0 || pageType=='sidebar'">
            <form @submit.prevent="submitform" class="d-flex align-items-center p-0">
                <!-- <input v-if="chatroomMembers.length > 0"  id="fileUpload" -->
                <input  id="fileUpload"
                    style="display: none;"
                    multiple
                    hidden type="file"
                    @change="UploadFile"
                    accept=".png,.PNG,.jpeg,.JPEG,.jpg,.JPG,.word,.pdf,.xlsx,.xls,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                >
                <div class="reply-emojis w-100 pos-rel">
                    <div class="reply-recording text-right ml-5 mr-5" v-if="updateMessage.editMessage == false && getmessageparams.chat_room_id!=''" @click="chooseFiles()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16">
                            <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"/>
                        </svg>
                    </div>
                    <div class="edit-close text-right" v-if="updateMessage.editMessage == true" @click="cancelEdit()">
                        <i class="fa fa-close"></i>
                    </div>
                    <div class="reply-send" v-if="getmessageparams.chat_room_id!=''">
                        <b-button type="submit" :disabled="(!sendChat.message || (/^\s*$/.test(sendChat.message)) || isContentEmpty(sendChat.message)) && sendChat.mediaData.length==0" style="padding:4px 8px;" class="btn chat-reply-btn"><i class="fa fa-send fa-2x" aria-hidden="true"></i></b-button>
                    </div>
                    <emoji-picker v-if="getmessageparams.chat_room_id!=''" @emoji="append" :search="searchEmoji">
                        <button
                            type="button"
                            class="emoji-invoker"
                            slot="emoji-invoker"
                            slot-scope="{ events: { click: clickEvent } }"
                            @click.stop="clickEvent"
                        >
                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current text-grey">
                                <path d="M0 0h24v24H0z" fill="none"/>
                                <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"/>
                            </svg>
                        </button>
                
                        <div slot="emoji-picker" slot-scope="{ emojis, append, display }">
                            <div class="emoji-picker" :style="{ top: display.y + 'px', left: display.x + 'px' }">
                                <div class="emoji-picker__search">
                                    <input type="text" v-model="searchEmoji">
                                </div>
                                <div>
                                    <div v-for="(emojiGroup, category) in emojis" :key="category">
                                    <h5>{{ category }}</h5>
                                    <div class="emojis">
                                        <span
                                        v-for="(emoji, emojiName) in emojiGroup"
                                        :key="emojiName"
                                        @click="insert(emoji)"
                                        :title="emojiName"
                                        >{{ emoji }}</span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </emoji-picker>
                    <div v-if="getmessageparams.chat_room_id!=''" class="settings-btn-wrap">
                        <b-dropdown id="dropdown-dropup" dropup right class="setting-btn enter-btn-setting">
                            <template #button-content>
                                <i class="fa fa-cog text-black" aria-hidden="true"></i>
                            </template>
                            <b-dropdown-item><b>Pressing Enter Key Will :</b></b-dropdown-item>
                            <b-dropdown-item @click="UpdateEnterBtnRole('A')">
                                <div class="custom-control custom-control-inline custom-radio">
                                    <input type="radio" class="custom-control-input" value="A" id="__BVID__62_BV_option_1" name="__BVID__62" v-model="SetEnterBtnRole">
                                    <label class="custom-control-label" for="__BVID__62_BV_option_1"><span>Send Message</span></label>
                                </div>

                            </b-dropdown-item>
                            <b-dropdown-item @click="UpdateEnterBtnRole('B')">
                                <div class="custom-control custom-control-inline custom-radio">
                                    <input type="radio" class="custom-control-input" value="B" id="__BVID__62_BV_option_2" name="__BVID__62" v-model="SetEnterBtnRole">
                                    <label class="custom-control-label" for="__BVID__62_BV_option_2"><span>Add a link break</span></label>
                                </div>
                            </b-dropdown-item>
                            <b-dropdown-item>You can always use Shift+Enter to type multi-line messages.</b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <!-- https://www.npmjs.com/package/vue2-editor -->
                    <!-- https://www.vuescript.com/best-wysiwyg-rich-text-editor/ -->
                    <!--<textarea class="form-control ml-5" v-model="sendChat.message" @keyup="sendChat.messageType = 1;" rows="1" id="comment" ></textarea>-->
                    <wysiwyg v-if="getmessageparams.chat_room_id!=''" class="form-control" v-model="sendChat.message" @keyup.enter="sendChat.messageType = 1;"/>
                </div>
            </form>
        </div>

        <b-modal id="modal-image-slider" size="xl" title="" centered hide-header hide-footer>
            <b-carousel
                :interval=0
                id="carousel-1"
                v-model="slider"
                controls
                indicators
                background="#ababab"
                img-width="1024"
                img-height="480"
                style="text-shadow: 1px 1px 2px #333;"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
                > 
                <!-- Slides with image only -->
                <b-carousel-slide v-for="(data,index) in sliderImages" :key="index"  :img-src="data.media">
                    <b-button class="download-btn btn-theme">Download</b-button>
                </b-carousel-slide>
                <!-- Slides with img slot -->
            </b-carousel>
        </b-modal>
    </span>
</template>
<script>
var that = '';
import $ from 'jquery';
import { mapActions,mapGetters} from "vuex";
export default {
    created() {
        that = this;
        window.addEventListener('keydown', function(event) {
            if(that.$store.state.enterBtnRole == 'A'){
                if (event.keyCode === 13 && !event.shiftKey) { 
                    var messageStatus = that.sendChat.message.replaceAll("&nbsp;", "").
                    replaceAll(" ", "").
                    replaceAll("<div><br></div>", "").trim();
                    event.preventDefault();
                    if(messageStatus !=''){
                        that.submitform();
                    }else{
                        that.sendChat.message = '';
                    }
                }
            }
        });
    },
    props:['chatroomdata','pageType'],
    data() {
        return {
            searchEmoji:'',
            roomlist:[],
            messagelist:[],
            getmessageparams:{
                chat_room_id:'',
                start_limit:0,
                end_limit:50,
                cycle:'',
                lastId :'',
                firstId:'',
            },
            sendChat:{
                message:'',
                messageType:'',
                mediaData:[],
            },
            currentUser:'',
            updateMessage:{
                chatData:'',
                chatIndex:'',
                chatParentIndex:'',
                chatChildIndex:'',
                editMessage:false,
            },
            chatroomMembers:[],
            emoji:[],
            append:'',
            hidePeople:false,
            preview_list:[],
            image_list:[],
            changeTimeZone:false,
            uploadRecentfiles:{
                chat_room_id:'',
                message:'',
                file_path:'',
                files:[],
            },
            sliderImages:'',
            slide: 0,
            sliding: null,
            slider:0,
            sendMsgStatus   : 0,
        }
    },
    methods: {
        ...mapActions(["genericAPIPOSTRequest","cancelApiRequest","UpdateEnterBtnRole"]),
        ...mapActions('global',["UpdateMessageRequestData","EmptyMessageData"]),
        handleScroll(event) {
            const currentScrollPosition = event.target.scrollTop;
            if (currentScrollPosition > this.previousScrollPosition) {
                // Scrolling down
                // this.getmessageDataLoad('scrollDown');
            } else {
                // Scrolling up
                if(currentScrollPosition <= 0){
                    // this.getmessageDataLoad('up');
                    var messageRequestdata = this.$store.state.global.mRequestData;
                    messageRequestdata.cycle = 'up';
                    this.UpdateMessageRequestData({params:messageRequestdata});
                }
            }
            this.previousScrollPosition = currentScrollPosition;
        }, 
        onSlideStart() {
            this.sliding = true
        },
        onSlideEnd() {
            this.sliding = false
        },
        setSlider(data){
            this.sliderImages = data;
        },  
        setImage(index){
            this.slider= index;
        },
        // this fun is for to cancel edit
        cancelEdit(){
            this.updateMessage={
                chatData:'',
                chatIndex:'',
                chatParentIndex:'',
                chatChildIndex:'',
                editMessage:false,
            };
            this.sendChat.message='';
        },
        // this fun is for to submit chat form according to need
        submitform(){
            if(this.updateMessage.editMessage == true){
                this.updateMsg();
            }else{
                this.sendmessageData();
            }
        },
        // this funis for to update chat message
        async updateMsg(){
            var res = await this.genericAPIPOSTRequest({
                requestUrl:'sendMessage',
                params: {
                    chat_room_id: this.updateMessage.chatData.chat_room_id,
                    chat_id: this.updateMessage.chatData.chat_id,
                    sended_by: this.$store.state.currentUserData.login_master_id,
                    type: this.updateMessage.chatData.type,
                    message: this.sendChat.message,
                },
            });
            if (res) {
                this.messagelist[this.updateMessage.chatIndex].value[this.updateMessage.chatParentIndex].value[this.updateMessage.chatChildIndex].message= this.sendChat.message;
                this.sendChat.message='';
                this.updateMessage={
                    chatData:'',
                    chatIndex:'',
                    chatParentIndex:'',
                    chatChildIndex:'',
                    editMessage:false,
                };
            }
        },
        // this fun is for to delete message
        async deleteMsg(_i,index,parentindex,childindex,event){
            var res = await this.genericAPIPOSTRequest({
                requestUrl:'deleteMessage',
                params: {
                    'chat_id':_i,
                    'is_deleted':1,
                    'deleted_by': this.$store.state.currentUserData.login_master_id,
                },
            });
            if (res ) {
                this.messagelist[index].value[parentindex].value[childindex].message='<span class="bg-grey p-2 br-10 text-muted"><i>You removed this message</i></span>';
                this.messagelist[index].value[parentindex].value[childindex].is_deleted = 1;
                event.target.parentElement.parentElement.parentElement.parentElement.style.display = 'none';
                //this.messagelist[index].value[parentindex].value.splice(childindex,1);
            }
        },
        // this fun is for to delete message
        async deleteFile(_i,index,parentindex,childindex,childchildindex){
            var res = await this.genericAPIPOSTRequest({
                requestUrl:'deleteMessage',
                params: {
                    'chat_id':_i,
                    'is_deleted':1,
                    'deleted_by': this.$store.state.currentUserData.login_master_id,
                },
            });
            if (res ) {
                this.messagelist[index].value[parentindex].value[childindex].data.splice(childchildindex,1);
            }
        },
        // this function is for to upload attachment
        async UploadFile(e){ 
            this.previewMultiImage(e);
            var error=0;
            for (let index = 0; index < e.target.files.length; index++) {
                var files=e.target.files[index];
                var ext = files.name.split('.').pop();
                if(ext=="pdf" || ext=="word" || ext=="png"|| ext=="jpeg"|| ext=="jpg" || ext=="xlsx" || ext=="xls" || ext=="docx" || ext=="doc"){
                    // var ext_type = (ext=="pdf" || ext=="word")?'3':'2';
                    const fileSize = files.size / 1024 / 1024; // in MiB
                    if ( fileSize <= 25) {
                    // if (fileSize >= 2 && fileSize <= 40) {
                        const formData = new FormData();
                        formData.append('image', files);
                        formData.append('chat_room_id', this.getmessageparams.chat_room_id);
                        formData.append('user_id', this.$store.state.currentUserData.login_master_id);
                        var getData = await this.genericAPIPOSTRequest({
                            requestUrl: "chatFile",
                            params: formData,
                        });
                        if(getData.filename!=""){
                            var that =this;
                            getData.is_type=ext;
                            that.sendChat.mediaData.push(getData);
                            this.preview_list.filter((data,pindex)=>{
                                if (data.name == files.name) {
                                    data.successStatus = 100;
                                    $('#progressbar_'+pindex).css('width',data.successStatus+'%');
                                    $('#progress-parent_'+pindex).css('display','none');
                                    $('#progress-complete_'+pindex).css('display','block');
                                    return false;
                                }
                            });
                            this.uploadRecentfiles.file_path = getData.uploadPath;
                            this.uploadRecentfiles.chat_room_id = this.getmessageparams.chat_room_id;
                        }else{
                            this.preview_list.splice(index,1);
                            this.image_list.splice(index,1);
                            error++;
                        }
                    }else{
                        this.$toastr.e('File Size Should be Less Than 25 Mb');
                    }
                } else{
                    this.$toastr.e('Only Allow Files (Pdf, Word, Png, Jpeg, Jpg, Xlsx, Xls, Docx, Doc)');
                }
                if(error!=0){
                    this.$toastr.e('Your All file is Not uploaded');
                }
            }
            document.getElementById("fileUpload").value = "";
        },
        // this fun is for to preview the multiple image upload
        previewMultiImage: function(event) {
            var input = event.target;
            var count = input.files.length;
            if (input.files) {
                for (let index = 0; index < count; index++) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        this.image_list.push(e.target.result)
                    }
                    input.files[index].successStatus = Math.floor((Math.random() * 70) + 1);
                    this.preview_list.push(input.files[index]);
                    reader.readAsDataURL(input.files[index]);
                }
            }
        },
        // this fun is for to remove preview files and images
        removePrev(_i){
            this.preview_list.splice(_i,1);
            this.image_list.splice(_i,1);
            this.sendChat.mediaData.splice(_i,1);
        },
        // this fun is for to choose file
        chooseFiles() {
            document.getElementById("fileUpload").value = "";
            document.getElementById("fileUpload").click()
        },
        // this fun is for to add emoji to message
        insert(emoji) {
            this.sendChat.message += emoji
        },
        // this fun is for to set get message id
        async  getselectedusermessage(){
            that.messagelist = [];
            that.getmessageparams.chat_room_id = this.chatroomdata.chat_room_id;
            that.getmessageparams.start_limit = 0;
            that.EmptyMessageData();
            that.UpdateMessageRequestData({params:{
                chat_room_id        : this.chatroomdata.chat_room_id,
                start_limit         : 0,
                end_limit           : 50,
                cycle               : 'first',
                lastId              : '',
                firstId             : '',
            }});
        },
        // this fun is for to get messages
        async readMessages(){
            var user_id;
            if(this.$store.state.accountType == 'freelancer'){
                user_id = this.$store.state.currentUserData.login_master_id;
            }else if(this.$store.state.accountType == 'client' && this.$store.state.listAccount.user != null && this.$store.state.listAccount.user != '' && this.$store.state.currentUserData.login_master_id == this.$store.state.listAccount.user.user_id){
                user_id = this.$store.state.currentUserData.login_master_id;
            }else if(this.$store.state.accountType == 'agency' && this.$store.state.currentUserData.login_master_id == this.$store.state.listAccount.company.login_master_id){
                user_id = this.$store.state.currentUserData.login_master_id;
            }else if(this.$store.state.accountType == 'client' && this.$store.state.listAccount.user != null && this.$store.state.listAccount.user != '' && this.$store.state.currentUserData.login_master_id != this.$store.state.listAccount.user.user_id){
                user_id = this.$store.state.currentUserData.permission.user_id;
            }else if(this.$store.state.accountType == 'client' && this.$store.state.currentUserData.permission.user_id == this.$store.state.listAccount.qp_user.user_id){
                user_id = this.$store.state.currentUserData.permission.user_id;
            }else if(this.$store.state.accountType == 'agency' && this.$store.state.currentUserData.login_master_id != this.$store.state.listAccount.company.login_master_id){
                user_id = this.$store.state.currentUserData.permission.user_id;
            }
            await this.genericAPIPOSTRequest({
                requestUrl  :'readMessage',
                params      : {
                    luid            : user_id,
                    chat_room_id    : this.chatroomdata.chat_room_id,
                    // chat_room_id    : this.getmessageparams.chat_room_id,
                },
            });
            this.$emit('updateUnreadMessage')
        },
        // this fun is for to send messages
        async sendmessageData(){
            var element = document.getElementById("conversation");
            var checkcontent=this.isContentEmpty(this.sendChat.message);
            this.sendChat.message = this.sendChat.message.replaceAll("&nbsp;", "").
            // replaceAll(" ", "").
            // replaceAll("<div><br></div>", "").trim();
            replaceAll("<div><br></div>", "").
            replaceAll( new RegExp( ">[ ]+<" , "g" ) , "><" ).
            replaceAll("<div></div>", "").trim();
            if(this.sendChat.message!='' && checkcontent==false && this.sendMsgStatus == 0){
                this.sendMsgStatus = 1;
                var response = await this.genericAPIPOSTRequest({
                    requestUrl:'sendMessage',
                    params: {
                        chat_room_id:this.getmessageparams.chat_room_id,
                        sended_by:this.$store.state.currentUserData.login_master_id,
                        type: 1,
                        message: this.sendChat.message,
                    },
                });
                if (response) {
                    this.uploadRecentfiles.message = this.sendChat.message;
                    this.sendChat.message='';
                    if(this.sendChat.mediaData.length > 0){
                        this.sendChat.mediaData.map((data,pindex)=>{
                            this.uploadRecentfiles.files.push({'type':data.is_type,'name':data.name,'size':data.size});
                            if(data.is_type =="png"|| data.is_type =="jpeg"|| data.is_type =="jpg") {
                                data.is_type = 1;
                            }else if(data.is_type =="pdf" || data.is_type =="word" ){
                                data.is_type = 3;
                            }
                            var res = this.genericAPIPOSTRequest({
                                requestUrl:'sendMessage',
                                params: {
                                    chat_room_id:this.getmessageparams.chat_room_id,
                                    sended_by:this.$store.state.currentUserData.login_master_id,
                                    type: (data.is_type == 1)?'2':'3',
                                    size: data.size,
                                    message: data.name,
                                    file_type: this.preview_list[pindex].type,
                                    parent_id:response,
                                },
                            });
                            if (res) {
                                setTimeout(() => {
                                    element.scrollTo({top: element.scrollHeight+100, behavior: 'smooth'});
                                }, 2000);
                            }
                        });
                        if(this.uploadRecentfiles.files.length > 0){
                            this.addRecentfiles();
                        }
                        this.preview_list=[];
                        this.image_list=[];
                        this.sendChat.mediaData=[];
                    }else{
                        setTimeout(() => {
                            element.scrollTo({top: element.scrollHeight+100, behavior: 'smooth'});
                        }, 2000);
                    }
                }
                this.sendMsgStatus = 0;
                that.UpdateMessageCycleData('');
            }else if(this.sendChat.mediaData.length > 0 && this.sendMsgStatus == 0){
                this.sendMsgStatus = 1;
                this.sendChat.mediaData.map((data,pindex)=>{
                    this.uploadRecentfiles.files.push({'type':data.is_type,'name':data.name,'size':data.size});
                    if(data.is_type =="png"|| data.is_type =="jpeg"|| data.is_type =="jpg") {
                        data.is_type = 1;
                    }else if(data.is_type =="pdf" || data.is_type =="word" ){
                        data.is_type = 3;
                    }
                    var res = this.genericAPIPOSTRequest({
                        requestUrl:'sendMessage',
                        params: {
                            chat_room_id:this.getmessageparams.chat_room_id,
                            sended_by:this.$store.state.currentUserData.login_master_id,
                            type: (data.is_type == 1)?'2':'3',
                            size: data.size,
                            message: data.name,
                            file_type: this.preview_list[pindex].type,
                        },
                    });
                    if (res) {
                        setTimeout(() => {
                            element.scrollTo({top: element.scrollHeight+100, behavior: 'smooth'});
                        }, 2000);
                    }
                });
                if(this.uploadRecentfiles.files.length > 0){
                    this.addRecentfiles();
                }
                this.preview_list=[];
                this.image_list=[];
                this.sendChat.mediaData=[];
                this.sendMsgStatus = 0;
                that.UpdateMessageCycleData('');

            }
        },
        // this fun is for to add recent files to database
        async addRecentfiles(){
            var res = await this.genericAPIPOSTRequest({
                requestUrl: "addContractfiles",
                params: {
                    files:this.uploadRecentfiles.files,
                    file_path:this.uploadRecentfiles.file_path,
                    message:this.uploadRecentfiles.message,
                    chat_room_id:this.uploadRecentfiles.chat_room_id,
                    type:'Chat-Files'
                },
            });
            if(res){
                this.uploadRecentfiles={
                    job_application_id:'',
                    message:'',
                    file_path:'',
                    files:[],
                };
            }
        },
        
        //function to detect if the content is empty
        isContentEmpty(text) {
            text = text.replaceAll("&nbsp;", "").
            replaceAll("<div><br></div>", "").
            replaceAll( new RegExp( ">[ ]+<" , "g" ) , "><" ).
            replaceAll("<div></div>", "").trim();
            return (text.length == 0) ? true : false;
        }
    },
    watch: {
        getmChatData(){
            if(this.getmChatData.length > 0){
                this.messagelist = this.getmChatData;
                if(this.getmessageCycle == 'first'){
                    this.readMessages(this.chatroomdata.chat_room_id);
                }
            }else{
                this.messagelist = [];
            }
        },
        getmessageCycle(){
            var element = document.getElementById("conversation");
            if(that.getmessageCycle == 'first'){
                setTimeout(() => {
                    element.scrollTo({top: element.scrollHeight+10000, behavior: 'smooth'});
                    setTimeout(() => {
                        that.UpdateChatRoomResponse(true);
                        that.chatLoader = false;
                    }, 1000);
                }, 1000);
                that.previousScrollPosition = element.scrollHeight;
            }else if(that.getmessageCycle == 'recurring'){
                setTimeout(() => {
                    element.scrollTo({top: element.scrollHeight+10000, behavior: 'smooth'});
                }, 1000);
                that.previousScrollPosition = element.scrollHeight;
            }else if(that.getmessageCycle == 'up'){
                setTimeout(() => {
                    element.scrollTo({top: 10, behavior: 'smooth'});
                }, 1000);
            }
        }
    },
    component:{
    },
    mounted() {
        that = this;
    },
    computed:{
        ...mapGetters('global',["getmChatData","getmessageCycle"]),
        ...mapGetters({loaderHideShow : "global/getchatLoader",SetEnterBtnRole: "getenterBtnRole"})
    },
    filters:{
        sizeInMb(value){
            let  sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (value == 0) return '0 Byte';
            let i = parseInt(Math.floor(Math.log(value) / Math.log(1024)));
            return Math.round(value / Math.pow(1024, i), 2) + ' ' + sizes[i];
        }
    },  
}
</script>