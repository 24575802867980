<template>
    <div>
        <b-container fluid>
            <b-row>
                <b-col xl="12" lg="12" sm="12" class="no-gutter p-0 mt-100" v-if="displayType == 'step-form'">
                  <div class="form-holder-wrapper bg-white pt-5">
                    <div class="form-steps-holder">
                      <div class="form-steps mt-4">
                          <div v-for="(step, index) in steps" :key="index" :class="{ 'active': currentStep >= index }">
                              {{ step }}
                          </div>
                      </div>
                    </div>
                    <div class="form mt-4">
                        <form @submit.prevent="submitForm">
                            <div v-show="currentStep === 0">
                              <div class="step-content-holder">
                                <h3 class="mb-3"><b>Why you're disputing</b></h3>
                                  <div class="bg-light mb-3 p-3">
                                    <i class="fa fa-eye pr-3" aria-hidden="true"></i> {{jobDetails.client_name+' will see your response'}} 
                                  </div>
                                <div class="response-wrapper">
                                  <h6><b>Choose your reason(s)</b></h6>
                                  <template v-if="disputeReason.length > 0">
                                    <b-form-checkbox  v-for="(data,index) in disputeReason" :key="index"
                                      :id="'ali'+index+1" 
                                      v-model="disputedData.reason"
                                      :value="data.reason" name="checkbox-1">
                                      {{data.reason}}
                                    </b-form-checkbox>
                                    <p class="error" v-if="disputeReasonError[0]">{{disputeReasonError[0]}}</p>
                                    <b-form-textarea 
                                      v-if = "disputedData.reason == 'Other'"
                                      v-model="disputedData.otherReason"
                                      class="mb-3"
                                      id="textarea"
                                      placeholder="Enter other reason"
                                      rows="3"
                                      max-rows="6"
                                    ></b-form-textarea>
                                    <p class="error" v-if="disputedData.reason == 'Other' && disputedData.otherReason == '' && showvalidationError">{{'Other Reason is required'}}</p>
                                  </template>
                                </div>
                              </div>
                              <div class="btn-holder border-top py-2 px-2 pb-2 text-right">
                                <button @click="nextStep(1)" type="button" class="btn btn-theme">Next</button>
                              </div>
                            </div>

                            <div v-show="currentStep === 1">
                              <div class="step-content-holder">
                                <!-- Step 2 -->
                                <h3 class="mb-3"><b>How you met requirements</b></h3>
                                <div class="bg-light mb-3 p-3">
                                  <i class="fa fa-eye-slash pr-3" aria-hidden="true"></i> {{jobDetails.client_name+" won't see your response, only Qapin."}}
                                </div>
                                <h6 class="mb-3"><b>Describe relevant agreements and how you met requirements</b></h6>
                                <b-form-textarea 
                                  v-model="disputedData.description"
                                  class="mb-3"
                                  id="textarea"
                                  placeholder="Describe..."
                                  rows="3"
                                  max-rows="6"
                                ></b-form-textarea>
                                <p class="error" v-if="disputeDescriptionError[0]">{{disputeDescriptionError[0]}}</p>

                                <h6 class="mb-3"><b>Attach proof of requirements and completed work</b></h6>
                                <p class="text-muted fz-14 mb-2 fw-500">Files or screenshots of contract details, messages/emails, your work, and other evidence</p>
                                <p class="text-muted fz-14 mb-2 fw-500">Up to 5 attachments (jpeg, png, pdf, doc, docx, txt), each less than 6.2 MB</p>
                                <p class="text-muted fz-14 mb-3 fw-500">Videos can be uploaded to a third party application such as Google Drive, Dropbox or YouTube and a public link should be shared with us for review</p>
                                <b-button class="btn btn-outline-theme mb-sm-4" @click="chooseFiles" v-if="uploadAttachment.length < 5"><i class="fa fa-paperclip" aria-hidden="true"></i> Attach</b-button><br>
                                <input id="disputeFileupload" hidden type="file" multiple @change="UploadDisputeFile" accept=".png,.PNG,.jpeg,.JPEG,.jpg,.JPG,.word,.pdf,.xlsx,.xls,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document">
                                <template v-if="uploadAttachment.length > 0">
                                  <p v-for="(media,index) in uploadAttachment" :key="index">
                                    {{ media.name }}<span class="ml-2 fa fa-close text-theme cursor-pointer" v-on:click="uploadAttachment.splice(index, 1)"></span>
                                  </p>
                                </template>
                                <p class="error" v-if="uploadAttachment.length == 0 && showvalidationError">{{'Attachment is required'}}</p>

                              </div>
                              <div class="btn-holder border-top py-2 px-2 d-flex align-items-center justify-content-space-between">
                                <!-- Your form fields for step 2 -->
                                <button @click="currentStep--" type="button" class="btn btn-outline-theme">Previous</button>
                                <button @click="nextStep(2)" type="button" class="btn btn-theme">Next</button>
                              </div>
                            </div>

                            <div v-show="currentStep === 2">
                              <div class="step-content-holder">
                                <!-- Step 3 -->
                                <h3 class="mb-3"><b>Request up to ${{proposalData.refund_requested_amount}}</b></h3>
                                <div class="bg-light mb-3 p-3">
                                  <i class="fa fa-eye pr-3" aria-hidden="true"></i> {{jobDetails.client_name+' will see this amount and pay it if they agree.'}}
                                </div>
                                <h6 class="mb-3"><b>Enter an amount up to ${{proposalData.refund_requested_amount}} that you'd accept to resolve the dispute:</b></h6>
                                <b-row>
                                  <b-col xl="3" lg="3" sm="12" class="pt-0" v-if="proposalData.refund_requested_amount > 0">
                                    <b-form-input 
                                      oninput="this.value = this.value.replace(/[^0-9.]/g, '');"
                                      v-model="disputedData.requested_amount"
                                      autocomplete="nope"
                                      onselectstart="return false"
                                      onpaste="return false"
                                      onCopy="return false"
                                      onCut="return false"
                                      onDrag="return false"
                                      onDrop="return false"
                                      :placeholder="proposalData.refund_requested_amount"></b-form-input>
                                      <p class="error" v-if="disputeAmountError[0]">{{disputeAmountError[0]}}</p>
                                      <p class="error" v-if="checkAmountError">{{'Enter an amount up to $'+proposalData.refund_requested_amount}}</p>

                                  </b-col>
                                </b-row>
                                <ul class="minus--30 mt-3">
                                  <li class="fz-14 text-muted">Amount released from escrow <span class="ml-5">${{ReleaseFund}}</span></li>
                                  <li class="fz-14 text-muted">Amount remaining in escrow&nbsp;&nbsp;&nbsp;&nbsp; <span class="ml-4">${{escrowAmount}}</span></li>
                                </ul>
                              </div>
                              <div class="btn-holder border-top py-2 px-2 d-flex align-items-center justify-content-space-between">
                                <!-- Your form fields for step 3 -->
                                <button @click="currentStep--" type="button" class="btn btn-outline-theme">Previous</button>
                                <button @click="nextStep(3)" type="button" class="btn btn-theme">Next</button>
                              </div>
                            </div>

                            <div v-show="currentStep === 3">
                              <div class="step-content-holder">
                                <!-- Step 4 -->
                                <h3 class="mb-0"><b>Ready to submit your dispute?</b></h3>
                                <p class="mb-3">Double check that everything looks correct to you now. You won't be able to edit details after submitting.</p>
                                <div class="dispute-options-wrapper mb-3 border-bottom">
                                  <div class="d-flex align-items-center py-4 border-top">
                                    <div>
                                      <h5 class="mb-0"><b>Why you're disputing</b></h5>
                                      <ul class="minus--30 mb-0 text-muted">
                                        <li>{{(disputedData.reason == 'Other')?disputedData.otherReason:disputedData.reason}}</li>
                                      </ul>
                                    </div>
                                    <div class="ml-auto">
                                      <p @click="currentStep = 0" class="text-theme cursor-pointer">Edit</p>
                                    </div>
                                  </div>
                                  <div class="d-flex align-items-center py-4 border-top">
                                    <div>
                                      <h5 class="mb-0"><b>how you met requirements</b></h5>
                                      <p class="mb-0 text-muted">{{disputedData.description}}</p>
                                    </div>
                                    <div class="ml-auto">
                                      <p @click="currentStep = 1" class="text-theme cursor-pointer">Edit</p>
                                    </div>
                                  </div>
                                  <div class="d-flex align-items-center py-4 border-top">
                                    <div>
                                      <h5 class="mb-0"><b>Attachments for proof</b></h5>
                                      <template v-if="uploadAttachment.length > 0">
                                        <p class="mb-0 text-muted" v-for="(media,index) in uploadAttachment" :key="index">
                                          {{ media.name }}
                                        </p>
                                      </template>
                                    </div>
                                    <div class="ml-auto">
                                      <p @click="currentStep = 1" class="text-theme cursor-pointer">Edit</p>
                                    </div>
                                  </div>
                                  <div class="d-flex align-items-center py-4 border-top">
                                    <div>
                                      <h5 class="mb-0"><b>Requesting ${{disputedData.requested_amount}}</b></h5>
                                      <p class="mb-0 text-muted">You will be paid ${{disputedData.requested_amount}} more if {{jobDetails.client_name}} accepts your request. If {{jobDetails.client_name}} doesn't respond within 5 days, Qapin will pay you ${{disputedData.requested_amount}} from their remaining funds in escrow.</p>
                                    </div>
                                    <div class="ml-auto">
                                      <p @click="currentStep = 2" class="text-theme cursor-pointer">Edit</p>
                                    </div>
                                  </div>
                                </div>
                                <p class="mb-3 fw-600">If {{jobDetails.client_name}} declines your request with in 5 days, Qapin will recommend a proposal 2 days later via our Dispute Assistance Program. </p>
                                <p class="mb-3 fz-16 fw-600">Your dispute will follow our terms and policies per your previous agreement to the Qapin Terms of service</p>
                              </div>
                              <div class="btn-holder border-top py-2 px-2 d-flex align-items-center justify-content-space-between">
                                <!-- Your form fields for step 4 -->
                                <button @click="currentStep--" type="button" class="btn btn-outline-theme">Previous</button>
                                <button type="submit" class="btn btn-theme" v-if="!submitBtn">Submit</button>
                                <span class="btn btn-theme" :disabled="submitBtn" v-else>Submit...</span>
                              </div>
                            </div>
                        </form>
                    </div>
                  </div>
                </b-col>
                <b-col offset-xl="2" xl="8" offset-lg="2" lg="8" sm="12" v-else-if="displayType == 'thankyou-form'">
                    <div class="next-steps-wrapper">
                        <div class="svg-container mt-100 margin-auto text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="bi bi-file-earmark-bar-graph m-auto" viewBox="0 0 16 16">
                                <path d="M10 13.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5z"/>
                                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
                            </svg>
                        </div>
                        <div class="content mt-5 mb-50 ">
                            <h2 class="fw-600">Next Steps</h2>
                            <h5 class="fz-16 fw-500 text-muted">You're all set for now. Thanks for submitting your dispute.</h5>
                            <h5 class="fz-16 fw-500 text-muted mb-3">{{jobDetails.client_name}} will review your dispute. If they don't respond or they agree to the additional amount you proposed, then you'll get paid ${{disputedData.requested_amount}} immediately.</h5>
                            <div class="bg-light mb-3 p-3 fz-14 ">
                                <i class="fa fa-clock-o pr-3" aria-hidden="true"></i> {{jobDetails.client_name}} has untill {{dateHelper("M d, Y",5)}} to respond.
                            </div>
                            <div class="accordion-wrap border br-20 bg-white p-3">
                                <div class="">
                                    <b-button v-b-toggle.collapse-1 class="bg-white border-0 text-black w-100 text-left">
                                        What if {{jobDetails.client_name}} declines my request?
                                        <i class="fa fa-chevron-down" aria-hidden="true"></i>    
                                    </b-button>
                                    <b-collapse id="collapse-1" class="mt-2">
                                        <b-card>
                                            <div class="d-flex align-items-normal">
                                                <div class="image-holder mr-3 w-10">
                                                    <img src="/web/dispute/balance.png" class="img-fluid">
                                                </div>
                                                <div class="content-holder w-90">
                                                    <h6><b>Qapin Dispute Assistance</b></h6>
                                                    <p class="text-muted fz-14">We'll review details submitted by you both and recommend a proposal within 2 days.<br> You'll then have 2 days to accept or decline our proposal.</p>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-normal">
                                                <div class="image-holder mr-3 w-10">
                                                    <img src="/web/dispute/handshake.png" class="img-fluid"> 
                                                </div>
                                                <div class="content-holder w-90">
                                                    <h6><b>Try negotiating with {{jobDetails.client_name}}</b></h6>
                                                    <p class="text-muted fz-14">While we review your dispute, we encourage you to contact {{jobDetails.client_name}} to negotiate if you feel comfortable doing so.</p>
                                                </div>
                                            </div>
                                        </b-card>
                                    </b-collapse>   
                                </div>
                                <div>
                                    <b-button v-b-toggle.collapse-2 class="mt-2 bg-white border-0 text-black w-100 text-left">
                                        What if we still disagree after Qapin Dispute Assistance? 
                                        <i class="fa fa-chevron-down" aria-hidden="true"></i> 
                                    </b-button>
                                    <b-collapse id="collapse-2" class="mt-2">
                                        <b-card>
                                            <p class="card-text text-muted fz-14">If you both don't accept Qapin Dispute Assistance proposal or negotiate another solution, and you don't withdraw your dispute  by our proposal deadline, then you both can purchase arbitration service from our legal partner. </p>
                                            <div class="d-flex align-items-normal">
                                                <div class="image-holder mr-3 w-10">
                                                    <img src="/web/dispute/auction.png" class="img-fluid">
                                                </div>
                                                <div class="content-holder w-90">
                                                    <h6><b>Qapin Dispute Assistance</b></h6>
                                                    <p class="text-muted fz-14">You both can request legal service from Brief Legal Software, a neutral third party organization that specializes in internet disputes. The arbitration process will make the final, legally binding decision about your pay. </p>
                                                    <ul class="minus--15 list-style-disc">
                                                        <li class="text-muted fz-14">You & {{jobDetails.client_name}} will split the cost of the $675 fee ($337.50 each)</li>
                                                        <li class="text-muted fz-14">Arbitration takes up to 90 days.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </b-card>
                                    </b-collapse> 
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
  var $_ ='';
  import { mapActions } from "vuex";
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'
  export default {
    mixins: [validationMixin],
    validations: {
      disputedData:{
        'reason'                  : {required},
        'description'             : {required},
        'requested_amount'        : {required},
      },
    },
    computed:{
      progress() {
        return ((this.currentStep + 1) / this.steps.length) * 100;
      },
      disputeReasonError() {
        const errors = [];
        if (!this.$v.disputedData.reason.$dirty) return errors;
        !this.$v.disputedData.reason.required && errors.push("Please Select Reason is required");
        return errors;
      },
      disputeDescriptionError() {
        const errors = [];
        if (!this.$v.disputedData.description.$dirty) return errors;
        !this.$v.disputedData.description.required && errors.push("Description is required");
        return errors;
      },
      disputeAmountError() {
        const errors = [];
        if (!this.$v.disputedData.requested_amount.$dirty) return errors;
        !this.$v.disputedData.requested_amount.required && errors.push("Amount is required");
        return errors;
      },
      checkAmountError(){
        if(parseFloat($_.disputedData.requested_amount) > 0 && parseFloat($_.disputedData.requested_amount) > parseFloat($_.proposalData.refund_requested_amount)){
          return true;
        }
        return false;
      },
      // this fun is for to calculate the released amount
      ReleaseFund() {
        var amount = 0;
        if (this.milestoneData.length > 0) {
            this.milestoneData.map(data => {
                if (data.paymentStatus == 1 && data.milestone_status == 1) {
                    amount += parseFloat(data.release_amount);
                }
            });
        }
        return amount.toFixed(2);
      },
      // this fun is for to calculate escrow amount
      escrowAmount() {
        var amount = parseFloat(0);
        if (this.milestoneData.length > 0) {
            this.milestoneData.map(data => {
                if (data.paymentStatus == 1 && data.milestone_status == 0) {
                    amount += parseFloat(data.amount);
                }else if(data.paymentStatus == 1 && data.milestone_status == 1){
                    if(parseFloat(data.amount) > parseFloat(data.release_amount) && parseFloat(data.refund_amount) == 0){
                        amount += (parseFloat(data.amount) - parseFloat(data.release_amount))
                    }
                }
            });
        }
        return amount.toFixed(2);
      },
    },
    data() {
      return {
        currentStep             : 0,
        steps                   : ['Reason', 'Proof', 'Payment', 'Submission'],
        job_id                  : '',
        user_id                 : '',
        disputeReason           : [],
        disputedData            : {
          job_application_id        : '',
          created_by                : '',
          reason                    : '',
          otherReason               : '',
          description               : '',
          attachment                : [],
          disputed_amount           : '',
          requested_amount          : '',
        },
        showvalidationError     : false,
        jobDetails              : '',
        proposalData            : {
          refund_requested_amount   : 0,
        },
        milestoneData           : [],
        uploadAttachment        : [],
        submitBtn               : false,        
        displayType             : 'step-form',
      };
    },
    mounted(){
      $_ = this;
      if($_.$route.params.pid!="" && $_.$route.params.uid !=""){
        $_.job_id         = $_.$route.params.pid;
        var userId        = $_.$route.params.uid;
        userId            = userId.split("ASS");
        $_.user_id        = $_.decodeId(userId.slice(1));
        // this fun is for to get dispute reason
        $_.getJobDetails();
      }else{
          $_.$router.push({'name':$_.$store.state.accountType+'allContracts','path':$_.$store.state.accountType+'/all-contracts'}).catch(()=>{});
      }
    },
    created() {
      // This hook is called when the component is created
    },
    methods: {
      ...mapActions(["genericAPIPOSTRequest"]),
      // this fun is for to get job details
      async getJobDetails(){
        var GetJobDetails = await $_.getApiData("getJobDetails_new",{'poid':$_.job_id});
        if(GetJobDetails){
          $_.jobDetails = GetJobDetails[0];
          $_.getProposalDetails();
        }
      },
      // this fun is for to get proposal details
      async getProposalDetails(){
        var response = await $_.getApiData("getCreatedMilestone",{"job_id":$_.jobDetails.jobs_id, "user_id":$_.user_id});
        if(response.proposal && response.proposal.refund_request_status == 1){
          $_.proposalData = response.proposal;
          $_.milestoneData = response.milestone;
          $_.getDisputeReason();
        }else{
          $_.$router.push({'name': $_.$store.state.accountType+'contractDetail','path' : $_.$store.state.accountType+'/contract-detail','params': {'pid': $_.$route.params.pid,'uid': 'A'+$_.encodedid($_.user_id)}}).catch(()=>{});
        }
      },
      // this fun is for to get dispute reason
      async getDisputeReason(){
        var res = await $_.getApiData("getDisputeReasonFreelancer",{});
        if(res.length > 0 ){
          $_.disputeReason = res;
        }
      },
      // this function is for to upload attachment
      async UploadDisputeFile(e) {
        if(this.uploadAttachment.length < 5 && e.target.files.length <= 5){
          var available_files = 5 - this.uploadAttachment.length;
          if((this.uploadAttachment.length == 0 && e.target.files.length <= 5) || available_files >= e.target.files.length ){
            for (let index = 0; index < e.target.files.length; index++) {
              var files = e.target.files[index];
              var ext = files.name.split('.').pop();
              if(ext=="pdf" || ext=="word" || ext=="png"|| ext=="jpeg"|| ext=="jpg" || ext=="xlsx" || ext=="xls" || ext=="docx" || ext=="doc"){
                const fileSize = files.size / 1024 / 1024; // in MiB
                if (fileSize <= 10) {
                    $_.uploadAttachment.push(files);
                } else {
                  this.$toastr.e('This '+files.name+' file Size Should be Less Than 10 Mb');
                }
              } else {
                this.$toastr.e('Only Allow Files (Pdf, Word, Png, Jpeg, Jpg, Xlsx, Xls, Docx, Doc)');
              }
            }
          }else if(available_files < e.target.files.length){
            this.$toastr.e('else -if Maximum 5 files can attach');
          }
        }else{
          this.$toastr.e('Maximum 5 files can attach');
        }
        document.getElementById("disputeFileupload").value = "";
      },
      // this fun is for to choose file
      chooseFiles() {
        document.getElementById("disputeFileupload").value = "";
        document.getElementById("disputeFileupload").click()
      },
      // this fun is for steep form
      nextStep(_v) {
        $_.$v.disputedData.$touch();
        if(_v == 1){
          if($_.disputedData.reason != ''){
            if($_.disputedData.reason != 'Other'){
              $_.showvalidationError = false;
              $_.currentStep = _v;
              $_.$v.$reset();
            }else{
              if($_.disputedData.otherReason != ''){
                $_.showvalidationError = false;
                $_.currentStep = _v;
                $_.$v.$reset();
              }else{
                $_.showvalidationError = true;
              }
            }
          }
        }else if(_v == 2){
          if($_.disputedData.description != '' && $_.uploadAttachment.length > 0){
            $_.$v.$reset();
            $_.currentStep = _v;
            $_.showvalidationError = false;
          }else{
            $_.showvalidationError = true;
          }
        }else if(_v == 3){
          if(!$_.checkAmountError && $_.disputedData.requested_amount > 0){
            $_.$v.$reset();
            $_.currentStep = _v;
          }
        }
      },
      // this fun is fot to submit for data
      async submitForm() {
        $_.submitBtn = true;
        $_.$v.disputedData.$touch();
        if(!$_.$v.disputedData.$invalid && $_.uploadAttachment.length > 0){
          $_.uploadAttachmentfiles();
        }
      },
      // this fun is for to upload attachment and other detail to server
      async uploadAttachmentfiles(){
        const formData = new FormData();
        $_.uploadAttachment.map(data => {
          formData.append('file[]', data);
        });
        formData.append('job_application_id', $_.proposalData.job_application_id);
        var getData = await this.genericAPIPOSTRequest({
          requestUrl: "disputeFileupload",
          params: formData,
        });
        if (getData.length > 0) {
          getData.map(data => {
            if(data.name != undefined && data.name != ''){
              $_.disputedData.attachment.push(data.name);
            }else{
              $_.$toastr.e('some file was not uploaded');
            }
          })
          if($_.disputedData.reason == 'Other'){
            $_.disputedData.reason = $_.disputedData.otherReason;
          }
          delete $_.disputedData.otherReason;
          $_.disputedData.job_application_id    = $_.proposalData.job_application_id;
          $_.disputedData.created_by            = $_.$store.state.currentUserData.login_master_id;
          $_.disputedData.disputed_amount       = $_.proposalData.refund_requested_amount;
          // var response = await $_.genericAPIPOSTRequest({
          //     requestUrl  : "raiseDispute",
          //     params      : $_.disputedData,
          // });
          var response = await $_.getApiData("raiseDispute",$_.disputedData);
          if(response){
              $_.$toastr.s('Dispute Raised Successfully')
              $_.displayType = 'thankyou-form';
          }else{
              $_.$toastr.e('Raise Dispute UnSuccessfully')
          }
        }else{
          return false;
        }
      }
    }
  };
</script>