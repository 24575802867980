<template>
    <div class=" add-account-section ">
        <b-container> 
            <b-row class="mt-100" >
                <b-col xl="12" lg="12" sm="12">
                    <div class="mb-3">
                        <h2 class="mb-0 fw-500 text-black page-title"><b>Add a bank account</b></h2>
                        <h5 class="mb-0 fw-200 text-muted">Get access to your funds in 3-5 business days with no fees. <a href="javascript:void(0);" class="text-theme">See how bank transfers work</a></h5>
                        <span class="text-muted fz-14">Incorrect information can mean a delay in receiving funds or fees.</span>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col xl="12" lg="12" sm="12">    
                    <b-card-group deck v-if="activeDiv == 1">
                        <b-card title-class="text-theme font-14" class="py-2 px-2 mt-0 mb-4">
                            <!-- <b-form> -->
                                <b-row>
                                    <b-col xl="6" lg="6" sm="12">
                                    <b-form-group>
                                        <label for="name" class="fz-16">Account holder name</label>
                                        <b-form-input
                                            v-model = "usaBankDetails.name"
                                            type="text"
                                            placeholder="Enter account holder name"
                                            class="form-control"
                                            @input="$v.usaBankDetails.name.$touch()"
                                            @blur="$v.usaBankDetails.name.$touch()"
                                        ></b-form-input>
                                        <p class="text-danger" v-if="usaBankDetailsnameErrors[0]">{{usaBankDetailsnameErrors[0]}}</p>

                                    </b-form-group>
                                    </b-col>
                                    <b-col xl="6" lg="6" sm="12">
                                        <b-form-group>
                                            <label for="type" class="fz-16">Account type</label>
                                            <b-form-select 
                                            v-model="usaBankDetails.account_type"
                                            :options="bankAccountType" 
                                            value-field="description"
                                            text-field="description"
                                            class="form-control mt-0"></b-form-select>
                                        </b-form-group>
                                        <p class="text-danger" v-if="usaBankDetailsaccounttypeErrors[0]">{{usaBankDetailsaccounttypeErrors[0]}}</p>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col xl="12" lg="12" sm="12">
                                    <b-form-group>
                                        <label for="name" class="fz-16">Routing number</label>
                                        <b-form-input
                                            v-model="usaBankDetails.swiftnumber"
                                            type="text"
                                            placeholder="Enter routing number"
                                            class="form-control"
                                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                        ></b-form-input>
                                        <p class="text-danger" v-if="usaBankDetailsroutingErrors[0]">{{usaBankDetailsroutingErrors[0]}}</p>
                                    </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col xl="6" lg="6" sm="12">
                                        <b-form-group>
                                            <label for="account" class="fz-16">Account number</label>
                                            <b-form-input
                                            v-model="usaBankDetails.account"
                                            placeholder="Enter account number"
                                            class="form-control"
                                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                            ></b-form-input>
                                            <p class="text-danger" v-if="usaBankDetailsaccountErrors[0]">{{usaBankDetailsaccountErrors[0]}}</p>
                                        </b-form-group>
                                    </b-col>
                                    <b-col xl="6" lg="6" sm="12">
                                        <b-form-group>
                                            <label for="account" class="fz-16">Confirm account number</label>
                                            <b-form-input
                                            v-model="usaBankDetails.confirmaccount"
                                            placeholder="Confirm account number"
                                            class="form-control"
                                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                            ></b-form-input>
                                            <p class="text-danger" v-if="usaBankDetailsconfirmaccountErrors[0]">{{usaBankDetailsconfirmaccountErrors[0]}}</p>
                                            <p class="text-danger" v-if="usaBankDetails.confirmaccount > 0 && usaBankDetails.account != usaBankDetails.confirmaccount">{{'Confirm number mismatch'}}</p>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col xl="4" lg="4" sm="12">
                                        <img src="web/usa-bank.png" class="img-fluid">
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col xl="12" lg="12" sm="12">
                                        <span class="text-muted fz-18">By adding this bank account you are confirming that you are the owner & have full authorization to this bank account.</span>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col xl="6" lg="6" sm="12">
                                    <div class="d-flex align-items-center p-3 bg-grey">
                                        <!-- <img src="/web/light-bulb.png" class="img-fluid mr-2"> -->
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="img-fluid mr-2 bi bi-lightbulb" viewBox="0 0 16 16">
                                        <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z"/>
                                        </svg>
                                        <span>This payment method will become active in 3 days.  </span>
                                    </div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col xl="12" lg="12" sm="12">
                                    <div class="d-flex align-items-center">
                                        <a class="mr-2 text-theme" href="javascript:void(0);" @click="$router.push({name:'Settings',path:'/settings',query:{'current':'getPaid'}}).catch(()=>{})">Cancel</a>
                                        <b-button class="btn-theme" @click="(paymentScheduleData!='') ? submitusaBankDetailsData() : activeDiv = 2;">{{(paymentScheduleData!='')?'Save':'Next'}}</b-button>
                                    </div>
                                    </b-col>
                                </b-row>
                            <!-- </b-form> -->
                        </b-card>
                    </b-card-group>
                    <b-card header-tag="header" footer-tag="footer" header-class="px-4 py-4 bg-white" class="mb-3" v-else-if="activeDiv == 2">
                        <template #header>
                            <div>
                            <h4 class="mb-0 text-black fz-24 fw-500">
                                <b>Set up payment schedule</b>
                            </h4>
                            </div>
                        </template>
                        <b-row>
                            <b-col cols="12" class="px-4 py-4">
                            <section class="card-section">
                                <h5 class="fz-20 fw-600">Payment Method</h5>
                                <h5 class="fz-16 fw-600 mb-0">{{usaBankDetails.name}} account number is {{usaBankDetails.account}} (USD)</h5>
                                <p class="fz-14 fw-500 text-muted mb-4">Earnings will be sent here according to the following schedule</p>
                                
                                <h5 class="fz-16 fw-600 mb-0">Withdraw Fee</h5>
                                <p class="fz-14 fw-500 text-muted mb-4">$30.00 per payment</p>

                                <h5 class="fz-16 fw-600 mb-0">Preferred Payment Schedule</h5>
                                <p class="fz-14 fw-500 text-muted mb-4">Earnings will be released upon your request</p>

                                <b-form-radio
                                    v-model="bankDetailsSetting.preferred_payment_schedule"
                                    name="schedule"
                                    :value="schedule.value"
                                    class="d-flex align-items-center fz-14"
                                    v-for="(schedule, index) in paymentSchedule"
                                    :key="index"
                                    @input="$v.bankDetailsSetting.preferred_payment_schedule.$touch()"
                                    @blur="$v.bankDetailsSetting.preferred_payment_schedule.$touch()"
                                >
                                {{schedule.value}}</b-form-radio>
                                <p class="text-danger" v-if="bankDetailsscheduleErrors[0]">{{bankDetailsscheduleErrors[0]}}</p>



                                <b-form-group class="mb-4">
                                <label class="fz-16 fw-600 mb-0">Only when balance is</label><br>
                                <b-form-select
                                    v-model="bankDetailsSetting.minimum_balance_withdraw"
                                    :options="balanceWithdraw"
                                    value-field="value"
                                    text-field="value"
                                    @input="$v.bankDetailsSetting.minimum_balance_withdraw.$touch()"
                                    @blur="$v.bankDetailsSetting.minimum_balance_withdraw.$touch()"
                                ></b-form-select><br>
                                <small>Minimize withdrawal fees by choosing larger amounts.</small>
                                <p class="text-danger" v-if="bankDetailsminimumErrors[0]">{{bankDetailsminimumErrors[0]}}</p>

                                </b-form-group>

                                <b-form-group class="mb-4">
                                <label class="fz-16 fw-600 mb-0">Maintain A reserve balance</label><br>
                                <b-form-select
                                    v-model="bankDetailsSetting.maintain_balance"
                                    :options="maintainBalance"
                                    value-field="value"
                                    text-field="value"
                                    @input="$v.bankDetailsSetting.maintain_balance.$touch()"
                                    @blur="$v.bankDetailsSetting.maintain_balance.$touch()"
                                ></b-form-select>
                                <p class="text-danger" v-if="bankDetailsmaintainErrors[0]">{{bankDetailsmaintainErrors[0]}}</p>

                                </b-form-group>

                                <!-- <h5 class="fz-16 fw-600 mb-0">Next Payment <small>(based on your schedule)</small></h5>
                                <p class="fz-14 fw-500 text-muted mb-4">December 7, 2022</p> -->

                                <div class="d-md-flex align-items-center">
                                <!-- <b-button class="btn-outline-theme">Back</b-button> -->
                                <div class="ml-auto">
                                    <a href="javascript:void(0);" class="text-theme mr-4" @click="activeDiv = 1">Back</a>
                                    <b-button class="btn-theme" @click="submitusaBankDetailsData()">Save</b-button>
                                    
                                </div>
                                </div>
                            </section>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
   var that = ''
  import { validationMixin } from 'vuelidate'; 
  import { required,maxLength,alpha } from 'vuelidate/lib/validators'
  import { mapActions } from "vuex";
  export default {
    mixins: [validationMixin],
    data() {
      return { 
        activeDiv               : 1,
        paymentScheduleData     : '',
        accountId               : '',
        bankAccountType         : [],
        usaBankDetails                 : {
          name                      : '',
          account                   : '',
          confirmaccount            : '',
          account_type              : '',
          swiftnumber               : '',
        //   get_paid_setting_id       : '',
          payment_type              : 3,
          user_type                 : '',
        },
        bankDetailsSetting      : {
            preferred_payment_schedule  : '',
            minimum_balance_withdraw    : '',
            maintain_balance            : '',
        },
        paymentSchedule         : [
            {'value':'Quarterly'},
            {'value':'Monthly (last Wednesday of each month)'},
            {'value':'Twice per month (1st and 3rd Wednesday of each month)'},
            {'value':'Weekly (every Wednesday)'},
        ],
        balanceWithdraw         : [
            {'value':'$100.00 or more'},
            {'value':'$200.00 or more'},
            {'value':'$300.00 or more'},
            {'value':'$400.00 or more'},
            {'value':'$500.00 or more'},
            {'value':'$600.00 or more'},
            {'value':'$700.00 or more'},
            {'value':'$800.00 or more'},
            {'value':'$900.00 or more'},
            {'value':'$1000.00 or more'}
        ],
        maintainBalance         : [
            {'value':'$0.00'},
            {'value':'$5.00'},
            {'value':'$10.00'},
            {'value':'$15.00'},
        ],
      };
    },
    methods: {
        ...mapActions(["genericAPIPOSTRequest"]),
        // this fun is for to submit bank data
        async submitusaBankDetailsData(){
            that.$v.$touch();
            if(!that.$v.usaBankDetails.$invalid && that.usaBankDetails.account == that.usaBankDetails.confirmaccount){
                if(that.paymentScheduleData == ''){
                    if(!that.$v.bankDetailsSetting.$invalid){
                        that.bankDetails.preferred_payment_schedule     = that.bankDetailsSetting.preferred_payment_schedule;
                        that.bankDetails.minimum_balance_withdraw       = that.bankDetailsSetting.minimum_balance_withdraw;
                        that.bankDetails.maintain_balance               = that.bankDetailsSetting.maintain_balance;
                    }else{
                        return false;
                    }
                }
                that.usaBankDetails.user_type = (that.$store.state.accountType == 'agency') ? 1 : 3;
                delete that.usaBankDetails.confirmaccount;
                var response = await that.genericAPIPOSTRequest({
                    requestUrl  : "addUpdateGetpaid",
                    params      : that.usaBankDetails,
                });
                if(response){
                    that.$toastr.s("Usa bank Details Added");
                    that.$router.push({name:'Settings',path:'/settings',query:{'current':'getPaid'}}).catch(()=>{});
                }
            }else{
                console.log('ali')
            }
        },
        // that fun is for to get all type of account
        async getTypeOfAccount(){
            var res = await that.genericAPIPOSTRequest({
                requestUrl    : "getBankAccountType",
                params        : {
                "type"          :1,
                },
            });
            if(res){
                that.bankAccountType = res;
                that.getPaidSettings();
            }
        },
        // this fun is for to get paid setttings
        async getPaidSettings(){
            var res = await that.genericAPIPOSTRequest({
                requestUrl: "getPaidsettings",
                params: {},
            });  
            that.paymentScheduleData = res;
        },

        async getAccountdetails(_i){
        var res = await that.genericAPIPOSTRequest({
            requestUrl      : "getPaidList",
            params          : {
                'get_paid_id' : _i,
            },
        });
        if(res){
            let data = res[0];
            that.usaBankDetails.name               = data.name;
            that.usaBankDetails.account            = data.account;
            that.usaBankDetails.confirmaccount     = data.account;
            that.usaBankDetails.account_type       = data.account_type;
            that.usaBankDetails.swiftnumber        = data.swiftnumber;
            that.usaBankDetails.payment_type       = data.payment_type;
            that.usaBankDetails.user_type          = data.user_type;
            that.usaBankDetails.get_paid_id        = data.get_paid_id;
        }else{
            that.$router.push({name:'Settings',path:'/settings',query:{'current':'getPaid'}}).catch(()=>{});
        }
    },
    },
    validations: {
        usaBankDetails                     : {
            name                        : {
                required,
                alpha,
                maxLength: maxLength(20),
            },
            account                     : {required},
            confirmaccount              : {required},
            account_type                : {required},
            swiftnumber                 : {required},
            // get_paid_setting_id         : {required},
        },
        bankDetailsSetting          : {
            preferred_payment_schedule  : {required},
            minimum_balance_withdraw    : {required},
            maintain_balance            : {required},
        }
    },
    computed: { 
        usaBankDetailsnameErrors() {
            const errors = [];
            if (!that.$v.usaBankDetails.name.$dirty) return errors;
            !that.$v.usaBankDetails.name.required && errors.push("Account holder name is required");
            !that.$v.usaBankDetails.name.alpha && errors.push("Only alphabet allow");
            !that.$v.usaBankDetails.name.maxLength && errors.push("Account holder name max 20 character");
            return errors;
        },
        usaBankDetailsaccountErrors() {
            const errors = [];
            if (!that.$v.usaBankDetails.account.$dirty) return errors;
            !that.$v.usaBankDetails.account.required && errors.push("Account number is required");
            return errors;
        },
        usaBankDetailsconfirmaccountErrors() {
            const errors = [];
            if (!that.$v.usaBankDetails.confirmaccount.$dirty) return errors;
            !that.$v.usaBankDetails.confirmaccount.required && errors.push("Confirm Account is required");
            return errors;
        },
        usaBankDetailsaccounttypeErrors() {
            const errors = [];
            if (!that.$v.usaBankDetails.account_type.$dirty) return errors;
            !that.$v.usaBankDetails.account_type.required && errors.push("Account type is required");
            return errors;
        },
        usaBankDetailsroutingErrors() {
            const errors = [];
            if (!that.$v.usaBankDetails.swiftnumber.$dirty) return errors;
            !that.$v.usaBankDetails.swiftnumber.required && errors.push("Account routing is required");
            return errors;
        },
        bankDetailsscheduleErrors() {
            const errors = [];
            if (!that.$v.bankDetailsSetting.preferred_payment_schedule.$dirty) return errors;
            !that.$v.bankDetailsSetting.preferred_payment_schedule.required && errors.push("Payment Schedule is required");
            return errors;
        },
        bankDetailsminimumErrors() {
            const errors = [];
            if (!that.$v.bankDetailsSetting.minimum_balance_withdraw.$dirty) return errors;
            !that.$v.bankDetailsSetting.minimum_balance_withdraw.required && errors.push("Balance Withdraw is required");
            return errors;
        },
        bankDetailsmaintainErrors() {
            const errors = [];
            if (!that.$v.bankDetailsSetting.maintain_balance.$dirty) return errors;
            !that.$v.bankDetailsSetting.maintain_balance.required && errors.push("Maintain Balance is required");
            return errors;
        },
    }, 
    created(){
        that = this;
    },
    mounted() {
        if(that.$route.params.aid!= undefined && that.$route.params.aid!=''){
            that.accountId      = that.decodeId(that.$route.params.aid);
            that.getAccountdetails(that.accountId);
        }
        that.getTypeOfAccount();
    },
  };
</script>