<template>
  <div class="h-auto py-12 pb-24 mt-7 xl:mt-24 xl:px-0" style="background: rgba(242, 97, 60, 0.07)">
    <div class="relative w-full px-4 mx-auto mb-40 max-w-7xl xl:px-0">
      <p class="pb-0 text-sm text-center uppercase fw-400 text-primary-500" style="padding-bottom: 0">
        Testimonial
      </p>
      <h2 style="font-weight: 700"
        class="flex justify-center text-3xl text-center text-black xl:text-6xl xl:text-left fw-bold">
        What our customers are Saying
      </h2>
      <p class="mt-4 text-lg text-center text-black font-200">
        Qapin a premium marketplace for source inspectors
        <!-- Qapin a premium marketplace for <a href="javascript:void(0)" @click="$router.push({name:'PrivacyPolicy',path:'/privacy-policy'}).catch(()=>{})">source inspectors</a> -->
      </p>

      <div class="w-full mt-16 slick-slider-wrapper">
        <div class="slick-slider">
          <div ref="slick" class="slider">
            <div class="items mt-4" v-for="(testi, index) in testimonial" :key="index">
              <h6 class="text-lg font-semibold">{{ testi.rating }}</h6>
              <div class="flex mt-1 space-x-2">
                <img :class="(testi.rating >=1 )?'':'filter-rating'" src="/web/rating.png" alt="" />
                <img :class="(testi.rating >=2 )?'':'filter-rating'" src="/web/rating.png" alt="" />
                <img :class="(testi.rating >=3 )?'':'filter-rating'" src="/web/rating.png" alt="" />
                <img :class="(testi.rating >=4 )?'':'filter-rating'" src="/web/rating.png" alt="" />
                <img :class="(testi.rating ==5 )?'':'filter-rating'" src="/web/rating.png" alt="" />
              </div>
              <p class="pt-4 text-sm italic xl:text-2xl sm:text-sm md:text-base lg:text-xl fw-400 xl:pt-6 md:pt-4">
                {{ testi.review }}
              </p>

              <div class="flex space-x-4 pr-6mt-0 xl:mt-4">
                <div class="w-10 h-10 p-2 rounded-md md:h-12 md:p-4 md:w-12 testimonail"
                  :style="{ backgroundImage: 'url(' + testi.image + ')' }"></div>
                <div class="">
                  <h4 style="font-weight: 700"
                    class="text-sm text-left text-black cursor-pointer hover:text-primary-500 fw-600 xl:text-lg">
                    {{ testi.name }}
                  </h4>
                  <p class="w-24 w-sm-100 text-xs text-left text-gray-400 uppercase fw-200 xl:w-auto md:text-sm md:mt-2">
                    {{ testi.title }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="inset-0 xl:px-0">
      <div
        class="left-0 right-0 w-full mx-auto mb-20 join-wrapper -mt-24 bg-black bg-center shadow-xl xl:px-10 rounded-2xl max-w-7xl xl:h-auto">
        <div class="relative footer-vectors">
          <img src="/web/testimonial-footer2.png" class="absolute w-20 xl:w-auto -top-5 left-4" alt="" />
          >
          <img src="/web/testimonial-footer1.png" class="absolute w-20 top-40 xl:top-28 xl:w-auto right-4" alt="" />
        </div>

        <div class="py-6 text-center xl:py-12">
          <p class="text-sm uppercase fw-400 text-primary-500">JOIN QAPIN </p>

          <h4 class="mt-2 text-xl text-white fw-bold md:text-5xl">
            FIND AN TALENT FOR ANYTHING
          </h4>

          <p class="max-w-sm mx-auto mt-4 text-sm text-white xl:text-lg fw-200 xl:w-auto">
            Work with curated talent from all over the world, Pay securely with confidence
          </p>

          <div class="px-10 mt-6 xl:px-0">
            <button @click="$router.push('/login').catch(()=>{})"
              class="w-40 px-4 py-2 text-base text-white transition-all duration-500 ease-in-out rounded-md shadow-sm cursor-pointer md:w-52 hover:bg-opacity-50 hover:text-black fw-200 xl:w-auto bg-primary-500 hover:shadow-lg">
              Join for Free
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel';
import axios from "axios";
export default {
  components: {
  },

  data() {
    return {
      testimonial: [],
    };
  },
  mounted() {
    this.gettestimonial();
  },
  methods: {
    async gettestimonial() {
      await axios.get(this.$store.state.baseUrl + 'getTestimonials')
        .then(response => {
          if (response.data.status) {
            this.testimonial = response.data.result;

            this.$nextTick(() => {
              this.slickInstance = $(this.$refs.slick).slick({
                // Slick options go here
                // For example:
                nav: false,
                loop: true,
                autoplay: true,
                slidesToShow: 2,
                responsive: [
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 2,
                    },
                  },
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 1,
                    },
                  },
                ],
              });
            });

          }
        })
    }

  }
};
</script>

<style scoped>
.testimonial-footer {
  background-image: url("/web/testimonials-footer.png");
  width: 1300px;
  height: 450.87px;
  overflow: hidden;
  position: absolute;
  left: 0;
}

.user-feedback {
  font-style: italic;
  font-weight: 400;
  /* line-height: 26px; */
}

.footer-vectors {
  display: block;
}

@media only screen and (max-width: 800px) {
  .testimonial-footer {
    position: absolute;
  }
}

@media only screen and (min-width: 2000px) {
  .testimonial-footer {
    width: 1300px;
    left: 0;
    position: absolute;
  }
}

@media only screen and (min-width: 1600px) {
  .testimonial-footer {
    width: 1300px;
    left: 0;
    position: absolute;
  }
}

.text-sm {
  font-size: 19px;
  font-weight: 600 !important;
}

.text-lg {
  font-size: 20px;
}

@media only screen and (max-width: 2400px) {
  .testimonial-footer {
    width: 1300px;
    left: 20%;
    position: absolute;
  }
}

@media only screen and (max-width: 390px) {
  .footer-vectors {
    display: none;
  }
}

@media (max-width: 480px) {

  .mt-24 {
    margin-top: 0px;
  }

  .pb-24 {
    padding-bottom: 0px;
  }



  .css-ajm3pi {
    max-width: 40rem;
    padding-top: 0px;
    margin: 0px 0px 24px;
    letter-spacing: 0.00735em;
    color: rgb(16, 16, 16);
    font-family: Silka, Helvetica, sans-serif;
    line-height: 43px !important;
    font-weight: 700 !important;
    max-width: 600px !important;
    font-size: 25px !important;
  }

  .css-1wfjlp {
    margin-top: 0px;
    letter-spacing: 0.00938em;
    color: rgb(16, 16, 16);
    font-family: Silka, Helvetica, sans-serif;
    line-height: 120%;
    font-weight: 700 !important;
    font-size: 28px !important;
  }
}
</style>
