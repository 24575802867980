import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const settings = {
  namespaced: true,

  state: () => ({
    currentUser: null,
    // headToken:'',
    selectedCategories: [],
    qpRoleList: [],
  }),

  getters: {
    getUserDetails: (state) => state.currentUser,
    getSelectedCategories: (state) => state.selectedCategories,
    getqpRoleList: (state) => state.qpRoleList,
  },

  mutations: {
    GET_USER_DETAILS(state, data) {
      state.currentUser = data;
    },
    SET_QPROLE(state, data) {
      state.qpRoleList = data;
    },
    UPDATE_PROFILE(state, data) {
      state.currentUser = data;
    },
    SET_SELECTED_CATEGORIES(state, data) {
      state.selectedCategories = data;
    },
  },

  actions: {
    enCodeId(payload ) {
      
      return new Promise((resolve) => {
         var id = btoa(payload);
         resolve(id);
      });
    },
    fetchUserDetails({ commit }, {params}) {
      this.headToken = { Authorization: 'Bearer '+this.state.currentUserData.token, TimeZone : this.state.userTimeZone };
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.state.baseUrl}/getUserParentDetails`, params , { "headers": this.headToken })
          .then((response) => {
            commit("GET_USER_DETAILS", response.data.result);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // this fun is for to get qp role master
    fetchQpRole({ commit }) {
      this.headToken = { Authorization: 'Bearer '+this.state.currentUserData.token, TimeZone : this.state.userTimeZone };
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.state.baseUrl}/common/getQpRoleMasterList`, { "headers": this.headToken })
          .then((response) => {
            commit("SET_QPROLE", response.data.result);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchSelectedCategories({ commit },{params}) {
      this.headToken = { Authorization: 'Bearer '+this.state.currentUserData.token, TimeZone : this.state.userTimeZone };
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.state.baseUrl}user/getCommodityExperience`,params, { headers: this.headToken })
          .then((response) => {
            commit("SET_SELECTED_CATEGORIES", response.data.result);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateProfile({ commit }, payload) {
      this.headToken = { Authorization: 'Bearer '+this.state.currentUserData.token, TimeZone : this.state.userTimeZone };
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.state.baseUrl}user/updateParentUserProfile`, payload, {
            headers: this.headToken,
          })
          .then((response) => {
            commit("UPDATE_PROFILE", response.data.result);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateUserConfig({ commit }, payload) {
      this.headToken = { Authorization: 'Bearer '+this.state.currentUserData.token, TimeZone : this.state.userTimeZone };
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.state.baseUrl}setting/updateUserConfig`, payload, {
            headers: this.headToken,
          })
          .then((response) => {
            commit("UPDATE_PROFILE", response.data.result);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    UpdateSocialLink({ commit }, payload) {
      this.headToken = { Authorization: 'Bearer '+this.state.currentUserData.token, TimeZone : this.state.userTimeZone };
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.state.baseUrl}user/UpdateSocialLink`, payload, {
            headers: this.headToken,
          })
          .then((response) => {
            commit("UPDATE_PROFILE", response.data.result);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateCategory({ commit }, payload) {
      this.headToken = { Authorization: 'Bearer '+this.state.currentUserData.token, TimeZone : this.state.userTimeZone };

      return new Promise((resolve, reject) => {
        axios
          .post(`${this.state.baseUrl}updateCategory`, payload, {
            headers: this.headToken,
          })
          .then((response) => {
            commit("UPDATE_PROFILE", response.data.result);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
export default settings;
