  <template>
    <b-container>
      <b-row class="mt-100 client-dashboard-wrap">
        <b-col xl="12" lg="12" sm="12">
          <div class="d-flex align-items-center w-100 mb-4">
            <div>
              <h4 class="fz-24 fw-600 text-black">Dashboard</h4>
            </div>
            <div class="ml-auto d-flex align-items-center">
              <b-button @click="$router.push({name:'post-job',path:'/client/post-job/',params:{pid:'POID'}}).catch(()=>{})" v-if="isPermissionForClient('post-job')" class="btn btn-theme">Post a Job</b-button>
            </div>
          </div>
          <b-card no-body v-if="DraftJobList.length == 0 && PostJobList.length == 0">
            <div class="view-wrapper mb-4">
              <div>
                <div class="header px-4 py-4">
                  <div class="d-flex align-items-center w-100">
                    <div>
                      <h4 class="fz-24 fw-600 text-black mb-0">Your Posting</h4>
                    </div>
                  </div>
                </div>
                <div class="text-center py-8 px-4" >
                  <svg width="172" height="173" viewBox="0 0 172 173" fill="none" xmlns="http://www.w3.org/2000/svg" class="m-auto">
                    <path d="M75.4142 9.1442C81.305 5.05891 89.1128 5.05891 95.0036 9.1442V9.1442C98.7906 11.7705 103.474 12.7659 108.002 11.907V11.907C115.045 10.5709 122.178 13.7466 125.897 19.8747V19.8747C128.289 23.8143 132.162 26.6285 136.648 27.6855V27.6855C143.626 29.3296 148.85 35.1319 149.756 42.2433V42.2433C150.338 46.8149 152.732 50.9612 156.4 53.7514V53.7514C162.106 58.0914 164.519 65.5171 162.454 72.382V72.382C161.126 76.7953 161.626 81.5568 163.843 85.5976V85.5976C167.29 91.8832 166.474 99.6482 161.795 105.08V105.08C158.787 108.571 157.308 113.125 157.689 117.718V117.718C158.281 124.862 154.377 131.624 147.894 134.683V134.683C143.726 136.649 140.522 140.207 139.002 144.558V144.558C136.638 151.326 130.321 155.915 123.154 156.072V156.072C118.547 156.174 114.173 158.121 111.014 161.477V161.477C106.102 166.698 98.4646 168.321 91.8531 165.55V165.55C87.6028 163.768 82.815 163.768 78.5647 165.55V165.55C71.9532 168.321 64.3161 166.698 59.4034 161.477V161.477C56.2451 158.121 51.8713 156.174 47.2638 156.072V156.072C40.0967 155.915 33.7801 151.326 31.4156 144.558V144.558C29.8955 140.207 26.6919 136.649 22.5239 134.683V134.683C16.0405 131.624 12.1367 124.862 12.7292 117.718V117.718C13.1101 113.125 11.6307 108.571 8.62286 105.08V105.08C3.94419 99.6482 3.12806 91.8832 6.57521 85.5976V85.5976C8.79129 81.5568 9.29175 76.7953 7.96423 72.382V72.382C5.89925 65.5171 8.31199 58.0914 14.0177 53.7514V53.7514C17.6857 50.9612 20.0796 46.8149 20.6619 42.2433V42.2433C21.5677 35.1319 26.7921 29.3296 33.7698 27.6855V27.6855C38.2555 26.6285 42.1289 23.8143 44.5203 19.8747V19.8747C48.2402 13.7466 55.373 10.5709 62.4161 11.907V11.907C66.944 12.7659 71.6271 11.7705 75.4142 9.1442V9.1442Z" fill="#F2613C"/>
                    <path d="M151.782 22.2932C152.296 22.3179 152.821 22.1463 153.232 21.7722L170.866 5.75467C171.689 5.0068 171.751 3.73304 171.003 2.90997C170.255 2.08663 168.981 2.02545 168.158 2.77334L150.524 18.7909C149.701 19.5387 149.64 20.8125 150.388 21.6356C150.761 22.0471 151.267 22.2684 151.782 22.2932Z" fill="#F2613C"/>
                    <path d="M170.199 30.5101C169.958 29.4243 168.883 28.7393 167.796 28.9804L159.273 30.8714C158.187 31.1123 157.502 32.1878 157.743 33.2737C157.945 34.1799 158.727 34.807 159.612 34.8495C159.787 34.8579 159.966 34.8434 160.146 34.8034L168.669 32.9124C169.755 32.6717 170.44 31.596 170.199 30.5101V30.5101Z" fill="#F2613C"/>
                    <path d="M139.33 13.1927C139.505 13.2498 139.682 13.2814 139.857 13.2898C140.742 13.3323 141.581 12.783 141.868 11.9003L144.567 3.59766C144.911 2.53991 144.332 1.40345 143.275 1.05988C142.217 0.715239 141.081 1.29458 140.737 2.35232L138.038 10.6549C137.693 11.7127 138.272 12.8489 139.33 13.1927V13.1927Z" fill="#F2613C"/>
                    <g clip-path="url(#clip0_3226_22395)">
                    <path d="M124.138 60.6055C122.008 60.6055 120.275 62.3384 120.275 64.4685C120.275 66.5986 122.008 68.3315 124.138 68.3315C126.268 68.3315 128.001 66.5986 128.001 64.4685C128.001 62.3384 126.268 60.6055 124.138 60.6055ZM124.138 65.8657C123.368 65.8657 122.741 65.2389 122.741 64.4685C122.741 63.698 123.368 63.0712 124.138 63.0712C124.909 63.0712 125.536 63.698 125.536 64.4685C125.536 65.2389 124.909 65.8657 124.138 65.8657Z" fill="white"/>
                    <path d="M47.865 105.318C45.7349 105.318 44.002 107.051 44.002 109.181C44.002 111.311 45.7349 113.044 47.865 113.044C49.995 113.044 51.728 111.311 51.728 109.181C51.728 107.051 49.995 105.318 47.865 105.318ZM47.865 110.579C47.0945 110.579 46.4677 109.952 46.4677 109.181C46.4677 108.411 47.0945 107.784 47.865 107.784C48.6354 107.784 49.2622 108.411 49.2622 109.181C49.2622 109.952 48.6354 110.579 47.865 110.579Z" fill="white"/>
                    <path d="M112.386 59.2088H114.852V56.4964H117.564V54.0307H114.852V51.3184H112.386V54.0307H109.674V56.4964H112.386V59.2088Z" fill="white"/>
                    <path d="M63.5628 110.496H61.0971V113.208H58.3848V115.674H61.0971V118.387H63.5628V115.674H66.2752V113.208H63.5628V110.496Z" fill="white"/>
                    <path d="M112.221 61.9217H77.4565L72.1963 54.0312H51.8926V103.839H70.2213V101.374H54.7045L61.9785 72.2778H117.3L110.026 101.374H105.728V103.839H111.951L120.458 69.8121H112.221V61.9217ZM70.8766 56.497L74.493 61.9217H54.3583V56.497H70.8766ZM60.0534 69.8121L54.3583 92.5917V64.3874H109.756V69.8121H60.0534Z" fill="white"/>
                    <path d="M86.0015 80.332C79.5206 80.332 74.248 85.6046 74.248 92.0854C74.248 98.5663 79.5206 103.839 86.0015 103.839C92.4823 103.839 97.7549 98.5663 97.7549 92.0854C97.7549 85.6046 92.4823 80.332 86.0015 80.332ZM86.0015 101.373C80.8803 101.373 76.7138 97.2066 76.7138 92.0854C76.7138 86.9642 80.8803 82.7978 86.0015 82.7978C91.1227 82.7978 95.2891 86.9642 95.2891 92.0854C95.2891 97.2066 91.1227 101.373 86.0015 101.373Z" fill="white"/>
                    <path d="M103.016 92.086C103.016 82.7046 95.3833 75.0723 86.002 75.0723C76.6206 75.0723 68.9883 82.7044 68.9883 92.086C68.9883 101.467 76.6206 109.1 86.002 109.1C89.2379 109.1 92.2656 108.191 94.8438 106.617L109.673 121.446L115.362 115.757L100.532 100.928C102.107 98.3495 103.016 95.3219 103.016 92.086ZM71.454 92.086C71.454 84.0642 77.9802 77.538 86.002 77.538C94.0237 77.538 100.55 84.0642 100.55 92.086C100.55 100.108 94.0237 106.634 86.002 106.634C77.9802 106.634 71.454 100.108 71.454 92.086ZM109.673 117.959L100.896 109.182L103.098 106.98L111.875 115.757L109.673 117.959ZM101.354 105.237L99.1527 107.438L96.8755 105.161C97.6744 104.495 98.4115 103.758 99.077 102.959L101.354 105.237Z" fill="white"/>
                    <path d="M86.1827 85.596C85.1157 85.5472 84.1044 85.9251 83.3339 86.661C82.5745 87.3865 82.1387 88.4048 82.1387 89.4549H84.6044C84.6044 89.0694 84.7581 88.7106 85.0369 88.4443C85.3154 88.1783 85.6836 88.0405 86.0692 88.0593C86.7694 88.0915 87.3651 88.6872 87.3973 89.3873C87.4192 89.8618 87.2028 90.3108 86.8187 90.5887C85.5352 91.5168 84.7688 93.0595 84.7688 94.7152H87.2346C87.2346 93.8484 87.6192 93.0526 88.2636 92.5867C89.3237 91.8201 89.9208 90.5818 89.8606 89.2741C89.7699 87.3023 88.1543 85.6866 86.1827 85.596V85.596Z" fill="white"/>
                    <path d="M84.7695 96.0312H87.2353V98.6614H84.7695V96.0312Z" fill="white"/>
                    <path d="M57.0703 57.9766H59.7004V60.4423H57.0703V57.9766Z" fill="white"/>
                    <path d="M62.3301 57.9766H64.9602V60.4423H62.3301V57.9766Z" fill="white"/>
                    <path d="M67.5918 57.9766H70.2219V60.4423H67.5918V57.9766Z" fill="white"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_3226_22395">
                    <rect width="84" height="84" fill="white" transform="translate(44.002 44.3828)"/>
                    </clipPath>
                    </defs>
                  </svg>
                  <h4 class="fz-20 fw-600 text-black">No Active Job Posts</h4>
                  <p class="fz-16 fw-500 text-muted">Post a job to the marketplace and let talent come to you.</p>
                  <b-button class="btn btn-theme mt-3" @click="$router.push({name:'post-job',path:'/client/post-job/',params:{pid:'POID'}}).catch(()=>{})" v-if="isPermissionForClient('post-job')">Post a Job</b-button>
                </div>
              </div>
            </div>
          </b-card>
          <div v-else-if="PostJobList.length > 0 || DraftJobList.length > 0">
            <b-card no-body>
              <div class="view-wrapper mb-4">
                <div>
                  <div class="header px-4 py-4">
                    <div class="d-flex align-items-center w-100">
                      <div>
                        <h4 class="fz-24 fw-600 text-black mb-0">Your Posting</h4>
                      </div>
                      <div class="ml-auto d-flex align-items-center">
                        <b-button @click="$router.push({name:'jobPosting',path:'/client/job-posting',params:{pid:'post'}}).catch(()=>{})"  class="btn btn-theme">See All Posting</b-button>
                      </div>
                    </div>
                  </div>
                  <div class="text-center py-8 px-4" v-if="PostJobList.length == 0">
                    <svg width="172" height="173" viewBox="0 0 172 173" fill="none" xmlns="http://www.w3.org/2000/svg" class="m-auto">
                      <path d="M75.4142 9.1442C81.305 5.05891 89.1128 5.05891 95.0036 9.1442V9.1442C98.7906 11.7705 103.474 12.7659 108.002 11.907V11.907C115.045 10.5709 122.178 13.7466 125.897 19.8747V19.8747C128.289 23.8143 132.162 26.6285 136.648 27.6855V27.6855C143.626 29.3296 148.85 35.1319 149.756 42.2433V42.2433C150.338 46.8149 152.732 50.9612 156.4 53.7514V53.7514C162.106 58.0914 164.519 65.5171 162.454 72.382V72.382C161.126 76.7953 161.626 81.5568 163.843 85.5976V85.5976C167.29 91.8832 166.474 99.6482 161.795 105.08V105.08C158.787 108.571 157.308 113.125 157.689 117.718V117.718C158.281 124.862 154.377 131.624 147.894 134.683V134.683C143.726 136.649 140.522 140.207 139.002 144.558V144.558C136.638 151.326 130.321 155.915 123.154 156.072V156.072C118.547 156.174 114.173 158.121 111.014 161.477V161.477C106.102 166.698 98.4646 168.321 91.8531 165.55V165.55C87.6028 163.768 82.815 163.768 78.5647 165.55V165.55C71.9532 168.321 64.3161 166.698 59.4034 161.477V161.477C56.2451 158.121 51.8713 156.174 47.2638 156.072V156.072C40.0967 155.915 33.7801 151.326 31.4156 144.558V144.558C29.8955 140.207 26.6919 136.649 22.5239 134.683V134.683C16.0405 131.624 12.1367 124.862 12.7292 117.718V117.718C13.1101 113.125 11.6307 108.571 8.62286 105.08V105.08C3.94419 99.6482 3.12806 91.8832 6.57521 85.5976V85.5976C8.79129 81.5568 9.29175 76.7953 7.96423 72.382V72.382C5.89925 65.5171 8.31199 58.0914 14.0177 53.7514V53.7514C17.6857 50.9612 20.0796 46.8149 20.6619 42.2433V42.2433C21.5677 35.1319 26.7921 29.3296 33.7698 27.6855V27.6855C38.2555 26.6285 42.1289 23.8143 44.5203 19.8747V19.8747C48.2402 13.7466 55.373 10.5709 62.4161 11.907V11.907C66.944 12.7659 71.6271 11.7705 75.4142 9.1442V9.1442Z" fill="#F2613C"/>
                      <path d="M151.782 22.2932C152.296 22.3179 152.821 22.1463 153.232 21.7722L170.866 5.75467C171.689 5.0068 171.751 3.73304 171.003 2.90997C170.255 2.08663 168.981 2.02545 168.158 2.77334L150.524 18.7909C149.701 19.5387 149.64 20.8125 150.388 21.6356C150.761 22.0471 151.267 22.2684 151.782 22.2932Z" fill="#F2613C"/>
                      <path d="M170.199 30.5101C169.958 29.4243 168.883 28.7393 167.796 28.9804L159.273 30.8714C158.187 31.1123 157.502 32.1878 157.743 33.2737C157.945 34.1799 158.727 34.807 159.612 34.8495C159.787 34.8579 159.966 34.8434 160.146 34.8034L168.669 32.9124C169.755 32.6717 170.44 31.596 170.199 30.5101V30.5101Z" fill="#F2613C"/>
                      <path d="M139.33 13.1927C139.505 13.2498 139.682 13.2814 139.857 13.2898C140.742 13.3323 141.581 12.783 141.868 11.9003L144.567 3.59766C144.911 2.53991 144.332 1.40345 143.275 1.05988C142.217 0.715239 141.081 1.29458 140.737 2.35232L138.038 10.6549C137.693 11.7127 138.272 12.8489 139.33 13.1927V13.1927Z" fill="#F2613C"/>
                      <g clip-path="url(#clip0_3226_22395)">
                      <path d="M124.138 60.6055C122.008 60.6055 120.275 62.3384 120.275 64.4685C120.275 66.5986 122.008 68.3315 124.138 68.3315C126.268 68.3315 128.001 66.5986 128.001 64.4685C128.001 62.3384 126.268 60.6055 124.138 60.6055ZM124.138 65.8657C123.368 65.8657 122.741 65.2389 122.741 64.4685C122.741 63.698 123.368 63.0712 124.138 63.0712C124.909 63.0712 125.536 63.698 125.536 64.4685C125.536 65.2389 124.909 65.8657 124.138 65.8657Z" fill="white"/>
                      <path d="M47.865 105.318C45.7349 105.318 44.002 107.051 44.002 109.181C44.002 111.311 45.7349 113.044 47.865 113.044C49.995 113.044 51.728 111.311 51.728 109.181C51.728 107.051 49.995 105.318 47.865 105.318ZM47.865 110.579C47.0945 110.579 46.4677 109.952 46.4677 109.181C46.4677 108.411 47.0945 107.784 47.865 107.784C48.6354 107.784 49.2622 108.411 49.2622 109.181C49.2622 109.952 48.6354 110.579 47.865 110.579Z" fill="white"/>
                      <path d="M112.386 59.2088H114.852V56.4964H117.564V54.0307H114.852V51.3184H112.386V54.0307H109.674V56.4964H112.386V59.2088Z" fill="white"/>
                      <path d="M63.5628 110.496H61.0971V113.208H58.3848V115.674H61.0971V118.387H63.5628V115.674H66.2752V113.208H63.5628V110.496Z" fill="white"/>
                      <path d="M112.221 61.9217H77.4565L72.1963 54.0312H51.8926V103.839H70.2213V101.374H54.7045L61.9785 72.2778H117.3L110.026 101.374H105.728V103.839H111.951L120.458 69.8121H112.221V61.9217ZM70.8766 56.497L74.493 61.9217H54.3583V56.497H70.8766ZM60.0534 69.8121L54.3583 92.5917V64.3874H109.756V69.8121H60.0534Z" fill="white"/>
                      <path d="M86.0015 80.332C79.5206 80.332 74.248 85.6046 74.248 92.0854C74.248 98.5663 79.5206 103.839 86.0015 103.839C92.4823 103.839 97.7549 98.5663 97.7549 92.0854C97.7549 85.6046 92.4823 80.332 86.0015 80.332ZM86.0015 101.373C80.8803 101.373 76.7138 97.2066 76.7138 92.0854C76.7138 86.9642 80.8803 82.7978 86.0015 82.7978C91.1227 82.7978 95.2891 86.9642 95.2891 92.0854C95.2891 97.2066 91.1227 101.373 86.0015 101.373Z" fill="white"/>
                      <path d="M103.016 92.086C103.016 82.7046 95.3833 75.0723 86.002 75.0723C76.6206 75.0723 68.9883 82.7044 68.9883 92.086C68.9883 101.467 76.6206 109.1 86.002 109.1C89.2379 109.1 92.2656 108.191 94.8438 106.617L109.673 121.446L115.362 115.757L100.532 100.928C102.107 98.3495 103.016 95.3219 103.016 92.086ZM71.454 92.086C71.454 84.0642 77.9802 77.538 86.002 77.538C94.0237 77.538 100.55 84.0642 100.55 92.086C100.55 100.108 94.0237 106.634 86.002 106.634C77.9802 106.634 71.454 100.108 71.454 92.086ZM109.673 117.959L100.896 109.182L103.098 106.98L111.875 115.757L109.673 117.959ZM101.354 105.237L99.1527 107.438L96.8755 105.161C97.6744 104.495 98.4115 103.758 99.077 102.959L101.354 105.237Z" fill="white"/>
                      <path d="M86.1827 85.596C85.1157 85.5472 84.1044 85.9251 83.3339 86.661C82.5745 87.3865 82.1387 88.4048 82.1387 89.4549H84.6044C84.6044 89.0694 84.7581 88.7106 85.0369 88.4443C85.3154 88.1783 85.6836 88.0405 86.0692 88.0593C86.7694 88.0915 87.3651 88.6872 87.3973 89.3873C87.4192 89.8618 87.2028 90.3108 86.8187 90.5887C85.5352 91.5168 84.7688 93.0595 84.7688 94.7152H87.2346C87.2346 93.8484 87.6192 93.0526 88.2636 92.5867C89.3237 91.8201 89.9208 90.5818 89.8606 89.2741C89.7699 87.3023 88.1543 85.6866 86.1827 85.596V85.596Z" fill="white"/>
                      <path d="M84.7695 96.0312H87.2353V98.6614H84.7695V96.0312Z" fill="white"/>
                      <path d="M57.0703 57.9766H59.7004V60.4423H57.0703V57.9766Z" fill="white"/>
                      <path d="M62.3301 57.9766H64.9602V60.4423H62.3301V57.9766Z" fill="white"/>
                      <path d="M67.5918 57.9766H70.2219V60.4423H67.5918V57.9766Z" fill="white"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_3226_22395">
                      <rect width="84" height="84" fill="white" transform="translate(44.002 44.3828)"/>
                      </clipPath>
                      </defs>
                    </svg>
                    <h4 class="fz-20 fw-600 text-black">No Active Job Posts</h4>
                    <p class="fz-16 fw-500 text-muted">Post a job to the marketplace and let talent come to you.</p>
                    <b-button class="btn btn-theme mt-3" @click="$router.push({name:'post-job',path:'/client/post-job/',params:{pid:'POID'}}).catch(()=>{})" v-if="isPermissionForClient('post-job')">Post a Job</b-button>
                  </div>
                  <div v-if="PostJobList.length > 0">
                    <div class="posted-job-wrapper border-bottom px-4 py-4" v-for="(postjobs, i) of PostJobList" :key="i" >
                      <div class="d-flex align-items-center mb-3">
                        <h4 class="mb-0 fz-18 fw-500 text-black">
                          <a href="javascript:void(0);" class="text-black" @click="$router.push({name: 'index',path:'/client/jobpostreview/',params: {pid: postjobs.uniq_id}}).catch(()=>{})"><b>{{postjobs.job_title}}</b></a>
                          <br><span>{{ postjobs.first_name }} {{ postjobs.last_name }}</span>
                        </h4>
                        <div class="ml-auto">
                          <b-dropdown size="lg" right  variant="link" toggle-class="text-decoration-none" no-caret>
                            <template #button-content>
                              <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M4 2C4 3.10457 3.10457 4 2 4C0.895431 4 0 3.10457 0 2C0 0.895431 0.895431 0 2 0C3.10457 0 4 0.895431 4 2ZM11 2C11 3.10457 10.1046 4 9 4C7.89543 4 7 3.10457 7 2C7 0.895431 7.89543 0 9 0C10.1046 0 11 0.895431 11 2ZM16 4C17.1046 4 18 3.10457 18 2C18 0.895431 17.1046 0 16 0C14.8954 0 14 0.895431 14 2C14 3.10457 14.8954 4 16 4Z" fill="#666666"/>
                              </svg>
                            </template>
                            <b-dropdown-item href="javascript:void(0);" @click="$router.push({name: 'index',path:'/client/jobpostreview/',params: {pid: postjobs.uniq_id},query:{current:2}}).catch(()=>{})">
                              <div class="d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye mr-2" viewBox="0 0 16 16">
                                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                </svg>
                                Review Proposals
                              </div>
                            </b-dropdown-item>
                            <b-dropdown-item href="javascript:void(0);" v-if="postjobs.is_delete == 0" @click="makePrivatepublic(i,postjobs.preferences_job_type,postjobs.jobs_id)">
                              <div class="d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock mr-2" viewBox="0 0 16 16" v-if="postjobs.preferences_job_type == 'Private'">
                                  <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"  fill="currentColor" class="bi bi-unlock mr-2" viewBox="0 0 16 16" v-else>
                                  <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2zM3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H3z"/>
                                </svg>
                                Make {{(postjobs.preferences_job_type != 'Private') ?'Private' :'Public'}}
                              </div>
                            </b-dropdown-item>
                            <b-dropdown-item href="javascript:void(0);" @click="$router.push({name: 'index',path:'/client/jobpostreview/',params: {pid: postjobs.uniq_id}}).catch(()=>{})">
                              <div class="d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-post mr-2" viewBox="0 0 16 16">
                                  <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
                                  <path d="M4 6.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-7zm0-3a.5.5 0 0 1 .5-.5H7a.5.5 0 0 1 0 1H4.5a.5.5 0 0 1-.5-.5z"/>
                                </svg>
                                View Job Posting
                              </div>
                            </b-dropdown-item>
                            <b-dropdown-item href="javascript:void(0);" v-if="postjobs.is_delete == 0" @click="$router.push({ name: 'postReview', path:'/client/post-review', params: {pid: postjobs.uniq_id} }).catch(()=>{})">
                              <div class="d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square mr-2" viewBox="0 0 16 16">
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                  <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                </svg>
                                Edit Posting
                              </div>
                            </b-dropdown-item>
                            <b-dropdown-item href="javascript:void(0);"  @click="$router.push({ name:'post-job',path:'/client/post-job', params:{ pid:postjobs.uniq_id}}).catch(()=>{})">
                              <div class="d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-stickies mr-2" viewBox="0 0 16 16">
                                  <path d="M1.5 0A1.5 1.5 0 0 0 0 1.5V13a1 1 0 0 0 1 1V1.5a.5.5 0 0 1 .5-.5H14a1 1 0 0 0-1-1H1.5z"/>
                                  <path d="M3.5 2A1.5 1.5 0 0 0 2 3.5v11A1.5 1.5 0 0 0 3.5 16h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 16 9.586V3.5A1.5 1.5 0 0 0 14.5 2h-11zM3 3.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5V9h-4.5A1.5 1.5 0 0 0 9 10.5V15H3.5a.5.5 0 0 1-.5-.5v-11zm7 11.293V10.5a.5.5 0 0 1 .5-.5h4.293L10 14.793z"/>
                                </svg>
                                Reuse Posting
                              </div>
                            </b-dropdown-item>
                            <b-dropdown-item href="javascript:void(0);" v-if="postjobs.is_delete == 0" v-b-modal.modal-postdelete @click="remove(postjobs.jobs_id,'post')" >
                              <div class="d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash mr-2" viewBox="0 0 16 16">
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                </svg>
                                Remove Posting
                              </div>
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </div>
                      <b-row> 
                        <b-col xl="6" lg="6" sm="12">
                          <p class="fz-16 fw-500 text-muted mb-1">{{postjobs.preferences_job_type}}</p>
                          <p class="fz-16 fw-500 text-muted mb-0">Posted at : {{postjobs.create_timestamp}}</p>
                        </b-col>
                        <b-col xl="2" lg="2" sm="4" class="col-4">
                          <p class="fz-16 fw-500 text-black mb-1">{{postjobs.appliedCount}} <span class="badge badge-theme" v-if="postjobs.UnseenProposals > 0">{{postjobs.UnseenProposals}} new</span></p>
                          <h4 class="fz-16 fw-500 text-muted mb-0">Proposals</h4>
                        </b-col>
                        <b-col xl="2" lg="2" sm="4" class="col-4">
                          <p class="fz-16 fw-500 text-black mb-1">{{postjobs.messagedCount}}</p>
                          <h4 class="fz-16 fw-500 text-muted mb-0">Messaged </h4>
                        </b-col>
                        <b-col xl="2" lg="2" sm="4" class="col-4">
                        <p class="fz-16 fw-500 text-black mb-1">{{postjobs.hiredCount}}</p>
                          <h4 class="fz-16 fw-500 text-muted mb-0">Hired</h4>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </div>
            </b-card>

            <b-card no-body v-if="DraftJobList.length > 0">
              <div class="view-wrapper mb-4">
                <b-row>
                  <b-col xl="12" lg="12" sm="12" class="left">
                    <div class="header px-4 py-4">
                      <div class="d-flex align-items-center w-100">
                        <div>
                          <h4 class="fz-24 fw-600 text-black mb-0">Your Drafts</h4>
                        </div>
                        <div class="ml-auto d-flex align-items-center">
                          <b-button class="btn btn-theme" @click="$router.push({name:'jobPosting',path:'/client/job-posting',params:{pid:'draft'}}).catch(()=>{})">See All Drafts</b-button>
                        </div>
                      </div>
                    </div>
                    <div class="text-center px-4 py-8" v-if="DraftJobList.length==0">
                      <svg width="172" height="173" viewBox="0 0 172 173" fill="none" xmlns="http://www.w3.org/2000/svg" class="m-auto">
                        <path d="M75.4142 9.1442C81.305 5.05891 89.1128 5.05891 95.0036 9.1442V9.1442C98.7906 11.7705 103.474 12.7659 108.002 11.907V11.907C115.045 10.5709 122.178 13.7466 125.897 19.8747V19.8747C128.289 23.8143 132.162 26.6285 136.648 27.6855V27.6855C143.626 29.3296 148.85 35.1319 149.756 42.2433V42.2433C150.338 46.8149 152.732 50.9612 156.4 53.7514V53.7514C162.106 58.0914 164.519 65.5171 162.454 72.382V72.382C161.126 76.7953 161.626 81.5568 163.843 85.5976V85.5976C167.29 91.8832 166.474 99.6482 161.795 105.08V105.08C158.787 108.571 157.308 113.125 157.689 117.718V117.718C158.281 124.862 154.377 131.624 147.894 134.683V134.683C143.726 136.649 140.522 140.207 139.002 144.558V144.558C136.638 151.326 130.321 155.915 123.154 156.072V156.072C118.547 156.174 114.173 158.121 111.014 161.477V161.477C106.102 166.698 98.4646 168.321 91.8531 165.55V165.55C87.6028 163.768 82.815 163.768 78.5647 165.55V165.55C71.9532 168.321 64.3161 166.698 59.4034 161.477V161.477C56.2451 158.121 51.8713 156.174 47.2638 156.072V156.072C40.0967 155.915 33.7801 151.326 31.4156 144.558V144.558C29.8955 140.207 26.6919 136.649 22.5239 134.683V134.683C16.0405 131.624 12.1367 124.862 12.7292 117.718V117.718C13.1101 113.125 11.6307 108.571 8.62286 105.08V105.08C3.94419 99.6482 3.12806 91.8832 6.57521 85.5976V85.5976C8.79129 81.5568 9.29175 76.7953 7.96423 72.382V72.382C5.89925 65.5171 8.31199 58.0914 14.0177 53.7514V53.7514C17.6857 50.9612 20.0796 46.8149 20.6619 42.2433V42.2433C21.5677 35.1319 26.7921 29.3296 33.7698 27.6855V27.6855C38.2555 26.6285 42.1289 23.8143 44.5203 19.8747V19.8747C48.2402 13.7466 55.373 10.5709 62.4161 11.907V11.907C66.944 12.7659 71.6271 11.7705 75.4142 9.1442V9.1442Z" fill="#F2613C"/>
                        <path d="M151.782 22.2932C152.296 22.3179 152.821 22.1463 153.232 21.7722L170.866 5.75467C171.689 5.0068 171.751 3.73304 171.003 2.90997C170.255 2.08663 168.981 2.02545 168.158 2.77334L150.524 18.7909C149.701 19.5387 149.64 20.8125 150.388 21.6356C150.761 22.0471 151.267 22.2684 151.782 22.2932Z" fill="#F2613C"/>
                        <path d="M170.199 30.5101C169.958 29.4243 168.883 28.7393 167.796 28.9804L159.273 30.8714C158.187 31.1123 157.502 32.1878 157.743 33.2737C157.945 34.1799 158.727 34.807 159.612 34.8495C159.787 34.8579 159.966 34.8434 160.146 34.8034L168.669 32.9124C169.755 32.6717 170.44 31.596 170.199 30.5101V30.5101Z" fill="#F2613C"/>
                        <path d="M139.33 13.1927C139.505 13.2498 139.682 13.2814 139.857 13.2898C140.742 13.3323 141.581 12.783 141.868 11.9003L144.567 3.59766C144.911 2.53991 144.332 1.40345 143.275 1.05988C142.217 0.715239 141.081 1.29458 140.737 2.35232L138.038 10.6549C137.693 11.7127 138.272 12.8489 139.33 13.1927V13.1927Z" fill="#F2613C"/>
                        <g clip-path="url(#clip0_3226_22395)">
                        <path d="M124.138 60.6055C122.008 60.6055 120.275 62.3384 120.275 64.4685C120.275 66.5986 122.008 68.3315 124.138 68.3315C126.268 68.3315 128.001 66.5986 128.001 64.4685C128.001 62.3384 126.268 60.6055 124.138 60.6055ZM124.138 65.8657C123.368 65.8657 122.741 65.2389 122.741 64.4685C122.741 63.698 123.368 63.0712 124.138 63.0712C124.909 63.0712 125.536 63.698 125.536 64.4685C125.536 65.2389 124.909 65.8657 124.138 65.8657Z" fill="white"/>
                        <path d="M47.865 105.318C45.7349 105.318 44.002 107.051 44.002 109.181C44.002 111.311 45.7349 113.044 47.865 113.044C49.995 113.044 51.728 111.311 51.728 109.181C51.728 107.051 49.995 105.318 47.865 105.318ZM47.865 110.579C47.0945 110.579 46.4677 109.952 46.4677 109.181C46.4677 108.411 47.0945 107.784 47.865 107.784C48.6354 107.784 49.2622 108.411 49.2622 109.181C49.2622 109.952 48.6354 110.579 47.865 110.579Z" fill="white"/>
                        <path d="M112.386 59.2088H114.852V56.4964H117.564V54.0307H114.852V51.3184H112.386V54.0307H109.674V56.4964H112.386V59.2088Z" fill="white"/>
                        <path d="M63.5628 110.496H61.0971V113.208H58.3848V115.674H61.0971V118.387H63.5628V115.674H66.2752V113.208H63.5628V110.496Z" fill="white"/>
                        <path d="M112.221 61.9217H77.4565L72.1963 54.0312H51.8926V103.839H70.2213V101.374H54.7045L61.9785 72.2778H117.3L110.026 101.374H105.728V103.839H111.951L120.458 69.8121H112.221V61.9217ZM70.8766 56.497L74.493 61.9217H54.3583V56.497H70.8766ZM60.0534 69.8121L54.3583 92.5917V64.3874H109.756V69.8121H60.0534Z" fill="white"/>
                        <path d="M86.0015 80.332C79.5206 80.332 74.248 85.6046 74.248 92.0854C74.248 98.5663 79.5206 103.839 86.0015 103.839C92.4823 103.839 97.7549 98.5663 97.7549 92.0854C97.7549 85.6046 92.4823 80.332 86.0015 80.332ZM86.0015 101.373C80.8803 101.373 76.7138 97.2066 76.7138 92.0854C76.7138 86.9642 80.8803 82.7978 86.0015 82.7978C91.1227 82.7978 95.2891 86.9642 95.2891 92.0854C95.2891 97.2066 91.1227 101.373 86.0015 101.373Z" fill="white"/>
                        <path d="M103.016 92.086C103.016 82.7046 95.3833 75.0723 86.002 75.0723C76.6206 75.0723 68.9883 82.7044 68.9883 92.086C68.9883 101.467 76.6206 109.1 86.002 109.1C89.2379 109.1 92.2656 108.191 94.8438 106.617L109.673 121.446L115.362 115.757L100.532 100.928C102.107 98.3495 103.016 95.3219 103.016 92.086ZM71.454 92.086C71.454 84.0642 77.9802 77.538 86.002 77.538C94.0237 77.538 100.55 84.0642 100.55 92.086C100.55 100.108 94.0237 106.634 86.002 106.634C77.9802 106.634 71.454 100.108 71.454 92.086ZM109.673 117.959L100.896 109.182L103.098 106.98L111.875 115.757L109.673 117.959ZM101.354 105.237L99.1527 107.438L96.8755 105.161C97.6744 104.495 98.4115 103.758 99.077 102.959L101.354 105.237Z" fill="white"/>
                        <path d="M86.1827 85.596C85.1157 85.5472 84.1044 85.9251 83.3339 86.661C82.5745 87.3865 82.1387 88.4048 82.1387 89.4549H84.6044C84.6044 89.0694 84.7581 88.7106 85.0369 88.4443C85.3154 88.1783 85.6836 88.0405 86.0692 88.0593C86.7694 88.0915 87.3651 88.6872 87.3973 89.3873C87.4192 89.8618 87.2028 90.3108 86.8187 90.5887C85.5352 91.5168 84.7688 93.0595 84.7688 94.7152H87.2346C87.2346 93.8484 87.6192 93.0526 88.2636 92.5867C89.3237 91.8201 89.9208 90.5818 89.8606 89.2741C89.7699 87.3023 88.1543 85.6866 86.1827 85.596V85.596Z" fill="white"/>
                        <path d="M84.7695 96.0312H87.2353V98.6614H84.7695V96.0312Z" fill="white"/>
                        <path d="M57.0703 57.9766H59.7004V60.4423H57.0703V57.9766Z" fill="white"/>
                        <path d="M62.3301 57.9766H64.9602V60.4423H62.3301V57.9766Z" fill="white"/>
                        <path d="M67.5918 57.9766H70.2219V60.4423H67.5918V57.9766Z" fill="white"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_3226_22395">
                        <rect width="84" height="84" fill="white" transform="translate(44.002 44.3828)"/>
                        </clipPath>
                        </defs>
                      </svg>
                      <h4 class="fz-20 fw-600 text-black">No Draft Job Posts</h4>
                      <p class="fz-16 fw-500 text-muted">Post a job to the marketplace and let talent come to you.</p>
                      <b-button class="btn btn-theme mt-3"  @click="$router.push({name:'post-job',path:'/client/post-job/',params:{pid:'POID'}}).catch(()=>{})" v-if="isPermissionForClient('post-job')">Post a Job</b-button>
                    </div>
                    <div class="px-4 py-4"  >
                      <div class="posted-job-wrapper border-bottom" v-for="(draftjobs, i) of DraftJobList" :key="i" >
                        <div class="d-flex align-items-center mb-3">
                          <div>
                            <h4 class="mb-3 fz-18 fw-500 text-black d-block"><b>{{draftjobs.job_title}}</b></h4>
                            <span>{{ draftjobs.createdby_Name }}</span>
                            <p class="mb-0 fz-16 fw-500 text-muted">Saved at : {{draftjobs.create_timestamp}}</p>
                          </div>
                          <div class="ml-auto">
                            <b-dropdown size="lg" right  variant="link" toggle-class="text-decoration-none" no-caret>
                              <template #button-content>
                                <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4 2C4 3.10457 3.10457 4 2 4C0.895431 4 0 3.10457 0 2C0 0.895431 0.895431 0 2 0C3.10457 0 4 0.895431 4 2ZM11 2C11 3.10457 10.1046 4 9 4C7.89543 4 7 3.10457 7 2C7 0.895431 7.89543 0 9 0C10.1046 0 11 0.895431 11 2ZM16 4C17.1046 4 18 3.10457 18 2C18 0.895431 17.1046 0 16 0C14.8954 0 14 0.895431 14 2C14 3.10457 14.8954 4 16 4Z" fill="#666666"/>
                                </svg>
                              </template>
                              <b-dropdown-item href="javascript:void(0);" @click="$router.push({name: 'postReview', path:'/client/post-review',params: {pid: draftjobs.uniq_id}}).catch(()=>{})">Edit Draft</b-dropdown-item>
                              <b-dropdown-item href="javascript:void(0);" v-b-modal.modal-draftdelete @click="remove(draftjobs.jobs_id,'draft')">Remove Draft</b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-card>

            <b-card no-body v-if="ActiveContractList.length > 0 || OffersentContractList.length > 0">
              <div class="view-wrapper">
                <b-row>
                  <b-col xl="12" lg="12" sm="12" class="left">
                    <div class="header px-4 py-4">
                      <div class="d-flex align-items-center w-100">
                        <div>
                          <h4 class="fz-24 fw-600 text-black mb-0">Your Contract</h4>
                        </div>
                        <div class="ml-auto d-flex align-items-center">
                          <b-button class="btn btn-theme" @click="$router.push('/client/all-contracts').catch(()=>{})">See All Contract</b-button>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col xl="12" lg="12" sm="12">
                    <b-card no-body class="px-4 py-2">
                      <span class="fz-24 text-success fw-600 pos-absolute r-2rem" v-if="tabValue=='active'">${{totalBurget}}</span>
                      <span class="fz-24 text-success fw-600 pos-absolute r-2rem" v-else-if="tabValue=='Offer sent'">${{totalofferSendBurget}}</span>
                      <span class="fz-24 text-success fw-600 pos-absolute r-2rem" v-else-if="tabValue=='work submitted'">${{totalworksubmitteddBurget}}</span>
                      <b-tabs>
                        <b-tab active @click="tabValue='active'">
                          <template #title>
                            <span class="d-block">Active <span class="badge badge-theme">{{(ActiveContractList.length>0)?ActiveContractList.length:''}}</span></span>
                          </template>
                          <template v-if="ActiveContractList.length>0">
                            <div class="posted-job-wrapper border-bottom" v-for="(active_con,aid) in ActiveContractList" :key="aid">
                              <div class="d-flex align-items-center mt-4">
                                <h5 class="fz-18 fw-600">{{active_con.job_title}}</h5>
                                <div class="ml-auto" v-if="active_con.application_status == 2 || active_con.application_status == 6">
                                  <b-dropdown size="lg" id="dropdown-right" right text="Right align" variant="link" toggle-class="text-decoration-none" no-caret>
                                    <template #button-content>
                                      <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M4 2C4 3.10457 3.10457 4 2 4C0.895431 4 0 3.10457 0 2C0 0.895431 0.895431 0 2 0C3.10457 0 4 0.895431 4 2ZM11 2C11 3.10457 10.1046 4 9 4C7.89543 4 7 3.10457 7 2C7 0.895431 7.89543 0 9 0C10.1046 0 11 0.895431 11 2ZM16 4C17.1046 4 18 3.10457 18 2C18 0.895431 17.1046 0 16 0C14.8954 0 14 0.895431 14 2C14 3.10457 14.8954 4 16 4Z" fill="#666666"/>
                                      </svg>
                                    </template>
                                    <b-dropdown-item @click="$router.push({'name':'contractDetails','path':'/client/jobpostreview/contract-detail/','params':{pid:active_con.uniq_id,uid:encodedid(active_con.freelancer_id)},query:{'current':'overview'}}).catch(()=>{})">View Milestones & payments</b-dropdown-item>
                                    <b-dropdown-item @click="$router.push({'name':'contractDetails','path':'/client/jobpostreview/contract-detail/','params':{pid:active_con.uniq_id,uid:encodedid(active_con.freelancer_id)},query:{'current':'message'}}).catch(()=>{})">Send Message</b-dropdown-item>
                                    <b-dropdown-item @click="$router.push({'name':'contractDetails','path':'/client/jobpostreview/contract-detail/','params':{pid:active_con.uniq_id,uid:encodedid(active_con.freelancer_id)},query:{'current':'overview'}}).catch(()=>{})">View terms & setting</b-dropdown-item>
                                    <b-dropdown-item @click="$router.push({'name':'contractDetails','path':'/client/jobpostreview/contract-detail/','params':{pid:active_con.uniq_id,uid:encodedid(active_con.freelancer_id)},query:{'current':'bonus'}}).catch(()=>{})">Give bonus</b-dropdown-item>
                                    <b-dropdown-item @click="$router.push({name:'freelancerProfile',path:'/client/freelancer-Profile/',params:{uid:encodedid(active_con.freelancer_id)}}).catch(()=>{})">View Profile</b-dropdown-item>
                                  </b-dropdown>
                                </div>
                              </div>
                              <b-row class="mt-2">
                                <b-col xl="6" lg="6" sm="12">
                                  <div class="d-md-flex align-items-center mb-2">
                                    <div class="img-wrapper mr-3 w-12 h-12 br-50 border">
                                      <img :src="active_con.profile_picture_path" class="img-fluid br-50 w-100 h-100">
                                    </div>
                                    <div>
                                      <h4 class="fz-18 fw-600 text-theme mb-0"><a href="javascript:void(0);" class="text-theme">{{shortName(active_con.first_name,active_con.last_name)}}</a></h4>
                                      <p class="fz-14 fw-600 text-muteed mb-0">{{active_con.localTime}} Local Time</p>
                                      <h6 class="fz-16 fw-600 mb-1 text-muted" v-if="active_con.company_name != null">{{'From '+active_con.company_name}}</h6>
                                      <h6 class="fz-16 fw-600 mb-1 text-muted" v-if="active_con.agency_name != null">{{'From '+active_con.agency_name}}</h6>
                                    </div>
                                  </div> 
                                </b-col>
                                <b-col xl="3" lg="3" sm="12">
                                  <span class="d-flex">
                                    <h4 class="fz-18 fw-600 text-black mr-2" v-if="active_con.application_status == 2">{{'Active: '}} {{ (active_con.button == 'Review & Pay')?'Request for payment':(active_con.button == 'Fund Milestone')?'Fund new Milestone':active_con.milestoneName }}</h4>
                                    <h4 class="fz-18 fw-600 text-black mr-2" v-if="active_con.application_status == 6">{{'Completed'}}</h4>
                                    <div class="star-rating mr-2 file" v-if="active_con.application_status == 6 && active_con.from_rating !='' && active_con.to_rating!='' && active_con.from_rating !=null && active_con.to_rating!=null">
                                        <i class="fa fa-star  fz-14" :class="(active_con.to_rating >=1 )?'text-theme':''" aria-hidden="true"></i>
                                        <i class="fa fa-star  fz-14" :class="(active_con.to_rating >=2 )?'text-theme':''" aria-hidden="true"></i>
                                        <i class="fa fa-star  fz-14" :class="(active_con.to_rating >=3 )?'text-theme':''" aria-hidden="true"></i>
                                        <i class="fa fa-star  fz-14" :class="(active_con.to_rating >=4 )?'text-theme':''" aria-hidden="true"></i>
                                        <i class="fa fa-star  fz-14" :class="(active_con.to_rating ==5 )?'text-theme':''" aria-hidden="true"></i>
                                    </div>
                                  </span>
                                  <h5 v-if="active_con.application_status != 7" class="fz-18 fw-600 text-black mr-2">${{active_con.burget}} Budget</h5>
                                  <p v-else class="fz-18 fw-600 text-black mr-2">{{ active_con.time }}</p>
                                </b-col>
                                <b-col xl="3" lg="3" sm="12" class="text-right">
                                  <b-button class="btn btn-theme" v-if="active_con.application_status == 7" @click="$router.push({name:'offerdeatils',path:'/client/offer-details',params:{ pid:active_con.uniq_id,uid:encodedid(active_con.freelancer_id)}}).catch(()=>{})">See Offer</b-button>
                                  <b-button class="btn btn-theme" v-else-if="active_con.application_status == 2" @click="$router.push({'name':'contractDetails','path':'/client/jobpostreview/contract-detail/','params':{pid:active_con.uniq_id,uid:encodedid(active_con.freelancer_id)}}).catch(()=>{})">{{active_con.button}}</b-button>
                                  <b-button class="btn btn-theme" v-else-if="isPermissionForClient('hire-btn') && active_con.application_status == 6" @click="$router.push({name: 'hireFreelancer',  path:'/client/hire-freelancer/', params: {uid: 'A'+encodedid(active_con.freelancer_id)}}).catch(()=>{})">Rehire</b-button>
                                  
                                  <p class="fz-14 text-black fw-600 text-right mt-3" v-if="active_con.application_status==2 || active_con.application_status == 6">{{active_con.contractStart}}  {{(active_con.contractStart!='' && active_con.contractEnd!='')?'- '+active_con.contractEnd:(active_con.contractStart!='')?'- Present':''}}</p>
                                </b-col>
                              </b-row>
                            </div>
                          </template>
                          <template v-else>
                              <div class="text-center py-8 px-4" >
                                <svg width="172" height="173" viewBox="0 0 172 173" fill="none" xmlns="http://www.w3.org/2000/svg" class="m-auto">
                                  <path d="M75.4142 9.1442C81.305 5.05891 89.1128 5.05891 95.0036 9.1442V9.1442C98.7906 11.7705 103.474 12.7659 108.002 11.907V11.907C115.045 10.5709 122.178 13.7466 125.897 19.8747V19.8747C128.289 23.8143 132.162 26.6285 136.648 27.6855V27.6855C143.626 29.3296 148.85 35.1319 149.756 42.2433V42.2433C150.338 46.8149 152.732 50.9612 156.4 53.7514V53.7514C162.106 58.0914 164.519 65.5171 162.454 72.382V72.382C161.126 76.7953 161.626 81.5568 163.843 85.5976V85.5976C167.29 91.8832 166.474 99.6482 161.795 105.08V105.08C158.787 108.571 157.308 113.125 157.689 117.718V117.718C158.281 124.862 154.377 131.624 147.894 134.683V134.683C143.726 136.649 140.522 140.207 139.002 144.558V144.558C136.638 151.326 130.321 155.915 123.154 156.072V156.072C118.547 156.174 114.173 158.121 111.014 161.477V161.477C106.102 166.698 98.4646 168.321 91.8531 165.55V165.55C87.6028 163.768 82.815 163.768 78.5647 165.55V165.55C71.9532 168.321 64.3161 166.698 59.4034 161.477V161.477C56.2451 158.121 51.8713 156.174 47.2638 156.072V156.072C40.0967 155.915 33.7801 151.326 31.4156 144.558V144.558C29.8955 140.207 26.6919 136.649 22.5239 134.683V134.683C16.0405 131.624 12.1367 124.862 12.7292 117.718V117.718C13.1101 113.125 11.6307 108.571 8.62286 105.08V105.08C3.94419 99.6482 3.12806 91.8832 6.57521 85.5976V85.5976C8.79129 81.5568 9.29175 76.7953 7.96423 72.382V72.382C5.89925 65.5171 8.31199 58.0914 14.0177 53.7514V53.7514C17.6857 50.9612 20.0796 46.8149 20.6619 42.2433V42.2433C21.5677 35.1319 26.7921 29.3296 33.7698 27.6855V27.6855C38.2555 26.6285 42.1289 23.8143 44.5203 19.8747V19.8747C48.2402 13.7466 55.373 10.5709 62.4161 11.907V11.907C66.944 12.7659 71.6271 11.7705 75.4142 9.1442V9.1442Z" fill="#F2613C"/>
                                  <path d="M151.782 22.2932C152.296 22.3179 152.821 22.1463 153.232 21.7722L170.866 5.75467C171.689 5.0068 171.751 3.73304 171.003 2.90997C170.255 2.08663 168.981 2.02545 168.158 2.77334L150.524 18.7909C149.701 19.5387 149.64 20.8125 150.388 21.6356C150.761 22.0471 151.267 22.2684 151.782 22.2932Z" fill="#F2613C"/>
                                  <path d="M170.199 30.5101C169.958 29.4243 168.883 28.7393 167.796 28.9804L159.273 30.8714C158.187 31.1123 157.502 32.1878 157.743 33.2737C157.945 34.1799 158.727 34.807 159.612 34.8495C159.787 34.8579 159.966 34.8434 160.146 34.8034L168.669 32.9124C169.755 32.6717 170.44 31.596 170.199 30.5101V30.5101Z" fill="#F2613C"/>
                                  <path d="M139.33 13.1927C139.505 13.2498 139.682 13.2814 139.857 13.2898C140.742 13.3323 141.581 12.783 141.868 11.9003L144.567 3.59766C144.911 2.53991 144.332 1.40345 143.275 1.05988C142.217 0.715239 141.081 1.29458 140.737 2.35232L138.038 10.6549C137.693 11.7127 138.272 12.8489 139.33 13.1927V13.1927Z" fill="#F2613C"/>
                                  <g clip-path="url(#clip0_3226_22395)">
                                  <path d="M124.138 60.6055C122.008 60.6055 120.275 62.3384 120.275 64.4685C120.275 66.5986 122.008 68.3315 124.138 68.3315C126.268 68.3315 128.001 66.5986 128.001 64.4685C128.001 62.3384 126.268 60.6055 124.138 60.6055ZM124.138 65.8657C123.368 65.8657 122.741 65.2389 122.741 64.4685C122.741 63.698 123.368 63.0712 124.138 63.0712C124.909 63.0712 125.536 63.698 125.536 64.4685C125.536 65.2389 124.909 65.8657 124.138 65.8657Z" fill="white"/>
                                  <path d="M47.865 105.318C45.7349 105.318 44.002 107.051 44.002 109.181C44.002 111.311 45.7349 113.044 47.865 113.044C49.995 113.044 51.728 111.311 51.728 109.181C51.728 107.051 49.995 105.318 47.865 105.318ZM47.865 110.579C47.0945 110.579 46.4677 109.952 46.4677 109.181C46.4677 108.411 47.0945 107.784 47.865 107.784C48.6354 107.784 49.2622 108.411 49.2622 109.181C49.2622 109.952 48.6354 110.579 47.865 110.579Z" fill="white"/>
                                  <path d="M112.386 59.2088H114.852V56.4964H117.564V54.0307H114.852V51.3184H112.386V54.0307H109.674V56.4964H112.386V59.2088Z" fill="white"/>
                                  <path d="M63.5628 110.496H61.0971V113.208H58.3848V115.674H61.0971V118.387H63.5628V115.674H66.2752V113.208H63.5628V110.496Z" fill="white"/>
                                  <path d="M112.221 61.9217H77.4565L72.1963 54.0312H51.8926V103.839H70.2213V101.374H54.7045L61.9785 72.2778H117.3L110.026 101.374H105.728V103.839H111.951L120.458 69.8121H112.221V61.9217ZM70.8766 56.497L74.493 61.9217H54.3583V56.497H70.8766ZM60.0534 69.8121L54.3583 92.5917V64.3874H109.756V69.8121H60.0534Z" fill="white"/>
                                  <path d="M86.0015 80.332C79.5206 80.332 74.248 85.6046 74.248 92.0854C74.248 98.5663 79.5206 103.839 86.0015 103.839C92.4823 103.839 97.7549 98.5663 97.7549 92.0854C97.7549 85.6046 92.4823 80.332 86.0015 80.332ZM86.0015 101.373C80.8803 101.373 76.7138 97.2066 76.7138 92.0854C76.7138 86.9642 80.8803 82.7978 86.0015 82.7978C91.1227 82.7978 95.2891 86.9642 95.2891 92.0854C95.2891 97.2066 91.1227 101.373 86.0015 101.373Z" fill="white"/>
                                  <path d="M103.016 92.086C103.016 82.7046 95.3833 75.0723 86.002 75.0723C76.6206 75.0723 68.9883 82.7044 68.9883 92.086C68.9883 101.467 76.6206 109.1 86.002 109.1C89.2379 109.1 92.2656 108.191 94.8438 106.617L109.673 121.446L115.362 115.757L100.532 100.928C102.107 98.3495 103.016 95.3219 103.016 92.086ZM71.454 92.086C71.454 84.0642 77.9802 77.538 86.002 77.538C94.0237 77.538 100.55 84.0642 100.55 92.086C100.55 100.108 94.0237 106.634 86.002 106.634C77.9802 106.634 71.454 100.108 71.454 92.086ZM109.673 117.959L100.896 109.182L103.098 106.98L111.875 115.757L109.673 117.959ZM101.354 105.237L99.1527 107.438L96.8755 105.161C97.6744 104.495 98.4115 103.758 99.077 102.959L101.354 105.237Z" fill="white"/>
                                  <path d="M86.1827 85.596C85.1157 85.5472 84.1044 85.9251 83.3339 86.661C82.5745 87.3865 82.1387 88.4048 82.1387 89.4549H84.6044C84.6044 89.0694 84.7581 88.7106 85.0369 88.4443C85.3154 88.1783 85.6836 88.0405 86.0692 88.0593C86.7694 88.0915 87.3651 88.6872 87.3973 89.3873C87.4192 89.8618 87.2028 90.3108 86.8187 90.5887C85.5352 91.5168 84.7688 93.0595 84.7688 94.7152H87.2346C87.2346 93.8484 87.6192 93.0526 88.2636 92.5867C89.3237 91.8201 89.9208 90.5818 89.8606 89.2741C89.7699 87.3023 88.1543 85.6866 86.1827 85.596V85.596Z" fill="white"/>
                                  <path d="M84.7695 96.0312H87.2353V98.6614H84.7695V96.0312Z" fill="white"/>
                                  <path d="M57.0703 57.9766H59.7004V60.4423H57.0703V57.9766Z" fill="white"/>
                                  <path d="M62.3301 57.9766H64.9602V60.4423H62.3301V57.9766Z" fill="white"/>
                                  <path d="M67.5918 57.9766H70.2219V60.4423H67.5918V57.9766Z" fill="white"/>
                                  </g>
                                  <defs>
                                  <clipPath id="clip0_3226_22395">
                                  <rect width="84" height="84" fill="white" transform="translate(44.002 44.3828)"/>
                                  </clipPath>
                                  </defs>
                                </svg>
                                <h4 class="fz-20 fw-600 text-black">No Active Contract Found</h4>
                              </div>
                          </template>
                        </b-tab>
                        <b-tab @click="tabValue='Offer sent'">
                          <template #title>
                            <span class="d-block">Offer sent <span class="badge badge-theme">{{(OffersentContractList.length>0)?OffersentContractList.length:''}}</span></span>
                          </template>
                          <template v-if="OffersentContractList.length>0">
                            <div class="posted-job-wrapper border-bottom" v-for="(awaiting_con,aid) in OffersentContractList" :key="aid">
                              <div class="d-flex align-items-center mt-4">
                                <h5 class="fz-18 fw-600">{{awaiting_con.job_title}}</h5>
                              </div>
                              <b-row class="mt-2">
                                <b-col xl="6" lg="6" sm="12">
                                  <div class="d-md-flex align-items-center mb-2">
                                    <div class="img-wrapper mr-3 w-12 h-12 br-50 border">
                                      <img :src="awaiting_con.profile_picture_path" class="img-fluid br-50 w-100 h-100">
                                    </div>
                                    <div>
                                      <h4 class="fz-18 fw-600 text-theme mb-0"><a href="javascript:void(0);" class="text-theme">{{awaiting_con.first_name+' '+awaiting_con.last_name}}</a></h4>
                                      <p class="fz-14 fw-600 text-muteed mb-0">{{awaiting_con.localTime}} Local Time</p>
                                      <h6 class="fz-16 fw-600 mb-1 text-muted" v-if="awaiting_con.company_name != null">{{'From '+awaiting_con.company_name}}</h6>
                                      <h6 class="fz-16 fw-600 mb-1 text-muted" v-if="awaiting_con.agency_name != null">{{'From '+awaiting_con.agency_name}}</h6>

                                    </div>
                                  </div> 
                                </b-col>
                                <b-col xl="3" lg="3" sm="12">
                                  <h5 class="fz-18 text-black mr-2" v-if="awaiting_con.application_status == 7">Pending: waiting for {{shortName(awaiting_con.first_name,awaiting_con.last_name)}} to approve</h5>
                                  <p v-if="awaiting_con.application_status == 7">Offer sent {{ awaiting_con.time }}</p>
                                </b-col>
                                <b-col xl="3" lg="3" sm="12" class="text-right">
                                  <b-button class="btn btn-theme" v-if="awaiting_con.application_status == 7" @click="$router.push({name:'offerdeatils',path:'/client/offer-details',params:{ pid:awaiting_con.uniq_id,uid:encodedid(awaiting_con.freelancer_id)}}).catch(()=>{})">See Offer</b-button>
                                  <p class="fz-14 text-black fw-600 text-right mt-3">$ {{awaiting_con.burget}} Budget</p>
                                </b-col>
                              </b-row>
                            </div>
                          </template>
                          <template v-else>
                              <div class="text-center py-8 px-4" >
                                <svg width="172" height="173" viewBox="0 0 172 173" fill="none" xmlns="http://www.w3.org/2000/svg" class="m-auto">
                                  <path d="M75.4142 9.1442C81.305 5.05891 89.1128 5.05891 95.0036 9.1442V9.1442C98.7906 11.7705 103.474 12.7659 108.002 11.907V11.907C115.045 10.5709 122.178 13.7466 125.897 19.8747V19.8747C128.289 23.8143 132.162 26.6285 136.648 27.6855V27.6855C143.626 29.3296 148.85 35.1319 149.756 42.2433V42.2433C150.338 46.8149 152.732 50.9612 156.4 53.7514V53.7514C162.106 58.0914 164.519 65.5171 162.454 72.382V72.382C161.126 76.7953 161.626 81.5568 163.843 85.5976V85.5976C167.29 91.8832 166.474 99.6482 161.795 105.08V105.08C158.787 108.571 157.308 113.125 157.689 117.718V117.718C158.281 124.862 154.377 131.624 147.894 134.683V134.683C143.726 136.649 140.522 140.207 139.002 144.558V144.558C136.638 151.326 130.321 155.915 123.154 156.072V156.072C118.547 156.174 114.173 158.121 111.014 161.477V161.477C106.102 166.698 98.4646 168.321 91.8531 165.55V165.55C87.6028 163.768 82.815 163.768 78.5647 165.55V165.55C71.9532 168.321 64.3161 166.698 59.4034 161.477V161.477C56.2451 158.121 51.8713 156.174 47.2638 156.072V156.072C40.0967 155.915 33.7801 151.326 31.4156 144.558V144.558C29.8955 140.207 26.6919 136.649 22.5239 134.683V134.683C16.0405 131.624 12.1367 124.862 12.7292 117.718V117.718C13.1101 113.125 11.6307 108.571 8.62286 105.08V105.08C3.94419 99.6482 3.12806 91.8832 6.57521 85.5976V85.5976C8.79129 81.5568 9.29175 76.7953 7.96423 72.382V72.382C5.89925 65.5171 8.31199 58.0914 14.0177 53.7514V53.7514C17.6857 50.9612 20.0796 46.8149 20.6619 42.2433V42.2433C21.5677 35.1319 26.7921 29.3296 33.7698 27.6855V27.6855C38.2555 26.6285 42.1289 23.8143 44.5203 19.8747V19.8747C48.2402 13.7466 55.373 10.5709 62.4161 11.907V11.907C66.944 12.7659 71.6271 11.7705 75.4142 9.1442V9.1442Z" fill="#F2613C"/>
                                  <path d="M151.782 22.2932C152.296 22.3179 152.821 22.1463 153.232 21.7722L170.866 5.75467C171.689 5.0068 171.751 3.73304 171.003 2.90997C170.255 2.08663 168.981 2.02545 168.158 2.77334L150.524 18.7909C149.701 19.5387 149.64 20.8125 150.388 21.6356C150.761 22.0471 151.267 22.2684 151.782 22.2932Z" fill="#F2613C"/>
                                  <path d="M170.199 30.5101C169.958 29.4243 168.883 28.7393 167.796 28.9804L159.273 30.8714C158.187 31.1123 157.502 32.1878 157.743 33.2737C157.945 34.1799 158.727 34.807 159.612 34.8495C159.787 34.8579 159.966 34.8434 160.146 34.8034L168.669 32.9124C169.755 32.6717 170.44 31.596 170.199 30.5101V30.5101Z" fill="#F2613C"/>
                                  <path d="M139.33 13.1927C139.505 13.2498 139.682 13.2814 139.857 13.2898C140.742 13.3323 141.581 12.783 141.868 11.9003L144.567 3.59766C144.911 2.53991 144.332 1.40345 143.275 1.05988C142.217 0.715239 141.081 1.29458 140.737 2.35232L138.038 10.6549C137.693 11.7127 138.272 12.8489 139.33 13.1927V13.1927Z" fill="#F2613C"/>
                                  <g clip-path="url(#clip0_3226_22395)">
                                  <path d="M124.138 60.6055C122.008 60.6055 120.275 62.3384 120.275 64.4685C120.275 66.5986 122.008 68.3315 124.138 68.3315C126.268 68.3315 128.001 66.5986 128.001 64.4685C128.001 62.3384 126.268 60.6055 124.138 60.6055ZM124.138 65.8657C123.368 65.8657 122.741 65.2389 122.741 64.4685C122.741 63.698 123.368 63.0712 124.138 63.0712C124.909 63.0712 125.536 63.698 125.536 64.4685C125.536 65.2389 124.909 65.8657 124.138 65.8657Z" fill="white"/>
                                  <path d="M47.865 105.318C45.7349 105.318 44.002 107.051 44.002 109.181C44.002 111.311 45.7349 113.044 47.865 113.044C49.995 113.044 51.728 111.311 51.728 109.181C51.728 107.051 49.995 105.318 47.865 105.318ZM47.865 110.579C47.0945 110.579 46.4677 109.952 46.4677 109.181C46.4677 108.411 47.0945 107.784 47.865 107.784C48.6354 107.784 49.2622 108.411 49.2622 109.181C49.2622 109.952 48.6354 110.579 47.865 110.579Z" fill="white"/>
                                  <path d="M112.386 59.2088H114.852V56.4964H117.564V54.0307H114.852V51.3184H112.386V54.0307H109.674V56.4964H112.386V59.2088Z" fill="white"/>
                                  <path d="M63.5628 110.496H61.0971V113.208H58.3848V115.674H61.0971V118.387H63.5628V115.674H66.2752V113.208H63.5628V110.496Z" fill="white"/>
                                  <path d="M112.221 61.9217H77.4565L72.1963 54.0312H51.8926V103.839H70.2213V101.374H54.7045L61.9785 72.2778H117.3L110.026 101.374H105.728V103.839H111.951L120.458 69.8121H112.221V61.9217ZM70.8766 56.497L74.493 61.9217H54.3583V56.497H70.8766ZM60.0534 69.8121L54.3583 92.5917V64.3874H109.756V69.8121H60.0534Z" fill="white"/>
                                  <path d="M86.0015 80.332C79.5206 80.332 74.248 85.6046 74.248 92.0854C74.248 98.5663 79.5206 103.839 86.0015 103.839C92.4823 103.839 97.7549 98.5663 97.7549 92.0854C97.7549 85.6046 92.4823 80.332 86.0015 80.332ZM86.0015 101.373C80.8803 101.373 76.7138 97.2066 76.7138 92.0854C76.7138 86.9642 80.8803 82.7978 86.0015 82.7978C91.1227 82.7978 95.2891 86.9642 95.2891 92.0854C95.2891 97.2066 91.1227 101.373 86.0015 101.373Z" fill="white"/>
                                  <path d="M103.016 92.086C103.016 82.7046 95.3833 75.0723 86.002 75.0723C76.6206 75.0723 68.9883 82.7044 68.9883 92.086C68.9883 101.467 76.6206 109.1 86.002 109.1C89.2379 109.1 92.2656 108.191 94.8438 106.617L109.673 121.446L115.362 115.757L100.532 100.928C102.107 98.3495 103.016 95.3219 103.016 92.086ZM71.454 92.086C71.454 84.0642 77.9802 77.538 86.002 77.538C94.0237 77.538 100.55 84.0642 100.55 92.086C100.55 100.108 94.0237 106.634 86.002 106.634C77.9802 106.634 71.454 100.108 71.454 92.086ZM109.673 117.959L100.896 109.182L103.098 106.98L111.875 115.757L109.673 117.959ZM101.354 105.237L99.1527 107.438L96.8755 105.161C97.6744 104.495 98.4115 103.758 99.077 102.959L101.354 105.237Z" fill="white"/>
                                  <path d="M86.1827 85.596C85.1157 85.5472 84.1044 85.9251 83.3339 86.661C82.5745 87.3865 82.1387 88.4048 82.1387 89.4549H84.6044C84.6044 89.0694 84.7581 88.7106 85.0369 88.4443C85.3154 88.1783 85.6836 88.0405 86.0692 88.0593C86.7694 88.0915 87.3651 88.6872 87.3973 89.3873C87.4192 89.8618 87.2028 90.3108 86.8187 90.5887C85.5352 91.5168 84.7688 93.0595 84.7688 94.7152H87.2346C87.2346 93.8484 87.6192 93.0526 88.2636 92.5867C89.3237 91.8201 89.9208 90.5818 89.8606 89.2741C89.7699 87.3023 88.1543 85.6866 86.1827 85.596V85.596Z" fill="white"/>
                                  <path d="M84.7695 96.0312H87.2353V98.6614H84.7695V96.0312Z" fill="white"/>
                                  <path d="M57.0703 57.9766H59.7004V60.4423H57.0703V57.9766Z" fill="white"/>
                                  <path d="M62.3301 57.9766H64.9602V60.4423H62.3301V57.9766Z" fill="white"/>
                                  <path d="M67.5918 57.9766H70.2219V60.4423H67.5918V57.9766Z" fill="white"/>
                                  </g>
                                  <defs>
                                  <clipPath id="clip0_3226_22395">
                                  <rect width="84" height="84" fill="white" transform="translate(44.002 44.3828)"/>
                                  </clipPath>
                                  </defs>
                                </svg>
                                <h4 class="fz-20 fw-600 text-black">No Awaiting Contract Found</h4>
                              </div>
                          </template>
                        </b-tab>
                        <b-tab @click="tabValue='work submitted'">
                          <template #title>
                            <span class="d-block">Work Submitted <span class="badge badge-theme">{{(workSubmittedContractList.length>0)?workSubmittedContractList.length:''}}</span></span>
                          </template>
                          <template v-if="workSubmittedContractList.length>0">
                            <div class="posted-job-wrapper border-bottom" v-for="(work_con,aid) in workSubmittedContractList" :key="aid">
                              <div class="d-flex align-items-center mt-4">
                                <h5 class="fz-18 fw-600">{{work_con.job_title}}</h5>
                                <div class="ml-auto" v-if="work_con.application_status == 2 || work_con.application_status == 6">
                                  <b-dropdown size="lg" id="dropdown-right" right text="Right align" variant="link" toggle-class="text-decoration-none" no-caret>
                                    <template #button-content>
                                      <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M4 2C4 3.10457 3.10457 4 2 4C0.895431 4 0 3.10457 0 2C0 0.895431 0.895431 0 2 0C3.10457 0 4 0.895431 4 2ZM11 2C11 3.10457 10.1046 4 9 4C7.89543 4 7 3.10457 7 2C7 0.895431 7.89543 0 9 0C10.1046 0 11 0.895431 11 2ZM16 4C17.1046 4 18 3.10457 18 2C18 0.895431 17.1046 0 16 0C14.8954 0 14 0.895431 14 2C14 3.10457 14.8954 4 16 4Z" fill="#666666"/>
                                      </svg>
                                    </template>
                                    <b-dropdown-item @click="$router.push({'name':'contractDetails','path':'/client/jobpostreview/contract-detail/','params':{pid:work_con.uniq_id,uid:encodedid(work_con.freelancer_id)},query:{'current':'overview'}})">View Milestones & payments</b-dropdown-item>
                                    <b-dropdown-item @click="$router.push({'name':'contractDetails','path':'/client/jobpostreview/contract-detail/','params':{pid:work_con.uniq_id,uid:encodedid(work_con.freelancer_id)},query:{'current':'message'}})">Send Message</b-dropdown-item>
                                    <b-dropdown-item @click="$router.push({'name':'contractDetails','path':'/client/jobpostreview/contract-detail/','params':{pid:work_con.uniq_id,uid:encodedid(work_con.freelancer_id)},query:{'current':'overview'}})">View terms & setting</b-dropdown-item>
                                    <b-dropdown-item @click="$router.push({'name':'contractDetails','path':'/client/jobpostreview/contract-detail/','params':{pid:work_con.uniq_id,uid:encodedid(work_con.freelancer_id)},query:{'current':'bonus'}})">Give bonus</b-dropdown-item>
                                    <b-dropdown-item @click="$router.push({name:'freelancerProfile',path:'/client/freelancer-Profile/',params:{uid:encodedid(work_con.freelancer_id)}})">View Profile</b-dropdown-item>
                                  </b-dropdown>
                                </div>
                              </div>
                              <b-row class="mt-2">
                                <b-col xl="6" lg="6" sm="12">
                                  <div class="d-md-flex align-items-center mb-2">
                                    <div class="img-wrapper mr-3 w-12 h-12 br-50 border">
                                      <img :src="work_con.profile_picture_path" class="img-fluid br-50 w-100 h-100">
                                    </div>
                                    <div>
                                      <h4 class="fz-18 fw-600 text-theme mb-0"><a href="javascript:void(0);" class="text-theme">{{shortName(work_con.first_name,work_con.last_name)}}</a></h4>
                                      <p class="fz-14 fw-600 text-muteed mb-0">{{work_con.localTime}} Local Time</p>
                                      <h6 class="fz-16 fw-600 mb-1 text-muted" v-if="work_con.company_name != null">{{'From '+work_con.company_name}}</h6>
                                      <h6 class="fz-16 fw-600 mb-1 text-muted" v-if="work_con.agency_name != null">{{'From '+work_con.agency_name}}</h6>
                                    </div>
                                  </div> 
                                </b-col>
                                <b-col xl="3" lg="3" sm="12">
                                  <span class="d-flex">
                                    <h4 class="fz-18 fw-600 text-black mr-2" v-if="work_con.application_status == 2">{{'Active: '}} {{ (work_con.button == 'Review & Pay')?'Request for payment':(work_con.button == 'Fund Milestone')?'Fund new Milestone':work_con.milestoneName }}</h4>
                                    <h4 class="fz-18 fw-600 text-black mr-2" v-if="work_con.application_status == 6">{{'Completed'}}</h4>
                                    <div class="star-rating mr-2 file" v-if="work_con.application_status == 6 && work_con.from_rating !='' && work_con.to_rating!='' && work_con.from_rating !=null && work_con.to_rating!=null">
                                        <i class="fa fa-star  fz-14" :class="(work_con.to_rating >=1 )?'text-theme':''" aria-hidden="true"></i>
                                        <i class="fa fa-star  fz-14" :class="(work_con.to_rating >=2 )?'text-theme':''" aria-hidden="true"></i>
                                        <i class="fa fa-star  fz-14" :class="(work_con.to_rating >=3 )?'text-theme':''" aria-hidden="true"></i>
                                        <i class="fa fa-star  fz-14" :class="(work_con.to_rating >=4 )?'text-theme':''" aria-hidden="true"></i>
                                        <i class="fa fa-star  fz-14" :class="(work_con.to_rating ==5 )?'text-theme':''" aria-hidden="true"></i>
                                    </div>
                                  </span>
                                  <h5 v-if="work_con.application_status != 7" class="fz-18 fw-600 text-black mr-2">${{work_con.burget}} Budget</h5>
                                  <p v-else class="fz-18 fw-600 text-black mr-2">{{ work_con.time }}</p>
                                </b-col>
                                <b-col xl="3" lg="3" sm="12" class="text-right">
                                  <b-button class="btn btn-theme" v-if="work_con.application_status == 7" @click="$router.push({name:'offerdeatils',path:'/client/offer-details',params:{ pid:work_con.uniq_id,uid:encodedid(work_con.freelancer_id)}}).catch(()=>{})">See Offer</b-button>
                                  <b-button class="btn btn-theme" v-else-if="work_con.application_status == 2" @click="$router.push({'name':'contractDetails','path':'/client/jobpostreview/contract-detail/','params':{pid:work_con.uniq_id,uid:encodedid(work_con.freelancer_id)}})">{{work_con.button}}</b-button>
                                  <b-button class="btn btn-theme" v-else-if="isPermissionForClient('hire-btn') && work_con.application_status == 6" @click="$router.push({name: 'hireFreelancer',  path:'/client/hire-freelancer/', params: {uid: 'A'+encodedid(work_con.freelancer_id)}}).catch(()=>{})">Rehire</b-button>
                                  
                                  <p class="fz-14 text-black fw-600 text-right mt-3" v-if="work_con.application_status==2 || work_con.application_status == 6">{{work_con.contractStart}}  {{(work_con.contractStart!='' && work_con.contractEnd!='')?'- '+work_con.contractEnd:(work_con.contractStart!='')?'- Present':''}}</p>
                                </b-col>
                              </b-row>
                            </div>
                          </template>
                          <template v-else>
                              <div class="text-center py-8 px-4" >
                                <svg width="172" height="173" viewBox="0 0 172 173" fill="none" xmlns="http://www.w3.org/2000/svg" class="m-auto">
                                  <path d="M75.4142 9.1442C81.305 5.05891 89.1128 5.05891 95.0036 9.1442V9.1442C98.7906 11.7705 103.474 12.7659 108.002 11.907V11.907C115.045 10.5709 122.178 13.7466 125.897 19.8747V19.8747C128.289 23.8143 132.162 26.6285 136.648 27.6855V27.6855C143.626 29.3296 148.85 35.1319 149.756 42.2433V42.2433C150.338 46.8149 152.732 50.9612 156.4 53.7514V53.7514C162.106 58.0914 164.519 65.5171 162.454 72.382V72.382C161.126 76.7953 161.626 81.5568 163.843 85.5976V85.5976C167.29 91.8832 166.474 99.6482 161.795 105.08V105.08C158.787 108.571 157.308 113.125 157.689 117.718V117.718C158.281 124.862 154.377 131.624 147.894 134.683V134.683C143.726 136.649 140.522 140.207 139.002 144.558V144.558C136.638 151.326 130.321 155.915 123.154 156.072V156.072C118.547 156.174 114.173 158.121 111.014 161.477V161.477C106.102 166.698 98.4646 168.321 91.8531 165.55V165.55C87.6028 163.768 82.815 163.768 78.5647 165.55V165.55C71.9532 168.321 64.3161 166.698 59.4034 161.477V161.477C56.2451 158.121 51.8713 156.174 47.2638 156.072V156.072C40.0967 155.915 33.7801 151.326 31.4156 144.558V144.558C29.8955 140.207 26.6919 136.649 22.5239 134.683V134.683C16.0405 131.624 12.1367 124.862 12.7292 117.718V117.718C13.1101 113.125 11.6307 108.571 8.62286 105.08V105.08C3.94419 99.6482 3.12806 91.8832 6.57521 85.5976V85.5976C8.79129 81.5568 9.29175 76.7953 7.96423 72.382V72.382C5.89925 65.5171 8.31199 58.0914 14.0177 53.7514V53.7514C17.6857 50.9612 20.0796 46.8149 20.6619 42.2433V42.2433C21.5677 35.1319 26.7921 29.3296 33.7698 27.6855V27.6855C38.2555 26.6285 42.1289 23.8143 44.5203 19.8747V19.8747C48.2402 13.7466 55.373 10.5709 62.4161 11.907V11.907C66.944 12.7659 71.6271 11.7705 75.4142 9.1442V9.1442Z" fill="#F2613C"/>
                                  <path d="M151.782 22.2932C152.296 22.3179 152.821 22.1463 153.232 21.7722L170.866 5.75467C171.689 5.0068 171.751 3.73304 171.003 2.90997C170.255 2.08663 168.981 2.02545 168.158 2.77334L150.524 18.7909C149.701 19.5387 149.64 20.8125 150.388 21.6356C150.761 22.0471 151.267 22.2684 151.782 22.2932Z" fill="#F2613C"/>
                                  <path d="M170.199 30.5101C169.958 29.4243 168.883 28.7393 167.796 28.9804L159.273 30.8714C158.187 31.1123 157.502 32.1878 157.743 33.2737C157.945 34.1799 158.727 34.807 159.612 34.8495C159.787 34.8579 159.966 34.8434 160.146 34.8034L168.669 32.9124C169.755 32.6717 170.44 31.596 170.199 30.5101V30.5101Z" fill="#F2613C"/>
                                  <path d="M139.33 13.1927C139.505 13.2498 139.682 13.2814 139.857 13.2898C140.742 13.3323 141.581 12.783 141.868 11.9003L144.567 3.59766C144.911 2.53991 144.332 1.40345 143.275 1.05988C142.217 0.715239 141.081 1.29458 140.737 2.35232L138.038 10.6549C137.693 11.7127 138.272 12.8489 139.33 13.1927V13.1927Z" fill="#F2613C"/>
                                  <g clip-path="url(#clip0_3226_22395)">
                                  <path d="M124.138 60.6055C122.008 60.6055 120.275 62.3384 120.275 64.4685C120.275 66.5986 122.008 68.3315 124.138 68.3315C126.268 68.3315 128.001 66.5986 128.001 64.4685C128.001 62.3384 126.268 60.6055 124.138 60.6055ZM124.138 65.8657C123.368 65.8657 122.741 65.2389 122.741 64.4685C122.741 63.698 123.368 63.0712 124.138 63.0712C124.909 63.0712 125.536 63.698 125.536 64.4685C125.536 65.2389 124.909 65.8657 124.138 65.8657Z" fill="white"/>
                                  <path d="M47.865 105.318C45.7349 105.318 44.002 107.051 44.002 109.181C44.002 111.311 45.7349 113.044 47.865 113.044C49.995 113.044 51.728 111.311 51.728 109.181C51.728 107.051 49.995 105.318 47.865 105.318ZM47.865 110.579C47.0945 110.579 46.4677 109.952 46.4677 109.181C46.4677 108.411 47.0945 107.784 47.865 107.784C48.6354 107.784 49.2622 108.411 49.2622 109.181C49.2622 109.952 48.6354 110.579 47.865 110.579Z" fill="white"/>
                                  <path d="M112.386 59.2088H114.852V56.4964H117.564V54.0307H114.852V51.3184H112.386V54.0307H109.674V56.4964H112.386V59.2088Z" fill="white"/>
                                  <path d="M63.5628 110.496H61.0971V113.208H58.3848V115.674H61.0971V118.387H63.5628V115.674H66.2752V113.208H63.5628V110.496Z" fill="white"/>
                                  <path d="M112.221 61.9217H77.4565L72.1963 54.0312H51.8926V103.839H70.2213V101.374H54.7045L61.9785 72.2778H117.3L110.026 101.374H105.728V103.839H111.951L120.458 69.8121H112.221V61.9217ZM70.8766 56.497L74.493 61.9217H54.3583V56.497H70.8766ZM60.0534 69.8121L54.3583 92.5917V64.3874H109.756V69.8121H60.0534Z" fill="white"/>
                                  <path d="M86.0015 80.332C79.5206 80.332 74.248 85.6046 74.248 92.0854C74.248 98.5663 79.5206 103.839 86.0015 103.839C92.4823 103.839 97.7549 98.5663 97.7549 92.0854C97.7549 85.6046 92.4823 80.332 86.0015 80.332ZM86.0015 101.373C80.8803 101.373 76.7138 97.2066 76.7138 92.0854C76.7138 86.9642 80.8803 82.7978 86.0015 82.7978C91.1227 82.7978 95.2891 86.9642 95.2891 92.0854C95.2891 97.2066 91.1227 101.373 86.0015 101.373Z" fill="white"/>
                                  <path d="M103.016 92.086C103.016 82.7046 95.3833 75.0723 86.002 75.0723C76.6206 75.0723 68.9883 82.7044 68.9883 92.086C68.9883 101.467 76.6206 109.1 86.002 109.1C89.2379 109.1 92.2656 108.191 94.8438 106.617L109.673 121.446L115.362 115.757L100.532 100.928C102.107 98.3495 103.016 95.3219 103.016 92.086ZM71.454 92.086C71.454 84.0642 77.9802 77.538 86.002 77.538C94.0237 77.538 100.55 84.0642 100.55 92.086C100.55 100.108 94.0237 106.634 86.002 106.634C77.9802 106.634 71.454 100.108 71.454 92.086ZM109.673 117.959L100.896 109.182L103.098 106.98L111.875 115.757L109.673 117.959ZM101.354 105.237L99.1527 107.438L96.8755 105.161C97.6744 104.495 98.4115 103.758 99.077 102.959L101.354 105.237Z" fill="white"/>
                                  <path d="M86.1827 85.596C85.1157 85.5472 84.1044 85.9251 83.3339 86.661C82.5745 87.3865 82.1387 88.4048 82.1387 89.4549H84.6044C84.6044 89.0694 84.7581 88.7106 85.0369 88.4443C85.3154 88.1783 85.6836 88.0405 86.0692 88.0593C86.7694 88.0915 87.3651 88.6872 87.3973 89.3873C87.4192 89.8618 87.2028 90.3108 86.8187 90.5887C85.5352 91.5168 84.7688 93.0595 84.7688 94.7152H87.2346C87.2346 93.8484 87.6192 93.0526 88.2636 92.5867C89.3237 91.8201 89.9208 90.5818 89.8606 89.2741C89.7699 87.3023 88.1543 85.6866 86.1827 85.596V85.596Z" fill="white"/>
                                  <path d="M84.7695 96.0312H87.2353V98.6614H84.7695V96.0312Z" fill="white"/>
                                  <path d="M57.0703 57.9766H59.7004V60.4423H57.0703V57.9766Z" fill="white"/>
                                  <path d="M62.3301 57.9766H64.9602V60.4423H62.3301V57.9766Z" fill="white"/>
                                  <path d="M67.5918 57.9766H70.2219V60.4423H67.5918V57.9766Z" fill="white"/>
                                  </g>
                                  <defs>
                                  <clipPath id="clip0_3226_22395">
                                  <rect width="84" height="84" fill="white" transform="translate(44.002 44.3828)"/>
                                  </clipPath>
                                  </defs>
                                </svg>
                                <h4 class="fz-20 fw-600 text-black">No Active Contract Found</h4>
                              </div>
                          </template>
                        </b-tab>
                      </b-tabs>
                    </b-card>
                  </b-col>
                </b-row>
              </div>
            </b-card>

            <HowToWorkWithTalent v-else></HowToWorkWithTalent>
          </div>
        </b-col>
      </b-row>
      <div v-if="DraftJobList.length == 0 && PostJobList.length == 0">
        <HowToWorkWithTalent></HowToWorkWithTalent>
      </div>

      <!-- model for delete draft -->
      <b-modal id="modal-draftdelete" title="Delete Draft" centered hide-footer no-close-on-backdrop size="md" > 
        <template>
          <div class="p-5 text-center m-auto">
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#F2613C" class="bi bi-trash m-auto" viewBox="0 0 16 16">
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
              <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
            </svg>
            <h5 class="mt-4">Are you sure, want to delete this draft?</h5>
          </div>
          <hr>
          <div>
              <div class="text-right">
                <a href="javascript:void(0);" class="text-theme mr-2" @click="closeModel(removePostType)">Cancel</a>
                <b-button type="button" class="btn btn-theme" @click="ConfirmDelete(removePostId,removePostType)">Confirm</b-button>
              </div>
          </div>
        </template>
      </b-modal>
      <!-- // model for post delete post -->
      <b-modal id="modal-postdelete" title="Delete Job Post"  size="md" centered hide-footer no-close-on-backdrop> 
        <template>
          <div class="p-5 text-center m-auto">
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#F2613C" class="bi bi-trash m-auto" viewBox="0 0 16 16">
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
              <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
            </svg>
            <h5 class="mt-4">Are you sure, Want to delete this Job Post?</h5>
          </div>
          <hr>
          <div>
              <div class="text-right">
                <a href="javascript:void(0);" class="text-theme mr-2" @click="closeModel(removePostType)">Cancel</a>
                <b-button type="button" class="btn btn-theme" @click="ConfirmDelete(removePostId,removePostType)">Confirm</b-button>
              </div>
          </div>
        </template>
      </b-modal>
    </b-container>
  </template>
  <script>
    import HowToWorkWithTalent from "../../components/HowToWorkWithTalent.vue";
    import { mapActions } from "vuex";
    export default {
      data() {
        return {
          selected: null,
          PostJobList:[],
          DraftJobList:[],
          transaction: [
            { value: null, text: 'All Transaction' },
            { value: 'a', text: 'This is First option' },
            { value: 'b', text: 'Selected Option' },
            { value: { C: '3PO' }, text: 'This is an option with object value' },
            { value: 'd', text: 'This one is disabled', disabled: true }
          ],
          postjobs:'',
          ActiveContractList:[],
          OffersentContractList:[],
          workSubmittedContractList : [],
          tabValue:'active',
          removePostId:'',
          removePostType:'',
        }
      },
      components: {
          HowToWorkWithTalent
      },
      async mounted() {

        this.isPermissionForClient('my-jobs',{path:'client/search',name:'clientSearch'});
        // this.tabledataUpdate();
        //this fun for to get posted job
        this.getPostedJob();
        //this fun for get draft job
        this.getPostedDraftJob();
        // this fun for active contract
        this.getActiveContract('2');
        // this fun for awaiting contract
        this.getAwaitingContract('7');
        // this fun for to get worked contract
        this.getWorkSubmittedContract('3');
      },
      computed:{
        // function to total burget amount
        totalBurget(){
            var amount=0;
            this.ActiveContractList.filter(user => {
                amount+=parseFloat(user.burget);
            });
            return amount;
        },
        // function to total burget amount
        totalofferSendBurget(){
            var amount=0;
            this.OffersentContractList.filter(user => {
                amount+=parseFloat(user.burget);
            });
            return amount;
        },
        // function to total burget amount
        totalworksubmitteddBurget(){
            var amount=0;
            this.workSubmittedContractList.filter(user => {
                amount+=parseFloat(user.burget);
            });
            return amount;
        },
      },
      methods: {
        //this fun for get draft job
        // async tabledataUpdate(){
        //   var res = await this.genericAPIPOSTRequest({
        //       requestUrl: "user/UpdateTableData",
        //       params: {},
        //   });  
        // },

        gotoPostJob(){
          this.$router.push({name:"post-job",path:"/client/post-job",params:{ pid:'POID'}});
        },
        ...mapActions(["genericAPIPOSTRequest"]),
        // this fun is for make job public private
        async makePrivatepublic(_i,_v,job_id){
          var updateValue;
          if (_v=='Private') {
            updateValue='Public';
            this.PostJobList[_i].preferences_job_type = updateValue;
          }else{
            updateValue='Private';
            this.PostJobList[_i].preferences_job_type = updateValue;
          }
          await this.genericAPIPOSTRequest({
            requestUrl: "updateJob",
            params: {
              "jobs_id":job_id,
              "preferences_job_type":updateValue
            },
          });
          this.$toastr.s('Job type Updated to '+updateValue);
        },
        //this fun for to get posted job
        async getPostedJob(){
          var res = await this.genericAPIPOSTRequest({
              requestUrl: "getUserPostedJob",
              params: {
                'search':{'limit':5},
                'created_by' : (this.$store.state.currentUserData.permission == null)?this.$store.state.currentUserData.created_by:this.$store.state.currentUserData.permission.user_id,
              },
          });  
          this.PostJobList = res;
        },
        //this fun for get draft job
        async getPostedDraftJob(){
          var res = await this.genericAPIPOSTRequest({
              requestUrl: "getUserPostedJob",
              params: {
              'filter':2,
              'search':{'limit':5},
              'created_by' : (this.$store.state.currentUserData.permission == null)?this.$store.state.currentUserData.created_by:this.$store.state.currentUserData.permission.user_id,
              },
          });  
          this.DraftJobList = res;
        },
        // this fun for active contract
        async getActiveContract(_v){
          var res = await this.genericAPIPOSTRequest({
              requestUrl  : "getClientContractList",
              params      : {
                "filter"      : _v,
                "user"        : this.$store.state.accountType,
                "search"      : {
                  "start_limit"     : 0,
                  "end_limit"       : 5,
                  "created_by"      : (this.$store.state.currentUserData.permission == null)?this.$store.state.currentUserData.created_by:this.$store.state.currentUserData.permission.user_id,

                }
              },
          });  
          this.ActiveContractList = res;
        },
        // this fun for awaiting contract
        async getAwaitingContract(_v){
          var res = await this.genericAPIPOSTRequest({
              requestUrl  : "getClientContractList",
              params      : {
                "filter"      : _v,
                "user"        : this.$store.state.accountType,
                "search"      : {
                  "start_limit"     : 0,
                  "end_limit"       : 5,
                  "created_by"      : (this.$store.state.currentUserData.permission == null)?this.$store.state.currentUserData.created_by:this.$store.state.currentUserData.permission.user_id,

                }
              },
          });  
          this.OffersentContractList = res;
        },
        // this fun for active contract
        async getWorkSubmittedContract(_v){
          var res = await this.genericAPIPOSTRequest({
              requestUrl    : "getClientContractList",
              params        : {
                "filter"          : '2,7',
                "user"            : this.$store.state.accountType,
                "search"          : {
                  "start_limit"       : 0,
                  "end_limit"         : 5,
                  "created_by"        : (this.$store.state.currentUserData.permission == null)?this.$store.state.currentUserData.created_by:this.$store.state.currentUserData.permission.user_id,
                  "milestone_status"  : [_v],
                }
              },
          });  
          this.workSubmittedContractList = res;
        },
        //this fun for delete posts
        async ConfirmDelete(_i,_t){
          await this.genericAPIPOSTRequest({
            requestUrl: "deletePostJob",
            params: {
              "jobData":{"jobs_id":_i}
            },
          });  
          if(_t == 'post'){
            this.$bvModal.hide('modal-postdelete');
            this.getPostedJob();
            this.$toastr.s('Please Select Budget Type');
            
          }else if(_t=='draft'){
            this.$bvModal.hide('modal-draftdelete');
            this.getPostedDraftJob();
            this.$toastr.s('Please Select Budget Type');
          }
        },
        //this fun for to close model
        closeModel(_t){
          if(_t == 'post'){
            this.$bvModal.hide('modal-postdelete');
          }else if(_t=='draft'){
            this.$bvModal.hide('modal-draftdelete');
          }
        },
        //this fun for to set delete value
        async remove(_i,_t){
          this.removePostId =_i;
          this.removePostType =_t;
        },
      }
    }
  </script>

  <style>
    .job-wrapper .dropdown-item.active, .dropdown-item:active { background-color: #ff5526 !important; }
    @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');
    body{font-family: 'Source Sans Pro', sans-serif!important;}
    .text-muted{color:#000!important;}
    .ralative {padding-bottom:0px;}
    .theme-bg{background-color: #FFF6F4!important;}
    .bg-none{background-color:transparent;}
    .border-bottom{border-bottom: 1px solid #EBEBEB!important;}
    .br-20{border-radius: 20px;}
    .border-none{border:0px!important;}
    .fz-14{font-size:14px!important;}
    .fz-16{font-size:16px!important;}
    .fz-18{font-size:18px!important;}
    .fw-600{font-weight:600!important;}
    .fw-500{font-weight:500!important;}
    .fw-400{font-weight:400!important;}
    .mb-23{margin-bottom:23px;}
    .no-bottom-border{border-bottom:0px!important;}
    .border-top-0{border-top:0px!important;}
    .theme-bg-box{background: rgba(242, 97, 60, 0.05);border-radius: 20px;padding:20px;}
    p {margin-top: 0!important;margin-bottom: 1rem!important;}
    h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {margin-top: 0!important;margin-bottom: 0.5rem!important;font-weight: 500!important;line-height: 1.2!important;}
    dl, ol, ul {margin-top: 0!important;margin-bottom: 1rem!important;}
    ol, ul {padding-left: 2rem!important;}
    .fz-30{font-size:26px!important;}

    .client-dashboard-wrap .card{background-color: transparent;border: 0px;}
    .client-dashboard-wrap .card-header{background-color: transparent;border: 0px;}
    .client-dashboard-wrap .card-header-pills{margin-left:-40px;}
    .client-dashboard-wrap .nav-pills .nav-link{border-radius: 0px;border-bottom: 1px solid #dfdfdf;border:0px;color: #333;background-color: #fff;padding: 15px 5px;font-weight: 600;font-size:18px;}
    .client-dashboard-wrap .nav-pills .nav-link.active{background-color: #fff;border-bottom: 2px solid #f2613b!important;border: 0px;color: #f2613b;}
    .client-dashboard-wrap .nav-tabs .tab-content{background-color: #fff;margin: 9px;border-radius:20px;}
    .client-dashboard-wrap .nav-pills .nav-item{background-color: #fff;}
    .client-dashboard-wrap .nav-tabs .nav-link .badge-theme{background-color:#9c9c9c;color:#fff!important;border-radius: 50%;width: 25px;height: 25px;padding: 6px 6px!important;font-size: .75em!important;line-height: 1!important;}
    .client-dashboard-wrap .nav-tabs .nav-link.active .badge-theme{background-color:#f2613b;color:#fff;border-radius: 50%;width: 25px;height: 25px;padding: 6px 6px!important;font-size: .75em!important;line-height: 1!important;}
    .client-dashboard-wrap .nav-pills .nav-link.active .badge-theme{background-color: #fff;color:#f2613b;}
    .client-dashboard-wrap .nav-tabs {border-bottom: 0;margin-left:-40px;}
    .client-dashboard-wrap .nav-tabs .nav-link{font-size: 16px;color: #999999;font-weight:500;}
    .client-dashboard-wrap .nav-tabs .nav-link.active{color: #000;background-color: transparent;border-color: #dee2e6 #dee2e6 #fff;border: 0;border-bottom: 2px solid #f2613b;font-weight: 600;}
    .client-dashboard-wrap input ~ label:before{display:none;}
    .br-20{border-radius:20px!important;}
    .br-50{border-radius:50px!important;}
    .ml-30{margin-left:8rem;}
    .pl-30{margin-left:8rem;}
    .job-detail-wrap .card{background-color:#fff;border-radius:20px!important;}
    .job-detail-wrap .card-header{background-color:#fff;padding:1.5rem;border-radius:20px 20px 0 0!important;}
    .job-detail-wrap .card-body{background-color:#fff;padding:1.5rem;border-radius:0 0 20px 20px!important;}
    .job-detail-wrap .card-body a{color:#f2613b!important;}
    .pos-absolute{position:absolute;}
    .r-2rem{right:2rem;}
    .client-dashboard-wrap .posted-job-wrapper h4{
      text-overflow: ellipsis;
      overflow: hidden;
      align-items: baseline;
      /*max-width: 265px;*/
    }
    .client-dashboard-wrap .btn-group{margin-top:0px!important;}
  </style>
