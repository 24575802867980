<template>
  <div id="app" class="app-wrap" style="float: left; width: 100%; position: relative;" >
    <!-- Site Header  -->
    <div>
      <MainHeader v-if="$route.path!='/demo/' && $route.path!='/demo' && $route.name != 'ResetPassword'"/>
    </div>

    <!-- Main Content -->
    <div class="relative" style="height: auto; width: 100%">
      <transition name="fade">
        <router-view />
      </transition>
    </div>

    <!-- Site Footer  -->
    <div>
      <the-site-footer v-if="$route.path!='/news-feed/' && $route.path!='/demo/' && $route.path!='/demo'&& $route.name != 'ResetPassword'"/>
    </div>
  </div>
</template>
<script>
// var hideStripe = true;
</script>
<!-- <script src="https://js.stripe.com/v3/" v-if="hideStripe"></script> -->

<script>
import MainHeader from './components/header/MainHeader.vue'
import TheSiteFooter from './components/TheSiteFooter.vue'
export default {
  components: {
    MainHeader,
    TheSiteFooter,
  },
  data() {
    return {
      
    }
  },

  mounted() {
    this.$store.dispatch("currentTimeZone",Intl.DateTimeFormat().resolvedOptions().timeZone);
  },

  methods: {
    
  },
}
</script>

<style scoped lang="css">
@import './assets/css/global.css';
@import './assets/css/demo.css';

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');
.body {
  background-color: #f2f2f2;
  font-family: 'Source Sans Pro', sans-serif !important;
}
.fade-enter {
  opacity: 0.5;
  transition: 0.9s all ease;
}

.fade-enter-to {
  opacity: 1;
  transition: 0.9s all ease;
}

.fade-leave-to {
  opacity: 0;
  transition: 0.5s all ease-out;
}
</style>
