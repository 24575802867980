<template>
    <div>
        <b-container>
            <b-row>
                <b-col offset-xl="2" xl="8" offset-lg="2" lg="8" sm="12" v-if="displayType == 'resquest-form'">
                    <div class="dispute-wrapper mt-100">
                        <div class="content mt-5 mb-50">
                            <div class="d-flex align-items-center mb-2">
                                <div class="image-holder mr-3">
                                    <img src="/web/dispute/balance.png" class="img-fluid">
                                </div>
                                <div class="content-holder">
                                    <p class="text-muted fz-14 mb-0 fw-600">Dispute #{{encodedid(disputedData.job_dispute_id)+disputedData.job_dispute_id}}</p>
                                </div>
                            </div>
                            <h1 class="fw-600 text-black">Respond to a payment dispute</h1>
                            <h6 class="fz-14 fw-600 text-black mb-4">{{shortName(userDetails.first_name,userDetails.last_name)}} submitted a dispute because you ended the contract and paid less than the total funded amount. They requested ${{disputedData.requested_amount}} to end the dispute. If you don't respond to the dispute before midnight UTC on {{proposalData.disputeExpireDate_mdy}}, ${{disputedData.requested_amount}} from your escrow funds will be released to {{shortName(userDetails.first_name,userDetails.last_name)}}.</h6>
                            <div class="bg-light mb-3 p-3">
                                <i class="fa fa-clock-o pr-3" aria-hidden="true"></i> You have 4 days, 23 hours, 55 minutesleft to accept or decline the dispute.
                            </div>
                            <b-card class="mb-4">
                                <div class="p-3">
                                    <h4 class="fw-600 text-black">{{jobDetails.job_title}}</h4>
                                    <div class="d-flex align-items-center mb-2">
                                        <img src="/web/dispute/commitment.png" class="img-fluid mr-2 w-10 h-10 shadow br-50">
                                        <h6 class="fz-14 fw-600 text-black mb-0">
                                            {{shortName(userDetails.first_name,userDetails.last_name)}} disputed the unpaid amount for this contract on March 8, 2024.
                                        </h6>
                                    </div>
                                    <div class="table-responsive">
                                        <table class="w-100">
                                            <tbody class="no-border">
                                                <tr>
                                                    <td class="p-1 fw-600 fz-14">Total contract amount funded in escrow</td>
                                                    <td class="p-1 fw-600 fz-14 text-right">${{totalescrowAmount}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="p-1 fw-600 fz-14">Paid amount</td>
                                                    <td class="p-1 fw-600 fz-14 text-right">${{ReleaseFund}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <hr>
                                    <h4 class="fw-600 fz-18">Dispute details</h4>
                                    <div class="table-responsive">
                                        <table class="w-100">
                                            <tbody class="no-border">
                                                <tr>
                                                    <td class="p-1 fw-600 fz-14">Unpaid amount remaining in escrow</td>
                                                    <td class="p-1 fw-600 fz-14 text-right">${{escrowAmount}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="p-1 fw-600 fz-14" colspan="2">Why {{shortName(userDetails.first_name,userDetails.last_name)}} disputed</td>
                                                </tr>
                                                <tr>
                                                    <td class="p-1 fw-600 fz-14 text-muted" colspan="2" valign="middle">
                                                        <i class="fa fa-circle fz-8" aria-hidden="true"></i> {{disputedData.reason}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="p-1 fw-600">Payment requested</td>
                                                    <td class="p-1 fw-600 text-right text-theme fz-20 fw-600">${{disputedData.requested_amount}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </b-card>
                            <h4 class="fw-600 fz-18">Approve the payment dispute?</h4>
                            <b-form-group class="mb-3">
                                <b-form-radio v-model="disputeRespond"  value="approve" class="dispute-custom-radio">
                                    Yes, pay {{shortName(userDetails.first_name,userDetails.last_name)}} ${{disputedData.requested_amount}} more from escrow funds.
                                </b-form-radio>
                                <span class="fz-12 text-muted ml-5 mb-3 d-block">This will end the dispute now.</span>
                                <b-form-radio v-model="disputeRespond"  value="decline" class="dispute-custom-radio">
                                    No, decline the payment dispute.
                                </b-form-radio>
                                <span class="fz-12 text-muted ml-5 mb-3 d-block">I want Qapin Dispute Assistancefor a chance to get a refund of the remaining funds in escrow.</span>
                                <p class="error" v-if="showError && disputeRespond == ''">Please select option</p>
                            </b-form-group>
                            <div class="d-flex align-items-center">
                                <b-button class="btn btn-outline-theme mr-3" v-on:click="$router.push({'name':'contractDetails','path':'/client/jobpostreview/contract-detail/','params': {'pid': $route.params.pid,'uid': encodedid(user_id)}}).catch(()=>{})">Not now</b-button>
                                <b-button class="btn btn-theme" v-on:click="submitRespond" >Respond</b-button>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col offset-xl="2" xl="8" offset-lg="2" lg="8" sm="12" v-else-if="displayType == 'approveRequest'">
                    <div class="dispute-wrapper">
                        <div class="svg-container mt-100 margin-auto text-center">
                            <img src="/web/dispute/commitment.png" class="img-fluid m-auto">
                        </div>
                        <div class="content mt-5 mb-50">
                            <div class="d-flex align-items-center mb-2">
                                <div class="image-holder mr-3">
                                    <img src="/web/dispute/balance.png" class="img-fluid">
                                </div>
                                <div class="content-holder">
                                    <p class="text-muted fz-14 mb-0 fw-600">Dispute #{{encodedid(disputedData.job_dispute_id)+disputedData.job_dispute_id}}</p>
                                </div>
                            </div>
                            <h1 class="fw-600 text-black">Dispute resolved on {{dateHelper("M d, Y")}}</h1>
                            <h6 class="fz-14 fw-600 text-black">You approved the payment request from {{shortName(userDetails.first_name,userDetails.last_name)}}.</h6>
                            <h6 class="fz-14 fw-600 text-black mb-4">Although the contract didn't work out as expected, we hope you both found our dispute process simple enough and continue to choose our global marketplace for contracts. </h6>
                            <b-card class="mb-4">
                                <div class="p-3">
                                    <h4 class="fw-600 text-black">{{jobDetails.job_title}}</h4>
                                    <hr>
                                    <div class="table-responsive">
                                        <table class="w-100">
                                            <tbody class="no-border">
                                                <tr>
                                                    <td class="p-1 fw-600">Additional payment to {{shortName(userDetails.first_name,userDetails.last_name)}}</td>
                                                    <td class="p-1 fw-600 text-right">${{disputedData.requested_amount}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="p-1 fw-600">Escrow refund to you</td>
                                                    <td class="p-1 fw-600 text-right">${{ escrowAmount - disputedData.requested_amount}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </b-card>
                            <!-- <b-button class="btn btn-theme">View transaction sumary</b-button> -->
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script type="module">
var $_;
    export default {
        data() {
            return {  
                displayType     : 'resquest-form',
                userDetails     : '',
                job_id          : '',
                user_id         : '',
                jobDetails      : '',
                proposalData    : '',
                milestoneData   : '',
                disputedData    : '',
                disputeRespond  : '',
                showError       : false,
            };
        },
        created(){
            $_ = this;
        },
        computed: {            
            // this fun is for to calculate the released amount
            ReleaseFund() {
                var amount = 0;
                if ($_.milestoneData.length > 0) {
                    $_.milestoneData.map(data => {
                        if (data.paymentStatus == 1 && data.milestone_status == 1) {
                            amount += parseFloat(data.release_amount);
                        }
                    });
                }
                return amount.toFixed(2);
            },
            // this fun is for to calculate escrow amount
            escrowAmount() {
                var amount = parseFloat(0);
                if ($_.milestoneData.length > 0) {
                    $_.milestoneData.map(data => {
                        if (data.paymentStatus == 1 && data.milestone_status == 0) {
                            amount += parseFloat(data.amount);
                        }else if(data.paymentStatus == 1 && data.milestone_status == 1){
                            if(parseFloat(data.amount) > parseFloat(data.release_amount) && parseFloat(data.refund_amount) == 0){
                                amount += (parseFloat(data.amount) - parseFloat(data.release_amount))
                            }
                        }
                    });
                }
                return amount.toFixed(2);
            },
            // this fun is for to calculate escrow amount
            totalescrowAmount() {
                var amount = parseFloat(0);
                if ($_.milestoneData.length > 0) {
                    $_.milestoneData.map(data => {
                        if (data.paymentStatus == 1) {
                            amount += parseFloat(data.amount);
                        }
                    });
                }
                return amount.toFixed(2);
            },
        },
        mounted() {
            $_.job_id     = $_.$route.params.pid;
            $_.user_id    = $_.decodeId($_.$route.params.uid);
            $_.getUserDetails();
        },
        methods:{
            // this fun is for to submit responde
            async submitRespond(){
                if($_.disputeRespond != ''){
                    if($_.disputeRespond == 'approve'){
                        var response = await $_.getApiData("updateDisputeData",{"job_application_id":$_.proposalData.job_application_id,"job_dispute_id":$_.disputedData.job_dispute_id,"status":1,"release_amount":$_.disputedData.requested_amount});
                        if(response == 1){
                            $_.releaseRequestedAmount();
                        }else{
                            $_.$toastr.e('approve request unsuccessfull');
                        }
                    }else if($_.disputeRespond == 'decline'){
                        var res = await $_.getApiData("updateDisputeData",{"job_application_id":$_.proposalData.job_application_id,"job_dispute_id":$_.disputedData.job_dispute_id,"status":2});
                        if(res){
                            $_.$toastr.s('Request decline successfull');
                            $_.$router.push({'name':'contractDetails','path':'/client/jobpostreview/contract-detail/','params': {'pid': $_.$route.params.pid,'uid': $_.encodedid($_.user_id)}}).catch(()=>{});
                        }else{
                            $_.$toastr.e('Decline request unsuccessfull');
                        }
                    }
                }else{
                    $_.showError = true;
                }
            },
            // this fun is for to get get user details
            async getUserDetails(){
                var userDetails = await $_.getApiData("user/getUserDetails",{ "user_id": 'ASS'+$_.encodedid($_.user_id)});
                if (userDetails) {
                    $_.userDetails = userDetails;
                    $_.getJobDetails();
                }else{
                    $_.$router.push({'name':'contractDetails','path':'/client/jobpostreview/contract-detail/','params': {'pid': $_.$route.params.pid,'uid': $_.encodedid($_.user_id)}}).catch(()=>{});
                }
            },
            // this fun is for to get job details
            async getJobDetails(){
                var GetJobDetails = await $_.getApiData("getJobDetails_new",{'poid':$_.job_id,'user_id': $_.$store.state.currentUserData.login_master_id,'data': $_.getCurrentUserIdRequest,});
                if(GetJobDetails){
                    $_.jobDetails = GetJobDetails[0];
                    $_.getproposalData();
                }
            },
            // this fun is for to get proposal details
            async getproposalData(){
                var response = await $_.getApiData("getCreatedMilestone",{"job_id":$_.jobDetails.jobs_id, "user_id":$_.user_id});
                if(response.proposal && response.proposal.application_status == 6){
                    $_.proposalData = response.proposal;
                    $_.milestoneData = response.milestone;
                    if($_.proposalData.refund_request_status == 3 && $_.dateHelper("M d, Y",'',$_.proposalData.disputeExpireDate_mdy) >= $_.dateHelper("M d, Y")){
                        $_.getDisputedDatas();
                    }else{
                        $_.$router.push({'name':'contractDetails','path':'/client/jobpostreview/contract-detail/','params': {'pid': $_.$route.params.pid,'uid': $_.encodedid($_.user_id)}}).catch(()=>{});
                    }
                }else{
                    $_.$router.push({'name':'contractDetails','path':'/client/jobpostreview/contract-detail/','params': {'pid': $_.$route.params.pid,'uid': $_.encodedid($_.user_id)}}).catch(()=>{});
                }
            },
            // this fun is for to get disputed data
            async getDisputedDatas(){
                var response = await this.getApiData('getDisputeData',{'job_application_id':$_.proposalData.job_application_id});
                if(response != '' && response.status == 0){
                    this.disputedData = response;
                }else{
                    $_.$router.push({'name':'contractDetails','path':'/client/jobpostreview/contract-detail/','params': {'pid': $_.$route.params.pid,'uid': $_.encodedid($_.user_id)}}).catch(()=>{});
                }
            },
            // this fun is for to release partial amount 
            async releaseRequestedAmount(){
                var response = await $_.genericAPIPOSTRequest({
                    requestUrl  : 'releasePartialAmount',
                    params      : {
                    'jobs_id'                   : $_.jobDetails.jobs_id,
                    'user_id'                   : $_.user_id,
                    'job_application_id'        : $_.proposalData.job_application_id,
                    'refund_requested_amount'   : $_.disputedData.requested_amount,
                    },
                });
                if(response == 1){
                    this.refundEscrow();
                }
            },
            // this fun is for to approve request
            async refundEscrow(){
                var response = await $_.genericAPIPOSTRequest({
                    requestUrl  : "approveRefundRequest",
                    params      : {
                        'job_application_id'        : $_.proposalData.job_application_id,
                        'refund_requested_amount'   : $_.escrowAmount - $_.disputedData.requested_amount,
                        'status'                    : 4,
                    },
                });
                if(response){
                    $_.$toastr.s('Escrow refund Request Successfully')
                    $_.displayType = 'approveRequest';
                    // $_.$router.push({'name':'contractDetails','path':'/client/jobpostreview/contract-detail/','params': {'pid': $_.$route.params.pid,'uid': $_.encodedid($_.user_id)}}).catch(()=>{});
                }else{
                    $_.$toastr.e('Escrow refund Request UnSuccessfully')
                }
            },
        },
    };
</script>