<template>
    <div>
        <section class="bg-half-170 bg-light d-table w-100 d-print-none mt-88 mt-sm-73 home-a">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12 text-center">
                        <div class="pages-heading">
                            <h4 class="fz-24 fw-600 mb-4"> Frequently Asked Questions </h4>
                        </div>
                    </div>
                </div>
                <div class="position-breadcrumb d-flex align-items-center justify-content-center">
                    <nav aria-label="breadcrumb" class="d-inline-block">
                        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                            <li class="breadcrumb-item">
                                <a class="text-theme" routerlink="/index" ng-reflect-router-link="/index" href="/index">Qapin</a>
                            </li>
                            <li aria-current="page" class="breadcrumb-item active">Faqs</li>
                        </ul>
                    </nav>
                </div>
            </div>
        </section>
        <section>
            <b-row>
                <b-col xl="12" lg="12" sm="12" class='bg-white'>
                    <b-container>
                        <div class="w-full mx-auto xl:mx-auto xl:max-w-7xl mb-5 pt-5">
                            <div class="faq-container">
                                <div v-for="(faq, index) in faqs" :key="index" class="faq-item">
                                    <div class="question d-flex align-items-center" @click="toggle(index)">
                                        <span>{{ faq.question }}</span>
                                        <div class="ml-auto">
                                            <span class="toggle-icon">{{ faq.isOpen ? '-' : '+' }}</span>
                                        </div>
                                    </div>
                                    <template v-if="faq.isOpen">
                                    <div class="answer"  v-html="faq.answer">
                                    </div>
                                </template>
                                </div>
                            </div>
                        </div>
                    </b-container>
                </b-col>
            </b-row>
        </section>
    </div>
</template>

<script>
export default {
    components: {

        //  TheHomeSiteHeader
    },
    data() {
        return {
            faqs: [
                { question: "How does Qapin work?", answer: 'As a cutting-edge freelance platform in the field inspection services industry, Qapin aims to simplify the process of connecting top-quality freelancers with agencies in need of their services. With our user-friendly platform, businesses can browse and select from verified source engineers based on their unique qualifications, expertise, location, and availability on demand. We also ensure seamless coordination between Source engineers and clients through our efficient communication tools and <a href="/safe-payments" class="text-theme">secure payment</a> system.', isOpen: false },
                { question: "How can I find a Source Inspector ? How does the search work?", answer: "The built-in site filter may seem simple and easy, but definitely has its more powerful sides too.The basics are really simple You can search for names, companies, job titles and skills in the search. If you'd like your freelancer to provide specific services, you can request them You can filter by price and category too.", isOpen: false },
                { question: "What fees does Qapin take?", answer: "Qapin takes a platform fee on each payment processed through the site. As a freelancer, you should have a clear expectation of what you'll earn at the end of the day. Our current fee rate is 5% on top of what you already charge.", isOpen: false },
                { question: "How can i Get Paid after completion of work with my client", answer: "To make it easy for you to access your money, we offer a number of choices for withdrawing your funds. You can withdraw directly to your bank account with the following payment methods International wire transfer - USD. Direct to US Banks (ACH) for US service providers. We are working other payment methods and will be notified you after implementation.", isOpen: false },
                // Add more FAQ items here
            ]
        };
    },
    methods: {
        toggle(index) {
            this.faqs[index].isOpen = !this.faqs[index].isOpen;
        }
    },
    mounted() {
        
    },
}
</script>
<style>
    .faq-container {
        width: 100%;
    }

    .faq-item {
        margin-bottom: 15px;
    }

    .question {
        cursor: pointer;
        padding: 15px;
        border: 1px solid #f0624430;
        background-color: #f0624414;
        color: #000;
    }

    .toggle-icon {
        margin-left: 5px;
    }

    .answer {
        margin-top: 5px;
        padding: 20px;
        border-top: 0;
        border: 1px solid #dbdbdb;
        color: #333;
    }
    .bg-half-170 {
        padding: 70px 0;
        background-size: cover;
        -ms-flex-item-align: center;
        align-self: center;
        position: relative;
        background-position: center bottom; 
        background-image:url(/web/Web-images/faq-banner.png);
    }
    .bg-light {
        background-color: #f8f9fa!important;
    }
</style>

