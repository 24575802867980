import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import guest from './middleware/guest'
import middlewarePipeline from './middlewarePipeline'
// Landing home page
import HomePage from "../views/landing-home/Index.vue";
// Middleware route
// Auth Login & Regi stration and Forgot password
import Login from "../components/Login.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import BasicInfo from "../views/sign-up/BasicInfo.vue";
import updateUserprofile from "../views/sign-up/UpdateUserProfile.vue"; 
// import AboutProfile from "../views/sign-up/AboutProfile";
import ResetLink from "../views/ResetLink.vue";
import ResetPassword from "../views/ResetPassword.vue";
import PrivacyPolicy from "../views/landing-home/privacy-policy.vue";
import TermsOfService from "../views/landing-home/terms-of-service.vue";
import ContactUs from "../views/landing-home/contact-us";
import About from "../views/landing-home/About.vue";
import AgencySolutions from "../views/landing-home/AgencySolutions.vue";
import HowQapinWork from "../views/landing-home/HowQapinWork.vue";
import GlobalExpansion from "../views/landing-home/GlobalExpansion.vue";
import WhyQapin from "../views/landing-home/WhyQapin.vue";
import SafePayments from "../views/landing-home/SafePayments.vue";
import Certifications from "../views/landing-home/Certifications.vue";
import Pricing from "../views/landing-home/Pricing.vue";
import Faqs from "../views/landing-home/Faqs.vue";

// View Pages
import InvitationAccept from "../views/InvitationAccept.vue";
import Verifyemail from "../views/Verifyemail.vue";
import NewsFeed from "../views/news-feed/NewsFeed.vue";
import Freelancer from "../views/freelancer/Index.vue";
import Chat from "../views/chat/index.vue";
import allContracts from "../views/freelancer/allContracts.vue";
import myJobs from "../views/freelancer/myJobs.vue";
import endContract from "../views/freelancer/endContract.vue";
import jobDetails from "../views/freelancer/jobDetails.vue";
import submitProposal from "../views/freelancer/submitProposal.vue";
import submitProposalslist from "../views/freelancer/submitProposalslist.vue";
import activeProposalslist from "../views/freelancer/activeProposalslist.vue";
import invitationInterviewlist from "../views/freelancer/invitationInterviewlist.vue";
import invitationDetail from "../views/freelancer/invitationDetails.vue";
import proposalDetails from "../views/freelancer/proposalDetails.vue";
import myProposals from "../views/freelancer/myProposals.vue";
import Offer from "../views/freelancer/Offer.vue";
import Profile from "../views/freelancer/Profile.vue"; 
import Agencyprofile from "../views/agency/agency-profile.vue";
import Settings from "../views/freelancer/Settings.vue";
import ClientSettings from "../views/client/Settings.vue";
import Search from "../views/freelancer/Search.vue";
import clientSearch from "../views/client/search.vue";
import clientNew from "../views/client/clientNew.vue"; 
import ContractDetails from "../views/client/ContractDetail.vue"; 
import freelanceNew from "../views/freelancer/freelanceNew.vue";
import agencyNew from "../views/freelancer/agencyNew.vue";
import saveJobs from "../views/freelancer/saveJobs.vue";
import savedUser from "../views/client/saved-user.vue";
import ContractDetail from "../views/freelancer/ContractDetail.vue"; 
import addWiretransfer from "../views/freelancer/addWiretransfer.vue"; 
import postJob from "../views/client/postJob.vue";
import clientDashboard from "../views/client/clientDashboard.vue";
import postReview from "../views/client/postReview.vue";
import postJobInvite from "../views/client/postJob-invite.vue";
import jobPosting from "../views/client/jobPosting.vue";
import draftPosting from "../views/client/draftPosting.vue";
import ClientContracts from "../views/client/allContract.vue";
import index from "../views/client/jobpostreview/index.vue";
import hireDetails from "../views/client/jobpostreview/hireDetails.vue";
import hireFreelancer from "../views/client/hireFreelancer.vue";
import offerDetails from "../views/client/jobpostreview/offerDetails.vue";
import jobpostjobDetails from "../views/client/jobpostreview/JobDetails.vue";
import messageDetails from "../views/client/jobpostreview/MessageFullDetails.vue";
import ViewProfile from "../views/client/jobpostreview/ViewProfile.vue";
import freelancerProfile from "../views/client/UserProfile.vue";
import agencyPublicProfile from "../views/agency/agency-public-Profile.vue"; 
import ClientendContract from "../views/client/endContract.vue";
import jobsDetail from "../views/client/jobsDetail.vue";
import freelancerDetails from "../views/client/freelancerDetails.vue";
import clientTalent from "../views/client/clientTalent.vue";
import agencyRoster from "../views/agency/agency-roaster.vue";
import transactionHistory from "../views/client/transactionHistory.vue"
import escrowData from "../views/client/escrowHIstory.vue"
import freelancerTransactionHistory from "../views/freelancer/transactionHistory.vue"
import agencyTransactionHistory from "../views/agency/transactionHistory.vue"
import agencyOverview from "../views/agency/overView.vue"
import freelancerOverview from "../views/freelancer/overView.vue"
import JobpostPublic from "../components/public/ViewJobPost.vue"
import refundRequest from "../views/freelancer/refundRequest.vue"
import raiseDispute from "../views/freelancer/raiseDispute.vue"
import disputeRespond from "../views/client/disputeRespond.vue";
import Globalhrservices from "../views/landing-home/Globalhrservices.vue"
// Demo page
import Demopage from "../views/demo/demo.vue"
import Enterprisepage from "../views/enterprise/enterprise.vue"
import UsaBankTransfer from "../views/freelancer/UsaBankTransfer.vue"

// import { ValidationProvider } from 'vee-validate';
Vue.use(VueRouter);
// Vue.component('ValidationProvider', ValidationProvider);
const routes = [
  // default route
  { 
    path: "*",
    component: HomePage,
    meta: { 
      title: 'The #1 Marketplace for Inspection and Engineering services - Qapin',
      metaTags: [
        { name: 'description', content: 'Discover skilled freelancers ready for your projects. Elevate your work with expertise. Hire freelancers to meet your unique needs and exceed expectations.' },
        { name: 'keywords', content: 'Hire freelancers, Inspection Services, Quality inspection services' }
      ] 
    }
  },
  {
    path: "/",
    name: "home",
    component: HomePage,
    meta: { 
      title: 'The #1 Marketplace for Inspection and Engineering services - Qapin',
      metaTags: [
        { name: 'description', content: 'Discover skilled freelancers ready for your projects. Elevate your work with expertise. Hire freelancers to meet your unique needs and exceed expectations.' },
        { name: 'keywords', content: 'Hire freelancers, Inspection Services, Quality inspection services' }
      ] 
    }
  },
  {
    path: "/home/",
    name: "home/",
    component: HomePage,
    meta: { 
      title: 'The #1 Marketplace for Inspection and Engineering services - Qapin',
      metaTags: [
        { name: 'description', content: 'Discover skilled freelancers ready for your projects. Elevate your work with expertise. Hire freelancers to meet your unique needs and exceed expectations.' },
        { name: 'keywords', content: 'Hire freelancers, Inspection Services, Quality inspection services' }
      ] 
    }
  },
  {
    path: "/demo",
    name: "demoPage",
    component: Demopage,
    meta: { 
      title: 'Demo|Expert Solutions by Global Technical Staffing services ',
      metaTags: [
        { name: 'description', content: 'Unlock expert solutions with our global technical staffing services. Connect with skilled professionals worldwide to meet your project needs. Contact us today.' },
        { name: 'keywords', content: 'Global Technical staffing services' }
      ] 
    }
  },
  {
    path: "/enterprise",
    name: "enterprisepage",
    component: Enterprisepage,
    meta: { 
      title: 'Ensuring Quality Assurance by Inspection agencies in China',
      metaTags: [
        { name: 'description', content: 'Trust inspection agencies in China for top-notch quality assurance. With expertise and reliability, we ensure your products meet the highest standards.' },
        { name: 'keywords', content: 'Inspection agencies in China' }
      ] 
    }
  },
  {
    path: "/invitation-accepted",
    name: "/InvitationAccept",
    component: InvitationAccept,
    meta: { 
      title: 'Invitation-accepted | Verified -Source Inspectors in USA ',
      metaTags: [
        { name: 'description', content: 'Ensure quality with source inspectors in USA. Trusted expertise in verification to meet industry standards. Contact us for reliable quality assurance solutions.' },
        { name: 'keywords', content: 'Source Inspectors in USA' }
      ] 
    }
  },
  {
    path: "/verify-email/:pid",
    name: "/Verifyemail",
    component: Verifyemail,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: { 
      title: 'Forgot password | Flexible Opportunities by Freelance Jobs',
      metaTags: [
        { name: 'description', content: 'Discover flexible opportunities with freelance jobs. Explore diverse roles across industries and take control of your work-life balance. Start your next project.' },
        { name: 'keywords', content: 'Freelance Jobs' }
      ],
      middleware: [
        guest
      ] 
    }
  },
  {
    path: "/expire-link",
    name: "ExpireLink",
    component: ResetLink,
    meta: { 
      title: 'Expire link |  QA Services by Indian Inspection Agencies',
      metaTags: [
        { name: 'description', content: 'Trust Indian inspection agencies for top-notch quality assurance. With expertise and reliability, we ensure your products meet the highest standards.' },
        { name: 'keywords', content: 'Inspection agencies in India' }
      ],
      }
  },
  {
    path: "/reset-password/:pid",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { 
      title: 'Qapin | Reset password',
      middleware: [
        guest
      ]
    },
  },
  {
    path: "/sign-up",
    name: "BasicInfo",
    component: BasicInfo,
    meta: { 
      title: 'Signup | Freelancer Inspectors in China for Quality Assurance',
      metaTags: [
        { name: 'description', content: "Find freelancer inspectors in China for quality assurance. Trusted expertise ensures top-notch standards. Connect with skilled professionals for peace of mind." },
        { name: 'keywords', content: 'Freelancer inspectors in China' }
      ],
      middleware: [
        guest
      ] 
    }
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: { 
      title: 'About | Find Talent with Engineering Staffing Solutions',
      metaTags: [
        { name: 'description', content: "Discover talent with our Engineering  staffing solutions. Streamlined hiring process and personalized service to meet your company's needs. Get started today." },
        { name: 'keywords', content: 'Engineering Staffing Solutions' }
      ] 
    }
  },
  {
    path: "/faqs",
    name: "Faqs",
    component: Faqs,
    meta: { 
      title: ' Faqs | Expert Solutions by Piping Design Freelancers',
      metaTags: [
        { name: 'description', content: "Find expert solutions for  projects with our skilled piping design freelancers. Get high-quality results tailored to your specific needs. Hire freelancers today." },
        { name: 'keywords', content: 'Piping design freelancers' }
      ] 
    }
  },
  {
    path: "/agency-solutions",
    name: "AgencySolutions",
    component: AgencySolutions,
    meta: { 
      title: 'Agency-solutions |Hire Skilled Freelancers for Work - Qapin',
      metaTags: [
        { name: 'description', content: 'Discover skilled freelancers ready for your projects. Elevate your work with expertise. Hire freelancers to meet your unique needs and exceed expectations.' },
        { name: 'keywords', content: 'Hire freelancers' }
      ] 
    }
  },
  {
    path: "/how-it-work",
    name: "HowQapinWork",
    component: HowQapinWork,
    meta: { 
      title: 'Efficient Solution for Companies to Hire Globally',
      metaTags: [
        { name: 'description', content: 'Empower your business with our comprehensive Global HR solutions. Transform workforce management on a global scale for sustained success and growth.' },
        { name: 'keywords', content: 'Global HR services' }
      ] 
    }
  },
  {
    path: "/global-expansion",
    name: "GlobalExpansion",
    component: GlobalExpansion,
    meta: { 
      title: 'Global Expansion | Talent acquire by Global Hiring Services',
      metaTags: [
        { name: 'description', content: 'Unlock talent acquisition with our global hiring services. Connect with skilled professionals worldwide to meet your staffing needs. Get started today.' },
        { name: 'keywords', content: 'Global Hiring services' }
      ] 
    }
  },
  {
    path: "/safe-payments",
    name: "SafePayments",
    component: SafePayments,
    meta: { 
      title: 'Safe-payments | Hire Contractors: Get the Job Done',
      metaTags: [
        { name: 'description', content: 'Find skilled contractors for your projects. From construction to renovations, deliver quality results. Hire contractors to get the job done efficiently.' },
        { name: 'keywords', content: 'Hire contractors' }
      ] 
    }
  },
  {
    path: "/why-qapin",
    name: " WhyQapin",
    component: WhyQapin,
    meta: { 
      title: 'why-qapin | Quality Inspections services for assured result',
      metaTags: [
        { name: 'description', content: 'Experience precision in quality inspections for assured results. Our services guarantee assurance, ensuring excellence in every aspect of your project.' },
        { name: 'keywords', content: 'Quality inspection services' }
      ] 
    }
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: { 
      title: 'Privacy policy | Quality Verification by Source Inspectors',
      metaTags: [
        { name: 'description', content: 'Ensure quality with our source inspectors. Trusted expertise in verification to meet your industry standards. Contact us for reliable quality assurance.' },
        { name: 'keywords', content: 'Source Inspectors' }
      ] 
    }
  },
  {
    path: "/terms-of-service",
    name: "TermsOfService",
    component: TermsOfService,
    meta: { 
      title: 'Terms of service | Hire Inspectors for Quality Assurance',
      metaTags: [
        { name: 'description', content: 'Ensure quality with our professional inspectors. Trusted expertise in quality assurance to meet your industry standards Hire inspectors today for peace of mind.' },
        { name: 'keywords', content: 'Hire inspectors' }
      ] 
    }
  },
  {
    path: "/certifications",
    name: "Certifications",
    component: Certifications,
    meta: { 
      title: 'Certifications List | Quality Certified Inspection Services',
      metaTags: [
        { name: 'description', content: 'Ensure top-notch quality with our certified inspection services. Trusted by industries worldwide for thorough and reliable assessments. Contact us today.' },
        { name: 'keywords', content: 'Certified inspection services' }
      ] 
    }
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: Pricing,
    meta: { 
      title: 'Pricing | Global Payroll Solutions Streamline Your Payments',
      metaTags: [
        { name: 'description', content: 'Optimize payroll processes with our Global Payroll services. Streamline payments across borders and ensure compliance with local regulations. Get started today.' },
        { name: 'keywords', content: 'Global Payroll services' }
      ] 
    }
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: ContactUs,
    meta: { 
      title: 'Contact us | Find Talent Worldwide for Oil & Gas Recruitment',
      metaTags: [
        { name: 'description', content: 'Discover top talent worldwide for oil & gas recruitment. Our services connect you with skilled professionals to meet your industry needs. Get started today.' },
        { name: 'keywords', content: 'Oil & Gas recruitment services' }
      ] 
    }
  },
  {
    path: "/job/public-view/:pid/:uid",
    name: "JobpostPublic",
    component: JobpostPublic,
    meta: { 
      title: 'Public view | Remote Piping Designers: Expert Solutions',
      metaTags: [
        { name: 'description', content: 'Access expert solutions by Remote piping designers. Skilled designers ensure quality results to your project needs. Hire remote designers for efficiency.' },
        { name: 'keywords', content: 'Remote piping designers' }
      ] 
    }
  },
  {
    path: "/client/post-job/:pid",
    name: "post-job",
    component: postJob,
    // props: true,
    meta: { 
      title: 'Qapin | Post a job',
      metaTags: [
        { name: 'description', content: 'Discover top talent worldwide for oil & gas recruitment. Our services connect you with skilled professionals to meet your industry needs. Get started today.' },
        { name: 'keywords', content: 'Oil & Gas recruitment services' }
      ],
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
  },
  {

    path: "/global-hr-services",
    name: "Globalhrservices",
    component: Globalhrservices,
    meta: { 
      title: 'Qapin Global HR Services',
      metaTags: [
        { name: 'description', content: '' },
        { name: 'keywords', content: '' }
      ] 
    }
  },
  {
    path: "/client",
    name: "client",
    component: clientDashboard,
    meta: { 
      title: 'Qapin | Client dashboard',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
  },
  {
    path: "/client/dashboard",
    name: "clientDashboard",
    component: clientDashboard,
    meta: { 
      title: 'Qapin | Client dashboard',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
  },
  {
    path: "/client/post-review/:pid",
    name: "postReview",
    component: postReview,
    meta: { 
      title: 'Qapin | Review post',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
  },
  {
    path: "/client/post-job-invite/:uid",
    name: "postJobInvite",
    component: postJobInvite,
    meta: { 
      title: 'Qapin | Post job invite',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
  },
  {
    path: "/client/job-posting/:pid",
    name: "jobPosting",
    component: jobPosting,
    meta: { 
      title: 'Qapin | Post job list',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
  },
  {
    path: "/chat",
    name: "chat",
    component: Chat,
    meta: { 
      title: 'Qapin | Chat',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
  },
  {
    path: "/client/draft-posting",
    name: "draftPosting",
    component: draftPosting,
    meta: { 
      title: 'Qapin | Draft job list',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
  },
  {
    path: "/client/all-contracts",
    name: "ClientContracts",
    component: ClientContracts,
    meta: { 
      title: 'Qapin | Client all contracts',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
  },
  {
    path: "/client/jobpostreview/job-details/:pid",
    name: "jobpostreview/job-details",
    component: jobpostjobDetails,
    meta: { 
      title: 'Qapin | Client job details',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
  },
  {
    path: "/client/jobpostreview/:pid",
    name: "index",
    component: index,
    meta: { 
      title: 'Qapin | Client job post review',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
    props:true,
  },
  {
    path: "/client/jobpostreview/message-details/:pid/:uid/:cpn",
    name: "messageDetails",
    component: messageDetails,
    meta: { 
      title: 'Qapin | Client job post review message',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
  },
  {
    path: "/client/jobpostreview/contract-detail/:pid/:uid",
    name: "contractDetails",
    component: ContractDetails,
    meta: { 
      title: 'Qapin | Client job post review contract details',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
  },

  {
    path: "/client/jobpostreview/ViewProfile/:pid/:uid",
    name: "ViewProfile",
    component: ViewProfile,
    meta: { 
      title: 'Qapin | Client job post review view-profile',
      requiresAuth: true,
      middleware: [
        guest
      ]  
    },
  },
  {
    path: "/client/freelancer-Profile/:uid",
    name: "clientProfile",
    component: freelancerProfile,
    meta: { 
      title: 'Qapin | Client freelancer profile',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
  },
  {
    path: "/client/hire-details/:pid/:uid",
    name: "hiredetails",
    component: hireDetails,
    meta: { 
      title: 'Qapin | Client hire details',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
  },
  {
    path: "/client/hire-freelancer/:uid",
    name: "hireFreelancer",
    component: hireFreelancer,
    meta: { 
      title: 'Qapin | Client hire freelancer',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
  },
  {
    path: "/client/offer-details/:pid/:uid",
    name: "offerdeatils",
    component: offerDetails,
    meta: { 
      title: 'Qapin | Client offer details',
      requiresAuth: true,
      middleware: [
        guest
      ]  },
  },
  {
    path: "/client/jobs-detail",
    name: "jobsDetail",
    component: jobsDetail,
    meta: { 
      title: 'Qapin | Client job details',
      requiresAuth: true,
      middleware: [
        guest
      ]  },
  },
  {
    path: "/client/freelancer-detail/:uid",
    name: "freelancerDetails",
    component: freelancerDetails,
    meta: { 
      title: 'Qapin | Client freelancer details',
      requiresAuth: true,
      middleware: [
        guest
      ] 
     },
  },
  {
    path: "/client/talent",
    name: "clientTalent",
    component: clientTalent,
    meta: { 
      title: 'Qapin | Client talent',
      requiresAuth: true,
      middleware: [
        guest
      ]  },
  },
  {
    path: "/client/search",
    name: "clientSearch",
    meta: { 
      title: 'Qapin | Client search freelancer',
      requiresAuth: true,
      middleware: [
        guest
      ]  },
    component: clientSearch,
    // props: true
  },
  
  {
    path: "/client/end-contract/:pid/:uid",
    name: "endContractClient",
    meta: { 
      title: 'Qapin | Client end contract',
      requiresAuth: true,
      middleware: [
        guest
      ]  },
    component: ClientendContract,
  },

  {
    path: "/client/trasaction-history",
    name: "transactionHistory",
    meta: { 
      title: 'Qapin | Client transaction history',
      requiresAuth: true,
      middleware: [
        guest
      ]  },
    component: transactionHistory,
  },

  {
    path: "/client/escrow-data",
    name: "escrowData",
    meta: { 
      title: 'Qapin | Client Escrow list',
      requiresAuth: true,
      middleware: [
        guest
      ]  },
    component: escrowData,
  },
  
  // Login
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { 
      title: 'Login | Simplifying Freelance Management',
      metaTags: [
        { name: 'description', content: ' Seamless login to our efficient freelancer services, simplifying employee management. Experience streamlined processes for enhanced efficiency and productivity.' },
        { name: 'keywords', content: 'EOR Services' }
      ],
      middleware: [
        guest
      ] 
    },
  },
  
  // Sign up
  {
    path: "/update-profile",
    name: "update-profile",
    component: updateUserprofile,
    meta: { 
      title: 'Qapin | Freelancer Update profile',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
  },
  // {
  //   path: "/sign-up/about-profile",
  //   name: "AboutProfile",
  //   component: AboutProfile,
  // },
  
  {
    path: "/news-feed",
    name: "NewsFeed",
    component: NewsFeed,
    meta: { 
      title: 'Qapin | News feed',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
  },
  
  {
    path: "/freelancer",
    name: "Freelancer",
    meta: { 
      title: 'Qapin | Freelancer dashboard',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
    component: Freelancer,
  },
  {
    path: "/add-wire-transfer",
    name: "addWiretransfer",
    meta: { 
      title: 'Qapin | Add wire transfer',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
    component: addWiretransfer,
  },
  {
    path: "/update-wire-transfer/:aid",
    name: "updateWiretransfer",
    meta: { 
      title: 'Qapin | Update wire transfer',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
    component: addWiretransfer,
  },
  {
    path: "/usa-bank",
    name: "addUsaBank",
    meta: { 
      title: 'Qapin | USA Bank',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
    component: UsaBankTransfer,
  },
  {
    path: "/update-usa-bank/:aid",
    name: "updateUsaBank",
    meta: { 
      title: 'Qapin | Update USA Bank',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
    component: UsaBankTransfer,
  },
  {
    path: "/freelancer/escrow-refund-request/:pid/:uid",
    name: "freelancerRefundRequest",
    meta: { 
      title: 'Qapin | Freelancer Escrow refund request',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
    component: refundRequest,
    props: true
  },
  {
    path: "/agency/escrow-refund-request/:pid/:uid",
    name: "agencyRefundRequest",
    meta: { 
      title: 'Qapin | Agency Escrow refund request',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
    component: refundRequest,
    props: true
  },
  {
    path: "/freelancer/raise-dispute/:pid/:uid",
    name: "freelancerRaiseDispute",
    meta: { 
      title: 'Qapin | Freelancer Raise dispute',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
    component: raiseDispute,
    props: true
  },
  {
    path: "/agency/raise-dispute/:pid/:uid",
    name: "agencyRaiseDispute",
    meta: { 
      title: 'Qapin | Agency Raise dispute',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
    component: raiseDispute,
    props: true
  },
  {
    path: "/freelancer/job-details",
    name: "freelancerjobDetails",
    meta: { 
      title: 'Qapin | Freelancer job details',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
    component: jobDetails,
    props: true
  },
  {
    path: "/agency/job-details",
    name: "agencyjobDetails",
    meta: { 
      title: 'Qapin | Agency job details',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
    component: jobDetails,
    props: true
  },
  {
    path: "/agency/roster",
    name: "agencyRoster",
    meta: { 
      title: 'Qapin | Agency roster',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
    component: agencyRoster,
    props: true
  },
  {
    path: "/freelancer/contract-detail/:pid/:uid",
    name: "freelancercontractDetail",
    component: ContractDetail,
    meta: { 
      title: 'Qapin | Freelancer contract details',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
  },
  {
    path: "/agency/contract-detail/:pid/:uid",
    name: "agencycontractDetail",
    component: ContractDetail,
    meta: { 
      title: 'Qapin | Agency contract details',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
  },
  {
    path: "/freelancer/over-view",
    name: "freelancerOverView",
    component: freelancerOverview,
    meta: { 
      title: 'Qapin | Freelancer over view',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
  },
  {
    path: "/agency/over-view",
    name: "agencyOverView",
    component: agencyOverview,
    meta: { 
      title: 'Qapin | Agency over view',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
  },
  {
    path: "/freelancer/end-contract/:pid/:uid",
    name: "endContractfreelancer",
    meta: { 
      title: 'Qapin | Freelancer end contract',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
    component: endContract,
  },
  {
    path: "/agency/end-contract/:pid/:uid",
    name: "endContractagency",
    meta: { 
      title: 'Qapin | Agency end contract',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
    component: endContract,
  },
  {
    path: "/freelancer/submit-proposal",
    name: "freelancersubmitProposal",
    meta: { 
      title: 'Qapin | Freelancer submit proposal',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
    component: submitProposal,
  },
  {
    path: "/agency/submit-proposal",
    name: "agencysubmitProposal",
    meta: { 
      title: 'Qapin | Agency submit proposal',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
    component: submitProposal,
  },
  {
    path: "/freelancer/trasaction-history",
    name: "freelancertransactionHistory",
    meta: { 
      title: 'Qapin | Freelancer transaction history',
      requiresAuth: true,
      middleware: [
        guest
      ]  },
    component: freelancerTransactionHistory,
  },
  {
    path: "/agency/trasaction-history",
    name: "agencytransactionHistory",
    meta: { 
      title: 'Qapin | Agency transaction history',
      requiresAuth: true,
      middleware: [
        guest
      ]  },
    component: agencyTransactionHistory,
  },
  {
    path: "/freelancer/proposal-details",
    name: "freelancerproposalDetails",
    meta: { 
      title: 'Qapin | Freelancer proposal details',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
    component: proposalDetails,
  },
  {
    path: "/agency/proposal-details",
    name: "agencyproposalDetails",
    meta: { 
      title: 'Qapin | Agency proposal details',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
    component: proposalDetails,
  },
  {
    path: "/freelancer/all-contracts",
    name: "freelancerallContracts",
    meta: { 
      title: 'Qapin | Freelancer contract list',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
    component: allContracts,
  },
  {
    path: "/agency/all-contracts",
    name: "agencyallContracts",
    meta: { 
      title: 'Qapin | Agency contract list',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
    component: allContracts,
  },
  {
    path: "/freelancer/my-jobs",
    name: "freelancerMyjobs",
    meta: { 
      title: 'Qapin | Freelancer my jobs',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
    component: myJobs,
  },
  {
    path: "/agency/my-jobs",
    name: "agencyMyjobs",
    meta: { 
      title: 'Qapin | Agency my jobs',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
    component: myJobs,
  },
  {
    path: "/freelancer/my-proposals",
    name: "freelancermyProposals",
    meta: { 
      title: 'Qapin | Freelancer proposals',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
    component: myProposals,
  },
  {
    path: "/agency/my-proposals",
    name: "agencymyProposals",
    meta: { 
      title: 'Qapin | Agency proposals',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
    component: myProposals,
  },
  {
    path: "/freelancer/submit-proposals-list",
    name: "freelancersubmitProposalslist",
    meta: { 
      title: 'Qapin | Freelancer submitted proposals list',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
    component: submitProposalslist,
  },
  {
    path: "/agency/submit-proposals-list",
    name: "agencysubmitProposalslist",
    meta: { 
      title: 'Qapin | Agency submitted proposals list',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
    component: submitProposalslist,
  },
  {
    path: "/freelancer/active-proposals-list",
    name: "freelanceractiveProposalslist",
    meta: { 
      title: 'Qapin | Freelancer active proposals list',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
    component: activeProposalslist,
  },
  {
    path: "/agency/active-proposals-list",
    name: "agencyactiveProposalslist",
    meta: { 
      title: 'Qapin | Agency active proposals list',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
    component: activeProposalslist,
  },
  {
    path: "/freelancer/invitation-interview-list",
    name: "freelancerinvitationInterviewlist",
    meta: { 
      title: 'Qapin | Freelancer invitation list',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
    component: invitationInterviewlist,
  },
  {
    path: "/agency/invitation-interview-list",
    name: "agencyinvitationInterviewlist",
    meta: { 
      title: 'Qapin | Agency invitation list',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
    component: invitationInterviewlist,
  },
  {
    path: "/freelancer/invitation-detail/:pid/:uid",
    name: "freelancerinvitationDetail",
    meta: { 
      title: 'Qapin | Freelancer invitation details',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
    component: invitationDetail,
  },
  {
    path: "/agency/invitation-detail/:pid/:uid",
    name: "agencyinvitationDetail",
    meta: { 
      title: 'Qapin | Agency invitation details',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
    component: invitationDetail,
  },
  {
    path: "/freelancer/offer/:pid/:uid/",
    name: "freelancerOffer",
    meta: { 
      title: 'Qapin | Freelancer offers',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
    component: Offer,
  },
  {
    path: "/agency/offer/:pid/:uid",
    name: "agencyOffer",
    meta: { 
      title: 'Qapin | Agency offers',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
    component: Offer,
  },
  
  {
    path: "/agency-profile",
    name: "agency-profile",
    meta: { 
      title: 'Qapin | Agency profile',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
    component: Agencyprofile,
  },
  {
    path: "/my-profile",
    name: "Profile",
    meta: { 
      title: 'Qapin | Freelancer Profile',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
    component: Profile,
  },
  
  {
    path: "/settings",
    name: "Settings",
    meta: { 
      title: 'Qapin | Settings',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
    component: Settings,
    props:true
  },
  {
    path: "/settings/addfreelancer",
    name: "clientSettings",
    meta: { 
      title: 'Qapin | Settings add freelancer',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
    component: ClientSettings,
    props:true
  },
  {
    path: "/freelancer/search",
    name: "Search",
    meta: { 
      title: 'Qapin | Freelancer search',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
    component: Search,
    // props: true
  },
  {
    path: "/agency/search",
    name: "agencySearch",
    meta: { 
      title: 'Qapin | Agency search',
      requiresAuth: true,
      middleware: [
        guest
      ] 
    },
    component: Search,
    // props: true
  },
  {
    path: "/add-freelancer-account",
    name: "addFreelanceAccount",
    meta: { 
      title: 'Qapin | Create freelancer account',
      requiresAuth: true,
      middleware: [
        guest
      ] 
     },
    component: freelanceNew,
  },
  {
    path: "/add-client-account",
    name: "addClientAccount",
    meta: { 
      title: 'Qapin | Create client account',
      requiresAuth: true,
      middleware: [
        guest
      ]  },
    component: clientNew,
  },
  {
    path: "/add-agency-account",
    name: "agencyNew",
    meta: { 
      title: 'Qapin | Create agency account',
      requiresAuth: true,
      middleware: [
        guest
      ]  },
    component: agencyNew,
  },
  {
    path: "/public-profile/:uid",
    name: "freelancerProfile",
    component: freelancerProfile,
    meta: { 
      title: 'Qapin | Freelancer public view',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
  },
  {
    path: "/agency-public-profile/:uid",
    name: "agencyPublicProfile",
    component: agencyPublicProfile,
    meta: { 
      title: 'Qapin | Agency public view',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
  },
  
  {
    path: "/saved-jobs",
    name: "saveJobs",
    meta: { 
      title: 'Qapin | Saved jobs',
      requiresAuth: true,
      middleware: [
        guest
      ]
    },
    component: saveJobs,
  },

  {
    path: "/client/saved-freelancer",
    name: "savedUser",
    meta: { 
      title: 'Qapin | Client saved freelancer',
      requiresAuth: true,
      middleware: [
        guest
      ]  },
    component: savedUser,
  },
  {
    path: "/client/hired-freelancer",
    name: "hiredUser",
    meta: { 
      title: 'Qapin | Client hired freelancers',
      requiresAuth: true,
      middleware: [
        guest
      ]  },
    component: savedUser,
  },
  {
    path: "/client/dispute-respond/:pid/:uid",
    name: "disputeRespond",
    meta: { 
      title: 'Qapin | Client dispute respond',
      requiresAuth: true,
      middleware: [
        guest
      ]  },
    component: disputeRespond,
  },
  
]
const router = new VueRouter({
  mode: "history",
  routes,
});
router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    var loadPath      = to.path.split("/")[1];
    var URL2          = to.path.split("/")[2];
    // var vuex          = JSON.parse(localStorage.getItem('vuex'));
    var vuex          = router.app.$store.state;
    if(loadPath == '' || loadPath == 'undefined' || loadPath == 'home'){
      if(vuex != null){
        if(vuex.currentUserData != ''){
          if(vuex.accountType == 'freelancer'){
            window.location.href = "/freelancer";
            next("/freelancer");
          }else if(vuex.accountType == 'agency'){
            window.location.href = "/agency/search";
            next("/agency/search");
          }else if(vuex.accountType == 'client'){
            window.location.href = "/client/dashboard";
            next("/client/dashboard");
          }
        }else{
          store.dispatch("currentUserData", '');
          store.dispatch("accountType", '');
          store.state.ExperienceLevelData = [];
          store.state.LanguageData = [];
          store.state.certificationList = [];
          store.state.currentUserOwnerData = [];
          store.state.listAccount = {
              company: '',
              qp_user: '',
              user: '',
          };
          store.state.loginUserUuid = '';
          store.state.profileImg = '';
          store.state.swiftKey = '';
          store.state.talentData = [];
          localStorage.removeItem("token");
          localStorage.removeItem("accountType");
          localStorage.removeItem("loginUserData");
          localStorage.removeItem("loginUserUuid");
          next();
        }
      }else{
        next();
      }
    }else if(loadPath == 'public-profile' || loadPath == 'agency-public-profile'){
      next();
    }else{
      const requiresAuth  = to.matched.some((record) => record.meta.requiresAuth);
      const isUserLogin   = localStorage.getItem("token");
      store.commit("isNewsfeed", true);

      if (to.name == "NewsFeed" || to.name == "ExpireLink" || to.name=="ResetPassword" || to.name=="Login") {
        store.commit("isNewsfeed", false);
      }
      if(loadPath == 'settings' && URL2 == 'addfreelancer' ){
        loadPath        = 'settings/addfreelancer';
      }

      if (requiresAuth && !isUserLogin) {
        next("/login");
      } else if (requiresAuth && isUserLogin) {
        if(vuex.accountType == loadPath && vuex.accountType != 'freelancer'){
          next();
        }else if(vuex.accountType == loadPath && vuex.accountType == 'freelancer' && vuex.currentUserData.updateProfile_afterVerification == 1){
          next();
        }else{
          if(loadPath =='add-client-account' || loadPath == 'settings' || loadPath == 'saved-jobs' || loadPath == 'news-feed' 
            || loadPath == 'public-profile' || loadPath =='my-profile' || loadPath == 'add-wire-transfer' || loadPath == 'update-wire-transfer' || loadPath == 'usa-bank' || loadPath == 'update-usa-bank'
            || loadPath =='add-freelancer-account' || loadPath == 'agency-public-profile' || loadPath == 'chat' || loadPath == 'update-profile'){
              
              if(vuex.accountType == 'freelancer' && vuex.currentUserData.updateProfile_afterVerification == 0 && loadPath != 'update-profile'){
                window.location.href = "update-profile";
              }else if(vuex.accountType == 'freelancer' && vuex.currentUserData.updateProfile_afterVerification == 1 && loadPath == 'update-profile'){
                window.location.href = "/freelancer";
              }else{
                next();
              }
          }else if(vuex.accountType == 'agency' && loadPath == 'agency-profile' ){
            next();
          }else if(vuex.accountType == 'freelancer' && loadPath=='add-agency-account'){
            next();
          }else if(vuex.accountType == 'client' && loadPath=='settings/addfreelancer'){
            next();
          }else{ 
            if (window.history.state == null) {
              if(vuex.accountType == 'freelancer' && vuex.currentUserData.updateProfile_afterVerification == 0){
                window.location.href = "/update-profile";
                next("/update-profile");

              }else if(vuex.accountType=='agency'){
                window.location.href = "/agency/search";
                next("/agency/search");

              }else if(vuex.accountType =='freelancer'){
                window.location.href = "/freelancer";
                next("/freelancer");

              }else if(vuex.accountType=='client'){
                window.location.href = "/client/dashboard";
                next("/client/dashboard");
              }
            }else{
              window.history.back()
            }
          }
        }
      } else {
        // window.location.href = "/news-feed";
        next();
      }
    }
  }else{
    const middleware = to.meta.middleware
    const context = {
        to,
        from,
        next,
        store
    }
    return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1,to, from)
    })
  }
  
});
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  Vue.nextTick(() => {
    // Update the title using the meta field
    document.title = to.meta.title || 'Qapin | Web';
    // Remove any stale meta tags
    var metaData = document.querySelectorAll('meta[name="description"], meta[name="keywords"]');
    if(metaData.length > 0){
      metaData.forEach(el => el.remove());
    }
    // Add new meta tags
    if(to.meta.metaTags != undefined){
      to.meta.metaTags.forEach(tagDef => {
        const tag = document.createElement('meta');
        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key]);
        });
        document.head.appendChild(tag);
      });
    }
    
  });
});
export default router;