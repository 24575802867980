import { render, staticRenderFns } from "./WhyQapin.vue?vue&type=template&id=058a8999&scoped=true"
import script from "./WhyQapin.vue?vue&type=script&lang=js"
export * from "./WhyQapin.vue?vue&type=script&lang=js"
import style0 from "./WhyQapin.vue?vue&type=style&index=0&id=058a8999&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "058a8999",
  null
  
)

export default component.exports