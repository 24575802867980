<template>

  <div class="relative w-full h-auto bg-white about-page-wrap home-a">
    <div class="w-full pt-12 mb-20 xl:pt-40 md:pt-20 xl:px-0  css-144ctnr">
            <div class="md:justify-between md:flex w-full mx-auto xl:mx-auto xl:max-w-7xl">
                <div>
                    <div class=""><span class="css-79e8z3">About Us</span></div>
                    <h1
                        class="w-full max-w-2xl text-4xl fw-600 xl:pt-6 md:pt-24 md:max-w-lg xl:max-w-2xl xl:text-6xl css-1wfjlp">
                        The world’s work marketplace</h1>
                    <div class=" xl:mt-1">
                      <p class="w-full max-w-2xl text-4xl fw-400 md:max-w-lg xl:max-w-2xl xl:text-2xl css-1plhbgk hero-benner"
                          style="font-size: 20px !important; line-height: 1.3 !important; font-family: inherit !important; padding: 5px !important; ">
                          Qapin attracts some of the most in-demand Engineers, like the star performers below</p>
                    </div>
                    <div class="items-center mt-10 space-y-4 md:space-x-4 md:space-y-0 md:flex">
                        <button @click="$router.push('/client/dashboard').catch(()=>{})"
                            class="w-full px-4 py-2 text-base text-white transition-all duration-500 ease-in-out rounded-md shadow-sm cursor-pointer md:w-40 hover:bg-opacity-50 bg-primary-500 hover:shadow-lg">
                            Post Job 
                        </button>
                          <br>
                        <button @click="$router.push('/freelancer').catch(()=>{})"
                            class="w-full px-4 py-2 text-base text-black transition-all duration-500 ease-in-out border-solid rounded-md shadow-sm cursor-pointer md:w-40 border-1 hover:text-white border-primary-500 hover:bg-primary-500 hover:shadow-lg">
                            Find Job </button>
                    </div>

                </div>
                <div class="mt-24 md:mt-0">
          <img src="web/Web-images/bgAsset 1.png" alt="" class="object-contain h-96 w-100 about-page-img mt-sm-3"  />
      </div>
            </div>
        </div>

      <!-- <TheHomeSiteHeader /> -->

      <div class="w-full pt-5 mb-2 mx-auto xl:mx-auto px-sm-1 mobile-padding" style="max-width: 80rem;">
          <div class="md:justify-between md:space-x-10 md:flex">
              <div>
                  <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi " >
                    Qapin is revolution for the inspection services industry to come together.
                  </h1>
                  <div class="mt-10 md:mt-6">
                      <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                        With our many years of experience in inspection service industry we know that convenient and simple way of working is long overdue. And that’s why we designed Qapin - to be a one-stop shop for Inspectors, clients and inspection agencies.</p>
                          <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                            Our revolutionary platform leads the way in industry offering a completely new approach to connecting those who are offering inspector services and those who need them.</p>
                            <!-- Our revolutionary platform leads the way in industry offering a completely new approach to connecting those who are offering <a href="javascript:void(0)" @click="$router.push({name:'demoPage',path:'/demo'}).catch(()=>{})">inspector services</a> and those who need them.</p> -->
                              <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                                We have the expertise to understand what’s needed from both sides, and that’s why Qapin was created.</p>
                      </div>
              </div>
              <div class="mt-24 md:mt-0">
                  <img src="web/Web-images/about/qapin.png" class="about-page-img" alt="" style="width: 100%; height: 500px;" />
              </div>
 
          </div>
      </div>

      <div class="w-full pt-5 mb-2 mx-auto xl:mx-auto px-sm-1" style="max-width: 80rem;">
        <div class="md:justify-between md:space-x-10 md:flex">
          <div class="mobile-sections">
            <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi " style=" max-width: 40rem; padding-top: 65px;">
              Budget effectively
            </h1>
            <div class="mt-10 md:mt-6">
              <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                For clients sourcing inspectors, it can be difficult to compare quotes. Finding the inspectors with the right skills and certification who are also available on the required schedules can be time-consuming so often it’s a case of just booking the first one you find.</p>
              <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                With Qapin there’s the opportunity to have a greater choice, with the ability to filter out the results that match your own criteria. Compare quotes, experience and narrow down the shortlist until you find an inspection service provider you want.</p>
              <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                It’s great for your budget and you’re guaranteed to get someone of the highest skills and experience. We’ve got a secure escrow system too, so the whole transaction is safe from start to finish.</p>
            </div>
          </div>
          <div class="mt-24 md:mt-0">
              <img src="web/Web-images/about/about-2.png" class="about-page-img" alt="" style="width: 100%; height: 500px;" />
          </div>
          <div class="desktop-sections">
            <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi " style=" max-width: 40rem; padding-top: 65px;">
              Budget effectively
            </h1>
            <div class="mt-10 md:mt-6">
              <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                For clients sourcing inspectors, it can be difficult to compare quotes. Finding the inspectors with the right skills and certification who are also available on the required schedules can be time-consuming so often it’s a case of just booking the first one you find.</p>
              <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                With Qapin there’s the opportunity to have a greater choice, with the ability to filter out the results that match your own criteria. Compare quotes, experience and narrow down the shortlist until you find an inspection service provider you want.</p>
              <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                It’s great for your budget and you’re guaranteed to get someone of the highest skills and experience. We’ve got a secure escrow system too, so the whole transaction is safe from start to finish.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full pt-5 mb-2 mx-auto xl:mx-auto px-sm-1 mobile-padding" style="max-width: 80rem;">
          <div class="md:justify-between md:space-x-10 md:flex">
  
              <div>
                  <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi " style=" ">
                    Find clients
                  </h1>
                  <div class="mt-10 md:mt-6">
                      <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko">
                        We’ve created Qapin to be a marketplace that’s dedicated solely to inspection services. That means that you won’t have to scroll through lots of irrelevant projects to find those which match your skills.</p>
                          <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko">
                            Our filter tool enables you to only see the projects you’re qualified for, and those available in your area. It’s the smart way to search for work and will save you time, while earning you more money!</p>
                  </div>

              </div>
              <div class="mt-24 md:mt-0">
                  <img src="web/Web-images/about/about-5.png" class="about-page-img" alt="" style="width: 100%; height: 500px;" />
              </div>
          </div>
      </div>

      <div class="w-full pt-5 mb-20 mx-auto xl:mx-auto px-sm-1" style="max-width: 80rem;">
        <div class="md:justify-between md:space-x-10 md:flex">
          <div class="mobile-sections">
            <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi " style=" max-width: 40rem; padding-top: 0px;">
              Ensuring quality
            </h1>
            <div class="mt-10 md:mt-6">
              <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-4">
                When you need a contractor engineer for inspection services, it can be a challenge to find a suitably qualified individual who’s available at short notice. Qapin solves the problem by allowing freelancers to manage their profile visibility when available to take up projects and showing prospective clients when they have availability.</p>
                <!-- When you need a contractor engineer for inspection services, it can be a challenge to find a suitably qualified individual who’s available at short notice. Qapin solves the problem by allowing <a href="javascript:void(0)" @click="$router.push({name:'BasicInfo',path:'/sign-up'}).catch(()=>{})">freelancers</a> to manage their profile visibility when available to take up projects and showing prospective clients when they have availability.</p> -->

              <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                It’s now simple to book in slots at short notice as both parties can see where there’s availability. Freelancers can choose their own working slots, so it’s a solution that’s completely customized.</p>
                <!-- It’s now simple to book in slots at short notice as both parties can see where there’s availability. <a href="javascript:void(0)" @click="$router.push({name:'BasicInfo',path:'/sign-up'}).catch(()=>{})">Freelancers</a> can choose their own working slots, so it’s a solution that’s completely customized.</p> -->
              <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                Like any industry, price is always a factor but that shouldn’t mean compromising on quality. By chosing a qualified inspector who’s local, the lower travel costs can lead to significant savings.</p>
              <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                Qapin has been deliberately designed as a platform to provide all the necessary options to create a marketplace that works for everyone. Our secure escrow payment system means there is protection for both clients and freelancers and makes it possible to take international jobs without any worries.</p>
                <!-- Qapin has been deliberately designed as a platform to provide all the necessary options to create a marketplace that works for everyone. Our secure escrow payment system means there is protection for both clients and <a href="javascript:void(0)" @click="$router.push({name:'BasicInfo',path:'/sign-up'}).catch(()=>{})">freelancers</a> and makes it possible to take international jobs without any worries.</p> -->
            </div>
          </div>
          <div class="mt-24 md:mt-0">
              <img src="web/Web-images/about/about-6.png" class="about-page-img" alt="" style="width: 100%; height: 580px;" />
          </div>
          <div class="desktop-sections">
            <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi " style=" max-width: 40rem; padding-top: 0px;">
              Ensuring quality
            </h1>
            <div class="mt-10 md:mt-6">
              <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-4">
                When you need a contractor engineer for inspection services, it can be a challenge to find a suitably qualified individual who’s available at short notice. Qapin solves the problem by allowing freelancers to manage their profile visibility when available to take up projects and showing prospective clients when they have availability.</p>
                <!-- When you need a contractor engineer for inspection services, it can be a challenge to find a suitably qualified individual who’s available at short notice. Qapin solves the problem by allowing <a href="javascript:void(0)" @click="$router.push({name:'BasicInfo',path:'/sign-up'}).catch(()=>{})">freelancers</a> to manage their profile visibility when available to take up projects and showing prospective clients when they have availability.</p> -->

              <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                It’s now simple to book in slots at short notice as both parties can see where there’s availability. Freelancers can choose their own working slots, so it’s a solution that’s completely customized.</p>
                <!-- It’s now simple to book in slots at short notice as both parties can see where there’s availability. <a href="javascript:void(0)" @click="$router.push({name:'BasicInfo',path:'/sign-up'}).catch(()=>{})">Freelancers</a> can choose their own working slots, so it’s a solution that’s completely customized.</p> -->
              <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                Like any industry, price is always a factor but that shouldn’t mean compromising on quality. By chosing a qualified inspector who’s local, the lower travel costs can lead to significant savings.</p>
              <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                Qapin has been deliberately designed as a platform to provide all the necessary options to create a marketplace that works for everyone. Our secure escrow payment system means there is protection for both clients and freelancers and makes it possible to take international jobs without any worries.</p>
                <!-- Qapin has been deliberately designed as a platform to provide all the necessary options to create a marketplace that works for everyone. Our secure escrow payment system means there is protection for both clients and <a href="javascript:void(0)" @click="$router.push({name:'BasicInfo',path:'/sign-up'}).catch(()=>{})">freelancers</a> and makes it possible to take international jobs without any worries.</p> -->
            </div>
          </div>
        </div>
      </div>

      <div class="xl:px-0">
          <div class="inset-0">
              <div
                  class="left-0 right-0 w-full mx-auto bg-center shadow-xl xl:px-10 xl:h-auto" style="background:  rgb(242 97 60);">
                  <div class="relative footer-vectors">
                 
                  </div>

                  <div class="py-6 text-center xl:py-14">
                      <p class="text-sm uppercase fw-600 text-white text-primary-500">JOIN QAPIN </p>

                      <h4 class="mt-2 text-xl text-white fw-bold md:text-5xl">
                          Register for Qapin today
                      </h4>

                      <p class="max-w-sm mx-auto mt-4 text-sm text-white xl:text-lg fw-200 xl:w-auto"
                          style="max-width: 60rem;">
                          it’s the future of inspection services. Bringing the inspection community
                          together in one easy to use platform, Qapin is simple, free and convenient
                          but don’t take our word for it - create your
                      </p>

                      <div class="px-10 mt-6 xl:px-0">
                          <button @click="$router.push('/login').catch(()=>{})"
                              class="w-40 px-4 py-2 text-base text-black transition-all duration-500 ease-in-out rounded-md bg-white shadow-sm cursor-pointer md:w-52 hover:bg-opacity-50 hover:text-black fw-200 xl:w-auto bg-primary-500 hover:shadow-lg">
                              Join for Free
                          </button>
                      </div>
                  </div>
              </div>
          </div>
      </div>


  </div>
</template>

<script>
// import TheHomeSiteHeader from "../../TheHomeSiteHeader.vue";
// import TheHomeSiteHeader from "../../components/TheHomeSiteHeader.vue";

import "tiny-slider/src/tiny-slider.scss";
export default {
  components: {

      //  TheHomeSiteHeader
  },
  data() {
      return {
          isAuth: null,
      }
  },
  mounted() {
      this.isAuth = localStorage.getItem("token");
      if (this.$route.name == "ExpireLink") {
          this.isAuth = null;
      }
      this.getCurrentUserDetails();
  },
}
</script>
<style scoped>
.css-144ctnr {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-flow: row nowrap;
    column-gap: 64px;
    padding: 126px 64px;
    min-height: 567px;
    background: rgba(242, 97, 60, 0.07);
}

.css-79e8z3 {
  margin: 0px;
  letter-spacing: 0.00938em;
  color: rgb(13, 32, 73);
  font-family: Silka, Helvetica, sans-serif;
  line-height: 170%;
  background-color: rgb(191 153 153 / 20%);
  padding: 4px 8px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.css-12k8mko {
  margin: 0px;
  color: rgb(6, 18, 44);
  font-family: Silka, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  max-width: 600px;
}

.css-1plhbgk {
  margin: 0px;
  letter-spacing: 0.00938em;
  color: rgb(28, 28, 28);
  font-family: Silka, Helvetica, sans-serif !important;
  line-height: 170% !important;
  font-weight: 400 !important;
  font-size: 18px !important;
}

.css-1wfjlp {
  margin-top: 0px;
  letter-spacing: 0.00938em;
  color: rgb(16, 16, 16);
  font-family: Silka, Helvetica, sans-serif;
  line-height: 120%;
  font-weight: 700 !important;
  font-size: 64px !important;
}

.css-ajm3pi {
  max-width: 40rem;
   padding-top: 100px;
  margin: 0px 0px 24px;
  letter-spacing: 0.00735em;
  color: rgb(16, 16, 16);
  font-family: Silka, Helvetica, sans-serif;
  line-height: 43px;
  font-weight: 700 !important;
  max-width: 600px !important;
  font-size: 28px !important;
}

.hero-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  line-height: 75px;
}

.margin-15 {
  font-size: 19px;
}

.agenct-scale {
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.36;
  margin-bottom: 16px;
  margin-top: 0;
  text-decoration: none;
}

@media (min-width: 1280px) {
  .xl\:text-6xl {
      font-size: 4.75rem;
      line-height: 1.2;
  }

  .xl\:pt-40 {
      padding-top: 12rem;
  }
  .xl\:max-w-7xl {
    max-width: 85rem;
}
}
@media (max-width: 480px) {

.mt-24{
  margin-top: 0px;
}
.pb-24 {
  padding-bottom: 0px;
}



.css-ajm3pi {
  max-width: 40rem;
  padding-top: 0px;
  margin: 0px 0px 24px;
  letter-spacing: 0.00735em;
  color: rgb(16, 16, 16);
  font-family: Silka, Helvetica, sans-serif;
  line-height: 43px;
  font-weight: 700 !important;
  max-width: 600px !important;
  font-size: 25px !important;
}

.css-1wfjlp {
  margin-top: 0px;
  letter-spacing: 0.00938em;
  color: rgb(16, 16, 16);
  font-family: Silka, Helvetica, sans-serif;
  line-height: 120%;
  font-weight: 700 !important;
  font-size: 28px !important;
}
}
@media screen and (min-width: 320px) and (max-width: 815px) {
    .mb-20{
        margin-bottom:0px!important;
    }
}
</style>
