<template>
    <div>
        <b-row>
            <b-col xl="8" lg="8" sm="12" id="paymentlistDiv" style="display:block;">
                <div class="left-card p-3">
                <div class="d-flex align-items-center">
                    <div>
                        <h4 class="mb-0 fw-600">Select a billing method</h4>
                    </div>
                    <div class="ml-auto">
                        <b-button class="btn-theme" @click="addBillingDiv">Add a new billing method</b-button>
                    </div>
                </div>
                <!-- <Paypal></Paypal>  -->
                <p class="mb-3 mt-2 border-bottom pb-3 text-muted fz-14">This will be your primary billing method acress all contracts, account activity and subscriptions.</p>
                <div class="pay-method-wrap scroll" v-if="savedBillings.length > 0">
                    <b-form-group class="mb-0 delete-card-layout" v-for="(bill,index) in savedBillings" :key="index">
                        <div class="d-md-flex align-items-center payment-radio">
                            <b-form-radio 
                                name="radio"
                                :value="bill.pay_method_id"
                                @change="checkoutdataFun(bill)"
                            >
                                <p class="fz-16 visa-card-line mb-0" @click="checkoutdataFun(bill)">{{bill.card.brand+', '+bill.card.funding+' '+bill.type+', '+bill.card.last4}}</p>    
                            
                            </b-form-radio>
                            <div class="ml-auto">
                                <a href="javascript:void(0);" class="btn btn-outline-danger" v-b-modal.confirm-modal @click="removeBillId = bill.id">
                                    <i class="fa fa-trash fz-18"></i>
                                </a>
                            </div>
                        </div>
                    </b-form-group>
                </div>
                </div>
            </b-col>
            <b-col xl="8" lg="8" sm="12" id="addList" >
                <div class="left-card p-3 border-right">
                    <div class="d-flex align-items-center">
                        <div>
                            <h4 class="mb-0 fw-600">Add a billing method</h4>
                        </div>
                        <div class="ml-auto">
                            <b-button class="btn-outline-theme" @click="listBillingDiv">Cancel</b-button>
                        </div>
                    </div>
                    <form @submit.prevent="handleForm" class="my-form" id="payment-form">
                        <div class="pay-method-wrap mt-4">
                            <b-form-group class="mb-0">
                                <b-form-radio
                                    value="card"
                                    v-model="addBilling.method_type"
                                >
                                    Payment Card
                                </b-form-radio>
                                <p class="fz-14 text-muted">Visa, Mastercard, American Express, Discover, Diners</p>
                                <p id="card-error"  style="display:none; margin-bottom: 0px!important;background-color: #ef5555;border-radius: 4px;padding: 10px; color: #fff;"></p>
                            </b-form-group>
                            <hr>
                        </div>
                        <b-row>
                            <b-col xl="6" lg="6" sm="12">
                                <b-form-group class="mb-0">
                                    <label class="text-black fz-16">First Name</label>
                                    <b-form-input v-model="addBilling.first_name" :maxlength="30"  size="sm" placeholder="Enter first name" class="inp-person-first-name custom-select"></b-form-input>
                                    <div class="error" v-if="billing_firstname[0]">{{billing_firstname[0]}}</div>
                                </b-form-group>
                            </b-col>
                            <b-col xl="6" lg="6" sm="12">
                                <b-form-group class="mb-0">
                                    <label class="text-black fz-16">Last Name</label>
                                    <b-form-input v-model="addBilling.last_name"  size="sm" placeholder="Enter last name" class="inp-person-last-name custom-select"></b-form-input>
                                    <div class="error" v-if="billing_lastname[0]">{{billing_lastname[0]}}</div>

                                </b-form-group>
                            </b-col>
                            <!-- <b-col xl="12" lg="12" sm="12">
                                <b-form-group class="mb-0">
                                    <label class="text-black fz-16">Email</label>
                                    <b-form-input  size="sm" disabled :value="$store.state.currentUserData.email_id" placeholder="Enter Email" class="inp-person-email custom-select"></b-form-input>
                                    <div class="error" v-if="billing_email[0]">{{billing_email[0]}}</div>
                                </b-form-group>
                            </b-col> -->
                        </b-row>
                        <b-row>
                            <b-col xl="12" lg="12" sm="12">
                                <b-form-group class="mb-0" id="card-num">
                                    <label class="text-black fz-16">Card Number</label>
                                    <!-- <b-form-input v-model="addBilling.cardnumber" size="sm" placeholder="Enter card number" class="custom-select"></b-form-input> -->
                                    <div id="card-number"></div>
                                </b-form-group>
                            </b-col>
                        
                            <b-col xl="6" lg="6" sm="12">
                                <b-row>
                                    <b-col xl="12" lg="12" sm="12" class="py-0">
                                    <b-form-group class="mb-0">
                                        <label class="text-black fz-16">Expires On</label>
                                        <!-- <b-form-input v-model="addBilling.expire" size="sm" placeholder="Enter Month" class="custom-select"></b-form-input> -->
                                        <div id="card-expiry"></div>
                                    </b-form-group>
                                    </b-col>
                                    <!-- <b-col xl="6" lg="6" sm="12">
                                    <b-form-group class="mb-0 mt-3">
                                        <b-form-input size="sm" placeholder="Enter Year" class="custom-select mt-1"></b-form-input>
                                    </b-form-group>
                                    </b-col> -->
                                </b-row>
                            </b-col>
                            <b-col xl="6" lg="6" sm="12">
                                <b-form-group class="mb-0">
                                    <label class="text-black fz-16">Security Code</label>
                                    <!-- <b-form-input size="sm" placeholder="Enter code" class="custom-select"></b-form-input> -->
                                    <div id="card-cvc"></div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col xl="12" lg="12" sm="12">
                                <h4 class="mb-0 fw-600 mt-4">Billing Address</h4>
                            </b-col>
                            <b-col xl="6" lg="6" sm="12">
                                <b-form-group class="mb-0">
                                    <label class="text-black fz-16">Country</label><br>
                                    <b-form-select 
                                        v-model="addBilling.country"
                                        :options="CountryData" 
                                        value-field="country_id"
                                        text-field="country_name"
                                        v-on:change="changecountry($event)"
                                        
                                        class="form-control"></b-form-select>
                                    <div class="error" v-if="billing_country[0]">{{billing_country[0]}}</div>

                                </b-form-group>
                            </b-col>
                            <b-col xl="6" lg="6" sm="12">
                                <b-form-group class="mb-0">
                                    <label class="text-black fz-16">State</label><br>
                                    <b-form-select
                                        v-model="addBilling.state"
                                        :options="stateData"
                                        value-field="state_id"
                                        text-field="state_name"
                                        v-on:change="changestate($event)"
                                        
                                        class="form-control"></b-form-select>
                                    <div class="error" v-if="billing_state[0]">{{billing_state[0]}}</div>

                                </b-form-group>
                            </b-col>
                            <b-col xl="6" lg="6" sm="12">
                                <b-form-group class="mb-0">
                                    <label class="text-black fz-16">City</label><br>
                                    <b-form-select
                                        v-model="addBilling.city"
                                        :options="cityData"
                                        value-field="city_id"
                                        text-field="city_name"
                                        
                                        class="form-control"></b-form-select>
                                    <div class="error" v-if="billing_city[0]">{{billing_city[0]}}</div>

                                </b-form-group>
                            </b-col>
                            <b-col xl="6" lg="6" sm="12">
                                <b-form-group class="mb-0">
                                    <label class="text-black fz-16">Postal Code</label><br>
                                    <b-form-input  maxlength="10" v-model="addBilling.zipcode"  size="sm" placeholder="Enter postal code" class="custom-select inp-person-zipcode"></b-form-input>
                                    <div class="error" v-if="billing_zipcode[0]">{{billing_zipcode[0]}}</div>

                                </b-form-group>
                            </b-col>
                            <b-col xl="12" lg="12" sm="12">
                                <b-form-group class="mb-0">
                                    <label class="text-black fz-16">Address</label><br>
                                    <b-form-input v-model="addBilling.address1" maxlength="250"  size="sm" placeholder="Enter address1" class="custom-select inp-person-line1"></b-form-input>
                                    <b-form-input v-model="addBilling.address2" maxlength="250"  size="sm" placeholder="Enter address2" class="custom-select inp-person-line2"></b-form-input>
                                    <div class="error" v-if="billing_address1[0]">{{billing_address1[0]}}</div>
                                </b-form-group>
                            </b-col>
                            
                            <b-col xl="12" lg="12" sm="12" class="text-right">
                                <b-button type="submit" class="btn-theme w-15 mb-2">Save</b-button>
                            </b-col>
                        </b-row>
                    </form>
                </div>
            </b-col>
            <b-col xl="4" lg="4" sm="12">
                <div class="right-card border-left">
                <div class="d-flex align-items-start border-bottom px-4 py-1 pb-2">
                    <div class="img-wrapper pos-rel">
                        <img :src="contractDetails.userDetails.profile_picture_path_url" class="img-fluid">
                        <span class="online" :class="(checkUserOnlineOrOffline(contractDetails.userDetails) == true) ? 'bg-success' : ''"></span>
                    </div>
                    <div class="profile-content ml-2">
                    <h5 class="fz-16 text-black mb-0 mt-2 fw-600">Pay {{contractDetails.userDetails.first_name+' '+contractDetails.userDetails.last_name}} for: {{contractDetails.jobDetails.job_title}}</h5>
                    </div>
                </div>
                <div class="details px-4 py-1">
                    <h4 class="mb-0 fw-600">Payment Details</h4>
                    <ul class="custom-margin d-flex align-items-center justify-content-between">
                    <li>Subtotal</li>
                    <li>${{currentpayment_arr.amount}}</li>
                    </ul>
                    <hr>
                    <ul class="custom-margin d-flex align-items-center justify-content-between">
                    <li>Estimated Taxes</li>
                    <li>${{estimatedTax}}</li>
                    </ul>
                    <hr>
                    <ul class="custom-margin d-flex align-items-center justify-content-between">
                    <li>Estimated Total</li>
                    <li>${{estimatedTotal}}</li>
                    </ul>
                    <hr>
                    <b-button class="btn-theme btn-block w-100 mb-2" @click="submitForPay()">Fund Contract</b-button>
                </div>
                </div>
            </b-col>
        </b-row>
        <!-- model for confirm reject -->
        <b-modal id="confirm-modal" title="Remove Billing" centered hide-footer size="md" no-close-on-backdrop> 
            <template>
            <div class="p-5 text-center m-auto">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#F2613C" class="bi bi-trash m-auto" viewBox="0 0 16 16">
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                </svg>
                <h5 class="mt-4">Are you sure, Want to Remove this Billing?</h5>
            </div>
            <hr>
            <div>
                <div class="text-right">
                    <a href="javascript:void(0);" class="text-theme mr-2" @click="$bvModal.hide('confirm-modal')">Cancel</a>
                    <b-button type="button" class="btn btn-theme" @click="removeBillingMethod(removeBillId)">Confirm</b-button>
                </div>
            </div>
            </template>
        </b-modal>
    </div>
</template>
<script src="https://js.stripe.com/v3/"></script> 
<!-- <script src="https://www.paypal.com/sdk/js?client-id=AatCdopsWYyKZqMmAVdPNTqYDTADVvDYTc_zK1-1e2o0fgeKFPDdZ_0R8Qk4sbRkuruuOc6W5p0wH3dH" async></script> -->
<script>
    var stripe = Stripe(`pk_live_51JFBOQAbQts6QUfnGHR8Wpq7F4szK8QQIVd15uvZXeL4aDMAmstX8gtsmo3HMfWOlqT3QDbRaVycGnVovU1FHwFY00RFvDpJ8H`);
    var elements = stripe.elements();
    var card;
    var card1;
    var card2;
    // Custom styling can be passed to options when creating an Element.
    var style = {
        base: {
            // Add your base input styles here. For example:
            fontFamily: 'sans-serif',
            lineHeight: '36px',
            fontSize: '14px',
            '::placeholder': {
                color: '#aaa',
            },
            ':-webkit-autofill': {
                color: '#e39f48',
            },
        },
    };
    
    // elements.on('change', function(event) {
    //     if (event.complete) {
    //         // enable payment button

    //     } else if (event.error) {
    //         // show validation to customer
    //     }
    // });
    // elements.on('change', function(event) {
    //     var displayError = document.getElementById('card-errors');
    //     if (event.error) {
    //         displayError.textContent = event.error.message;
    //     } else {
    //         displayError.textContent = '';
    //     }
    // });
    import $ from 'jquery';
    import axios from 'axios'
    import { validationMixin } from 'vuelidate'
    import { required , email, helpers } from 'vuelidate/lib/validators'
    import { mapActions} from "vuex";
    // import Paypal from '@/components/Paypal.vue'
    const char = helpers.regex('alphaNumAndDot', /^[a-zA-Z\s]*$/);
    export default {
        props:['contractDetails','currentpayment_arr'],
        mixins: [validationMixin],
        validations: {
            addBilling: {
                method_type:{ required },
                first_name:{ required , char },
                last_name:{ required, char },
                email:{ required ,email },
                country:{ required },
                state:{ required },
                city:{ required },
                zipcode:{ required },
                address1:{ required },
            },
        },
        components: {
            //  Paypal
        },
        computed:{
            estimatedTax(){
                if(this.currentpayment_arr.amount > 0){
                    return ((this.currentpayment_arr.amount/100)*5).toFixed(2);
                    // return numeral(vale).format('0.000');
                }
            },
            estimatedTotal(){
                if(this.currentpayment_arr.amount > 0){
                    return parseFloat(parseFloat(this.currentpayment_arr.amount)+parseFloat(this.estimatedTax)).toFixed(2);
                    // return numeral(vale).format('0.000');
                }
            },
            // this fun id for validate for first name
            billing_firstname() {
                const errors = []
                if (!this.$v.addBilling.first_name.$dirty) return errors
                !this.$v.addBilling.first_name.required && errors.push('First Name is required')
                !this.$v.addBilling.first_name.char && errors.push('Only character allowed')
                return errors
            },
            // this fun id for validate for last name
            billing_lastname() {
                const errors = []
                if (!this.$v.addBilling.last_name.$dirty) return errors
                !this.$v.addBilling.last_name.required && errors.push('Last Name is required')
                !this.$v.addBilling.last_name.char && errors.push('Only character allowed')

                return errors
            },
            // this fun id for validate for first name
            billing_email() {
                const errors = []
                if (!this.$v.addBilling.email.$dirty) return errors
                !this.$v.addBilling.email.email && errors.push("Must be valid e-mail");
                !this.$v.addBilling.email.required && errors.push('Email is required')
                return errors
            },
            // this fun id for validate for first name
            billing_country() {
                const errors = []
                if (!this.$v.addBilling.country.$dirty) return errors
                !this.$v.addBilling.country.required && errors.push('Country is required')
                return errors
            },
            // this fun id for validate for first name
            billing_state() {
                const errors = []
                if (!this.$v.addBilling.state.$dirty) return errors
                !this.$v.addBilling.state.required && errors.push('State is required')
                return errors
            },
            // this fun id for validate for first name
            billing_city() {
                const errors = []
                if (!this.$v.addBilling.city.$dirty) return errors
                !this.$v.addBilling.city.required && errors.push('Cityis required')
                return errors
            },
            // this fun id for validate for first name
            billing_zipcode() {
                const errors = []
                if (!this.$v.addBilling.zipcode.$dirty) return errors
                !this.$v.addBilling.zipcode.required && errors.push('Zipcode is required')
                return errors
            },
            // this fun id for validate for first name
            billing_address1() {
                const errors = []
                if (!this.$v.addBilling.address1.$dirty) return errors
                !this.$v.addBilling.address1.required && errors.push('Address is required')
                return errors
            },
            
        },
        data(){
            return{
                cardNumber:'',
                cardExpiry:'',
                cardCvc:'',
                billingForm:true,
                CountryData:[],
                stateData:[],
                cityData:[],
                addBilling:{
                    method_type:'card',
                    first_name:'',
                    last_name:'',
                    email:this.$store.state.currentUserData.email_id,
                    country:'',
                    state:'',
                    city:'',
                    zipcode:'',
                    address1:'',
                    address2:'',
                    payment_method:'',
                },
                savedBillings:[],
                checkoutdata:'',
                removeBillId:'',
            }
        },
        mounted: function () { 
            elements._elements = [];
            // Create an instance of the card Element.
            card = elements.create('cardNumber', {style: style});
            // Add an instance of the card Element into the `card-element` <div>.
            card.mount('#card-number');
            // card.mount(this.$refs.card);
            card1 = elements.create('cardExpiry', {style: style});
            // Add an instance of the card Element into the `card-element` <div>.
            card1.mount('#card-expiry');

            card2 = elements.create('cardCvc', {style: style});
            card2.mount('#card-cvc');
            
            // this.getBillings();
            this.listBillingDiv();
            this.getCountry();
          
        },
        methods:{
            // this fun is for to assgin select payment method
            checkoutdataFun(_v){
                this.checkoutdata =_v.id;
            },
            
            // this fun is for to add billing method 
            async handleForm(event) {
                var submtiData=this.addBilling;
                var country_name;
                this.CountryData.filter(data=>{
                    if(data.country_id == this.addBilling.country){
                        country_name=data.sortname
                    }
                })
                var city_name;
                this.cityData.filter(data=>{
                    if(data.city_id == this.addBilling.city){
                        city_name=data.city_name
                    }
                })
                const paymentmethod = await stripe.createPaymentMethod({
                    type: 'card',
                    card: card,
                    billing_details: {
                        name: this.addBilling.first_name+' '+this.addBilling.last_name,
                        email: this.addBilling.email,
                        address: {
                            city:city_name,
                            country:country_name,
                            line1:this.addBilling.address1,
                            line2:this.addBilling.address2,
                            postal_code:this.addBilling.zipcode,
                        }
                    },
                }).then(function(result) {
                    // Handle result.error or result.paymentMethod
                    if (typeof result.error != 'undefined' && result.error != '') {
                        $('#card-error').show();
                        document.getElementById('card-error').innerHTML =result.error.message
                    }else{
                        submtiData.payment_method=result.paymentMethod.id;
                        $('#card-error').hide();
                    }
                });
                this.$v.addBilling.$touch();
                if (!this.$v.addBilling.$invalid) {
                    // submtiData.payment_method=paymentmethod.paymentMethod.id;
                    if (submtiData.payment_method != '') {
                        this.$emit('hideLoader');
                        submtiData.country=country_name;
                        submtiData.city=city_name;
                        // return false;
                        var res = await this.genericAPIPOSTRequest({
                            requestUrl: "createStripecustomrer",
                            params: submtiData,
                        }); 
                        if (res) {
                            // return false;
                            this.getBillings();
                            if (res[0] == 'Card Already Exits') {
                                this.$toastr.e(res);
                            }else{
                                this.$toastr.s(res);
                                this.listBillingDiv();
                            }
                            this.$emit('hideLoader');
                        }else{
                            this.$toastr.e('New Billing details Not Added');
                            this.$emit('hideLoader');
                            return false;
                        }    
                    }else{
                        this.$toastr.e('New Billing details Not Added');
                        return false;
                    }
                }
                
                // const accountResult = await stripe.createToken('account', {
                //     business_type: 'company',
                //     company: {
                //     name: 'Qapin',
                //     address: {
                //         line1: 'china',
                //         city: 'china',
                //         state: 'china',
                //         postal_code: '452001',
                //     },
                //     },
                //     tos_shown_and_accepted: true,
                // });
                //  var customer = await stripe.customers.create({
                //     email: 'test@gmai.com',
                //     name : 'test',
                //     address: {
                //         city: "indore",
                //         country: "IN",
                //         line1: "indore",
                //         line2: "indore",
                //         postal_code: "452001",
                //         state: "MP"
                //     }

                // });
                // return false
                // const personResult = await stripe.createToken('person', {
                //     person: {
                //     first_name: document.querySelector('.inp-person-first-name').value,
                //     last_name: document.querySelector('.inp-person-last-name').value,
                //     address: {
                //         line1: document.querySelector('.inp-person-street-address1').value,
                //         city: document.querySelector('.inp-person-city').value,
                //         state: document.querySelector('.inp-person-state').value,
                //         postal_code: document.querySelector('.inp-person-zip').value,
                //     },
                //     },
                // });
                // return false
                // if (accountResult.token && personResult.token) {
                //     // document.querySelector('#token-account').value = accountResult.token.id;
                //     // document.querySelector('#token-person').value = personResult.token.id;
                //     // document.querySelector('.my-form').submit();
                // }
            },
            // this fun is for to hide show
            addBillingDiv(){
                // paymentlistDiv to hide
                document.getElementById("paymentlistDiv").style.display = "none";
                // addList to show
                document.getElementById("addList").style.display = "block";

            },
            // this fun is for to hide show
            listBillingDiv(){
                // paymentlistDiv to show
                document.getElementById("paymentlistDiv").style.display = "block";
                // addList to hide
                document.getElementById("addList").style.display = "none";
            },
            ...mapActions(["genericAPIPOSTRequest"]),
            
            async submitForPay(token) {
                if (this.checkoutdata !='') {
                    this.$emit('hideLoader');
                    // Insert the token ID into the form so it gets submitted to the server
                    var res = await  this.genericAPIPOSTRequest({
                        requestUrl: "paymentSubmit",
                        params: {
                            'amount' : this.estimatedTotal,
                            'currency' : 'usd',
                            'description' : this.currentpayment_arr.description,
                            'payment_method' : this.checkoutdata,
                            'job_id':this.currentpayment_arr.job_id,
                            // 'user_id':this.currentpayment_arr.user_id,
                            'job_milestone_id':this.currentpayment_arr.job_milestone_id,
                            'estimatedTax' : this.estimatedTax,
                        },
                    });  
                    if (res) {
                        // this.$toastr.s('payment Successfully done');
                        this.$emit('milseStoneDatarefresh');
                        this.$emit('closeModal');
                        this.$emit('thankYouModal')
                        this.$emit('paymentResponse',true);
                        this.$emit('hideLoader');
                    }else{
                        this.$toastr.e('payment Not done');
                        this.$emit('paymentResponse',false);
                    }
                }else{
                    this.$toastr.e('Please Select Billing Method');
                    return false;
                }
            },
            integrategateway(){
                // Create an instance of the card Element.
                card = elements.create('cardNumber', {style: style});
                // Add an instance of the card Element into the `card-element` <div>.
                card.mount('#card-number');
                // card.mount(this.$refs.card);
                card1 = elements.create('cardExpiry', {style: style});
                // Add an instance of the card Element into the `card-element` <div>.
                card1.mount('#card-expiry');

                card2 = elements.create('cardCvc', {style: style});
                card2.mount('#card-cvc');
            },
            // this fun is for to get Country
            async getCountry(){
                var res = await this.genericAPIPOSTRequest({
                    requestUrl: "common/getCountryList",
                    params: {},
                });  
                this.CountryData = res;
                this.addBilling.country = '';
                this.addBilling.state = '';
                this.addBilling.city = '';
            },
            // THIS FUNCTIONIS FOR GET STATE DATA
            async changecountry(event){
                var res = await this.genericAPIPOSTRequest({
                    requestUrl: "common/getStateList",
                    params: { country_id: event },
                });  
                if(res.length > 0){
                    this.stateData = res;
                }
                this.cityData =[];
                this.addBilling.state = '';
                this.addBilling.city = '';
            },
            // THIS FUNCTIONIS FOR GET CITY DATA
            async changestate(event){
                var res = await this.genericAPIPOSTRequest({
                    requestUrl: "common/getCityList",
                    params: { state_id: event },
                });  
                this.cityData = res;
                this.addBilling.city = '';
            },
            //this fun is for to get billing methods
            async getBillings(){
                var savebilling = await this.genericAPIPOSTRequest({
                    requestUrl: "getBillingMethod",
                    params: {},
                });
                if (savebilling.length>0) {
                    this.savedBillings = savebilling;
                }else{
                    this.savedBillings =[];
                }
            },
            // this fun is for to remove billing details
            async removeBillingMethod(_i){
                this.$emit('hideLoader');
                var removebilling = await this.genericAPIPOSTRequest({
                    requestUrl: "removeBillingMethod",
                    params: {
                        'payment_id':_i
                    },
                });
                // consaole.log('response',removebilling)
                if (removebilling) {
                    this.$bvModal.hide('confirm-modal')
                    this.getBillings();
                    this.$toastr.s('Billing details removed Successfully')
                }
                this.$emit('hideLoader');
            },
        }
    };
</script>
<style>
    #bonus-modal .input-group .form-control{height:40px;}
    #bonus-modal .input-group-text{padding: 7px 12px;border-radius: 0.25rem 0 0 0.25rem;}
    
    /* #payment-form input, */
    #payment-form .StripeElement { 
        font-size: 15px!important;
        padding: 0px 12px!important;
        background: #FFFFFF;
        border: 1px solid #CECECE!important;
        border-radius:6px;
    }

    #payment-form .StripeElement:focus{box-shadow:0 0 0 0.25rem rgb(13 110 253 / 25%);}
    #payment-form .StripeElement iframe {height: 38px!important;} 
    #payment-form .ElementsApp input {    line-height: 45px!important;height: 64px!important;}
    #payment-modal .modal-body{padding:0px;}
    #payment-modal .img-wrapper{width:27%!important;height:75px!important;border-radius:10%;padding: 2px;
    border: 1px solid #eee;}
    #payment-modal .img-wrapper img{width:100%;border-radius:10%;}
    #payment-modal .pay-method-wrap .custom-control {margin-bottom:0px;} 
    #payment-modal .scroll { 
        /* min-height: 205px; */
        overflow-y: auto;overflow-x: hidden; max-height: 217px;}
    #payment-modal .custom-control-label:after, .custom-control-label:before{top:0.1rem!important;}
    
</style>
<!-- this is old payment gateway -->
<!-- <template>
    <form @submit.prevent="newFunction" id="payment-form">
        <div class="form-row">
            <label for="card-element">
            Credit or debit card
            </label>
            <div id="card-element"></div>
            <div id="card-expiry"></div>
            <div id="card-cvc"></div>
            <div id="card-errors" role="alert"></div>
        </div>

        <button>Submit Payment</button>
    </form>
</template> -->
<!-- reference link -->
<!-- https://stripe.com/docs/payments/accept-a-payment-charges?platform=web&client=html -->
<!-- https://www.digitalocean.com/community/tutorials/vuejs-stripe-elements-vue-integration#what-is-stripe-elements -->
<!-- https://stripe.com/partners/directory/vue-stripe -->
<!-- https://www.vuescript.com/vue-plugin-stripe-checkout/ -->
<!-- https://vuestripe.com/stripe-elements/card/ -->
<!-- https://vuejsexamples.com/a-vue-2-component-collection-for-stripe-js/ -->
<!-- https://stripe.com/docs/js -->
<!-- reference link for multiple payment option -->
<!-- https://stripe.com/docs/payments/quickstart -->
<!-- <script src="https://js.stripe.com/v3/"></script> -->
  
<!-- <script>
    var stripe = Stripe(`pk_test_51JdqlfBCeXjCoMqA9kvCfL3NeJ1S3QD3ICvbmB66GwjwIvi9XafbWxFVEZqDUq0YENdHJY4OEhxM0QG5sb2HgRmi00ZPTT890M`);
    var elements = stripe.elements();
    // var card = 'debit card';
    var card;
    var card1;
    var card2;
    // Custom styling can be passed to options when creating an Element.
    var style = {
        base: {
            // Add your base input styles here. For example:
            fontSize: '16px',
            color: '#32325d',
        },
    };

    import axios from 'axios'
    import { mapActions} from "vuex";
    export default {
        mounted: function () {
            // Create an instance of the card Element.
            card = elements.create('cardNumber', {style: style});
            // Add an instance of the card Element into the `card-element` <div>.
            card.mount('#card-element');
            // card.mount(this.$refs.card);
            card1 = elements.create('cardExpiry', {style: style});
            // Add an instance of the card Element into the `card-element` <div>.
            card1.mount('#card-expiry');

            card2 = elements.create('cardCvc', {style: style});
            card2.mount('#card-cvc');
        },
        methods:{
            ...mapActions(["genericAPIPOSTRequest"]),
            async newFunction(){
                // Create a token or display an error when the form is submitted.
                var form = document.getElementById('payment-form');
                var that = this;
                form.addEventListener('submit', function(event) {
                    event.preventDefault();
                    stripe.createToken(card).then(function(result) {
                        // if (result.error) {
                        //     // Inform the customer that there was an error.
                        //     var errorElement = document.getElementById('card-errors');
                        //     errorElement.textContent = result.error.message;
                        //     that.$toastr.e(result.error.message);
                        // } else {
                        //     // Send the token to your server.
                        //     that.stripeTokenHandler(result.token.id)
                        // }
                    });
                });
            },
            async stripeTokenHandler(token) {
                // Insert the token ID into the form so it gets submitted to the server
                // var res = await  this.genericAPIPOSTRequest({
                //     requestUrl: "paymentSubmit",
                //     params: {
                //         'amount' : 150,
                //         'currency' : 'usd',
                //         'description' : 'Example charge',
                //         'source' : token,
                //     },
                // });  
                // if (res) {
                //     this.$toastr.s('payment Successfully done');
                    
                // }
            }
        }
    };
</script> -->