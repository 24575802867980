<template>
    <div class="relative w-full h-auto overflow-auto bg-white home-a">
      <div class="w-full pt-12 mb-20 xl:pt-40 md:pt-20 xl:px-0  css-144ctnr">
              <div class="md:justify-between md:flex w-full mx-auto xl:mx-auto xl:max-w-7xl">
          <div>
              <div class=""><span class="css-79e8z3">PRICING </span></div>
              <h1
                  class="w-full max-w-2xl text-4xl fw-600 xl:pt-6 md:pt-24 md:max-w-lg xl:max-w-2xl xl:text-6xl css-1wfjlp">
                  Save big with Qapin and Expand your business efficiently</h1>
                  <!-- Save big with <a href="javascript:void(0)" @click="$router.push({name:'home',path:'/'}).catch(()=>{})">Qapin</a> and Expand your business efficiently</h1> -->
              <div class=" xl:mt-1">
                <p class="w-full max-w-2xl text-4xl fw-400 md:max-w-lg xl:max-w-2xl xl:text-2xl css-1plhbgk"
                style="font-size: 20px; line-height: 1.3; font-family: inherit;">
                Scale your global team and never worry about cost</p>
              </div>
              <div class="items-center mt-10 space-y-4 md:space-x-4 md:space-y-0 md:flex"><button @click="$router.push('/client/dashboard').catch(()=>{})"
                      class="w-full px-4 py-2 text-base text-white transition-all duration-500 ease-in-out rounded-md shadow-sm cursor-pointer md:w-40 hover:bg-opacity-50 bg-primary-500 hover:shadow-lg">
                      Post Job </button><br>
                  <button @click="$router.push('/freelancer').catch(()=>{})"
                      class="w-full px-4 py-2 text-base text-black transition-all duration-500 ease-in-out border-solid rounded-md shadow-sm cursor-pointer md:w-40 border-1 hover:text-white border-primary-500 hover:bg-primary-500 hover:shadow-lg">
                      Find Job </button>
              </div>
          </div>
                  <div class="mt-24 md:mt-0">
            <img src="web/makeapayment.svg" alt="" class=" object-contain h-96 w-100"  />
        </div>
      </div>
    </div>

    <!-- <TheHomeSiteHeader /> -->
    <div class="w-full pt-5 mx-auto xl:mx-auto px-sm-1" style="max-width: 80rem;">
      <div class="container master-tag">
        <div id="w-node-_412574db-0221-58f3-f7ef-37cfdc222117-e5ae7e41" class="column">
          <h1 class="flex justify-center text-3xl text-center text-black xl:text-4xl xl:text-left fw-bold ">
            Save thousands of dollars while growing your business </h1>
        </div>
      </div>
    </div>

    <div class="w-full mx-auto xl:mx-auto pb-sm-0 px-xl-5 pb-lg-5" style="max-width: 50rem;">
      <div class="md:justify-between md:space-x-10 md:flex">
        <div class="flex flex-wrap">
          <div class="w-full">
            <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row pl-0 pricing-btns">
              <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                  v-on:click="toggleTabs(1)"
                  v-bind:class="{ 'text-pink-600 bg-white': openTab !== 1, 'text-white bg-pink-600': openTab === 1 }">
                  Pricing for Employers
                </a>
              </li>
              <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
                  v-on:click="toggleTabs(2)"
                  v-bind:class="{ 'text-pink-600 bg-white': openTab !== 2, 'text-white bg-pink-600': openTab === 2 }">
                  Pricing for Freelancers
                </a>
              </li>
            </ul>
            <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg h-auto rounded ">
              <div class="flex-auto table-box" style="margin: 5px;">
                <div class="tab-content tab-space">
                  <div v-bind:class="{ 'hidden': openTab !== 1, 'block': openTab === 1 }">

                    <div class="tbl-content">
                      <table style="cellpadding:0; cellspacing:0; border:0">
                        <tbody>
                          <th colspan="2">You can find, hire, and manage source inspectors on Qapin.</th>
                          <!-- <th colspan="2">You can find, hire, and manage source inspectors on <a href="javascript:void(0)" @click="$router.push({name:'home',path:'/'}).catch(()=>{})">Qapin</a>.</th> -->
                          <tr>
                            <td>Post Unlimited Jobs</td>
                            <td><i class='fa fa-check-circle' style='color:#f5613c'></i></td>
                          </tr>
                          <tr style="background: rgba(242, 97, 60, 0.07);">
                            <td>Request Quotes from Top Freelancers</td>
                            <td><i class='fa fa-check-circle' style='color:#f5613c'></i></td>
                          </tr>
                          <tr>
                            <td>Advanced Work Management</td>
                            <td><i class='fa fa-check-circle' style='color:#f5613c'></i></td>
                          </tr>
                          <tr style="background: rgba(242, 97, 60, 0.07);">
                            <td>In-app Communication</td>
                            <td><i class='fa fa-check-circle' style='color:#f5613c'></i></td>
                          </tr>
                          <tr>
                            <td>Projects Activity Reports</td>
                            <td><i class='fa fa-check-circle' style='color:#f5613c'></i></td>
                          </tr>
                          <tr style="background: rgba(242, 97, 60, 0.07);">
                            <td>Use escrow protection for</td>
                            <td><i class='fa fa-check-circle' style='color:#f5613c'></i></td>
                          </tr>
                          <tr>
                            <td>Percentage (%) of work order value</td>
                            <td><i class='fa fa-check-circle' style='color:#f5613c'></i></td>
                          </tr>
                          <th colspan="2">We do charge a nominal handling fee of 5% for each invoice you pay. </th>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div v-bind:class="{ 'hidden': openTab !== 2, 'block': openTab === 2 }">
                    <div class="tbl-content">
                      <table style="cellpadding:0; cellspacing:0; border:0">
                        <tbody>
                          <th colspan="2">You can search, apply, and manage inspection jobs on Qapin.</th>
                          <!-- <th colspan="2">You can search, apply, and manage inspection jobs on <a href="javascript:void(0)" @click="$router.push({name:'home',path:'/'}).catch(()=>{})">Qapin</a>.</th> -->
                          <tr>
                            <td>Apply Unlimited Jobs</td>
                            <td><i class='fa fa-check-circle' style='color:#f5613c'></i></td>
                          </tr>
                          <tr style="background: rgba(242, 97, 60, 0.07);">
                            <td>Get paid automatically </td>
                            <td><i class='fa fa-check-circle' style='color:#f5613c'></i></td>
                          </tr>
                          <tr>
                            <td>Advanced Work Management</td>
                            <td><i class='fa fa-check-circle' style='color:#f5613c'></i></td>
                          </tr>
                          <tr style="background: rgba(242, 97, 60, 0.07);">
                            <td>In-app Communication</td>
                            <td><i class='fa fa-check-circle' style='color:#f5613c'></i></td>
                          </tr>
                          <tr>
                            <td>Advanced Work History</td>
                            <td><i class='fa fa-check-circle' style='color:#f5613c'></i></td>
                          </tr>
                          <tr style="background: rgba(242, 97, 60, 0.07);">
                            <td>Projects Activity Reports</td>
                            <td><i class='fa fa-check-circle' style='color:#f5613c'></i></td>
                          </tr>
                          <tr>
                            <td>Use escrow protection for milestone </td>
                            <td><i class='fa fa-check-circle' style='color:#f5613c'></i></td>
                          </tr>
                          <tr style="background: rgba(242, 97, 60, 0.07);">
                            <td> Percentage (%) of work order value </td>
                            <td><i class='fa fa-check-circle' style='color:#f5613c'></i></td>
                          </tr>
                          <th colspan="2">We do charge a nominal commission of 5% for each work order you work.</th>
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container w-full mb-2 mx-auto xl:mx-auto content-box px-sm-1 px-xl-5 pb-lg-5">
      <div class="md:justify-between md:space-x-10 md:flex">
        <div>
          <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi ">
            Maximize profitability
          </h1>
          <div class="mt-10 md:mt-6">

            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
              <i class="fa fa-check"></i> Real-time, on-demand, market-wide pricing and coverage data
            </p>
            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2"><i class="fa fa-check"></i>
              Actionable data from across the Qapin platform that can be filtered by location, type of work, keyword,
              <!-- Actionable data from across the <a href="javascript:void(0)" @click="$router.push({name:'home',path:'/'}).catch(()=>{})">Qapin</a> platform that can be filtered by location, type of work, keyword, -->
              client, project, and more</p>
            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2"><i class="fa fa-check"></i>
              Help sales respond to RFIs more quickly, competitively, and profitably</p>
            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2"><i class="fa fa-check"></i>
              Enable operations to proactively identify and fill coverage gaps</p>
          </div>
        </div>
        <div class="mt-24 md:mt-0">
          <img src="web/Web-images/pricing/Pricing-1.jpg" alt="" class="about-page-img" style="width: 100%; height: 500px;" />
        </div>

      </div>
    </div>


    <div class="container w-full  mb-2 mx-auto xl:mx-auto content-box px-sm-1 px-xl-5 pb-lg-5">
      <div class="md:justify-between md:space-x-10 md:flex">
        <div class="mobile-sections">
          <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi ">
            Smart Talent Management
          </h1>
          <div class="mt-10 md:mt-6">

            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
              <i class="fa fa-check"></i> Everything needed to source, vet, and manage on-demand talent at scale
            </p>
            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2"><i class="fa fa-check"></i>
              Our platform helps source and vet hard-to-find or specialized skills</p>
            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2"><i class="fa fa-check"></i>
              Advanced freelancers Profiles allow clients to focus on Engineers with recent, relevant, proven experience</p>
              <!-- Advanced freelancers Profiles allow clients to focus on <a href="javascript:void(0)" @click="$router.push({name:'About',path:'/about'}).catch(()=>{})">Engineers</a> with recent, relevant, proven experience</p> -->
            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2"><i class="fa fa-check"></i>
              Pre-Assignment Surveys enable teams to verify technical knowledge and skill before assignments</p>
              <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2"><i class="fa fa-check"></i>
                Smart Talent Pools notify you when a preferred talents no longer meets your standards for screenings and quality ratings</p>
          </div>
        </div>
        <div class="mt-24 md:mt-0 mt-sm-2">
          <img src="web/Web-images/pricing/Pricing-2.jpg" alt="" class="about-page-img" style="width: 100%; height: 500px;" />
        </div>
        <div class="desktop-sections">
          <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi ">
            Smart Talent Management
          </h1>
          <div class="mt-10 md:mt-6">

            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
              <i class="fa fa-check"></i> Everything needed to source, vet, and manage on-demand talent at scale
            </p>
            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2"><i class="fa fa-check"></i>
              Our platform helps source and vet hard-to-find or specialized skills</p>
            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2"><i class="fa fa-check"></i>
              Advanced freelancers Profiles allow clients to focus on Engineers with recent, relevant, proven experience</p>
              <!-- Advanced freelancers Profiles allow clients to focus on <a href="javascript:void(0)" @click="$router.push({name:'About',path:'/about'}).catch(()=>{})">Engineers</a> with recent, relevant, proven experience</p> -->
            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2"><i class="fa fa-check"></i>
              Pre-Assignment Surveys enable teams to verify technical knowledge and skill before assignments</p>
              <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2"><i class="fa fa-check"></i>
                Smart Talent Pools notify you when a preferred talents no longer meets your standards for screenings and quality ratings</p>
          </div>
        </div>


      </div>
    </div>


    <div class="container w-full pb-20  mb-2 mx-auto xl:mx-auto content-box px-sm-1 mobile-padding">
      <div class="md:justify-between md:space-x-10 md:flex">
  
        <div>
          <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi ">
            Smart Productivity Suite
          </h1>
          <div class="mt-10 md:mt-6">

            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
              <i class="fa fa-check"></i> Automatically assign work based on pre-configured selection rules, significantly reducing time to assign
            </p>
            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2"><i class="fa fa-check"></i>
              Review and approve work with just a few clicks, saving valuable time without sacrificing accuracy</p>
            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2"><i class="fa fa-check"></i>
              Hire global engineers for field inspections or office work with few clicks and pay from anywhere. </p>
              <!-- Hire global <a href="javascript:void(0)" @click="$router.push({name:'About',path:'/about'}).catch(()=>{})">engineers</a> for field inspections or office work with few clicks and pay from anywhere. </p> -->
         
          </div>
        </div>
        <div class="mt-24 md:mt-0">
          <img src="web/Web-images/pricing/Pricing-3.jpg" alt="" class="about-page-img" style="width: 100%; height: 500px;" />
        </div>

      </div>
    </div>



    <div class="xl:px-0">
      <div class="inset-0 xl:px-0">
        <div class="left-0 right-0 w-full mx-auto bg-center shadow-xl xl:px-10 xl:h-auto"
          style="background:  rgb(242 97 60);">
          <div class="py-6 text-center xl:py-14">
            <p class="text-sm uppercase fw-600 text-white text-primary-500">JOIN QAPIN </p>
            <!-- <p class="text-sm uppercase fw-600 text-white text-primary-500"><a href="javascript:void(0)" @click="$router.push({name:'home',path:'/'}).catch(()=>{})">JOIN QAPIN</a> </p> -->
            <h4 class="mt-2 text-xl text-white fw-bold md:text-5xl">
              Register for Qapin today
              <!-- Register for <a href="javascript:void(0)" @click="$router.push({name:'home',path:'/'}).catch(()=>{})">Qapin</a> today -->
            </h4>
            <p class="max-w-sm mx-auto mt-4 text-sm text-white xl:text-lg fw-200 xl:w-auto" style="max-width: 60rem;">
              it’s the future of inspection services. Bringing the inspection community
              together in one easy to use platform, Qapin is simple, free and convenient
              <!-- together in one easy to use platform, <a href="javascript:void(0)" @click="$router.push({name:'home',path:'/'}).catch(()=>{})">Qapin</a> is simple, free and convenient -->
              but don’t take our word for it - create your
            </p>
            <div class="px-10 mt-6 xl:px-0">
              <button @click="$router.push('/login').catch(()=>{})"
                class="w-40 px-4 py-2 text-base text-black transition-all duration-500 ease-in-out rounded-md bg-white shadow-sm cursor-pointer md:w-52 hover:bg-opacity-50 hover:text-black fw-200 xl:w-auto bg-primary-500 hover:shadow-lg">
                Join for Free
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <TheHomeSiteFooter class="" v-if="!isAuth" />
  </div>
</template>

<script>
// import TheHomeSiteHeader from "../../TheHomeSiteHeader.vue";
// import TheHomeSiteHeader from "../../components/TheHomeSiteHeader.vue";
// import TheHomeSiteFooter from "./TheHomeSiteFooter.vue";
import "tiny-slider/src/tiny-slider.scss";
export default {
  components: {
    // TheHomeSiteFooter,
    //  TheHomeSiteHeader
  },
  data() {
    return {
      isAuth: null,
      openTab: 1
    }
  },
  methods: {
    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber
    }
  },
  mounted() {
    this.isAuth = localStorage.getItem("token");
    if (this.$route.name == "ExpireLink") {
      this.isAuth = null;
    }
    this.getCurrentUserDetails();
  },
}
</script>
<style scoped>
.made-with-love {
  margin-top: 40px;
  padding: 10px;
  clear: left;
  text-align: center;
  font-size: 10px;
  font-family: arial;
  color: #fff;
}

.made-with-love i {
  font-style: normal;
  color: #F50057;
  font-size: 14px;
  position: relative;
  top: 2px;
}

.made-with-love a {
  color: #fff;
  text-decoration: none;
}

.made-with-love a:hover {
  text-decoration: underline;
}

.text-xs {
  padding: 8px 60px !important;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
}

.bg-pink-600 {
  background-color: #f2613c !important;
  cursor: pointer;
}

.text-pink-600 {
  color: #f2613c !important;
  cursor: pointer;
}

.css-144ctnr {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-flow: row nowrap;
    column-gap: 64px;
    padding: 126px 64px;
    min-height: 567px;
    background: rgba(242, 97, 60, 0.07);
}

.css-79e8z3 {
  margin: 0px;
  letter-spacing: 0.00938em;
  color: rgb(13, 32, 73);
  font-family: Silka, Helvetica, sans-serif;
  line-height: 170%;
  background-color: rgb(191 153 153 / 20%);
  padding: 4px 8px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.css-12k8mko i {
  margin-right: 15px;
  color: chocolate;
}


.css-12k8mko {
  margin: 0px;
  color: rgb(6, 18, 44);
  font-family: Silka, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  max-width: 600px;
  display: flex;
  align-items: baseline;
}

.css-1plhbgk {
  margin: 0px;
  letter-spacing: 0.00938em;
  color: rgb(28, 28, 28);
  font-family: Silka, Helvetica, sans-serif !important;
  line-height: 170% !important;
  font-weight: 400 !important;
  font-size: 18px !important;
}

.css-1wfjlp {
  margin-top: 0px;
  letter-spacing: 0.00938em;
  color: rgb(16, 16, 16);
  font-family: Silka, Helvetica, sans-serif;
  line-height: 120%;
  font-weight: 700 !important;
  font-size: 64px !important;
}

.css-ajm3pi {
  max-width: 40rem;
  padding-top: 100px;
  margin: 0px 0px 24px;
  letter-spacing: 0.00735em;
  color: rgb(16, 16, 16);
  font-family: Silka, Helvetica, sans-serif;
  line-height: 43px;
  font-weight: 700 !important;
  max-width: 600px !important;
  font-size: 28px !important;
}

.hero-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  line-height: 75px;
}

.margin-15 {
  margin: 0px;
  color: rgb(6, 18, 44);
  font-family: Silka, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;

}

.agenct-scale {
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.36;
  margin-bottom: 16px;
  margin-top: 0;
  text-decoration: none;
}



@media (min-width: 1280px) {
  .content-box{
  padding: 45px 100px;
}
  .xl\:text-6xl {
    font-size: 4.75rem;
    line-height: 1.2;
  }

  .xl\:pt-40 {
    padding-top: 12rem;
  }
  .xl\:max-w-7xl {
    max-width: 85rem;
}
  table {
    width: 100%;
    table-layout: fixed;
  }

  th {
    padding: 8px 15px !important;
    text-align: center;
    font-weight: 700;
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
  }

  table tbody {
    border: 0px solid #0000001f !important;
  }


  td i {
    padding: 0px 30px 0px 170px !important;
  }


  td {
    padding: 12px 0px 12px 90px !important;
    text-align: left;
    vertical-align: middle;
    font-weight: 500;
    font-size: 17px;
    color: #000000;
  }


  table th {
    background-color: #f2613c !important;
    border-radius: 4px;

  }
}

table th {
  background-color: #f2613c !important;
  border-radius: 4px;

}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {

  .mt-24{
    margin-top: 0px;
  }
  .pb-24 {
    padding-bottom: 0px;
  }




  .css-ajm3pi {
    max-width: 40rem;
    padding-top: 0px;
    margin: 0px 0px 24px;
    letter-spacing: 0.00735em;
    color: rgb(16, 16, 16);
    font-family: Silka, Helvetica, sans-serif;
    line-height: 43px;
    font-weight: 700 !important;
    max-width: 600px !important;
    font-size: 25px !important;
}

  .css-1wfjlp {
    margin-top: 0px;
    letter-spacing: 0.00938em;
    color: rgb(16, 16, 16);
    font-family: Silka, Helvetica, sans-serif;
    line-height: 120%;
    font-weight: 700 !important;
    font-size: 28px !important;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .css-1wfjlp {
    margin-top: 0px;
    letter-spacing: 0.00938em;
    color: rgb(16, 16, 16);
    font-family: Silka, Helvetica, sans-serif;
    /* line-height: 120%; */
    font-weight: 700 !important;
    font-size: 35px !important;
    padding-top: 1rem;
  }

}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .css-1wfjlp {
    margin-top: 0px;
    letter-spacing: 0.00938em;
    color: rgb(16, 16, 16);
    font-family: Silka, Helvetica, sans-serif;
    font-weight: 700 !important;
    font-size: 27px !important;
    padding-top: 1rem;
  }

  table {
    width: 100%;
  }

  ul {
    padding: 30px !important;
  }

  .pb-24 {
    padding-bottom: 0px;
    height: 300px;
  }
}
@media (max-width: 480px) {

.mt-24{
  margin-top: 0px;
}
.pb-24 {
  padding-bottom: 0px;
}



.css-ajm3pi {
  max-width: 40rem;
  padding-top: 0px;
  margin: 0px 0px 24px;
  letter-spacing: 0.00735em;
  color: rgb(16, 16, 16);
  font-family: Silka, Helvetica, sans-serif;
  line-height: 43px ;
  font-weight: 700 !important;
  max-width: 600px !important;
  font-size: 25px !important;
}

.css-1wfjlp {
  margin-top: 0px;
  letter-spacing: 0.00938em;
  color: rgb(16, 16, 16);
  font-family: Silka, Helvetica, sans-serif;
  line-height: 120%;
  font-weight: 700 !important;
  font-size: 28px !important;
}
}
@media screen and (min-width: 320px) and (max-width: 815px) {
    .mb-20{
        margin-bottom:0px!important;
    }
}
</style>
