<template>
  <div
    v-if="(isAuth && this.$route.name != 'Login' && this.$route.name != 'BasicInfo' && this.$route.name != 'ExpireLink' && this.$route.name != 'ForgotPassword' && this.$route.name != 'ResetPassword' && this.$route.name != 'update-profile') || this.$route.name == 'agencyPublicProfile' || this.$route.name == 'freelancerProfile'"
    class="fixed top-0 flex items-center justify-between w-full px-4 transition-all ease-in bg-white shadow-sm duration-600 navbar-main sm:px-6">
    <!-- Logo -->
    <div class="h-full mr-4">
      <router-link :to="($store.state.accountType=='freelancer')?'/freelancer':($store.state.accountType == 'client')?'/client':'/agency/search'">
        <WebLogo class="mt-4 lg:w-full lg:h-full" />
      </router-link>
    </div>

    <!-- Mobile Version -->
    <div class="block md:hidden lg:hidden mobile-search" v-if="isAuth">
      <button @click="toggleMobileSearch" class="p-2 searchbtn">
        <SearchIcon class="w-6 h-6 text-gray-600" />
      </button>
      <div v-if="isMobileSearchVisible" class="absolute left-0 w-full mt-2 inputwrapper ">
        <form @submit.prevent="openSearch('mobile')" class="d-flex align-items-center">
          <input v-model="searchValue" type="text" name="mobile-search" id="mobile-search" placeholder="Search..."
            class="w-full px-2 py-2 border border-gray-400 h-9 rounded-md focus:outline-none hover:border-indigo-500" />
          <button class="btn bg-gray-100 border border-gray-400 cursor-pointer hover:bg-gray-400 mobile-search-btn" type="submit"><i class="fa fa-paper-plane"></i></button>
        </form>
      </div>
    </div>

    <!-- Desktop Version -->
    <div class="hidden md:block lg:block">
      <div class="relative flex justify-start ml-4 rounded-sm" v-if="isAuth">
        <form @submit.prevent="openSearch('web')">
          <input v-model="searchValue" type="text" name="search" id="search" placeholder="Search..."
            class="w-full px-2 py-2 mr-3 border border-gray-400 h-9 rounded-l-md focus:outline-border focus:outline-none lg:w-56 sm:w-60 hover:border-indigo-500" />
          <button type="submit"
            class="absolute top-0 w-10 px-2 py-1 transition-all ease-in bg-gray-100 border border-gray-400 cursor-pointer duration-600 rounded-r-md h-9 -right-6 hover:bg-gray-400">
            <SearchIcon class="absolute w-5 h-5 text-gray-600 top-2 right-2.5" />
          </button>
        </form>
      </div>
    </div>

    <WebMenuBar ></WebMenuBar>

    <!-- Login& & SignUp Resources -->
    <div class="hidden sm:hidden md:block lg:block">
      <div class="flex items-center justify-end" v-if="!isAuth">
        <label v-if="$route.path === '/login'"
          class="flex justify-end mr-5 text-sm font-medium text-gray-500 no-underline transition-all duration-300 ease-in-out w-96">
          New to Qapin ?
        </label>

        <label v-if="$route.path === '/sign-up'"
          class="flex justify-end mr-5 text-sm font-medium text-gray-500 no-underline transition-all duration-300 ease-in-out w-96">
          Already a member?
        </label>

        <router-link v-if="$route.path === '/forgot-password' || $route.path === '/reset-link'
          " to="/login"
          class="flex justify-end p-2 text-sm font-medium text-gray-500 no-underline transition-all duration-300 ease-in-out border-white border-solid rounded-md cursor-pointer hoveer:rounded-md hover:bg-gray-100 hover:border hover:border-primary-500 hover:shadow-lg hover:text-primary-500 hovr:border-solid w-max">
          Back To Login
        </router-link>

        <div class="">
          <router-link to="/sign-up" type="button" v-if="$route.path === '/login'"
            class="w-32 px-4 py-2 text-sm font-semibold text-center text-gray-900 no-underline transition-all duration-300 ease-in border border-gray-400 rounded-md shadow-sm cursor-pointer flex-inline hover:text-primary-500 hover:bg-gray-200">
            Sign Up
          </router-link>

          <router-link to="/login" type="button" v-if="$route.path === '/sign-up'"
            class="px-4 py-2 text-sm font-semibold text-gray-900 no-underline transition-all duration-300 ease-in border border-gray-400 rounded-md shadow-sm cursor-pointer hover:text-primary-500 hover:bg-gray-200">
            Sign In
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var that = '';
import { SearchIcon } from "@vue-hero-icons/solid";
import WebLogo from "./icons/WebLogo.vue";
import WebMenuBar from "../components/menu-bar/web-menu-bar.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    SearchIcon,
    WebLogo,
    WebMenuBar,
  },

  data() {
    return {
      loadAPIonload       : false,
      isOpen              : false,
      isAccount           : false,
      isMessage           : false,
      isNotification      : false,
      currentUser         : null,
      isAuth              : null,
      searchValue         : '',

      isMobileSearchVisible: false, // Controls the visibility of the search input on mobile
      mobileSearchValue: '', // Search value for mobile
      desktopSearchValue: '', // Search value for desktop
    };
  },

  computed: {
    ...mapGetters(["getcurrentUrl","getstopBodyScroll","getUserOnlinestatus"]),
  },

  async mounted() {
    that = this;
    that.checkwindowResolution();
    that.$store.dispatch("updateSingleInvoiceData", '');
    that.isAuth = that.$store.state.currentUserData.token;
    if (that.$route.name == "ExpireLink") {
      that.isAuth = null;
    }
    if(that.isAuth != null){
      that.updateOnlineOfflineStatus();
      that.getCompanyCount();
      that.getCurrentUserDetails();
      that.UpdateMessageRequestData({params:{'chat_room_id': '','start_limit': 0,'end_limit': 10,'cycle': '','lastId': '','firstId': '',}});
      that.EmptyMessageData();
      that.EmptyRoomData();
    }
    if(that.isAuth != null  && !that.loadAPIonload && (that.getcurrentUrl == 'chat'|| that.getcurrentUrl == 'contractDetails' || that.getcurrentUrl == 'freelancercontractDetail' || that.getcurrentUrl == 'agencycontractDetail' || that.getcurrentUrl == 'messageDetails') && (that.$route.name == 'chat'|| that.$route.name == 'contractDetails' || that.$route.name == 'freelancercontractDetail' || that.$route.name == 'agencycontractDetail' || that.$route.name == 'messageDetails')){
      that.loadAPIonload = true;
      if(that.$store.state.accountType == 'freelancer'){
        await that.UpdateRequestRoomData({params:{'user_id':that.$store.state.currentUserData.login_master_id,'status':1,'userType': that.$store.state.accountType,'title': '','sort': 'DESC'}});
        await that.callChatRoomApi('getchatroom');
      }else if(that.$store.state.accountType == 'client' && that.$store.state.listAccount.user != null && that.$store.state.listAccount.user != ''  && that.$store.state.currentUserData.login_master_id == that.$store.state.listAccount.user.user_id){
        await that.UpdateRequestRoomData({params:{'client_id':that.$store.state.currentUserData.login_master_id,'status':1,'userType': that.$store.state.accountType,'title': '','sort': 'DESC'}});
        await that.callChatRoomApi('getchatroom');
      }else if(that.$store.state.accountType == 'agency' && that.$store.state.currentUserData.login_master_id == that.$store.state.listAccount.company.login_master_id){
        await that.UpdateRequestRoomData({params:{'agency_id':that.$store.state.currentUserData.login_master_id,'status':1,'userType': that.$store.state.accountType,'title': '','sort': 'DESC'}});
        await that.callChatRoomApi('getchatroom');
      }else if(that.$store.state.accountType == 'client' && that.$store.state.listAccount.user != null && that.$store.state.listAccount.user != '' && that.$store.state.currentUserData.login_master_id != that.$store.state.listAccount.user.user_id ){
        await that.UpdateInvitationRequestRoomData({params:{'user_id':that.$store.state.currentUserData.permission.user_id,'member_permission_id': that.$store.state.currentUserData.permission.members_permission_id,'title': '','sort': 'DESC'}});
        await that.callChatRoomApi('getChatInvitation');
      }else if(that.$store.state.accountType == 'client' && that.$store.state.currentUserData.permission.user_id == that.$store.state.listAccount.qp_user.user_id){
        await that.UpdateInvitationRequestRoomData({params:{'user_id':that.$store.state.currentUserData.permission.user_id,'member_permission_id': that.$store.state.currentUserData.permission.members_permission_id,'title': '','sort': 'DESC'}});
        await that.callChatRoomApi('getChatInvitation');
      }else if(that.$store.state.accountType == 'agency' && that.$store.state.currentUserData.login_master_id != that.$store.state.listAccount.company.login_master_id){
        await that.UpdateInvitationRequestRoomData({params:{'user_id':that.$store.state.currentUserData.permission.user_id,'member_permission_id': that.$store.state.currentUserData.permission.members_permission_id,'title': that.search,'sort': that.sort}});
        await that.callChatRoomApi('getChatInvitation');
      }
      await that.callChatData();
    }
  },
  watch: {
    // this fun is for to check current page url 
    async getcurrentUrl(){
      if(this.isAuth != null && (this.getcurrentUrl == 'chat' || this.getcurrentUrl == 'contractDetails' || this.getcurrentUrl == 'freelancercontractDetail' || this.getcurrentUrl == 'agencycontractDetail' || this.getcurrentUrl == 'messageSideBar' || this.getcurrentUrl == 'messageDetails')){
        await this.EmptyMessageData();
        if(this.getcurrentUrl != 'chat'){
          await this.EmptyRoomData();
        }
        if(this.$store.state.accountType == 'freelancer'){
          await this.UpdateRequestRoomData({params:{'user_id':this.$store.state.currentUserData.login_master_id,'status':1,'userType': this.$store.state.accountType,'title': '','sort': 'DESC'}});
          await this.callChatRoomApi('getchatroom');
        }else if(this.$store.state.accountType == 'client' && this.$store.state.listAccount.user != null && this.$store.state.listAccount.user != ''  && this.$store.state.currentUserData.login_master_id == this.$store.state.listAccount.user.user_id){
          await this.UpdateRequestRoomData({params:{'client_id':this.$store.state.currentUserData.login_master_id,'status':1,'userType': this.$store.state.accountType,'title': '','sort': 'DESC'}});
          await this.callChatRoomApi('getchatroom');
        }else if(this.$store.state.accountType == 'agency' && this.$store.state.currentUserData.login_master_id == this.$store.state.listAccount.company.login_master_id){
          await this.UpdateRequestRoomData({params:{'agency_id':this.$store.state.currentUserData.login_master_id,'status':1,'userType': this.$store.state.accountType,'title': '','sort': 'DESC'}});
          await this.callChatRoomApi('getchatroom');
        }else if(this.$store.state.accountType == 'client' && this.$store.state.listAccount.user != null && this.$store.state.listAccount.user != '' && this.$store.state.currentUserData.login_master_id != this.$store.state.listAccount.user.user_id ){
          await this.UpdateInvitationRequestRoomData({params:{'user_id':this.$store.state.currentUserData.permission.user_id,'member_permission_id': this.$store.state.currentUserData.permission.members_permission_id,'title': '','sort': 'DESC'}});
          await this.callChatRoomApi('getChatInvitation');
        }else if(this.$store.state.accountType == 'client' && this.$store.state.currentUserData.permission.user_id == this.$store.state.listAccount.qp_user.user_id){
          await this.UpdateInvitationRequestRoomData({params:{'user_id':this.$store.state.currentUserData.permission.user_id,'member_permission_id': this.$store.state.currentUserData.permission.members_permission_id,'title': '','sort': 'DESC'}});
          await this.callChatRoomApi('getChatInvitation');
        }else if(this.$store.state.accountType == 'agency' && this.$store.state.currentUserData.login_master_id != this.$store.state.listAccount.company.login_master_id){
          await this.UpdateInvitationRequestRoomData({params:{'user_id':this.$store.state.currentUserData.permission.user_id,'member_permission_id': this.$store.state.currentUserData.permission.members_permission_id,'title': this.search,'sort': this.sort}});
          await this.callChatRoomApi('getChatInvitation');
        }
        await this.callChatData();
      }
    },
    // this fun is for to stop body scroll
    getstopBodyScroll(){
      var root = document.getElementsByTagName( 'html' )[0]; // '0' to assign the first (and only `HTML` tag)
      if(this.getstopBodyScroll == true){
        root.setAttribute( 'class', 'htmlClass' );
      }else{
        root.setAttribute( 'class', '');
      }
    },
    // this fun is for to check user online status 
    getUserOnlinestatus(){
      that.updateOnlineOfflineStatus();
    },
    
  },
  methods: {
    ...mapActions(["genericAPIPOSTRequest","UpdateCurrentUrl","UpdateBodyScrollRole"]),
    ...mapActions("global",["UpdateRequestRoomData","UpdateInvitationRequestRoomData","fetchChatRoomData","UpdateMessageRequestData","fetchChatData","EmptyMessageData","EmptyRoomData"]),
    // this fun is for to toggle search option in mobile view
    toggleMobileSearch() {
      this.isMobileSearchVisible = !this.isMobileSearchVisible;
    },
    // this fun is for to update user online offline status
    async updateOnlineOfflineStatus(){
      var res = await that.updateOnlineOffline();
      if(res){
        setTimeout(() => {
          if(this.getUserOnlinestatus == 1){
            that.updateOnlineOfflineStatus();
          }
        }, 1800000);
      }
    },
    // this fun is for to call chatroom api 
    async callChatData(){
      if(this.getcurrentUrl == 'chat' || this.getcurrentUrl == 'contractDetails' || this.getcurrentUrl == 'freelancercontractDetail' || this.getcurrentUrl == 'agencycontractDetail' || this.getcurrentUrl == 'messageSideBar' || this.getcurrentUrl == 'messageDetails'){
        var res = await this.fetchChatData();
        if(res == true){
          setTimeout(() => {
            this.callChatData();
          }, 2000);
        }
      }
    },
    // this fun is for to call chatroom api 
    async callChatRoomApi(_v){
      if(this.getcurrentUrl == 'chat'){
        var res = await this.fetchChatRoomData({requestUrl:_v});
        if(res == true){
          setTimeout(() => {
            this.callChatRoomApi(_v);
          }, 5000);
        }
      }
    },
    // this fun is for to check tab permission for mobile responsive
    checkTabPermission(_data,_type){
      if(_type == 'agency' && _data.label =='Find Work'){
        return this.isPermissionForAgency('find-job');
      }else if(_type == 'agency' && _data.label =='My Jobs'){
        return this.isPermissionForAgency('all-Contracts');
      }else if(_type == 'agency' && _data.label =='Reports'){
        return this.isPermissionForAgency('transactionHistory');
      }else if(_type == 'client' && _data.label =='Talent'){
        return this.isPermissionForClient('talent');
      }else if(_type == 'agency' && _data.label =='Reports'){
        return this.isPermissionForAgency('transactionHistory');
      }
      return true;
    },
    toggleDropdown(item) {
      item.isDropdownOpen = !item.isDropdownOpen;
    },
    // this fun is for to open search page
    openSearch(_v) {
      if(_v == 'mobile'){
        this.toggleMobileSearch();
      }
      var rName = 'Search';
      if (this.$store.state.accountType == 'client') {
        rName = 'clientSearch';
      } else if (this.$store.state.accountType == 'agency') {
        rName = 'agencySearch';
      }
      this.$router.push({
        name: rName,
        params: { input: this.searchValue }
      }).catch(()=>{});
      this.searchValue = '';
    },
    // this fun is for to get company count
    async getCompanyCount() {
      this.UpdateBodyScrollRole({params: false});
      var res = await this.genericAPIPOSTRequest({
        requestUrl: "company/getCompanyCount",
        params: { data: this.getLoginUserIdRequest() },
      });
      this.$store.dispatch("listAccount", res);
    },
    // this fun is for to get current user details
    getCurrentUserDetails() {
      const loginData = JSON.parse(localStorage.getItem("loginUserData"));
      this.currentUser = loginData;
    },
  },
};
</script>

<style scoped>
.icon-wrapper-header {
  width: 18px;
  height: 18px;
  padding: 1px;
}

.icon-wrapper-header svg {
  width: 16px;
  height: 16px;
  color: #b0b0b0;
}

.account-dropdown-enter-active,
.account-dropdown-leave-active {
  transition: all 0.5s;
}

.account-dropdown-enter,
.account-dropdown-leave-to {
  opacity: 0;
  transform: translateY(-110px);
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
  transform: translateY(20px);
}

.list-enter,
.list-leave-to .list-leave-active {
  opacity: 0;
  transform: translateY(-30px);
}

.navbar-main {
  height: 80px;
}

@media only screen and (max-width: 1165px) {
  .navbar-main {
    height: 80px;
  }
}

.menu-items-list {
  /*padding-right: 250px;*/
  padding-right: 0px;
  margin-left: 10px;
}

@media only screen and (max-width: 1870px) {
  .menu-items-list {
    padding-right: 250px;
  }
}

@media only screen and (max-width: 1400px) {
  .menu-items-list {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 1450px) {
  .menu-items-list {
    padding-right: 0px;
  }
}
@media only screen and (max-width: 1112px) {
  .menu-items-list {
    padding-right: 0px;
    margin-left: 0px;
  }
}
@media only screen and (max-width: 1269px) {
  .toggle-switch {
    display: none;
  }
}
.text-muted{color:#000!important;}
.text-grey {
  color: #979797 !important;
}
.fz-14 {
  font-size: 14px;
}
.fz-30 {
  font-size: 26px !important;
}
.fz-16 {
  font-size: 16px;
}
fw-600 {
  font-weight: 600;
}
.w-14 {
  width: 3rem;
}
.h-14 {
  height: 3rem;
}
.badge-theme {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-transform: capitalize;
  color: #ffffff !important;
  background: #f2613c;
  border-radius: 10px;
}
.text-decoration-none {
  text-decoration: none !important;
}
.menu {
  text-decoration: none !important;
  color: #000;
}
.menu:hover {
  color: #f2613c;
}
.active-profile {
  background-color: #FFF6F4 !important;
}
</style>