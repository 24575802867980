<template>
    <div>
        <b-row class="mb-4 agency-new-design mt-68 bg-white">
            <b-col xl="12" lg="12" sm="12">
                <b-card-group deck>
                    <b-card class="px-4 py-4 br-0 shadow banner-agency-img">
                    <b-container>
                        <div class="image-change-icon d-flex align-items-center justify-content-center">
                        </div>
                        <div class="pos-rel">
                        <img :src="profileImg" class="img-fluid pos-absolute proImage" />
                        </div>
                    </b-container>
                    </b-card>
                </b-card-group>
                <b-container>
                    <b-row>
                    <b-col xl="9" lg="9" md="9" sm="12" class="mt-5">
                        <div class="d-flex align-items-center details-wrapper">
                        <h4 class="mb-0 text-black fz-40 fw-600 mr-2 left-part">{{userFullDetails.company_settings.company_name}}</h4>
                        </div>
                        <div class="d-flex align-items-center details-wrapper">
                        <vue-read-more-smooth :lines="3" class="left-part">
                            <h4 class="mb-0 text-black fz-20 fw-600 mr-2">{{tagLineVal}}</h4>
                        </vue-read-more-smooth>
                        </div>
                    </b-col>
                    <b-col xl="3" lg="3" md="3" sm="12">
                        <div class="btn-wrapper">
                        <!-- <b-button class="btn btn-outline-theme w-100 mb-2" @click="$router.push('/agency-public-profile/'+'ASS'+encodedid(login_master_id)).catch(()=>{})">View Profile as Client</b-button> -->
                        <!-- <b-button class="btn btn-outline-theme w-100 mb-2">Hire Agency</b-button> -->
                        <div class="d-flex align-items-center justify-content-center" v-if="userFullDetails.talent_id > 0">
                            <label class="badge mr-2 d-flex text-black align-items-center fz-14 px-0 fw-500 pb-0"> 
                            <img width="20" height="20" style="margin-right: 10px;" :src="getTalentImg(userFullDetails.talent_id)" />
                            <b>{{filterName(userFullDetails.talent_id)}}</b>
                            </label>
                        </div>
                        </div>
                    </b-col>
                    </b-row>
  
                     <b-row>
                        <b-col xl="9" lg="9" md="9" sm="12">
                            <div class="overview-wrapper">
                            <div class="d-flex align-items-center">
                                <h4 class="mb-2 text-black fz-32 fw-600">Overview</h4>
                                <div class="ml-auto">
                                </div>
                            </div>
                            <hr />
                            <template v-if="aboutYourselfVal == '' || aboutYourselfVal == null">
                                <p class="fz-16 fw-600 text-black">
                                No Details Available
                                </p>
                            </template>
                            <template v-else>
                                <vue-read-more-smooth :lines="6">
                                <p class="fz-16 fw-600 text-black line-break-normal" v-html="getHtmlContent(aboutYourselfVal)"></p>
                                </vue-read-more-smooth>
                               
                            </template>
                            </div>
                            <AgencyService />
            
                            <div class="skills-wrapper mt-4">
                            <div class="d-flex align-items-center">
                                <h4 class="mb-2 text-black fz-32 fw-600">Skills</h4>
                                <div class="ml-auto">
                                </div>
                            </div>
                            <hr>
                            <div class="skills-container d-flex flex-wrap" v-if="companySettingData.company_skills">
                                <b-badge
                                v-for="(catName, cids) in companySettingData.company_skills"
                                :key="cids"
                                variant="primary"
                                class="skill-badge mx-1 my-1"
                                >
                                {{ catName.skill_name }}
                                
                                </b-badge>
                            </div>
                            </div>
            
                            <div class="portfolio-wrapper mt-4">
                            <AgencyProjectNew editProject="1" pageName='agency-profile' />
                            </div>
            
                            <div class="business-manager-wrapper mt-4" v-if ="agencyManagerCount > 0">
                            <h4 class="mb-2 text-black fz-32 fw-600">Business Managers</h4>
                            <hr>
                            <b-row>
                                <b-col xl="12" lg="12" md="12" sm="12" v-if="associatedFreelancerData.length > 0 ">
                                <div class="bg-grey p-4 br-20">
                                    <template v-for="(manager,index) in associatedFreelancerData">
                                    <div class="list-managers" :key="index" v-if="manager.business == 2">
                                        <div class="d-md-flex align-items-center mb-1">
                                        <div class="img-wrapper mr-2">
                                            <img :src="manager.profile_picture_path" class="img-fluid w-100">
                                        </div>
                                        <div>
                                            <div class="name-content mr-4 d-md-flex align-items-center">
                                            <h5 class="fz-18 fw-600 text-black mr-2" @click="$router.push('/public-profile/'+'ASS'+encodedid(manager.login_master_id)).catch(()=>{})">{{manager.first_name}} {{manager.last_name}}</h5>
                                            <label class="badge mr-2 d-flex text-black align-items-center text-center fz-14 px-0 fw-500 pb-2" v-if="manager.talent_id > 0">
                                                <img width="20" height="20" style="margin-right: 10px;" :src="getTalentImg(manager.talent_id)">
                                                <b>{{filterName(manager.talent_id)}}</b>
                                            </label>
                                            </div>
                                            <div class="percent-content d-flex align-items-center">
                                            <span class="mr-5">Job Success</span>
                                            <h5 class="fz-18 fw-600 text-black pt-1 mb-0">{{manager.jobSuccessRate}}%</h5>
                                            </div>
                                        </div>
                                        </div>
                                        <!-- <b-button class="btn btn-outline-theme mb-4">Invite to Job</b-button> -->
                                    </div>
                                    </template>
                                </div>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col xl="12" lg="12" md="12" sm="12" class="text-center">
                                <b-button class="btn btn-outline-theme" v-if="associatedFreelancerData.length > 1 ">View all Business Managers</b-button>
                                </b-col>
                            </b-row>
                            </div>
            
                            <div class="agency-manager-wrapper mt-4" v-if="agencyMemberCount > 0 ">
                            <h4 class="mb-2 text-black fz-32 fw-600">Agency Managers</h4>
                            <hr>
                            <b-row v-if="agencyMemberCount > 0 ">
                                <b-col cols="12">
                                <h4 class="mt-2 mb-0 text-black fz-24 fw-600">
                                    Agency Members
                                </h4>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col xl="12" lg="12" md="12" sm="12" v-if="associatedFreelancerData.length > 0 ">
                                <div class="bg-grey p-4 br-20">
                                    <template v-for="(manager,index) in associatedFreelancerData" class="d-flex">
                                    <div class="list-managers mb-4" :key="index" v-if="manager.business == 1">
                                        <div class="d-md-flex align-items-center">
                                        <div class="img-wrapper mr-2">
                                            <img :src="manager.profile_picture_path" class="img-fluid w-100">
                                        </div>
                                        <div class="name-content mr-4">
                                            <h5 class="fz-18 fw-600 text-black" @click="$router.push('/public-profile/'+'ASS'+encodedid(manager.login_master_id)).catch(()=>{})">{{manager.first_name}} {{manager.last_name}}</h5>
                                            <span>Job Success</span>
                                        </div>
                                        <div class="percent-content">
                                            <label class="badge mr-2 d-flex text-black align-items-center fz-14 px-0 fw-500 pb-2" v-if="manager.talent_id > 0">
                                            <img width="20" height="20" style="margin-right: 10px;" :src="getTalentImg(manager.talent_id)">
                                            <b>{{filterName(manager.talent_id)}}</b>
                                            </label>
                                            <h5 class="fz-18 fw-600 text-black pt-1" >{{manager.jobSuccessRate}}%</h5>
                                        </div>
                                        </div>
                                    </div>
                                    </template>
                                </div>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col xl="12" lg="12" md="12" sm="12" class="text-center">
                                <b-button class="btn btn-outline-theme" v-if="associatedFreelancerData.length > 3 ">View all Agency Managers</b-button>
                                </b-col>
                            </b-row>
                            </div>
            
                            <div class="whistory-wrapper" >
                            <h4 class="mb-2 text-black fz-32 fw-600">Work History</h4>
                            <hr>
                            <b-tabs>
                                <!-- Completed Jobs Tab -->
                                <b-tab :title="'Completed Jobs ('+getCompletedJobList.length+')'" active>
                                <template v-if="getCompletedJobList.length > 0">
                                    <b-row >
                                    <b-col xl="12" lg="12" md="12" sm="12">
                                        <template v-for="(contract ,ind) in  getCompletedJobList">
                                        <div class="inner-content border-bottom" :key="ind" v-if="ind+1 <= competedJobView">
                                            <h5 class="fz-22 fw-600 text-black" @click="$router.push({name: $store.state.accountType+'contractDetail',path:'/'+this.$store.state.accountType+'/contract-detail/',params:{pid:contract.uniq_id,uid:'A'+encodedid(contract.freelancer_id)}}).catch(()=>{})">{{contract.job_title}}</h5>
                                            <div class="d-md-flex align-items-center">
                                            <ul class="d-flex align-items-center minus__32 mb-2">
                                                <li class="d-flex align-items-center mr-2">
                                                <b-form-rating
                                                    v-model="contract.to_rating"
                                                    stars="5"
                                                    icon-empty="star"
                                                    icon-filled="star-fill"
                                                    icon-half="star-half"
                                                    variant="warning"
                                                    show-value
                                                ></b-form-rating>
                                                </li>
                                                <li class="d-flex align-items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#666666" class="bi bi-clock-fill mr-2" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
                                                </svg>
                                                <span class="fz-16 fw-500 text-muted">{{contract.contractStart}}  {{contract.contractEnd}}</span>
                                                </li>
                                            </ul>
                                            <div class="ml-auto">
                                                <span class="fz-18 fw-600 text-black">{{'$'+contract.burget}}</span>
                                            </div>
                                            </div>
                                            <template v-if="contract.to_review != '' && contract.to_review != null">
                                            <vue-read-more-smooth :lines="4" >
                                                <p class="fz-18 fw-400 text-black" v-html="getHtmlContent(contract.to_review)"></p>
                                            </vue-read-more-smooth>
                                            </template>
                                            <template v-else>
                                            <p class="fz-18 fw-400 text-black">No feedback given.</p>
                                            </template>
                                            <!-- <p class="fz-18 fw-400 text-black">"Infotech delivered good work on this project and I enjoyed working with them and will likely have additional jobs for him in the future."</p> -->
                                        </div>
                                        </template>
                                    </b-col>
                                    <b-col xl="12" lg="12" md="12" sm="12" class="text-center" v-if="getCompletedJobList.length > competedJobView">
                                        <b-button class="btn btn-outline-theme" @click="competedJobView += 3">View More Work History</b-button>
                                    </b-col>
                                    </b-row>
                                </template>
                                <div class="whistory-wrapper" v-else>
                                    <div class="pro-img-wrapper">
                                    <img
                                        :src="$store.state.baseUrlImg+'upload/freelancer/work-history.png'"
                                        class="m-auto img-fluid"
                                    />
                                    </div>
                                    <div class="pro-content text-center">
                                    <h4 class="mt-2 mb-0 text-black fz-24 fw-600">
                                        Completed Jobs
                                    </h4>
                                    <p class="fz-16 fw-600 text-muted">
                                        Not Available
                                    </p>
                                    </div>
                                </div>
                                </b-tab>
            
                                <!-- In-Progress Tab -->
                                <b-tab :title="'In-Progress ('+getPendingJobList.length+')'">
                                <template v-if="getPendingJobList.length > 0">
                                    <b-row>
                                    <b-col xl="12" lg="12" md="12" sm="12">
                                        <template v-for="(contract ,ind) in  getPendingJobList">
                                        <div class="inner-content border-bottom" :key="ind" v-if="ind+1 <= pendingJobView">
                                            <h5 class="fz-22 fw-600 text-black">{{contract.job_title}}</h5>
                                        </div>
                                        </template>
                                    </b-col>
                                    <b-col xl="12" lg="12" md="12" sm="12" class="text-center" v-if="getPendingJobList.length > pendingJobView">
                                        <b-button class="btn btn-outline-theme"  @click="pendingJobView += 3">View More Work History</b-button>
                                    </b-col>
                                    </b-row>
                                </template>
                                <div class="whistory-wrapper" v-else>
                                    <div class="pro-img-wrapper">
                                    <img
                                        :src="$store.state.baseUrlImg+'upload/freelancer/work-history.png'"
                                        class="m-auto img-fluid"
                                    />
                                    </div>
                                    <div class="pro-content text-center">
                                    <h4 class="mt-2 mb-0 text-black fz-24 fw-600">
                                        In-progress Jobs
                                    </h4>
                                    <p class="fz-16 fw-600 text-muted">
                                        Not Available
                                    </p>
                                    </div>
                                </div>
                                </b-tab>
                            </b-tabs>
                            </div>
                        </b-col>
                        <b-col xl="3" lg="3" md="3" sm="12">
                            <div class="activity-wrapper mt-4 pt-1">
                            <hr>
                            <h4 class="mb-2 text-black fz-32 fw-600">Qapin Activity</h4>
                            <b-row>
                                <b-col xl="12" lg="12" md="12" sm="12">
                                <span class="fz-16 fw-600 text-muted">Job Success</span>
                                <h5 class="fz-24 fw-600 text-black mb-0">{{userFullDetails.jobSuccessRate}}%</h5>
                                </b-col>
                                <b-col xl="12" lg="12" md="12" sm="12">
                                <span class="fz-16 fw-600 text-muted">Total Jobs</span>
                                <h5 class="fz-24 fw-600 text-black mb-0">{{getCompletedJobList.length}}</h5>
                                </b-col>
                                <b-col xl="12" lg="12" md="12" sm="12">
                                <span class="fz-16 fw-600 text-muted">Total Earnings</span>
                                <h5 class="fz-24 fw-600 text-black mb-0">${{userFullDetails.totalEarn}}</h5>
                                </b-col>
                                <b-col xl="12" lg="12" md="12" sm="12">
                                <AgencyPriceRange :companyData='userFullDetails.company_settings'></AgencyPriceRange>
                                </b-col>
                                <b-col xl="12" lg="12" md="12" sm="12">
                                <div class="d-flex align-items-center">
                                    <div class="left-part">
                                    <span class="fz-16 fw-600 text-muted">Company Size</span>
                                    <h5 class="fz-24 fw-600 text-black mb-0" v-if="companySettingData.company_size" >{{companySettingData.company_size}} Workers</h5>
                                    </div>
                                    <div class="ml-auto d-flex align-items-center right-part">
                                    </div>
                                </div>
                                </b-col>
                                <b-col xl="12" lg="12" md="12" sm="12">
                                <div class="d-flex align-items-center">
                                    <div class="left-part">
                                    <span class="fz-16 fw-600 text-muted">Year Founded</span>
                                    <h5 class="fz-24 fw-600 text-black mb-0" v-if="companySettingData.company_founded">{{companySettingData.company_founded}}</h5>
                                    </div>
                                    <div class="ml-auto d-flex align-items-center right-part">
                                    </div>
                                </div>
                                </b-col>
                                <b-col xl="12" lg="12" md="12" sm="12">
                                <span class="fz-16 fw-600 text-muted">Qapin Member since</span>
                                <h5 class="fz-24 fw-600 text-black mb-0">{{convertdateMDY(userFullDetails.created_at)}}</h5>
                                </b-col>
                            </b-row>
                            </div>
            
                            <div class="language-wrapper mt-4 pt-1">
                            <hr>
                            <div class="d-flex align-items-center">
                                <div>
                                <h4 class="mb-2 text-black fz-32 fw-600">Languages</h4>
                                </div>
                                <div class="ml-auto d-flex align-items-center">
                                
                                </div>
                            </div>
                            <b-row v-if="userFullDetails.language_arr.length > 0">
                                <b-col xl="12" lg="12" md="12" sm="12" v-if="userFullDetails.language_arr.length > 0">
                                    <div class="d-flex align-items-center" v-for="(lang,index) in userFullDetails.language_arr" :key="index">
                                        <div>
                                        <h5 class="fz-20 fw-600 text-black mb-2">{{lang}} : <span class="fz-20 fw-600 text-muted">{{_proficiency(userFullDetails.proficiency_id[index])}}</span></h5>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                            </div>
            
                            <div class="location-wrapper mt-4 pt-1">
                            <hr>
                            <div class="d-flex align-items-center">
                                <div>
                                <h4 class="mb-2 text-black fz-32 fw-600">Office Location</h4>
                                </div>
                                <div class="ml-auto d-flex align-items-center">
                               
                                
                                </div>
                            </div>
                            <b-row>
                                <b-col xl="12" lg="12" md="12" sm="12">
                                <div class="d-flex align-items-normal mb-2" v-if="locationData.branch_primary!= undefined && locationData.branch_primary!=''">
                                    <div class="left-part d-flex align-items-normal">
                                    <div class="img-wrapper mr-2">
                                        <img src="web/pin.png" class="img-fluid">
                                    </div>
                                    <div class="address">
                                        <h5 class="fz-20 fw-600 mb-0">{{locationData.branch_primary.city_name}}, {{(locationData.branch_primary.country_name!='')?locationData.branch_primary.country_name:''}}</h5>
                                        <span class="fz-14 text-muted fw-400">6:07PM GMT+5:30 Primary location</span>
                                    </div>
                                    </div>
                                    <div class="ml-auto d-flex align-items-center right-part">
                                    
                                    </div>
                                </div>
                                <template v-if="locationData.head != undefined && locationData.head.length > 0">
                                    <div class="d-flex align-items-normal" v-for="(loc,index) in locationData.head" :key="index">
                                    <div class="left-part d-flex align-items-normal">
                                        <div class="img-wrapper mr-2">
                                        <!-- <img src="../../web/pin.png" class="img-fluid"> -->
                                        <!-- <img src="../../assets/logo" class="img-fluid"> -->
                                        </div>
                                        <div class="address">
                                        <h5 class="fz-20 fw-600 mb-0">{{loc.city_name}}, {{(loc.country_name!='')?loc.country_name:''}}</h5>
                                        <span class="fz-14 text-muted fw-400">5:07PM GMT+5:30</span>
                                        </div>
                                    </div>
                                    <div class="ml-auto d-flex align-items-center right-part">
                                    </div>
                                    </div>
                                </template>
                                </b-col>
                            </b-row>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    import VueReadMoreSmooth from "vue-read-more-smooth";
    import { mapActions } from "vuex";
    import { validationMixin } from "vuelidate";
    import { required } from "vuelidate/lib/validators";
    import AgencyService from "../../components/profile/agencyService.vue";
    import AgencyPriceRange from "../../components/profile/agencyPriceRange.vue"; 
    import AgencyProjectNew from "../../components/profile/agency-project-new.vue";         
    // import ClockSmallIcon from "../../components/icons/ClockSmall_Icon.vue";
    // import mapIcon from "../../components/icons/map_Icon.vue";
    // import CertificationList from "@/components/public-profile/public-certification.vue";
    // import workHistory from "@/components/public-profile/work-history.vue";
    // import AgencyProject from "../../components/profile/agency-project.vue"; 

    export default {
        mixins: [validationMixin],
        validations: {
            addFlagging: {
                flagging_id: { required },
                report_content: { required },
            },
            addDecline: {
                decline_reason_id: { required },
                decline_reason: { required },
            },
        },
        components: {
            VueReadMoreSmooth,
            AgencyService,
            AgencyPriceRange,
            AgencyProjectNew,
            // ClockSmallIcon,
            // workHistory,
            // AgencyProject,
            // CertificationList
            // mapIcon,
        },
        data() {
            return {
                companySettingData: [],
                locationData: [],
                timeClock: "",
                value: new Date().toISOString().substr(0, 10),
                striped: true,
                max: 0,
                job_id: "",
                user_id: "",
                messageData: {
                    jobDetails: "",
                    userDetails: {
                        company_settings:"",                        
                    },
                    proposalDetails: "",
                    similarjobHistory: [],
                    jobUnderprocess: [],
                    jobHistory: [],
                },
                jobtitle: "",
                jobId: "",
                flaggingoptions: [],
                addFlagging: {
                    flagging_id: "",
                    report_content: "",
                },
                addDecline: {
                    decline_reason_id: "",
                    decline_reason: "",
                    application_status: 8,
                }, 
                workExprinceList: [],
                serviceData:[],
                getUserCertificationListArr: [],
                associatedFreelancerData:[],
                agencyManagerCount:0,
                agencyMemberCount:0,
                talentQualilty:[],
                // NEW STATE
                profileImg:'',
                bannerImg:'',
                userFullDetails:{
                    language_arr:{},
                    company_settings:{
                        company_name:'',
                    }
                },
                tagLineVal:'',
                aboutYourselfVal:'',
                getCompletedJobList:[],
                getPendingJobList:[],
            };
        },
        methods: {
            ...mapActions(["genericAPIPOSTRequest","fetchTalentList"]),
            // this fun is for to get filter name
            filterName(_i){
            var name;
            this.talentQualilty.map(data=>{
                if(data.talent_id == _i){
                name = data.talent_title;
                return false;
                }
            });
            return name;
            },
            // this fun is for to get the talent tag 
            getTalentImg(_i){
            var name;

            this.talentQualilty.map(data=>{
                if(data.talent_id==_i){
                name = data.img
                return false;
                }
            });
            return name;
            },
            // this fun is for to get the data of associated with agency
            async getAssociatedFreelancer() {
                var res =  await this.genericAPIPOSTRequest({
                    requestUrl: "associatedFreelancer",
                    params: {},
                });
                if(res){
                this.associatedFreelancerData = res;
                this.associatedFreelancerData.filter(data=>{
                    if(data.business == 2){
                        this.agencyManagerCount++;
                    }else if(data.business != 2){
                        this.agencyMemberCount++;
                    }
                });
                }
                var tatentdata = await this.fetchTalentList({params:{"talent_type":"Badges"}});
                if(tatentdata.data.status == true){
                    this.talentQualilty = tatentdata.data.result;
                }
            },
            async getUserCertificationList() {
                var res = await this.genericAPIPOSTRequest({
                    requestUrl: "user/getUserCertificationList",
                    params: { user_id: this.user_id },
                });
                this.getUserCertificationListArr = res;
            },
            async getWorkExprince() {
                var res = await this.genericAPIPOSTRequest({
                    requestUrl: "user/getWorkExprince",
                    params: { user_id: this.user_id },
                });
                this.workExprinceList = res;
            },
            async getCompanyService(){
                    this.serviceData =  await this.genericAPIPOSTRequest({
                            requestUrl: "getCompanyService",
                            params: { 
                                      'data':this.getCurrentUserIdRequest(),
                                      'user_id':this.user_id 
                                    },
                    }); 
            },
            async getCertificationType(index) {
                var res = await this.genericAPIPOSTRequest({
                    requestUrl: "user/getCertificationType/" + this.certification.certification_list[index].certification_id,
                    params: {},
                });
                this.$set(this.certificatetype, index, res);
            },
            startTime() {
                var timezone_name = this.userFullDetails.timezone_name;
                if (timezone_name != "" && timezone_name != undefined) {
                    let options = {
                            timeZone: timezone_name,
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                        },
                        formatter = new Intl.DateTimeFormat([], options);
                    this.timeClock = formatter.format(new Date());
                } else {
                    const today = new Date();
                    let h = today.getHours();
                    let m = today.getMinutes();
                    let s = today.getSeconds();
                    m = this.checkTime(m);
                    s = this.checkTime(s);
                    this.timeClock = h + ":" + m + ":" + s;
                }
                setTimeout(this.startTime, 1000);
            },
            checkTime(i) {
                if (i < 10) {
                    i = "0" + i;
                } // add zero in front of numbers < 10
                return i;
            },
            // this fun is for to add flag on user
            async submitDeclineData() {
                this.$v.addDecline.$touch();
                if (!this.$v.addDecline.$invalid) {
                    this.addDecline.user_id = this.userFullDetails.user_id;
                    this.addDecline.jobs_id = this.messageData.jobDetails.jobs_id;
                    var res = await this.genericAPIPOSTRequest({
                        requestUrl: "updateJobApply",
                        params: this.addDecline,
                    });
                    if (res) {
                        this.$toastr.s("Proposal Decline Done");
                        this.$bvModal.hide("decline-modal");
                    }
                }
            },
            // this fun is for to add flag on user
            async submitFlaggingData() {
                this.$v.addFlagging.$touch();
                if (!this.$v.addFlagging.$invalid) {
                    this.addFlagging.report_user_id = this.userFullDetails.user_id;
                    var res = await this.genericAPIPOSTRequest({
                        requestUrl: "addUserflag",
                        params: this.addFlagging,
                    });
                    if (res) {
                        this.$toastr.s("Flag as inappropriate Done");
                        this.$bvModal.hide("flagging-modal");
                    }
                }
            },
            async messageModel() {
                var userDetails = await this.genericAPIPOSTRequest({
                    requestUrl: "user/getUserDetails",
                    params: { 'user_id': this.$route.params.uid , usertype : "agency" },
                });
                if (userDetails) {
                    this.userFullDetails = userDetails;
                    this.companySettingData = userDetails.company_settings;
                    this.profileImg = this.$store.state.baseUrlImg+'/upload/profile_image/'+userDetails.profile_picture_path;
                    this.bannerImg = this.$store.state.baseUrlImg+'/upload/banner_image/'+userDetails.company_settings.company_banner_path;
                }
                
                var jobUnderprocess = await this.genericAPIPOSTRequest({
                    requestUrl: "getJobdataList",
                    params: { user_id: this.user_id, filter: 2, type: 'agency' },
                });
                if (jobUnderprocess) {
                    this.messageData.jobUnderprocess = jobUnderprocess;
                }
                var jobHistory = await this.genericAPIPOSTRequest({
                    requestUrl: "getJobdataList",
                    params: { user_id: this.user_id, filter: 6, type: 'agency' },
                });
                if (jobHistory) {
                    this.messageData.jobHistory = jobHistory;
                }
            },
             
            //this fun is for to like the post
            async proposalLike(_i) {
                var res = await this.genericAPIPOSTRequest({
                    requestUrl: "job/addHire",
                    params: {
                        jobData: {
                            user_id: this.userFullDetails.user_id,
                            jobs_id: this.jobId,
                        },
                        action: _i,
                    },
                });
                if (res) {
                    this.messageData.proposalDetails.application_status = 3;
                    //   this.$refs.mysidebar.hide();
                    this.$toastr.s("Proposal Shortlisted Successfully");
                } else {
                    this.$toastr.e("Proposal Like Unsuccessfull");
                }
            },

            //this fun is for to dislike the post
            async proposalDislike(_v, _i) {
                var res = await this.genericAPIPOSTRequest({
                    requestUrl: "job/addHire",
                    params: { jobData: _v, action: _i },
                });
                if (res) {
                    this.$toastr.s("Proposal Archived Successfully");
                } else {
                    this.$toastr.e("Proposal DisLike Unsuccessfull");
                }
            },
            // this fun is for to get location data
            async getLocation() {
                var res = await this.genericAPIPOSTRequest({
                    requestUrl: "getCompanyLocation",
                    params: { user_id: this.user_id },
                });
                if (res) {
                    this.locationData = res;
                }
            },
        },
        computed: {
            flagflagging_id() {
                const errors = [];
                if (!this.$v.addFlagging.flagging_id.$dirty) return errors;
                !this.$v.addFlagging.flagging_id.required && errors.push("Please Select Flag is required");
                return errors;
            },
            flagreport_content() {
                const errors = [];
                if (!this.$v.addFlagging.report_content.$dirty) return errors;
                !this.$v.addFlagging.report_content.required && errors.push("Description is required");
                return errors;
            }, 
            // function to total burget amount
            totalEarning() {
                var amount = 0;
                this.messageData.similarjobHistory.map((data) => {
                    amount += parseFloat(data.cost);
                });
                return amount;
            },
        },
        mounted() {
            var userId = this.$route.params.uid;
            userId = userId.split("ASS");
            this.user_id = this.decodeId(userId[1]);
            this.messageModel();
            this.startTime();
            this.getAssociatedFreelancer();
            this.getWorkExprince();
            this.getLocation();
            this.getCompanyService();
            this.getUserCertificationList();
        },
    };
</script>
<style>
    .msgdetails .card {
        border: 1px solid rgba(0, 0, 0, 0.125) !important;
    }
    .msgdetails .card-body {
        padding: 12px;
    }
    .msgdetails .w-80 {
        width: 80% !important;
    }
    .msgdetails .tabs-wrapper {
        min-height: 327px;
    }
    .msgdetails .tags-wrap ul {
        margin-left: -32px;
    }
    .msgdetails .img-wrapper {
        width: 95px;
        height: 100px;
        border-radius: 6px;
        position: relative;
        box-shadow: 0px 0px 12px -2px rgb(0 0 0 / 20%);
        padding: 5px;
    }
    .msgdetails .img-wrapper img {
        width: 100%;
        height: 100%;
        border-radius: 0px;
    }
    .msgdetails .img-wrapper .dot {
        position: absolute;
        width: 15px;
        height: 15px;
        background-color: #48c048;
        border-radius: 50%;
        top: 5px;
        right: 22px;
        border: 2px solid #d0caca;
    }
    .msgdetails .img-wrap {
        width: 10%;
    }
    .msgdetails .minus-margin {
        margin-left: -32px;
    }
    .justify-content-space-around {
        justify-content: space-around;
    }
    .width-80 {
        width: 80%;
    }
    .t-0 {
        top: 0px !important;
    }
    .t-0.dropdown ul {
        padding-left: 0px !important;
    }
    .custom-margin {
        margin-left: -32px;
    }
    .width-90 {
        width: 90%;
    }
    .work-history-wrapper .nav-tabs .nav-link {
        color: #666666;
        border: 0;
        padding-left: 0;
    }
    .work-history-wrapper .nav-tabs .nav-link.active {
        color: #f2613d;
        border: 0;
        border-bottom: 2px solid #f2613d;
        padding-left: 0;
    }
    .work-history-wrapper .nav-tabs {
        border: 0px;
        margin-left: -32px;
    }
    #industry-modal .search-bar input {
        border: 0px !important;
    }
    #industry-modal .search-bar .tt-menu {
        border-radius: 4px !important;
        padding: 0px 0px !important;
    }
    #industry-modal .search-bar .tt-menu fieldset {
        padding: 0px 0px 0px 12px !important;
    }
    #industry-modal .custom-control-label:before {
        display: none !important;
    }
    .minus-margin-37 {
        margin-left: -37px;
    }
</style>
